import {createSlice} from "@reduxjs/toolkit";

const cvReducer = createSlice({
    name: 'cv',
    initialState: {
       backgroundImage: '',
       mainPhoto: '',
       fullName: 'Tudor Ursachi',
       location: 'Moldova',
       job: 'Entrepreneur, Startup Investor and Business Trainer'
    },

    reducers: {
        changeBackground: (state, action) => {
            state.backgroundImage = action.payload.backgroundImage
        },

        editPersonalData: (state, action) => {
            state.mainPhoto = action.payload.mainPhoto;
            state.fullName = action.payload.fullName;
            state.job = action.payload.job;
            state.location = action.payload.location;
        }
    }
});

export default cvReducer.reducer;
export const { changeBackground, editPersonalData } = cvReducer.actions
