import {createSlice} from "@reduxjs/toolkit";
import {getInitiativeCompanyActions, initiativeCompanyEditActions} from "../actions/initiativeCompanyApi";

const initiativeCompanyReducer = createSlice({
    name: 'initiative',
    initialState: {
        initiative: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInitiativeCompanyActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(getInitiativeCompanyActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.initiative = payload
                }
            })

            .addCase(getInitiativeCompanyActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(initiativeCompanyEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(initiativeCompanyEditActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.initiative.map(item => {
                        if(item.Id === payload.data.Id) {
                            item.Title = payload.data.Title;
                            item.Image = payload.data.Image;
                            item.Description = payload.data.Description
                        }
                    });
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(initiativeCompanyEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })
    }
});

export default initiativeCompanyReducer.reducer;
