import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import 'react-dropdown/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faChartArea,
} from '@fortawesome/free-solid-svg-icons'
import './HomeFilter.scss'
import { useTranslation } from 'react-i18next'

const HomeFilter = () => {
  const navigate = useNavigate()
  const [job, setJob] = useState('')
  const [region, setRegion] = useState()
  const [available, setAvailable] = useState()
  const [profile, setProfile] = useState()
  const [allRegion, setAllRegion] = useState([])
  const { t } = useTranslation()

  const location = [
    'Cahul',
    'Cantemir',
    'Comrat',
    'Cimsilia',
    'Hancesti',
    'Chisinau',
    'Balti',
  ]

  const availableOptions = [
    {
      value: '',
      label: 'Availabilities    +',
    },
    {
      value: 0,
      label: 'Remote',
    },
    {
      value: 1,
      label: 'part-time',
    },
    {
      value: 2,
      label: 'full-time',
    },
    {
      value: 3,
      label: 'flexible',
    },
    {
      value: 4,
      label: 'cumul',
    },
    {
      value: 5,
      label: 'oricare',
    },
  ]
  const profileOptions = [
    {
      value: '',
      label: 'Select the profile    +',
    },
    {
      value: 0,
      label: 'Job',
    },
    {
      value: 1,
      label: 'CV-uri',
    },
    {
      value: 2,
      label: 'Companii',
    },
  ]

  const handleSubmit = () => {
    return navigate('/vacancies', {
      state: {
        job: job,
        available: available,
        region: region,
        profile: profile,
      },
    })
  }

  return (
    <>
      <section className="container grid grid-cols-1 md:grid-cols-2 home-filter">
        <input
          type="text"
          name="job"
          value={job}
          onChange={(e) => setJob(e.target.value)}
          placeholder="Search by profession  +"
          className="filter-input"
        />
        <select
          id="available"
          name="available"
          autoComplete="available"
          onChange={(selected) => setAvailable(selected.target.value)}
          className="filter-input"
        >
          {availableOptions.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>
        <select
          id="profile"
          name="profile"
          autoComplete="profile"
          onChange={(selected) => setProfile(selected.target.value)}
          className="filter-input"
        >
          {profileOptions.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>
        <select
          id="region"
          name="region"
          autoComplete="region"
          onChange={(selected) => setRegion(selected.target.value)}
          className="filter-input"
        >
          <option value="">Select the location +</option>
          {location.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
        <button
          type="submit"
          name="button"
          onClick={handleSubmit}
          className="btn"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          {t('Home.btnSearch')}
        </button>
        <Link to={'/find-a-job'} className="btn btn-reverse">
          <FontAwesomeIcon icon={faChartArea} /> {t('Home.btnJobMyArea')}
        </Link>
      </section>
    </>
  )
}

export default HomeFilter
