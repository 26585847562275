import {createSlice} from "@reduxjs/toolkit";
import {howGetWorkActions, howEditWorkActions} from "../actions/howWorkApi";

const howWorkReducer = createSlice({
    name: 'howWork',
    initialState: {
        howWork: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(howGetWorkActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(howGetWorkActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.howWork = payload
                }
            })

            .addCase(howGetWorkActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(howEditWorkActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(howEditWorkActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.howWork.map(item => {
                        if(item.Id === payload.data.Id) {
                            item.Title = payload.data.Title;
                            item.Order = payload.data.Order;
                            item.Image = payload.data.Image;
                            item.Description = payload.data.Description
                        }
                    });
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(howEditWorkActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })
    }
});

export default howWorkReducer.reducer;
// export const { addAllEducation, editEducationItem, addNewEducation, removeEducation } = blogReducer.actions
