import React, {forwardRef, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {faCalendarDays, faSave, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {deleteEducationActions, editEducationActions} from "../../redux/actions/educationApi";
import {editEducationItem} from "../../redux/reducer/educationReducer";
import Image from "../Image/Image";

const EducationEdit = (props) => {
    const dispatch = useDispatch();
    const {education, editItem, editButtonItem} = props;
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const [typeExperience, setTypeExperience] = useState(education.TitleInstitution);
    const [titleSpeciality, setTitleSpeciality] = useState(education.Speciality);
    const [secondTitleSpeciality, setSecondTitleSpeciality] = useState(education.TitleCertificate);
    const [grade, setGrade] = useState(education.Domain);
    const [dataTo, setDataTo] = useState(education.StartDate !== '' ? new Date(education.StartDate) : '');
    const [dataFrom, setDataFrom] = useState(education.EndDate !== '' ? new Date(education.EndDate) :'');
    const [image, setImage] = useState(education.Logo);

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const handleRemoveEducation = () => {
        editItem(false);
        editButtonItem(false);
        dispatch(deleteEducationActions({
            Id: education.Id,
            token: token
        }));
    }

    const handleUpdateEducation = () => {
        const data = {
            Id: education.Id,
            data: {
                titleInstitution: typeExperience,
                speciality: titleSpeciality,
                titleCertificate: secondTitleSpeciality,
                domain: grade,
                dateStart: dataTo,
                dateEnd: dataFrom,
                logo: image
            },
            token: token
        }
        editItem(false);
        editButtonItem(false);
        dispatch(editEducationActions(data));
    }


    const StyleDataComponent = forwardRef(({ value, onClick }, ref) => (
        <span className="data-input" onClick={onClick} ref={ref}>
          <span className="data">{value} <FontAwesomeIcon icon={faCalendarDays} /></span>
       </span>
    ));

    return (
        <div className="edit-form">
            <div className="image-edit">
                <Image value={image} onChange={(result) => setImage(result)} withBase64={true}/>
            </div>
           <input type="text" value={typeExperience} placeholder="Institutie de invatamant" onChange={(e) => setTypeExperience(e.target.value)} />
           <input type="text" value={titleSpeciality} placeholder="Specialitatea" onChange={(e) => setTitleSpeciality(e.target.value)} />
           <input type="text" value={secondTitleSpeciality} placeholder="Domeniu de Studii" onChange={(e) => setSecondTitleSpeciality(e.target.value)} />
           <input type="text" value={grade} placeholder="Diploma" onChange={(e) => setGrade(e.target.value)} />

            <div className="dataContainer">
                <DatePicker
                    selected={dataTo}
                    dateFormat="MM.dd.yyyy"
                    onChange={(date) => setDataTo(date)}
                    customInput={<StyleDataComponent />}
                    isClearable
                />

                <DatePicker
                    selected={dataFrom}
                    dateFormat="MM.dd.yyyy"
                    onChange={(date) => setDataFrom(date)}
                    isClearable
                    customInput={<StyleDataComponent />}
                />
            </div>

            <div className="actions">
                <button onClick={ handleRemoveEducation }><FontAwesomeIcon icon={faTrash} />Remove</button>
                <button onClick={() => { editItem(false); editButtonItem(false); }}><FontAwesomeIcon icon={faXmark} />Cancel</button>
                <button onClick={ handleUpdateEducation }><FontAwesomeIcon icon={faSave} />Save</button>
            </div>
        </div>
    )
}

export default EducationEdit;
