import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCategory, editCategory, addCategory, deleteCategory} from "../../service/CategoryService";

export const categoryGetCategoriesActions = createAsyncThunk(
    'category/categoryGetCategoriesActions',
    async (params = {}) => {
        try {
            return await getCategory(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const categoryEditActions = createAsyncThunk(
    'category/categoryEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editCategory(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)

export const categoryCreateActions = createAsyncThunk(
    'category/categoryCreateActions',
    async({data, token},) => {
        try{
            return await addCategory(data, token).then(response => response.data.data)
        } catch (error) {
            return error.message
        }
    }
)

export const categoryDeleteActions = createAsyncThunk(
    'category/categoryDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteCategory(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
