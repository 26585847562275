import {createAsyncThunk} from "@reduxjs/toolkit";
import {addJob, deleteJob, editJob, getJobs} from "../../service/JobService";

export const jobGetJobsActions = createAsyncThunk(
    'job/jobGetJobsActions',
    async (params = {}) => {
        try {
            return await getJobs(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const jobEditActions = createAsyncThunk(
    'job/jobEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editJob(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)

export const jobCreateActions = createAsyncThunk(
    'job/jobCreateActions',
    async({data, token},) => {
        try{
            return await addJob(data, token).then(response => response.data.data)
        } catch (error) {
            return error.message
        }
    }
)

export const jobDeleteActions = createAsyncThunk(
    'job/jobDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteJob(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
