import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

const EditCurrentQuestion = ({question, resultEdited}) => {
    const questionId = question.id;
    const [questionTitle, setQuestionTitle] = useState(question.question);
    const [answerFirst, setAnswerFirst] = useState(question.answers[0]);
    const [answerSecond, setAnswerSecond] = useState(question.answers[1]);
    const [answerThird, setAnswerThird] = useState(question.answers[2]);
    const [answerFourth, setAnswerFourth] = useState(question.answers[3]);
    const [selectCorrectAnswer, setSelectCorrectAnswer] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(question.correctAnswers === 'answer' ? false : question.correctAnswers);
    const [editTitleQuestion, setEditTitleQuestion] = useState(false);
    const [editFirstAnswer, setEditFirstAnswer] = useState(false);
    const [editSecondAnswer, setEditSecondAnswer] = useState(false);
    const [editThirdAnswer, setEditThirdAnswer] = useState(false);
    const [editFourthAnswer, setEditFourthAnswer] = useState(false);

    const handleChangeTitle = (e) => {
      setQuestionTitle(e.target.value);
    }

    const handleSelectCorrectAnswers = (value, fieldEdit) => {
        if(fieldEdit === false){
          setSelectCorrectAnswer(true);
          setCorrectAnswer(value);

            resultEdited({...question, ...{
                    'id': questionId,
                    'question':questionTitle,
                    'answers': [answerFirst,answerSecond,answerThird,answerFourth],
                    'correctAnswers': value
            }});
        }
    }

    const handleKeyDown = (e) => {
        if(e.keyCode === 13){
            setEditTitleQuestion(false);
            setEditFirstAnswer(false);
            setEditSecondAnswer(false);
            setEditThirdAnswer(false);
            setEditFourthAnswer(false);

            resultEdited({...question, ...{
                'id': questionId,
                'question':questionTitle,
                'answers': [answerFirst,answerSecond,answerThird,answerFourth],
                'correctAnswers': correctAnswer
            }});
        }
    }

    return (
        <section className="question-section">
            <div className="head-question">
              <span className={editTitleQuestion === false ? "head-question-title disabled" : "head-question-title"}>
                <span className="nrQuestion">{question.id}</span>
                 <input type="text" placeholder="Care este intrebarea?" value={questionTitle} onChange={e => handleChangeTitle(e)} onKeyDown={handleKeyDown}/>
                 <span className="edit-question" onClick={() => setEditTitleQuestion(true)}>
                    <FontAwesomeIcon icon={faPencil} />
                 </span>
              </span>
            </div>
            <div className="question-content">
                 <div className={editFirstAnswer === false ? "answer disabled" : "answer"} onClick={() => {handleSelectCorrectAnswers(answerFirst, editSecondAnswer)}} style={selectCorrectAnswer !== false && (correctAnswer === answerFirst && correctAnswer !== '') ? {border: "1px solid #2EC086"} : {}}>
                     <input type="text" placeholder="answer" value={answerFirst} onChange={(e) => setAnswerFirst(e.target.value)} onKeyDown={handleKeyDown}/>
                     <span className="edit-question" onClick={() => setEditFirstAnswer(true)}>
                       <FontAwesomeIcon icon={faPencil} />
                     </span>
                 </div>
                 <div className={editSecondAnswer === false ? "answer disabled" : "answer"} onClick={() => {handleSelectCorrectAnswers(answerSecond, editSecondAnswer)}} style={selectCorrectAnswer !== false && (correctAnswer === answerSecond && correctAnswer !== '') ? {border: "1px solid #2EC086"} : {}}>
                     <input type="text" placeholder="answer" value={answerSecond} onChange={(e) => setAnswerSecond(e.target.value)} onKeyDown={handleKeyDown}/>
                     <span className="edit-question" onClick={() => setEditSecondAnswer(true)}>
                       <FontAwesomeIcon icon={faPencil} />
                     </span>
                 </div>
                 <div className={editThirdAnswer === false ? "answer disabled" : "answer"} onClick={() => {handleSelectCorrectAnswers(answerThird, editSecondAnswer)}} style={selectCorrectAnswer !== false && (correctAnswer === answerThird && correctAnswer !== '') ? {border: "1px solid #2EC086"} : {}}>
                     <input type="text" placeholder="answer" value={answerThird} onChange={(e) => setAnswerThird(e.target.value) } onKeyDown={handleKeyDown}/>
                     <span className="edit-question" onClick={() => setEditThirdAnswer(true)}>
                       <FontAwesomeIcon icon={faPencil} />
                     </span>
                 </div>
                 <div className={editFourthAnswer === false ? "answer disabled" : "answer"} onClick={() => {handleSelectCorrectAnswers(answerFourth, editSecondAnswer)}} style={selectCorrectAnswer !== false && (correctAnswer === answerFourth && correctAnswer !== '')? {border: "1px solid #2EC086"} : {}}>
                     <input type="text" placeholder="answer" value={answerFourth} onChange={(e) => setAnswerFourth(e.target.value) } onKeyDown={handleKeyDown}/>
                     <span className="edit-question" onClick={() => setEditFourthAnswer(true)}>
                       <FontAwesomeIcon icon={faPencil} />
                     </span>
                 </div>
            </div>
        </section>
    );
}

export default EditCurrentQuestion;
