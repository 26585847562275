import {createSlice} from "@reduxjs/toolkit";

const appliedJobsReducer = createSlice({
   name: 'appliedJobs',
   initialState: {
       category: [
           {
               id: 1,
               titleCategory: 'Vanzator Consultant',
               CV: [
                   {
                       idCv: 1,
                       name: 'Iulia Board',
                       data: '03.06.2020 14:30',
                       visited: false,
                       interesting: 1,
                       status: 'oferta',
                   },
                   {
                       idCv: 2,
                       name: 'Dumitru Radu',
                       data: '15.10.2022 10:30',
                       visited: true,
                       interesting: 0,
                       status: 'refuzuri',
                   },
                   {
                       idCv: 3,
                       name: 'Dumitru Radu',
                       data: '15.10.2022 10:30',
                       visited: true,
                       interesting: 0,
                       status: 'acceptari',
                   },
                   {
                       idCv: 4,
                       name: 'Dumitru Radu',
                       data: '15.10.2022 10:30',
                       visited: true,
                       interesting: 0,
                       status: 'angajari',
                   }
               ]
           },
           {
               id: 2,
               titleCategory: 'Lacatus',
               CV: [
                   {
                       idCv: 5,
                       name: 'Iulia Board',
                       data: '03.06.2020 14:30',
                       visited: false,
                       interesting: 1,
                       status: 'oferta',
                   },
                   {
                       idCv: 6,
                       name: 'Dumitru Radu',
                       data: '15.10.2022 10:30',
                       visited: true,
                       interesting: 0,
                       status: 'refuzuri',
                   },
                   {
                       idCv: 7,
                       name: 'Dumitru Radu',
                       data: '15.10.2022 10:30',
                       visited: true,
                       interesting: 0,
                       status: 'acceptari',
                   },
                   {
                       idCv: 8,
                       name: 'Dumitru Radu',
                       data: '15.10.2022 10:30',
                       visited: true,
                       interesting: 0,
                       status: 'angajari',
                   }
               ]
           }
       ]
   },
    reducers: {

    }
});

export default appliedJobsReducer.reducer;
export const {} = appliedJobsReducer.actions
