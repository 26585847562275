import {createAsyncThunk} from "@reduxjs/toolkit";
import authService from "../../service/AuthService";
import {userInfoActions} from "./userApi";

export const loginActions = createAsyncThunk(
    'auth/loginActions',
    async (params, thunkAPI) => {
        const { dispatch } = thunkAPI;
        try {
            return await authService.login(params).then(response => {
                if (response?.status === 200) {
                    dispatch(userInfoActions(response.data?.access_token));
                    return response.data;
                } else {
                    return response.data?.error_message;
                }
            }).catch(error => error);
        } catch (error) {
            return error.message;
        }
    }
);

export const registerActions = createAsyncThunk(
    'auth/registerActions',
    async (params) => {
        const response = await authService.register(params);
        return response.data;
    }
);
