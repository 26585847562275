import React from 'react'
import TakeSkillComponent from "../../TakeSkillQuizComponent/TakeSkillQuiz";

const TakeAskillQuiz = () => {
    return (
        <TakeSkillComponent />
    )
}

export default TakeAskillQuiz;
