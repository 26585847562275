import React from 'react';
import { faUsers, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './activity.scss';

const ActivityComponent = () => {
    return (
        <section className="activity">
           <div className="head-activity">
               <div className="head-activity-info">
                   <span className="title">Activity</span>
                   <span className="followers"><FontAwesomeIcon icon={faUsers} /> 1,400 followers</span>
               </div>
               <span className="activity-button">Start a post</span>
           </div>

           <div className="activity-content">
               <span className="activity-title">You haven’t posted lately</span>
               <span className="activity-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</span>
           </div>
           <div className="activity-action">
               <span>Show all activities <span><FontAwesomeIcon icon={faChevronRight} /></span></span>
           </div>
        </section>
    )
}

export default ActivityComponent;
