import {apiClient} from "./index";

export const getCompanies = (params) => {
    return apiClient.get('/api/company',{params}).then(response => response.data);
}

export const addCompany = ({data, token}) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/company',data);
}

export const editCompany = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/company/${Id}?_method=PUT`,data.data);
}

export const deleteCompany = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/company/${Id}`);
}
