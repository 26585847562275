import {createAsyncThunk} from "@reduxjs/toolkit";
import {getOpportunities, editOpportunity, addOpportunity, deleteOpportunity} from "../../service/OpportunityService";

export const opportunityGetOpportunitiesActions = createAsyncThunk(
    'opportunity/opportunityGetOpportunitiesActions',
    async (params= {}) => {
        try {
            return await getOpportunities(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const opportunityEditActions = createAsyncThunk(
    'opportunity/opportunityEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editOpportunity(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)

export const opportunityCreateActions = createAsyncThunk(
    'opportunity/opportunityCreateActions',
    async({data, token},) => {
        try{
            return await addOpportunity(data, token).then(response => response.data.data)
        } catch (error) {
            return error.message
        }
    }
)

export const opportunityDeleteActions = createAsyncThunk(
    'opportunity/opportunityDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteOpportunity(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
