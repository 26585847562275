import React, {useEffect, useMemo, useState} from 'react';
import QuizItem from "../../QuizzComponent/QuizItem";
import {useSelector} from "react-redux";
import Pagination from "../../Pagination/Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import AddQuizz from "./AddQuizz";
import './my-quiz.scss';
import EditQuizz from "./EditQuizz";


const CreateQuiz = (props) => {
    const quizList = useSelector(state => state.quizz.quizList);
    const [currentPage, setCurrentPage] = useState(1);
    const [addQuizz, setAddQuizz] = useState(false);
    const [editQuizz, setEditQuizz] = useState(false);

    const handleCloseQuizz = (value) => {
        setAddQuizz(value);
    }

    const handleEditQuizz = (value) => {
        setEditQuizz(value);
    }

    const handleAddQuizz = (value) => {
        setAddQuizz(value);
    }

    const pageSize = 3;

    const quizData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return quizList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, quizList]);


    return (
        <section className="create-quiz-sections">
            {
                editQuizz ? <></> :
                <div className="action-create-quiz-header">
                    <button className="create-quiz-button" onClick={() => handleAddQuizz(true)}><FontAwesomeIcon icon={faPlus} />Adauga Test</button>
                </div>
            }

            {addQuizz ? <AddQuizz closeQuizz={handleCloseQuizz} /> : <></>}

            {quizData.map(quiz => <QuizItem key={quiz.idCategory} addQuiz={handleAddQuizz} closeQuizz={handleCloseQuizz} quiz={quiz} editQuizz={handleEditQuizz} />)}

            <Pagination
                currentPage={currentPage}
                totalCount={quizList.length}
                pageSize={pageSize}
                template="template-two"
                onPageChange={page => setCurrentPage(page)}
            />
        </section>
    );
}

export default CreateQuiz;
