import {apiClient} from "./index";

export const getCards = (params) => {
    return apiClient.get('/api/card',{params}).then(response => response.data);
}

export const addCard = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/card',data);
}

export const editCard = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/card/${Id}?_method=PUT`,data.data);
}

export const deleteCard = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/card/${Id}`);
}
