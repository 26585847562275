import React from 'react';
import InterviewReadyItem from "components/Profiles/InterviewReady/InterviewReadyItem";
import BasicTable from "components/TableComponent/BasicTable";

const InterviewReady = () => {
    const data = [
        {
            id: 1,
            name: 'Radu',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            email: 'radu.rata@gmail.com',
            data: '12.12.2023'
        },
        {
            id: 2,
            name: 'Nicolae',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            email: 'radu.rata@gmail.com',
            data: '12.12.2023'
        },
        {
            id: 3,
            name: 'anton',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            email: 'radu.rata@gmail.com',
            data: '12.12.2023'
        }
    ]

    const basic_colum = [
        {
            id: 'select',
            header: ({ table }) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({ row }) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            header: 'Photo',
            accessorKey: 'photo',
            enableSorting: false,
            cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)} alt={info.getValue()}/></div>
        },
        {
           header: 'Nume',
           accessorKey: 'name',
        },
        {
            header: 'Prenume',
            accessorKey: 'surname'
        },
        {
            header: 'Tara',
            accessorKey: 'city'
        },
        {
            header: 'E-mail',
            accessorKey: 'email'
        },
        {
            header: 'Data inregistrari',
            enableSorting: false,
            accessorKey: 'data',
        },
        {
            header: 'Actiuni',
            enableSorting: false,
            cell: ({ row }) =>
              <div className="actions-table">
                  <button onClick={() => alert(row.id)} className="button-table enrole">Enrole</button>
                  <button onClick={() => alert(row.id)} className="button-table decline">Decline</button>
              </div>
        }
    ]

    function IndeterminateCheckbox({
                                       indeterminate,
                                       className = '',
                                       ...rest
                                   }) {
        const ref = React.useRef(!null);

        React.useEffect(() => {
            if (typeof indeterminate === 'boolean') {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
        )
    }


    return (
        <section className="interview-ready-section">
            <InterviewReadyItem />
            <InterviewReadyItem />
            <InterviewReadyItem />

            <BasicTable basic_column={basic_colum} data={data} className="table-interview-ready-list" />
        </section>
    );
}

export default InterviewReady;
