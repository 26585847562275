import {apiClient} from "./index";

export const getEducation = () => {
    return apiClient.get('/api/education');
}

export const addEducation = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/education',data);
}

export const editEducation = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/education/${Id}?_method=PUT`,data);
}

export const deleteEducation = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/education/${Id}`);
}
