import React from 'react';
import '../BlogComponent.scss';
import {Link} from "react-router-dom";
import {friendlyURL} from "../../../utils/string.util";
import { Parser } from 'html-to-react'

const BlogItem = ({blog}) => {
    return (
        <div className="flex flex-wrap blog-item">
            <div className="blog-item__image">
                {blog.Image &&
                    <img className="w-full h-full object-cover" src={blog.Image} alt={blog.AltTitle} /> }

                <div className="blog-item__author rounded-full">
                    <img src={blog.User.Image} alt={blog.Title}/>
                </div>
            </div>

            <div className="blog-item__content">
                <Link to={`opportunity/${friendlyURL(blog.Title)}/${blog.Id}`}><span className="blog-title">{blog.Title}</span></Link>
                <Link to={`opportunity/${friendlyURL(blog.Title)}/${blog.Id}`} className="blog-learnMore">Learn More</Link>
                <p>{Parser().parse(blog.ShortDescription)}</p>
            </div>
        </div>
    );
}

export default BlogItem
