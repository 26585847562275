import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBaby, faPlay, faClock, faCertificate, faListCheck, faChartArea} from "@fortawesome/free-solid-svg-icons";
import FavouriteEmployer from "../../components/Job/FavouriteEmployer";
import {Link, useParams} from "react-router-dom";
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider";
import {useSelector} from "react-redux";
import Loader from "../../components/Loader/Loader";
import {Parser} from "html-to-react";

const TrainingDetail = () => {
    const [loadMore, setLoadMore] = useState(false);
    const [course, setCourse] = useState([]);

    const {courseTitle} = useParams();
    const {courses} = useSelector((state) => state.course);

    useEffect(() => {
        const data = courses.filter(course => course.slug === courseTitle)[0];
        setCourse(data);
    },[courseTitle]);

    const showMore = () =>
    {
       setLoadMore(prevCheck => !prevCheck);
    }

    const loadCount = loadMore ? course.lesson.length : 4;

    return (
        <>
            {course && course.length === 0 && <Loader /> }
            {course &&
                <main className="training-page">
                    <section className="main-training container flex flex-wrap">
                        <div className="main-training__details">
                            <h1 className="title">{course.title}</h1>
                            <span className="description">{course.shortDescription}</span>
                        </div>
                        <div className="main-training__image">
                            <div className="image_block">
                                <img src={require("assets/images/shadow.png")} className="w-full h-full object-cover" alt="image block" />
                            </div>
                            <div className="image_block">
                                <img src={require("assets/images/courses.jpg")} className="w-full h-full object-cover" alt="image block" />
                            </div>
                        </div>
                    </section>

                    <section className="ability-level">
                        <div className="item-ability flex flex-wrap align-items-center"><FontAwesomeIcon icon={faBaby} className="icon"/> <span className="block labelTitle">Nivel de abilitati <span className='block subLabelTitle'>Incepator</span></span></div>
                        <div className="item-ability flex flex-wrap align-items-center"><FontAwesomeIcon icon={faClock} className="icon"/> <span className="block labelTitle">Time to completare <span className='block subLabelTitle'>Approx. 6 hours</span></span></div>
                        <div className="item-ability flex flex-wrap align-items-center"><FontAwesomeIcon icon={faCertificate} className="icon"/> <span className="block labelTitle">Certificate de absolvire <span className='block subLabelTitle flex flex-wrap align-items-end'>Yes <span className="pro">PRO</span></span></span></div>
                        <div className="item-ability flex flex-wrap align-items-center"><FontAwesomeIcon icon={faListCheck} className="icon"/> <span className="block labelTitle">Prerequisites <span className='block subLabelTitle'><strong>None</strong></span></span></div>
                    </section>

                    <section className="about-course">
                        <h2 className="title-head">Despre acest curs</h2>

                        <div className="about-course-content">
                            {Parser().parse(course.description)}
                        </div>
                    </section>

                    <section className="about-skills">
                        <h2 className="title-head">Abilitati pe care le vei obtine</h2>

                        <div className="step-skills">
                            <div className="step-skill">Sa realizezi un Business-Plan<div className="background"></div></div>
                            <div className="step-skill">Sa realizezi un Business-Plan<div className="background"></div></div>
                            <div className="step-skill">Sa realizezi un Business-Plan<div className="background"></div></div>
                        </div>
                    </section>

                    {course.lesson && course.lesson.length > 0 &&
                        <section className="lessons-sections container">
                            <div className="lessonList" id="accordionExample">
                                <div className="lesson-head">
                                    <div className="lesson-head-title">
                                        <h3 className="title-head">Syllabus</h3>
                                        <span className="lesson-head-total">
                               <span>{course.lesson.length - course.quizNr} lessons</span>
                               <span>0 projects</span>
                               <span>{course.quizNr} quizzes</span>
                           </span>
                                    </div>
                                    <div className="lesson-head-action">
                                        <span onClick={showMore}>{!loadMore ? 'Vizualizeaza intraga lista' : 'Vizualizeaza partial'}</span>
                                    </div>
                                </div>

                                { course.lesson.slice(0, loadCount).map(lesson =>
                                    <div className="lesson-list" key={lesson.id}>
                                        <h2 className="accordion-header" id={"heading" + lesson.id}>
                                            <button className="accordion-button collapsed relative focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+ lesson.id} aria-expanded="true"
                                                    aria-controls={"collapse"+ lesson.id}>
                                                <div className="header-detail-course">
                                                    <div className="course-header-title">
                                                        <div className="count-lesson">{lesson.id + 1}</div>
                                                        <div>
                                                            <span className="courseTitle">{lesson.title}</span>
                                                            <span className="detailCourse">{lesson.title}</span>
                                                        </div>
                                                    </div>

                                                    <div className="symbol-plus">+</div>
                                                    <div className="symbol-min">-</div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id={"collapse"+ lesson.id} className="header-detail-body accordion-collapse collapse" aria-labelledby={"heading" + lesson.id}
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                 {/* <a href={require(`assets/files/courses/${course.id}/${lesson.url}`)} target="_blank">View Lesson</a>  */}
                                                 
                                                 {/* <iframe src={lesson.videoUrl} height="400" width="60%"></iframe>  */}
                                                 

                                                 {/* {lesson.videoUrl ? <iframe src={lesson.videoUrl} height="400" width="60%"></iframe> : <a href={require(`assets/files/courses/${course.id}/${lesson.url}`)} target="_blank">View lesson - {lesson.url}</a> } */}
                                                 
                                                 {lesson.videoUrl ? <a href={lesson.videoUrl} target="_blank">View lesson on youtube - {lesson.title}</a> : <a href={require(`assets/files/courses/${course.id}/${lesson.url}`)} target="_blank">View lesson - {lesson.title}</a> }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/*<div className="actions">*/}
                            {/*    <button className="start"><FontAwesomeIcon icon={faPlay} />Start</button>*/}
                            {/*</div>*/}
                        </section>
                    }

                    {/*<section className="testimonial-section">*/}
                    {/*     <h3 className="title-head">Testimoniale de la benificiarii cursului</h3>*/}
                    {/*     <div className="testimonial-content">*/}
                    {/*         <TestimonialSlider />*/}
                    {/*     </div>*/}
                    {/*</section>*/}

                    {/*<section className="container employer-section">*/}
                    {/*    <h3 className="title-head">Cursantii nostri au gasit Job la:</h3>*/}

                    {/*    <div className="employer-section-content flex flex-wrap justify-center">*/}
                    {/*        {*/}
                    {/*            company.map(item =>*/}
                    {/*                <FavouriteEmployer key={item.id} item={item} />*/}
                    {/*            )*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</section>*/}

                    <section className="interesting-course container">
                        <h3 className="title-head">Cursuri ce te-ar putea interesa</h3>

                        <div className="course-content">
                            <div className="item-category-course">
                                <div className="image">
                                    <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                                </div>
                                <div className="content">
                                    <p>Limbi strainie</p>
                                    <div className="break"></div>
                                    <p>38 anunturi</p>
                                </div>
                            </div>
                            <div className="item-category-course">
                                <div className="image">
                                    <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                                </div>
                                <div className="content">
                                    <p>IT & WebDesign</p>
                                    <div className="break"></div>
                                    <p>43 anunturi</p>
                                </div>
                            </div>
                            <div className="item-category-course">
                                <div className="image">
                                    <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                                </div>
                                <div className="content">
                                    <p>Economie si Marketing</p>
                                    <div className="break"></div>
                                    <p>383 anunturi</p>
                                </div>
                            </div>
                            <div className="item-category-course ">
                                <div className="image">
                                    <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                                </div>
                                <div className="content">
                                    <p>Dezvoltare personala</p>
                                    <div className="break"></div>
                                    <p>79 anunturi</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            }
        </>
    );
}

export default TrainingDetail;
