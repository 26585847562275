import React, {useMemo, useState} from 'react';
import Pagination from "../../../../components/Pagination/Pagination";

import 'components/Profiles/MyCourse/my-course.scss';
import Course from "components/Profiles/MyCourse/Course";
const MyCourse = () => {
    let PageSize = 4;
    const courses = [
        {
            id: 0,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 1,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 2,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 3,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 4,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 5,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 6,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 7,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        },
        {
            id: 8,
            titleCourse: 'EcoPress',
            descriptionCourse: 'Cum sa scrim un reportaj ECO',
            nrProject: 1,
            practicallyHours: 20,
            theoreticallyHours: 19
        }
    ];
    const [currentPage, setCurrentPage] = useState(1);

    const MyCourses = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return courses.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return (
        <>
            <section className="my-courses-list">
                {
                    MyCourses.map(course => <Course course={course} key={course.id} />)
                }
            </section>

            <Pagination
                currentPage={currentPage}
                totalCount={courses.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </>
    );
}

export default MyCourse;
