import {createSlice} from "@reduxjs/toolkit";

const quizzReducer = createSlice({
    name: 'quizz',
    initialState: {
        loading: false,
        startQuizz: false,
        amount_of_question: 1,
        amount_correct_answers: 0,
        quizz: {},
        quizList: [
            {
                "idCategory": 1,
                "Category": "IT",
                "Title": "Html Test",
                "Quiz": [
                    {
                        "id": 1,
                        "question": "Question 1",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 1"
                    },
                    {
                        "id": 2,
                        "question": "Question 2",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 2"
                    },
                    {
                        "id": 3,
                        "question": "Question 3",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 3"
                    }
                ]
            },
            {
                "idCategory": 2,
                "Category": "IT",
                "Title": "Css Test",
                "Quiz": [
                    {
                        "id": 1,
                        "question": "Question 1",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 1"
                    },
                    {
                        "id": 2,
                        "question": "Question 2",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 2"
                    },
                    {
                        "id": 3,
                        "question": "Question 3",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 3"
                    }
                ]
            },
            {
                "idCategory": 3,
                "Category": "IT",
                "Title": "PHP Test",
                "Quiz": [
                    {
                        "id": 1,
                        "question": "Question 1",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 1"
                    },
                    {
                        "id": 2,
                        "question": "Question 2",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 2"
                    },
                    {
                        "id": 3,
                        "question": "Question 3",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 3"
                    }
                ]
            },
            {
                "idCategory": 4,
                "Category": "Business",
                "Title": "Business Test",
                "Quiz": [
                    {
                        "id": 1,
                        "question": "Question 1",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 1"
                    },
                    {
                        "id": 2,
                        "question": "Question 2",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 2"
                    },
                    {
                        "id": 3,
                        "question": "Question 3",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 3"
                    }
                ]
            },
            {
                "idCategory": 5,
                "Category": "Business",
                "Title": "Business Test second",
                "Quiz": [
                    {
                        "id": 1,
                        "question": "Question 1",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 1"
                    },
                    {
                        "id": 2,
                        "question": "Question 2",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 2"
                    },
                    {
                        "id": 3,
                        "question": "Question 3",
                        "answers": ["Answers 1", "Answers 2", "Answers 3","Answers 4"],
                        "correctAnswers": "Answers 3"
                    }
                ]
            },
        ]
    },
    reducers: {
       setStartQuizz: (state) => {
           state.startQuizz = !state.startQuizz;
           state.amount_of_question = 1;
           state.amount_correct_answers = 0;
           state.quiz = {};
       },

       setQuizz: (state, action) => {
           state.quizz = {...action.payload};
       },

       incrementAmountOfQuestion: (state) => {
           state.amount_of_question += 1;
       },

       checkAnswer: (state, action) => {
           const quiz = state.quizz;

           if(quiz.Quiz[state.amount_of_question - 1].correctAnswers === action.payload){
               state.amount_correct_answers += 1;
           }
       },

       saveQuizz: (state, action) => {
           state.quizList.map(item => {
               if(item.idCategory === action.payload.idCategory) {
                   item.Category = action.payload.Category;
                   item.Title = action.payload.Title
                   item.Quiz = action.payload.Quiz
               }
           });
       },

        addNewQuiz: (state, action) => {
            state.quizList = [...state.quizList, {...action.payload}]
        },
    }
});

export default quizzReducer.reducer;
export const { setStartQuizz, setQuizz, incrementAmountOfQuestion, checkAnswer, saveQuizz, addNewQuiz } = quizzReducer.actions
