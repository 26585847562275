import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencil, faXmark} from "@fortawesome/free-solid-svg-icons";
import {getMenuActions} from "../../../../../redux/actions/menuApi";
import Loader from "components/Loader/Loader";

const Menu = () => {
    const dispatch = useDispatch();
    const {loading, error, menu} = useSelector(state => state.menu);

    useEffect(() => {
        dispatch(getMenuActions());
    },[dispatch])

    const basicColumArticlesList = [
        {
            header: 'Label',
            accessorKey: 'Label',
            enableSorting: false,
            cell: (info) => <div>{JSON.stringify(info.getValue())}</div>
        },
        {
            header: 'Visible',
            accessorKey: 'Hide',
            enableSorting: false,
            cell: (info) => <div className="flex justify-center align-items-center flex-wrap text-xl">
                {info.getValue() == 1 ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
            </div>
        },
        {
            header: '',
            accessorKey: 'Id',
            enableSorting: false,
            cell: (info) => <div className="table-download"><Link to={`/dashboard/menu/${info.getValue()}`}><FontAwesomeIcon icon={faPencil} /></Link></div>
        }
    ];

    return (
        <>
            {/*{error && <p>Something went wrong</p>}*/}
            { loading && <Loader /> }
            {!loading &&
                <section className="table-articlesList">
                    <BasicTable basic_column={basicColumArticlesList} enableSorting={true} data={menu} className="basic-table-JobsList clientColor2"/>
                </section>
            }
        </>
    );
}

export default Menu;
