import {createSlice} from "@reduxjs/toolkit";
import {
    accountBankCreateActions,
    accountBankDeleteActions,
    accountBankEditActions,
    accountBankGetBankActions,
} from "../actions/accountBankApi";

const accountBankReducer = createSlice({
    name: 'accountBank',
    initialState: {
        accountBank: [],
        error: null,
        loading: false,
    },
    reducers: {
        addAccountBank: (state) => {
            const dataCard = {
                id: state.accountBank.length + 1,
                IBAN: '',
                banks_id: 0,
            }
            state.accountBank = [...state.accountBank, {...dataCard}];
        },

        allAccountsBanks: (state, {payload}) => {
            state.accountBank = payload;
        },

        editAccountBank: (state, {payload}) => {
            const { id, IBAN, banks_id } = payload;
            return {
                ...state,
                accountBank: state.accountBank.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            IBAN,
                            banks_id
                        };
                    }
                    return item;
                })
            }
       },
    },
    extraReducers: (builder) => {
        builder
            .addCase(accountBankGetBankActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(accountBankGetBankActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.accountBank = payload
                }
            })

            .addCase(accountBankGetBankActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(accountBankEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(accountBankEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.loading = false;
                        state.error = payload.message;
                    } else {
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(accountBankEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(accountBankCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(accountBankCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(accountBankCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(accountBankDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(accountBankDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(accountBankDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default accountBankReducer.reducer;
export const { addAccountBank, allAccountsBanks, editAccountBank } = accountBankReducer.actions;
