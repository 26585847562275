import React from "react";
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import LoginComponent from "../../components/Forms/LoginComponent";

const CreatCV = () => {
    const {t} = useTranslation();
    return (
        <main>
            <h1>Creare CV</h1>
            <section className="createCV">
                <div className="container">
                    <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3 text-center">
                                <p>
                                    Detinatorii semnaturii mobile sau semnaturii digitale au posibilitatea sa acceseze serviciul prin autentificare cu "M-Pass".
                                </p>

                                <div className="mx-auto w-auto">
                                    <a href="" className="btn block">Autentificare cu M-Pass</a>
                                    sau
                                    <Link to={"/registration"} className="btn block">Creaza cont Angajator</Link>
                                    sau
                                    <Link to={"/registration"} className="btn block">Inregistreaza un angajat</Link>
                                </div>
                            </div>

                            <div className="col-span-6 sm:col-span-3 shadow-xl lg:max-w-xl">
                               <LoginComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CreatCV;
