import {createAsyncThunk} from "@reduxjs/toolkit";
import {getBanks, editBank, addBank, deleteBank} from "../../service/BankService";

export const bankGetBankActions = createAsyncThunk(
    'bank/bankGetBankActions',
    async (params = {}) => {
        try {
            return await getBanks(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const bankEditActions = createAsyncThunk(
    'bank/bankEditActions',
    async({Id, data, token}, thunkAPI) => {
        try{
            return await editBank(Id, data, token).then(response => response.data.data);
        } catch (error) {
            return error.message;
        }
    }
)

export const bankCreateActions = createAsyncThunk(
    'bank/bankCreateActions',
    async({data, token}) => {
        try{
            return await addBank(data, token).then(response => response.data.data).catch(error => error.response.data);
        } catch (error) {
            return error.message
        }
    }
)

export const bankDeleteActions = createAsyncThunk(
    'bank/bankDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteBank(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
