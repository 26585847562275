import React, {useEffect} from 'react';
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import {fetchJobsActions} from "../../../../redux/actions/jobsApi";
import Loader from "components/Loader/Loader";

const Jobs = () => {
    const dispatch = useDispatch();

    const jobs = [
        {
            Id: 1,
            title: 'Laravel Developer',
            description: 'Laravel Developer',
            category: 'Business',
            company: 'Linella',
            personName: 'Vasile Lupu',
            status: 'Procesare',
            data: '12.12.2024'
        },
        {
            Id: 2,
            title: 'Laravel Developer',
            description: 'Laravel Developer',
            category: 'IT',
            company: 'Linella',
            personName: 'Vasile Lupu',
            status: 'Procesare',
            data: '12.12.2024'
        },
        {
            Id: 3,
            title: 'Laravel Developer',
            description: 'Laravel Developer',
            category: 'Management',
            company: 'Linella',
            personName: 'Vasile Lupu',
            status: 'Procesare',
            data: '12.12.2024'
        },
        {
            Id: 4,
            title: 'Laravel Developer',
            description: 'Laravel Developer developer php oop Laravel Developer developer php oop Laravel Developer developer php oop',
            category: 'IT',
            company: 'Linella',
            personName: 'Vasile Lupu',
            status: 'Procesare',
            data: '12.12.2024'
        },
        {
            Id: 5,
            title: 'Laravel Developer',
            description: 'Laravel Developer',
            category: 'Business',
            company: 'Linella',
            personName: 'Vasile Lupu',
            status: 'Procesare',
            data: '12.12.2024'
        },
        {
            Id: 6,
            title: 'Laravel Developer',
            description: 'Laravel Developer',
            category: 'IT',
            company: 'Linella',
            personName: 'Vasile Lupu',
            status: 'Procesare',
            data: '12.12.2024'
        }
    ]

    const basicColumJobsList = [
        {
            id: 'select',
            header: ({table}) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row}) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            header: 'Job ID',
            accessorKey: 'Id',
            enableSorting: false,
            minSize: 75,
        },
        {
            header: 'Job name',
            accessorKey: 'title',
            enableSorting: true,
            minSize: 100,
            maxSize: 150,
        },
        {
            header: 'Job description',
            accessorKey: 'description',
            maxSize: 200,
            minSize: 100,
            enableSorting: false,
        },
        {
            header: 'Categorie',
            accessorKey: 'category',
            enableSorting: false,
        },
        {
            header: 'Nume Organizatie',
            accessorKey: 'company',
            enableSorting: false
        },
        {
            header: 'Persona aplicanta',
            accessorKey: 'personName',
            enableSorting: false,
        },
        {
            header: 'Status',
            accessorKey: 'status',
            enableSorting: false,
        },
        {
            header: 'Data expirarii',
            accessorKey: 'data',
            enableSorting: false
        }
    ];

    function IndeterminateCheckbox({
                                       indeterminate,
                                       className = '',
                                       ...rest
                                   }) {
        const ref = React.useRef(!null);

        React.useEffect(() => {
            if (typeof indeterminate === 'boolean') {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
        )
    }


    return (
        <>
            {/*{error && <p>Something went wrong</p>}*/}
                <section className="table-JobsList">
                    <BasicTable basic_column={basicColumJobsList} perPage={3} data={jobs}
                                className="basic-table-JobsList clientColor2"/>
                </section>
        </>
    );
}

export default Jobs;
