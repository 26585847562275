import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter, faPinterestP, faTiktok, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import { Parser } from 'html-to-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getInitiativeCompanyActions} from "../../redux/actions/initiativeCompanyApi";
import i18n from "i18next";
import PopUpInitiativeCompany from "../../components/PopUp/PopUpInitiativeCompany";
import {advisersGetAdvisersActions} from "../../redux/actions/advisersApi";

const About = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {initiative} = useSelector(state => state.initiative);
    const {advisers, loading} = useSelector(state => state.advisers);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = (value) => {
        setShowModal(value);
    }

    useEffect(() => {
        dispatch(getInitiativeCompanyActions());
        dispatch(advisersGetAdvisersActions());
    },[dispatch]);

    return (
        <main className="about-page">
            <section className="header flex flex-wrap">
                <div className="header-content">
                    <span className="motto">{Parser().parse(t('About.Motto'))}</span>

                    <h1 className="title">{t('About.Title')}</h1>
                </div>
                <div className="header-image">
                    <img src={require("assets/images/about.png")} className="w-full h-full object-cover" alt="about-us"/>
                </div>
            </section>

            <section className="container about-content">
                <span className="content-text">{Parser().parse(t('About.Description'))}</span>
                <span className="content-text">{Parser().parse(t('About.DescriptionSecond'))}</span>
            </section>

            {initiative &&
                <section className="about-initiative-section relative container">
                    <div className="text-center">
                        <h2 className="title-head">{t('About.TitleInitiative')}</h2>
                        <span className="text">{t('About.DescriptionInitiative')}</span>
                    </div>

                    <div className="about-initiative-content flex flex-wrap">
                        {
                            initiative.map(item =>
                                <>
                                    <article className="about-item" key={item.Id}>
                                        <div className="about-item-image">
                                            <div className="img">
                                                <img src={item.Image} className="w-full h-full object-contain" alt="about-us"/>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <span className="about-item-title"><a onClick={() => setShowModal(true)}>{item.Title[i18n.language]}</a></span>
                                            <p className="about-item-content">{Parser().parse(item.Description[i18n.language])}</p>
                                            <div className="breakLine"></div>
                                            <a className="read-more" onClick={() => setShowModal(true)}>{t('Home.btnReadMore')}</a>
                                        </div>
                                    </article>
                                    { showModal ? <PopUpInitiativeCompany showModal={handleCloseModal} title={item.Title} description={item.Description} /> : null }
                                </>
                            )
                        }
                    </div>
                </section>
            }

            {!loading && advisers &&
                <section className="section-counselors container flex flex-wrap">
                    <div className="counselors-image overflow-hidden">
                        <img src={advisers[0]?.Image} alt={advisers[0]?.Name}/>
                    </div>
                    <div className="counselors-content">
                        <h2 className="title-head">{t('About.TitleAdvisers')}</h2>

                        <div className="counselors-text">{Parser().parse(t('About.DescriptionAdvisers'))}</div>


                        <div className="social-counselors-section flex flex-wrap justify-center align-items-center">
                            {advisers[0]?.TwitterUrl &&
                                <div className="icon flex justify-center align-items-center">
                                    <a href={advisers[0]?.TwitterUrl} target="_blank"><FontAwesomeIcon icon={faTwitter}/></a>
                                </div>
                            }
                            {advisers[0]?.PinterestUrl &&
                                <div className="icon flex justify-center align-items-center">
                                    <a href={advisers[0]?.PinterestUrl} target="_blank"><FontAwesomeIcon icon={faPinterestP}/></a>
                                </div>
                            }
                            {advisers[0]?.TikTokUrl &&
                                <div className="icon flex justify-center align-items-center">
                                    <a href={advisers[0]?.TikTokUrl} target="_blank"><FontAwesomeIcon icon={faTiktok}/></a>
                                </div>
                            }
                            {advisers[0]?.Phone &&
                                <div className="icon flex justify-center align-items-center">
                                    <a href={"tel:" + advisers[0]?.Phone}><FontAwesomeIcon icon={faWhatsapp}/></a>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            }
        </main>
    );
}

export default About;
