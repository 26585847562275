import {createSlice} from "@reduxjs/toolkit";
import {resOpportunityGetOpportunityActions, resOpportunityEditOpportunityActions} from "../actions/resultOpportunityApi";

const resultOpportunityReducer = createSlice({
    name: 'resultOpportunity',
    initialState: {
        resultOpportunity: null,
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(resOpportunityGetOpportunityActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(resOpportunityGetOpportunityActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.resultOpportunity = payload
                }
            })

            .addCase(resOpportunityGetOpportunityActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(resOpportunityEditOpportunityActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(resOpportunityEditOpportunityActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.resultOpportunity = payload.data
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(resOpportunityEditOpportunityActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })
    }
});

export default resultOpportunityReducer.reducer;
