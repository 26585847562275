import React, {useState} from 'react';
import CareerVisible from "./CareerVisible";
import CareerEdit from "./CareerEdit";

const CareerItem = (props) => {
    const {career, editButton, editButtonItem} = props;
    const [editItem, setEditItem] = useState(false);

    const handleEditItemChange = (value) => {
        setEditItem(value);
    };

    return (
        <div className="career-item">
            {
                !editItem ? <CareerVisible career={career} editItem={handleEditItemChange} editButton={editButton} /> :
                    <CareerEdit career={career} editItem={handleEditItemChange} editButton={editButton} editButtonItem={editButtonItem}/>
            }
        </div>
    )
}

export default CareerItem;
