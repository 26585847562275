import {apiClient} from "./index";

export const getJobs = (params) => {
    return apiClient.get('/api/job',{params}).then(response => response.data).catch(error => error)
}

export const addJob = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/job',data);
}

export const editJob = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/job/${Id}?_method=PUT`,data);
}

export const deleteJob = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/job/${Id}`);
}
