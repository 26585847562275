import React from 'react';
import "./Courses.scss";

const CollaborationOfferItem = (props) => {
    return (
        <div className="registration-course-item collaboration-offer-item">
           <div className="course-content">
              <div className="about-course">
                  <span className="title-main">HTML</span>
                  <span className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor aliquyam erat, sed diam voluptua.</span>
              </div>
               <div className="break"></div>
               <div className="about-data">
                   <span>Domeniu: 8</span>
                   <span>Primita de la: 22</span>
               </div>

               <div className="actions-button-course">
                   <span className='button-course accepted'>Accept</span>
                   <span className='button-course ignore'>Ignore</span>
               </div>
           </div>
        </div>
    );
}

export default CollaborationOfferItem;
