import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

const PersonalInformation = (props) => {
    const {editItem, user} = props;

    return (
        <>
          <div className="image">
            <img src={user.Image !== '' ? user.Image : require("@images/tudor-ursachi.png")} alt={user?.Name + ' ' + user?.Surname} />
          </div>
          <div className="content-cv">
            <div className="information">
              <span className="title">{user?.Name + ' ' + user?.Surname}</span>
                {user.Work?.Position && <span className="job">{user.Work?.Position}</span>}
                {user.Address?.addressCurrent && <span className="location">{user.Address?.CurrentAddress} <span className="break" /> <span className="contact-info">Contact Info</span></span>}
            </div>
            <div className="edit-cv" onClick={() => editItem(true)}>
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
        </>
    );
}

export default PersonalInformation;
