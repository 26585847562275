import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Input.scss";

const Input = (props) => {
    const {icon, text, value, placeholder, onChangeInput, className, id} = props;
    const handleInputChange = (e) => {
        e.preventDefault();
        onChangeInput(e.target.value);
    }

    return (
        text !== '' ?
            <div className={`custom-input ${className}`}>
                <label htmlFor={id}>{text}</label>
                   {icon !== '' && icon !== undefined ? <div className="icon-input"><input id={id} type="text" placeholder={placeholder} value={value} onChange={handleInputChange} /><FontAwesomeIcon icon={icon} /></div> : <input id={id} type="text" placeholder={placeholder} value={value} onChange={handleInputChange} />}
            </div>
        :
            <div className={`custom-input ${className}`}>
                   {icon !== '' && icon !== undefined ? <div className="icon-input"><input type="text" placeholder={placeholder} value={value} onChange={handleInputChange} /><FontAwesomeIcon icon={icon} /></div> : <input type="text" placeholder={placeholder} value={value} onChange={handleInputChange} />}
            </div>
    );
}

export default Input;
