import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {
    faFacebook,
    faFlickr,
    faGooglePlus, faInstagram,
    faLinkedin, faMedium, faSquareOdnoklassniki, faSquarePinterest,
    faSquareTumblr,
    faSquareTwitter, faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import Image from "../../../../components/Image/Image";
import {useDispatch, useSelector} from "react-redux";
import {settingGetSettingActions, settingEditSettingActions} from "../../../../redux/actions/settingApi";
import {toast, ToastContainer} from "react-toastify";
import {socialEditSocialMediaActions} from "../../../../redux/actions/socialMediaApi";

const Settings = () => {
    const {social} = useSelector(state => state.socialMedia);
    const {setting} = useSelector(state => state.setting);
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const dispatch = useDispatch();

    const [FacebookUrl, setFacebookUrl] = useState(social.FacebookUrl ?? null);
    const [LinkedInUrl, setLinkedInUrl] = useState(social.LinkedInUrl ?? null);
    const [GmailUrl, setGmailUrl] = useState(social.GmailUrl ?? null);
    const [TwitterUrl, setTwitterUrl] = useState(social.TwitterUrl ?? null);
    const [FlickrUrl, setFlickrUrl] = useState(social.FlickrUrl ?? null);
    const [FabUrl, setFabUrl] = useState(social.FabUrl ?? null);
    const [YoutubeUrl, setYoutubeUrl] = useState(social.YoutubeUrl ?? null);
    const [OkUrl, setOkUrl] = useState(social.OkUrl ?? null);
    const [MediumUrl, setMediumUrl] = useState(social.MediumUrl ?? null);
    const [InstagramUrl, setInstagramUrl] = useState(social.InstagramUrl ?? null);
    const [PinterestUrl, setPinterestUrl] = useState(social.PinterestUrl ?? null);

    useEffect(() => {
        // Check if token is null and set it from localStorage if available
        if (token === null) {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                setToken(storedToken);
            }
        }
        dispatch(settingGetSettingActions());
    }, [token]);

    const SignupSchema = Yup.object({
        Address: Yup.string()
            .min(2,'Address must be 2 characters or more')
            .required('Address is a required field'),
        Phone: Yup.string()
            .min(2, 'Phone must be 2 characters or more')
            .required('Phone is a required field'),
        Email: Yup.string()
            .email('Invalid email address').required('Email is a required field')
            .min(2, 'Email must be 2 characters or more'),
        Copyright: Yup.string()
            .required('Copyright is a required field')
            .min(2, 'Copyright must be 2 characters or more')
    });

    const updateSocialMedia = (data) => {
        const params = {
            Id: social.Id,
            data: data,
            token: token
        }

        dispatch(socialEditSocialMediaActions(params)).then(res => {
            toast.success("Successfully Saved", {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    }

    return (
        <section className="profile-settings">
           <div className="social-media">
               <div className="social-media--heading">
                   <h3>Social Media</h3>
               </div>
                <div className="social-media--content">
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faFacebook} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Facebook" value={FacebookUrl} onChange={(event) => setFacebookUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({facebookUrl: FacebookUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                              setFacebookUrl('');
                              updateSocialMedia({facebookUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faLinkedin} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="LinkedIn" value={LinkedInUrl} onChange={(event) => setLinkedInUrl(event.target.value)}/>
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({linkedInUrl: LinkedInUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setLinkedInUrl('');
                                updateSocialMedia({linkedInUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faGooglePlus} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Gmail" value={GmailUrl} onChange={(event) => setGmailUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({gmailUrl: GmailUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setGmailUrl('');
                                updateSocialMedia({gmailUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faSquareTwitter} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Twitter" value={TwitterUrl} onChange={(event) => setTwitterUrl(event.target.value)}/>
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({twitterUrl: TwitterUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setTwitterUrl('');
                                updateSocialMedia({twitterUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faFlickr} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Flickr" value={FlickrUrl} onChange={(event) => setFlickrUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({flickrUrl: FlickrUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setFlickrUrl('');
                                updateSocialMedia({flickrUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faSquareTumblr} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Fab" value={FabUrl} onChange={(event) => setFabUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({fabUrl: FabUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setFabUrl('');
                                updateSocialMedia({fabUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faYoutube} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Youtube" value={YoutubeUrl} onChange={(event) => setYoutubeUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({youtubeUrl: YoutubeUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setYoutubeUrl('');
                                updateSocialMedia({youtubeUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faSquareOdnoklassniki} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Ok" value={OkUrl} onChange={(event) => setOkUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({okUrl: OkUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setOkUrl('');
                                updateSocialMedia({okUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faMedium} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Medium" value={MediumUrl} onChange={(event) => setMediumUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({mediumUrl: MediumUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setMediumUrl('');
                                updateSocialMedia({mediumUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faInstagram} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Instagram" value={InstagramUrl} onChange={(event) => setInstagramUrl(event.target.value)} />
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({instagramUrl: InstagramUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setInstagramUrl('');
                                updateSocialMedia({instagramUrl: null})}}>Delete</button>
                        </div>
                    </div>
                    <div className="social-item">
                        <div className="social-item-form">
                            <FontAwesomeIcon icon={faSquarePinterest} />
                            <div className="input">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faLink} />
                                </span>
                                <input type="text" name="Pinterest" value={PinterestUrl} onChange={(event) => setPinterestUrl(event.target.value)}/>
                            </div>
                        </div>
                        <div className="social-item-action">
                            <button className="btn btn-update" onClick={() => updateSocialMedia({pinterestUrl: PinterestUrl})}>Update</button>
                            <button className="btn btn-delete" onClick={() => {
                                setPinterestUrl('');
                                updateSocialMedia({pinterestUrl: null})}}>Delete</button>
                        </div>
                    </div>
                </div>
           </div>
           <div className="footer-setting">
               <div className="footer-setting--heading">
                   <h3>Footer</h3>
               </div>
               <div className="footer-setting--content">
                  <div className="form">
                      <Formik  initialValues={
                          {
                              Address: setting?.Address,
                              Phone: setting?.Phone,
                              Email: setting?.Email,
                              Copyright: setting?.Copyright,
                              Photo: setting?.Photo ?? null,
                              PhotoSecond: setting?.PhotoSecond ?? null,
                              PhotoThird: setting?.PhotoThird ?? null,
                              PhotoFourth: setting?.PhotoFourth ?? null
                          }
                      }
                               validationSchema={SignupSchema}
                               onSubmit={(values) => {
                                   const data = {
                                       Id: setting?.Id, data: {
                                           Address: values.Address,
                                           Phone: values.Phone,
                                           Email: values.Email,
                                           Copyright: values.Copyright,
                                           Photo: values.Photo,
                                           PhotoSecond: values.PhotoSecond,
                                           PhotoThird: values.PhotoThird,
                                           PhotoFourth: values.PhotoFourth
                                       }, token: token
                                   }

                                   // handleEditData(false);
                                   dispatch(settingEditSettingActions(data)).then(res => {
                                       toast.success("Successfully Saved", {
                                           position: toast.POSITION.TOP_CENTER,
                                       });
                                   });
                               }}
                      >
                          {
                              ({ errors, touched, values, setFieldValue }) => (
                              <Form>
                                  <div className="form-input">
                                      <Field type="text" name="Address" placeholder="Address" />
                                      <ErrorMessage name="Address" render={msg => <div className="error-message">{msg}</div>} />
                                  </div>

                                  <div className="form-input">
                                      <Field type="text" name="Phone" placeholder="Nr de telefon" />
                                      <ErrorMessage name="Phone" render={msg => <div className="error-message">{msg}</div>} />
                                  </div>

                                  <div className="form-input">
                                      <Field type="text" name="Email" placeholder="Email" />
                                      <ErrorMessage name="Email" render={msg => <div className="error-message">{msg}</div>} />
                                  </div>

                                  <div className="form-input">
                                      <Field type="text" name="Copyright" placeholder="Copyright" />
                                      <ErrorMessage name="Copyright" render={msg => <div className="error-message">{msg}</div>} />
                                  </div>

                                  <div className="form-input-images">
                                      <div className="form-input">
                                          <Image value={values.Photo} onChange={(result) => setFieldValue('Photo', result)} withBase64={true}/>
                                      </div>

                                      <div className="form-input">
                                          <Image value={values.PhotoSecond} onChange={(result) => setFieldValue('PhotoSecond',result)} withBase64={true}/>
                                      </div>

                                      <div className="form-input">
                                          <Image value={values.PhotoThird} onChange={(result) => setFieldValue('PhotoThird',result)} withBase64={true}/>
                                      </div>

                                      <div className="form-input">
                                          <Image value={values.PhotoFourth} onChange={(result) => setFieldValue('PhotoFourth',result)} withBase64={true}/>
                                      </div>
                                  </div>

                                  <div className="action-button-section">
                                      <button type="submit">Save</button>
                                  </div>
                                  <ToastContainer autoClose={3000} />
                              </Form>
                          )}
                      </Formik>
                  </div>
               </div>
           </div>
        </section>
    );
}

export default Settings;
