import React from 'react';
import "./Courses.scss";

const ActiveInactiveCourseItem = (props) => {
    return (
        <div className="registration-course-item">
           <div className="course-image">
              <img src={require('@images/background-quiz/background-test.png')} alt='course' />
              <span className='title-second'>HTML</span>
           </div>
           <div className="course-content">
              <div className="about-course">
                  <span className="title-main">HTML</span>
                  <span className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor aliquyam erat, sed diam voluptua.</span>
              </div>
               <div className="break"></div>
               <div className="about-data">
                   <span>Lectii: 8</span>
                   <span>Practica: 12</span>
                   <span>Inscrieri: 22</span>
               </div>

               <div className="actions-button-course">
                   <span className='button-course-status active'><span className="icon-button-course active"></span> Active</span>
               </div>
           </div>
        </div>
    );
}

export default ActiveInactiveCourseItem;
