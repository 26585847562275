import {createSlice} from '@reduxjs/toolkit';
import {loginActions, registerActions} from "../actions/authApi";

const authReducer = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        refreshToken: null,
        expireToken: null,
        error: null,
        loading: false,
    },
    reducers: {
        logout: (state) => {
            state.token = null;
            state.refreshToken = null;
            state.expireToken = null;
            localStorage.removeItem('token');
            localStorage.removeItem('expireToken');
            localStorage.removeItem('refreshToken');
        }
    },

    extraReducers: (builder) => {
         builder
             .addCase(loginActions.pending, (state, action) => {
                 state.loading = true;
                 state.token = null;
                 state.refreshToken = null;
                 state.error = null;
             })

             .addCase(loginActions.fulfilled, (state, {payload}) => {
                 if(payload?.access_token){
                     localStorage.setItem('token', payload?.access_token);
                     localStorage.setItem('expireToken', payload?.expires_in);
                     localStorage.setItem('refreshToken', payload?.refresh_token);

                     state.token = payload?.access_token;
                     state.refreshToken = payload?.refresh_token;
                     state.expireToken = payload?.expires_in;
                     state.error = null;
                     state.loading = false;
                 } else {
                     state.token = null;
                     state.refreshToken = null;
                     state.loading = false;
                     state.error = payload;
                 }
             })

             .addCase(loginActions.rejected, (state, {payload}) => {
                 state.error = 'Error';
                 state.loading = false;
             })
             .addCase(registerActions.pending, (state,) => {
                 state.token = null;
                 state.loading = true;
                 state.error = null;
                 state.refreshToken = null;
             })
             .addCase(registerActions.fulfilled, (state, action) => {
                 localStorage.setItem('token', action.payload.token);
                 localStorage.setItem('expireToken', action.payload.expires_in);
                 localStorage.setItem('refreshToken', action.payload.refresh_token);


                 state.token = action.payload.token;
                 state.refreshToken = action.payload.refresh_token;
                 state.expireToken = action.payload.expires_in;
                 state.error = null;
             })

             .addCase(registerActions.rejected, (state, action) => {
                 state.error = action?.error?.message;
                 state.loading = false;
                 state.token = null;
             })
    }
})

export default authReducer.reducer;
export const {logout} = authReducer.actions
