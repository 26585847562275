import React from 'react';
import Info from "components/Profiles/Info/Info";
import {useSelector} from "react-redux";

const Profile = () => {
    const {user} = useSelector(state => state.user);

    return (
      <Info user={user} />
    );
}

export default Profile;
