import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import HomeFilter from 'components/HomeFilter/HomeFilter'
import { useTranslation } from 'react-i18next'
import FavouriteEmployer from 'components/Job/FavouriteEmployer'
import FavouriteJob from 'components/Job/FavouriteJob'
import BlogItem from 'components/BlogComponents/BlogItem/BlogItem'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import BlogItemGrid from 'components/BlogComponents/BlogItem/BlogItemGrid'
import { useDispatch, useSelector } from 'react-redux'
import { blogGetBlogsActions } from '../../redux/actions/blogApi'
import { opportunityGetOpportunitiesActions } from '../../redux/actions/opportunityApi'
import { howGetWorkActions } from '../../redux/actions/howWorkApi'
import { sortArrayByField } from '../../utils/other.util'
import { friendlyURL } from '../../utils/string.util'
import { Parser } from 'html-to-react'
import i18n from 'i18next'
import { aboutGetAboutActions } from '../../redux/actions/aboutApi'
import { resOpportunityGetOpportunityActions } from '../../redux/actions/resultOpportunityApi'
import { companyGetCompanyActions } from '../../redux/actions/companyApi'

const Home = () => {
  const dispatch = useDispatch()
  const [category] = useOutletContext()
  const { blog } = useSelector((state) => state.blog)
  const { company } = useSelector((state) => state.company)
  const { howWork } = useSelector((state) => state.howWork)
  const { social } = useSelector((state) => state.socialMedia)
  const { opportunity } = useSelector((state) => state.opportunity)
  const { loading, about } = useSelector((state) => state.about)
  const { resultOpportunity } = useSelector((state) => state.resultOpportunity)
  const [categoryOpportunity, setCategoryOpportunity] = useState(false)
  const [jobCategory, setJobCategory] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(blogGetBlogsActions({ expand: 'user,tags', status: 5 }))
    dispatch(
      opportunityGetOpportunitiesActions({ expand: 'user,tags', status: 5 })
    )
    dispatch(howGetWorkActions())
    dispatch(aboutGetAboutActions())
    dispatch(resOpportunityGetOpportunityActions())
    dispatch(companyGetCompanyActions({ expand: 'jobs,applications' }))

    const blogCat = category.filter((cat) => cat.Type === 3)
    setCategoryOpportunity(blogCat)
    const jobsCat = category.filter((cat) => cat.Type === 1)
    setJobCategory(jobsCat)
  }, [])

  const showMore = () => {
    setLoadMore((prevCheck) => !prevCheck)
  }

  const loadCount = loadMore ? 6 : 3
  const orders = ['first', 'second', 'third', 'fourth']

  return (
    <>
      <main className="home-page">
        <section className="header-section flex flex-wrap">
          <div className="position-relative header-section-content">
            <h1 className="title">{Parser().parse(t('Home.Header'))}</h1>
            <span className="second-title">
              {Parser().parse(t('Home.HeaderSecond'))}
            </span>
            <div className="circle"></div>

            <div className="absolute home-filter">
              <HomeFilter />
            </div>
          </div>
          <div className="position-relative header-section-video">
            <div className="absolute bg-white vide-item-1"></div>
            <div className="absolute bg-white vide-item-2"></div>
            <div className="video">
              <video
                muted
                autoPlay
                loop
                className="w-full h-full object-cover"
                src={require('../../assets/video/Sequence 01_1.mp4')}
              ></video>
            </div>
          </div>
        </section>

        {categoryOpportunity.length > 0 && (
          <section className="features-section-menu grid grid-flow lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
            {sortArrayByField(categoryOpportunity, 'Order').map((item) => (
              <div
                className={`item-section ${orders[item.Order - 1]}`}
                key={item.Id}
              >
                <span className="head-item-title">
                  {item.Title} <span className="symbol-plus">+</span>
                  <span className="symbol-min">-</span>
                </span>

                {item.Opportunities.length > 0 &&
                  item.Opportunities.slice(0, 3).map((opportunity) => (
                    <span className="item-features-menu" key={opportunity.Id}>
                      <Link
                        to={`opportunity/${friendlyURL(opportunity.Title)}/${
                          opportunity.Id
                        }`}
                      >
                        {opportunity.Title}
                      </Link>
                    </span>
                  ))}
              </div>
            ))}
          </section>
        )}

        {opportunity.length > 0 && (
          <section className="video-blog-section grid grid-flow grid-cols-1 gap-3 mb-10">
            <div className="container">
              <h3 className="title">Opportunities / Lorem Ipson </h3>

              {opportunity.slice(0, loadCount).map((blog) => (
                <BlogItem blog={blog} key={blog.id} />
              ))}
              {opportunity.length >= 4 && (
                <div className="action">
                  <button onClick={showMore} className="btn-load-more">
                    {loadMore ? t('Home.btnShowLess') : t('Home.btnLoadMore')}
                  </button>
                </div>
              )}
            </div>
          </section>
        )}
        {company && (
          <section className="container employer-section">
            <h3 className="title-head">{t('Home.favouriteEmployer')}</h3>

            <div className="employer-section-content flex flex-wrap justify-center">
              {sortArrayByField(
                sortArrayByField(
                  company.slice(0, 21),
                  'NrOfApplications',
                  'asc'
                ),
                'NrOfJobs',
                'asc'
              ).map((item) => (
                <FavouriteEmployer key={item.id} item={item} />
              ))}
            </div>
            <div className="action">
              <Link to={'vacancies'} className="btn-load-more">
                {t('Home.btnLoadMore')}
              </Link>
              <Link to={'/find-a-job'} className="btn-load-more job-my-area">
                <FontAwesomeIcon icon={faChartArea} /> {t('Home.btnJobMyArea')}
              </Link>
            </div>
          </section>
        )}

        {jobCategory && (
          <section className="container job-section">
            <h3 className="title-head text-center">{t('Home.favouriteJob')}</h3>
            <p className="job-info">
              JOBHUB in Chișinău, 13256 specialists are looking for work
            </p>

            <div className="job-section-content flex flex-wrap justify-between">
              {jobCategory.slice(0, 4).map((item) => (
                <FavouriteJob key={item.id} item={item} />
              ))}
            </div>

            <div className="job-second-section flex flex-wrap">
              {jobCategory.slice(0, 8).map((item) => (
                <div className="category-section-job">
                  <span className="title-job">
                    <Link to={`vacancies?category=${item.Id}`}>
                      {item.Title}
                      <span>{item.nrOfJobs ?? 0}</span>
                    </Link>
                  </span>
                  {item.Jobs &&
                    item.Jobs.slice(0, 5).map((job) => (
                      <span key={job.Id}>
                        <Link to={`vacancies`}>{job.Title}</Link>
                      </span>
                    ))}
                </div>
              ))}
            </div>
          </section>
        )}

        {resultOpportunity && (
          <section className="results-opportunities container grid grid-flow grid-cols-1 sm:grid-cols-2">
            <div className="content">
              <h3 className="title-head text-center">
                {resultOpportunity.Title[i18n.language]}
              </h3>
              <p>
                {Parser().parse(resultOpportunity.Description[i18n.language])}
              </p>
            </div>
            <div className="content-progress">
              <Link to="" className="btn">
                {t('Home.btnSeeAllProject')}
              </Link>

              {resultOpportunity.Progress &&
                resultOpportunity.Progress.map((item, i) => (
                  <ProgressBar
                    key={i}
                    label={`${item.Title[i18n.language]} ${item.Value}%`}
                    visualParts={[
                      {
                        percentage: `${item.Value}%`,
                        color: 'tan',
                      },
                    ]}
                  />
                ))}
            </div>
          </section>
        )}

        {about && (
          <section className="about-section container flex flex-wrap">
            <h3 className="title-head text-center w-full ">
              {about.Title[i18n.language] ?? about.Title['en']}
            </h3>
            <div className="image">
              <img
                className="w-full h-full object-cover"
                src={about.Image}
                alt={''}
                width="384"
                height="512"
              />
            </div>
            <div className="content">
              <h3 className="title-head2 text-center">
                {about.Title[i18n.language] ?? about.Title['en']}
              </h3>
              <p>
                {Parser().parse(
                  about.Description[i18n.language] ?? about.Description['en']
                )}
              </p>
              <div className="gradient"></div>

              {social && (
                <div className="social-section">
                  <span>{t('Home.socialNetworkTitle')}</span>
                  {
                    <div className="social-icons flex flex-wrap justify-center align-items-center">
                      {social?.LinkedInUrl && social?.LinkedInUrl !== null && (
                        <div className="icon flex justify-center align-items-center">
                          <a href={social.LinkedInUrl} target="_blank">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                          </a>
                        </div>
                      )}

                      {social?.FacebookUrl && social?.FacebookUrl !== null && (
                        <div className="icon flex justify-center align-items-center">
                          <a href={social?.FacebookUrl} target="_blank">
                            <FontAwesomeIcon icon={faFacebookF} />
                          </a>
                        </div>
                      )}

                      {social?.InstagramUrl &&
                        social?.InstagramUrl !== null && (
                          <div className="icon flex justify-center align-items-center">
                            <a href={social.InstagramUrl} target="_blank">
                              <FontAwesomeIcon icon={faInstagram} />
                            </a>
                          </div>
                        )}

                      {social?.YoutubeUrl && social?.YoutubeUrl !== null && (
                        <div className="icon flex justify-center align-items-center">
                          <a href={social?.YoutubeUrl} target="_blank">
                            <FontAwesomeIcon icon={faYoutube} />
                          </a>
                        </div>
                      )}
                    </div>
                  }
                </div>
              )}
            </div>
          </section>
        )}
        {howWork && (
          <section className="howWorks">
            <h3 className="title-head">{t('Home.howItWorkTitle')}?</h3>

            <div className="container relative">
              <div className="step-content">
                {howWork.map((item) => (
                  <div className={`step-${item.Order}`}>
                    <div className="step-nr">{item.Order}/</div>
                    <div className="step-text">
                      <span>
                        {Parser().parse(item.Description[i18n.language])}
                      </span>
                      <span>{item.Title[i18n.language]}</span>
                    </div>
                    <div className="step-image">
                      <img src={item.Image} alt="step1" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </section>
        )}

        {blog.length > 0 && (
          <section className="blog-section container">
            <h3 className="title-head text-center">{t('Home.ourBlogTitle')}</h3>

            <div className="flex flex-wrap justify-content-center">
              {blog.slice(0, 3).map((item) => (
                <BlogItemGrid blog={item} key={item.Id} />
              ))}
            </div>

            <div className="action">
              <Link to={'/blog'} className="btn-load-more">
                {t('Home.btnReadMore')}
              </Link>
            </div>
          </section>
        )}
      </main>
    </>
  )
}

export default Home
