import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faPlus} from "@fortawesome/free-solid-svg-icons";
import 'components/Profiles/Banking/Banking.scss';
import AccountCard from "components/Profiles/Banking/AccountCard/AccountCard";
import AccountBanking from "components/Profiles/Banking/AccountBanking/AccountBanking";
import {addCard} from "../../../../redux/reducer/cardReducer";
import {addAccountBank} from "../../../../redux/reducer/accountBankReducer";
import {accountBankCreateActions, accountBankGetBankActions} from "../../../../redux/actions/accountBankApi";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import {bankGetBankActions} from "../../../../redux/actions/bankApi";
import {cardCreateActions, cardGetCardActions} from "../../../../redux/actions/cardApi";

const Banking = () => {
    const dispatch = useDispatch();
    const {loading, bank} = useSelector(state => state.bank);
    const {user} = useSelector(state => state.user);
    const { card } = useSelector(state => state.card);
    const { accountBank } = useSelector( state => state.accountBank);
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);

    useEffect(() => {
        // Check if token is null and set it from localStorage if available
        if (token === null) {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                setToken(storedToken);
            }
        }

    }, [token]);

    useEffect(() => {
        dispatch(cardGetCardActions({user_id: user.Id}))
        dispatch(bankGetBankActions());
        dispatch(accountBankGetBankActions({user_id: user.Id} ));
    },[]);

    const handleSaveCards = (e) => {
        e.preventDefault();

        const data = {data: card, token: token}

        dispatch(cardCreateActions(data)).then(res => toast.success("Successfully Saved", {
            position: toast.POSITION.TOP_CENTER,
        }));
    }

    const handleSaveAccounts = (e) => {
        e.preventDefault();

        const data = {data: accountBank, token: token}

        dispatch(accountBankCreateActions(data)).then(res => toast.success("Successfully Saved", {
            position: toast.POSITION.TOP_CENTER,
        }));
    }

    return (
        <>
            {loading && <Loader /> }
            {!loading &&
                <section className="banking-section">
                    <section className="banking-account-card">
                        { card && card.map(item => <AccountCard key={item.id + 'card'} id={item.id} card={item} user={user} /> )}

                        <section className="addNewCard">
                            <span className="btn-card" onClick={ () => dispatch(addCard())}><FontAwesomeIcon icon={faPlus} />Adauga</span>
                            <span className="btn-card" onClick={event => handleSaveCards(event) }><FontAwesomeIcon icon={faSave} />Salveaza</span>
                        </section>
                    </section>

                    <section className="banking-account-card banking-account-bank">
                        { accountBank && accountBank.map(item => <AccountBanking key={item.id} id={item.id} bank={bank} accountBank={item} user={user}/> )}

                        <section className="addNewCard">
                            <span className="btn-card" onClick={ () => dispatch(addAccountBank())}><FontAwesomeIcon icon={faPlus} />Adauga</span>
                            <span className="btn-card" onClick={event  => handleSaveAccounts(event) }><FontAwesomeIcon icon={faSave} />Salveaza</span>
                        </section>
                    </section>
                    <ToastContainer autoClose={3000} />
                </section>
            }
        </>
    )
}

export default Banking;
