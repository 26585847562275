import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContactForm from "../../components/ContactFormComponent/ContactForm";
import {faFacebookF, faInstagram, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";



const Contact = () => {
    return (
        <main className="contact-page">
           <section className="container grid grid-flow lg:grid-cols-2 sm:grid-cols-1">
               <div className="content-block">
                  <h1 className="title">Contacteaza-ne</h1>

                   <div className="container contact-details">
                       <div className="contact">
                           <a href="tel:022 77 11 23"><img src={require("assets/images/phone.png")} alt="022 77 11 23"/> 022 77 11 23</a>
                       </div>

                       <div className="contact">
                           <a href="mailto:office@jobhub.md"><img src={require("assets/images/mail.png")} alt="office@jobhub.md"/> office@jobhub.md</a>
                       </div>

                       <div className="contact">
                           <a href=""><img src={require("assets/images/location.png")} alt="MD-2051, Chisinau, Republica Moldova, str. 31 august 1989"/> MD-2051, Chisinau, Republica Moldova, str. 31 august 1989</a>
                       </div>

                       <div className="contact social-icons flex flex-wrap justify-center align-items-center">
                           <div className="icon flex justify-center align-items-center">
                               <a href=""><FontAwesomeIcon icon={faLinkedinIn} /></a>
                           </div>
                           <div className="icon flex justify-center align-items-center">
                               <a href=""><FontAwesomeIcon icon={faFacebookF} /></a>
                           </div>
                           <div className="icon flex justify-center align-items-center">
                               <a href=""><FontAwesomeIcon icon={faInstagram} /></a>
                           </div>
                           <div className="icon flex justify-center align-items-center">
                               <a href=""><FontAwesomeIcon icon={faYoutube} /></a>
                           </div>
                       </div>
                   </div>

               </div>
               <div className="image-block">
                   <div className="blue-block"></div>
                   <div className="yellow-block"></div>
                   <div className="green-block"></div>
                   <div className="image">
                       <img src={require("../../assets/images/portrait-woman.png")} className="w-full h-full rounded-full object-cover" alt="contact-us"/>
                   </div>
               </div>
           </section>
            <section className="container map overflow-hidden">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.9479914758954!2d28.828790215630196!3d47.021625879150214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c3230580131%3A0x6eeeb9b58948b143!2sStrada%2031%20August%201989%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sro!2s!4v1671648623686!5m2!1sro!2s"
                    allowFullScreen="" title="location" loading="lazy"
                    className="w-full h-full object-cover"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </section>
           <section className="container">
               <div className="contact-form">
                   <h2 className="sub-title">Scrie-ne un mesaj și te vom contacta cât de curând posibil</h2>
                   <ContactForm />
               </div>
           </section>
        </main>
    );
}

export default Contact;
