import React, {useEffect, useState} from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react"
import Dropzone from "components/Dropzone/Dropzone";
import {useDispatch, useSelector} from "react-redux";
import {howEditWorkActions} from "../../../../../redux/actions/howWorkApi";
import {useParams, useNavigate} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

const HowItWorkEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const howWork = useSelector(state => state.howWork.howWork);
  const auth = useSelector(state => state.auth);
  const [token, setToken] = useState(auth.token);
  const dispatch = useDispatch();

  useEffect(() => {

    // Check if token is null and set it from localStorage if available
    if (token === null) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }

  }, [token]);

  const item = howWork.filter(item => item.Id === parseInt(id))[0];

  const SignupSchema = Yup.object({
    arTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    enTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    esTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    frTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    grTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    isTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    roTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    ruTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    uaTitle: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    order: Yup.number()
           .required()
  });

  const handleClose = () => {
    navigate('/dashboard/how-it-work');
  }

  return (
      <section className="profile-settings">
        <div className="profile-settings-dictionary__heading">
          <h3>How It Works</h3>
        </div>

        <section className="profile-settings-dictionary__content addBorder">
          <div className="form-section">
            <Formik  initialValues={
              {
                order: item?.Order,
                image: item?.Image ?? null,
                arTitle: item?.Title['ar'],
                enTitle: item?.Title['en'],
                esTitle: item?.Title['es'],
                frTitle: item?.Title['fr'],
                grTitle: item?.Title['gr'],
                isTitle: item?.Title['is'],
                roTitle: item?.Title['ro'],
                ruTitle: item?.Title['ru'],
                uaTitle: item?.Title['ua'],
                arDescription: item?.Description['ar'],
                enDescription: item?.Description['en'],
                esDescription: item?.Description['es'],
                frDescription: item?.Description['fr'],
                grDescription: item?.Description['gr'],
                isDescription: item?.Description['is'],
                roDescription: item?.Description['ro'],
                ruDescription: item?.Description['ru'],
                uaDescription: item?.Description['ua'],
              }
            }
                     validationSchema={SignupSchema}
                     onSubmit={(values) => {
                       const data = {
                         Id: id,
                         data: {
                           order: values.order,
                           image: values.image,
                           title: {
                             ar: values.arTitle,
                             en: values.enTitle,
                             es: values.esTitle,
                             fr: values.frTitle,
                             gr: values.grTitle,
                             is: values.isTitle,
                             ro: values.roTitle,
                             ru: values.ruTitle,
                             ua: values.uaTitle,
                           },
                           description: {
                             ar: values.arDescription,
                             en: values.enDescription,
                             es: values.esDescription,
                             fr: values.frDescription,
                             gr: values.grDescription,
                             is: values.isDescription,
                             ro: values.roDescription,
                             ru: values.ruDescription,
                             ua: values.uaDescription,
                           }
                         },
                         token: token
                       }

                       // handleEditData(false);
                       dispatch(howEditWorkActions(data)).then(navigate('/dashboard/how-it-work'));
                     }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <div className="formContent">
                      <div className="TitleSection">
                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="SA"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Saudi Arabia"
                        />
                      </span>
                          <Field type="text" name="arTitle" placeholder="Title" />
                          <ErrorMessage name="arTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="GB"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="United Kingdom"
                        />
                      </span>
                          <Field type="text" name="enTitle" placeholder="Title" />
                          <ErrorMessage name="entitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="ES"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Spain"
                        />
                      </span>
                          <Field type="text" name="esTitle" placeholder="Title" />
                          <ErrorMessage name="esTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="FR"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="France"
                        />
                      </span>
                          <Field type="text" name="frTitle" placeholder="Title" />
                          <ErrorMessage name="frTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="DE"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Germany"
                        />
                      </span>
                          <Field type="text" name="grTitle" placeholder="Title" />
                          <ErrorMessage name="grTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="IS"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Iceland"
                        />
                      </span>
                          <Field type="text" name="isTitle" placeholder="Title" />
                          <ErrorMessage name="isTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="RO"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Romania"
                        />
                      </span>
                          <Field type="text" name="roTitle" placeholder="Title" />
                          <ErrorMessage name="roTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="RU"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Russia"
                        />
                      </span>
                          <Field type="text" name="ruTitle" placeholder="Title" />
                          <ErrorMessage name="ruTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="ua"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Ukraine"
                        />
                      </span>
                          <Field type="text" name="uaTitle" placeholder="Title" />
                          <ErrorMessage name="uaTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>
                      </div>

                      <span className="title-head-settings">Description</span>
                      <div className="DescriptionSection">
                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('arDescription', data);
                              }}
                              data={values.arDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('enDescription', data);
                              }}
                              data={values.enDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('esDescription', data);
                              }}
                              data={values.esDescription}
                          />
                          <ErrorMessage name="esTitle" render={msg => <div className="error-message">{msg}</div>} />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('frDescription', data);
                              }}
                              data={values.frDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('grDescription', data);
                              }}
                              data={values.grDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('isDescription', data);
                              }}
                              data={values.isDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('roDescription', data);
                              }}
                              data={values.roDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('ruDescription', data);
                              }}
                              data={values.ruDescription}
                          />
                        </div>

                        <div className="form-input">
                          <CKEditor
                              editor={ClassicEditor}
                              onInit={editor => {}}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('uaDescription', data);
                              }}
                              data={values.uaDescription}
                          />
                        </div>
                      </div>


                      <span className="title-head-settings">Order</span>
                      <div className="TitleSection">
                        <div className="form-input">
                          <span className="icon"></span>
                          <Field type="text" name="order" placeholder="Order" />
                          <ErrorMessage name="order" render={msg => <div className="error-message">{msg}</div>} />
                        </div>
                      </div>

                      <div className="form-message">
                        <Dropzone image={values.image} onFileSelect={(file => setFieldValue('image', file))} />
                      </div>
                    </div>

                    <div className="actions-button-section">
                      <button onClick={() => handleClose()} className="mr-2">Close</button>
                      <button type="submit">Save</button>
                    </div>
                  </Form>
              )}
            </Formik>
          </div>
        </section>
      </section>
  );
}

export default HowItWorkEdit;
