import React from 'react';
import './Job.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUserSlash,
    faLocationArrow,
    faCalendarCheck,
    faPencil,
    faAnglesUp,
    faRotateRight,
    faTrashCan,
    faPause
} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import {Link} from "react-router-dom";

const ActiveJobItem = ({job}) => {
    return (
        <section className="active-job-item">
           <div className="active-job-item-head">
               <span className="title-active-job">{job?.Title}</span>
               <span className="active-job-cv">2 CV-uri</span>
           </div>
           <div className="active-job-info">
               <span><FontAwesomeIcon icon={faUserSlash} />Suspendat</span>
               <div className="break"></div>
               <span><FontAwesomeIcon icon={faLocationArrow} />{job?.Country}</span>
               <div className="break"></div>
               <span><FontAwesomeIcon icon={faCalendarCheck} /><Moment local date={job?.CreatedAt} format="DD MMM YYYY, hh:mm"/></span>
           </div>
           <div className="active-job-category">
               <span>Category</span>
               <span>Vanzari</span>
           </div>
           <div className="break"></div>
           <div className="active-job-actions">
               <span><span className="icon"><FontAwesomeIcon icon={faPause} /></span>Suspendeaza</span>
               <span><span className="icon"><FontAwesomeIcon icon={faPencil} /></span><Link to={'/dashboard/job/' + job.Id}>Redacteaza</Link></span>
               <span><span className="icon"><FontAwesomeIcon icon={faAnglesUp} /></span>Ridica in top</span>
               <span><span className="icon"><FontAwesomeIcon icon={faRotateRight} /></span>Activare ridicare automata</span>
               <span><span className="icon"><FontAwesomeIcon icon={faTrashCan} /></span>Sterge</span>
           </div>
        </section>
    );
}

export default ActiveJobItem;
