import React from 'react';
import "./HowItWork.scss";
const HowItWork = (props) => {
    const {title, data} = props;
    return (
        <section className="howWorks">
            <h3 className="title-head">{title}</h3>

            <div className="container relative">
                <div className="step-content">
                    {
                        data.map((item,key) =>
                            <div className={'step-'+ (key +1) } key={key +1}>
                                <div className="step-nr">{key + 1+" /"}</div>
                                <div className="step-text">
                                    <span>
                                        <span className="mainTitle">{item.title}</span>
                                        <span className="subTitle">{item.subTitle}</span>
                                    </span>

                                    <div className="step-image">
                                        <img src={require("assets/images/1500-02.jpg")} alt="step1"/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </section>
    );
}

export default HowItWork;
