import React from 'react';
import { usePagination} from '../../hooks/usePagination';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./Pagination.scss";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        template
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        template
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <section className="pagination">
            <ul className={template}>
                {/* Left navigation arrow */}
                <li onClick={onPrevious} className={currentPage === 1 ? 'arrow disabled' : 'arrow'}><FontAwesomeIcon icon={faChevronLeft} /></li>
                {paginationRange.map(pageNumber => {
                    // Render our Page Pills
                    return (
                        <li onClick={() => onPageChange(pageNumber)} className={pageNumber === currentPage ? 'active item-page' : 'item-page'} key={pageNumber} >{pageNumber}</li>
                    );
                })}
                {/*  Right Navigation arrow */}
                <li onClick={onNext} className={currentPage === lastPage ? 'arrow disabled' : "arrow"}><FontAwesomeIcon icon={faChevronRight} /></li>
            </ul>
        </section>
    );
};

export default Pagination;

