import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import EditCurrentQuestion from "./EditCurrentQuestion";
import {addNewQuiz} from "../../../redux/reducer/quizzReducer";

const AddQuizz = (props) => {
    const {closeQuizz} = props;
    const quizzCount = useSelector(state => state.quizz.quizList.length);
    const [category, setCategory] = useState();
    const [titleQuiz, setTitleQuiz]= useState("");
    const [questions, setQuestions] = useState([
        {
            "id": 1,
            "question": "",
            "answers": ["","","",""],
            "correctAnswers": ""
        }
    ]);
    const dispatch = useDispatch();

    let allQuizzList = {
        "idCategory": quizzCount + 1,
        "Title": titleQuiz,
        "Category": category
    };

    const handleAddNewQuestion = () => {
        const newQuestion = {
            "id": questions.length + 1,
            "question": "",
            "answers": ["","","",""],
            "correctAnswers": ""
        }

        setQuestions([...questions,{...newQuestion}]);
    }

    const resultEdited = (value) => {
        let dataQuiz = [];
        questions.map(question => {
            if(question.id === value.id){
                dataQuiz = [...dataQuiz,{...value}];
            } else {
                dataQuiz = [...dataQuiz, {...question}];
            }
        });

        allQuizzList = {...allQuizzList, ...{'Quiz':dataQuiz}};
    }

    const handleAddQuizz = () => {
        dispatch(addNewQuiz(allQuizzList));
        closeQuizz(false)
    }

    return (
        <section className="add-quizz-section">
            <div className="add-quizz-section__header">
               <span className="title-add-quizz">Creare test</span>
               <div className="header-actions-quizz">
                   <div>
                       <svg xmlns="http://www.w3.org/2000/svg" width="25.844" height="14.386" viewBox="935.105 261 25.844 14.386"><path d="m952.385 261 8.564 8.564-2.915 2.92-5.649-5.65-1.985 1.994-2.924-2.92 4.91-4.908Zm-5.809 5.813 2.915 2.924 2.743 2.734-2.924 2.915-5.64-5.641-4.497 4.497-1.153 1.144-2.915-2.915 8.565-8.564 2.906 2.906Zm0 0" fill="#fff" fillRule="evenodd" data-name="Icon simple-codeclimate"/></svg>
                       <select defaultValue={category} onChange={e => setCategory(e.target.value)}>
                           <option value="">Domeniu</option>
                           <option value="it">It</option>
                           <option value="business">Business</option>
                       </select>
                   </div>
                   <div onClick={() => handleAddNewQuestion()}>
                       <svg xmlns="http://www.w3.org/2000/svg" width="15.949" height="15.949" viewBox="796 261 15.949 15.949"><path d="M811.152 264.19h-1.595v7.177H799.19v1.595a.8.8 0 0 0 .797.797h8.772l3.19 3.19v-11.962a.8.8 0 0 0-.797-.797Zm-3.19 4.785v-7.178a.8.8 0 0 0-.798-.797h-10.367a.8.8 0 0 0-.797.797v11.165l3.19-3.19h7.974a.8.8 0 0 0 .798-.797Z" fill="#fff" fillRule="evenodd" data-name="Icon material-question-answer"/></svg>
                       Adaugare intrebare <FontAwesomeIcon icon={faPlus} />
                   </div>
               </div>
            </div>
            <div className="add-quizz-section__header__second">
                <span className="quizz-main-title"><input type="text" value={titleQuiz} placeholder="Nume Test" onChange={e => setTitleQuiz(e.target.value)} style={{outline:"none"}} /></span>
                <span className="domain-quizz">{category !== '' && category !== undefined ? category : 'Domeniu'}</span>
            </div>
            <div className="add-quizz-section__content">
                {  questions.map(question => <EditCurrentQuestion question={question} key={question.id} resultEdited={resultEdited} />)}
            </div>
            <div className="add-quizz-section__footer">
               <button className="addQuizz" onClick={() => handleAddNewQuestion()}>
                   <span className="icon">
                       <FontAwesomeIcon icon={faPlus} />
                   </span>
                   Adauga intrebare
               </button>
               <div className="footer-action-quizz">
                   <button className="quizz-button" onClick={() => handleAddQuizz()}>Salveaza</button>
                   <button className="quizz-button" onClick={() => closeQuizz(false)}>Cancel</button>
               </div>
            </div>
        </section>
    );
}

export default AddQuizz;
