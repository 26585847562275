import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {companyCreateActions, companyEditActions} from "../../../../redux/actions/companyApi";
import {toast, ToastContainer} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSave} from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Dropzone from "components/Dropzone/Dropzone";
import {updateCompany} from "../../../../redux/reducer/userReducer";

const AboutCompany = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const {error} = useSelector(state => state.company);
    const {company, loader} = useSelector(state => state.user)
    const [token, setToken] = useState(auth.token);

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const SignupSchema = Yup.object({
        titleCompany: Yup.string()
            .required('Title is must required')
            .min(2,'Title must have at least 2 characters')
            .max(150, 'Title must must be 150 characters or less'),
        name: Yup.string()
            .min(2,'Name must have at least 2 characters')
            .max(150, 'Name must must be 150 characters or less'),
        surname: Yup.string()
            .min(2,'Surname must have at least 2 characters')
            .max(150, 'Surname must must be 150 characters or less'),
        email: Yup.string()
            .email('Email is invalid')
            .max(100, 'Email must must be 100 characters or less'),
        IDNO: Yup.number()
            .test('len', 'IDNO must be exactly 13 characters of type numeric', (val) => {
                if (val === undefined) {
                    return false; // Return false if val is undefined
                }
                return val.toString().length === 13;
            })
            .typeError('IDNO must be a number'), // Add a custom type error message
    });

    return (
        <div className="personal-information">
            <Formik  initialValues={
                {
                    titleCompany: company?.Title ?? '',
                    name: company?.Name ?? '',
                    surname: company?.Surname ?? '',
                    email: company?.Email ?? '',
                    IDNO: company?.IDNO ?? '',
                    logo: company?.Logo ?? null,
                }
            }
                     validationSchema={SignupSchema}
                     onSubmit={(values) => {

                         const data = {
                             data: values,
                             token: token
                         }

                         if(company === null){
                             dispatch(companyCreateActions({data: data, token})).then(res => {
                                 if(res.payload.errors){
                                     toast.error("Something is wrong, try again",{
                                         position: toast.POSITION.TOP_CENTER
                                     });
                                 } else if(error){
                                     toast.error(error,{
                                         position: toast.POSITION.TOP_CENTER
                                     });
                                 } else {
                                     dispatch(updateCompany(res.payload));
                                     toast.success("Successfully Saved", {
                                         position: toast.POSITION.TOP_CENTER,
                                     });
                                 }
                             });
                         } else {
                             dispatch(companyEditActions({Id: company.Id, data: data, token})).then(res => {
                                 if(error || res.payload === undefined){
                                     toast.error("Something is wrong, try again",{
                                         position: toast.POSITION.TOP_CENTER
                                     });
                                 } else {
                                     dispatch(updateCompany(res.payload));
                                     toast.success("Successfully Saved", {
                                         position: toast.POSITION.TOP_CENTER,
                                     });
                                 }
                             });
                         }
                     }}
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <Form className="Form companyForm" autoComplete="off">
                        <div className="personal-information-section">
                            <span className="title-section">Date despre companie</span>
                            <div className="personal-information-input">
                                <Field type="text" name="titleCompany" placeholder="Company Name" />
                                <ErrorMessage name="titleCompany" render={msg => <span className="form-input-error">{msg}</span>} />
                            </div>

                            <div className="personal-information-input">
                                <Field type="text" name="name" placeholder="Name" />
                                <ErrorMessage name="name" render={msg => <span className="form-input-error">{msg}</span>} />
                            </div>

                            <div className="personal-information-input">
                                <Field type="text" name="surname" placeholder="Surname" />
                                <ErrorMessage name="surname" render={msg => <span className="form-input-error">{msg}</span>} />
                            </div>

                            <div className="personal-information-input">
                                <Field type="text" name="email" placeholder="Email" />
                                <ErrorMessage name="email" render={msg => <span className="form-input-error">{msg}</span>} />
                            </div>

                            <div className="personal-information-input">
                                <Field type="text" name="IDNO" placeholder="IDNO" />
                                <ErrorMessage name="IDNO" render={msg => <span className="form-input-error">{msg}</span>} />
                            </div>

                            <div className="personal-information-input"></div>
                            <div className="personal-information-input">
                                <Dropzone image={values.logo} onFileSelect={(file => setFieldValue('logo', file))} />
                            </div>

                            <div className="personal-information-action">
                                <button className="btn" type="submit"><FontAwesomeIcon icon={faSave}/> Save</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <ToastContainer autoClose={3000} />
        </div>
    );
}

export default AboutCompany;
