import React from 'react';
import {Link} from "react-router-dom";

const FavouriteEmployer = ({item}) => {
    return (
        <div className="favourite_employer_item">
            <Link to={`vacancies?company=${item.Id}`}  className="block w-full h-full overflow-hidden">
                <img className="w-full h-full object-contain" src={item.Logo} alt={item.Title}/>
            </Link>
        </div>
    )
}

export default FavouriteEmployer;
