import React from 'react';
import PersonalInformationCV from "./PersonaInformationCV";
import MyExperience from './MyExperience';
import './CV.scss';
import EducationSection from "./EducationSection";
import MyActivity from "./MyActivity";
import Analytics from "./Analytics";
import Resources from "./Resources";
import TakeAskillQuiz from "./TakeAskillQuiz";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import MyInterests from "./MyInterests";
import CvSlider from "./CvSlider";

const CV = ({user}) => {

    return (
        <section className="main-cv-content">
            <PersonalInformationCV user={user}/>

            <section className="cv-other-section-content">
                <CvSlider />
            </section>

            <section className="cv-other-section-content">
                <TakeAskillQuiz />
            </section>

            <section className="cv-other-section-content">
                <Analytics />
            </section>

            <section className="cv-other-section-content">
                <Resources />
            </section>

            <section className="cv-other-section-content">
                <MyActivity />
            </section>

            <section className="cv-other-section-content">
                <MyExperience  />
            </section>

            <section className="cv-other-section-content">
                <EducationSection />
            </section>

            <section className="cv-other-section-content">
                <MyInterests />
            </section>

            <div className="action"><button><FontAwesomeIcon icon={faSave} />Save</button></div>
        </section>
    );
}

export default CV;
