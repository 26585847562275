import React, {useEffect, useState} from 'react';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {Field, Formik, Form} from 'formik';
import ReactCountryFlag from "react-country-flag";
import {useDispatch, useSelector} from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
    resOpportunityGetOpportunityActions, resOpportunityEditOpportunityActions
} from "../../../../redux/actions/resultOpportunityApi";
import {toast, ToastContainer} from "react-toastify";
import {aboutGetAboutActions, aboutEditAboutActions} from "../../../../redux/actions/aboutApi";
import Dropzone from "../../../../components/Dropzone/Dropzone";


const HomeSettings = () => {
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const {resultOpportunity} = useSelector(state => state.resultOpportunity);
    const {about} = useSelector(state => state.about);
    const dispatch = useDispatch();

    useEffect(() => {

        // Check if token is null and set it from localStorage if available
        if (token === null) {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                setToken(storedToken);
            }
        }
        dispatch(resOpportunityGetOpportunityActions());
        dispatch(aboutGetAboutActions());
    }, [token]);

    return (
        <section className="profile-settings">
            <div className="opportunity-settings">
                <div className="opportunity-settings__heading">
                    <h3>Oportunități</h3>
                </div>

                <div className="opportunity-settings__content">
                    <div className="form-section">
                        <Formik initialValues={{
                            arTitle: resultOpportunity?.Title['ar'],
                            enTitle: resultOpportunity?.Title['en'],
                            esTitle: resultOpportunity?.Title['es'],
                            frTitle: resultOpportunity?.Title['fr'],
                            grTitle: resultOpportunity?.Title['gr'],
                            isTitle: resultOpportunity?.Title['is'],
                            roTitle: resultOpportunity?.Title['ro'],
                            ruTitle: resultOpportunity?.Title['ru'],
                            uaTitle: resultOpportunity?.Title['ua'],
                            arDescription: resultOpportunity?.Description['ar'],
                            enDescription: resultOpportunity?.Description['en'],
                            esDescription: resultOpportunity?.Description['es'],
                            frDescription: resultOpportunity?.Description['fr'],
                            grDescription: resultOpportunity?.Description['gr'],
                            isDescription: resultOpportunity?.Description['is'],
                            roDescription: resultOpportunity?.Description['ro'],
                            ruDescription: resultOpportunity?.Description['ru'],
                            uaDescription: resultOpportunity?.Description['ua'],
                            arTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['ar'],
                            enTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['en'],
                            esTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['es'],
                            frTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['fr'],
                            grTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['gr'],
                            isTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['is'],
                            roTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['ro'],
                            ruTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['ru'],
                            uaTitleProgressFirst: resultOpportunity?.Progress[0]['Title']['ua'],
                            arTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['ar'],
                            enTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['en'],
                            esTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['es'],
                            frTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['fr'],
                            grTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['gr'],
                            isTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['is'],
                            roTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['ro'],
                            ruTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['ru'],
                            uaTitleProgressSecond: resultOpportunity?.Progress[1]['Title']['ua'],
                            arTitleProgressThird: resultOpportunity?.Progress[2]['Title']['ar'],
                            enTitleProgressThird: resultOpportunity?.Progress[2]['Title']['en'],
                            esTitleProgressThird: resultOpportunity?.Progress[2]['Title']['es'],
                            frTitleProgressThird: resultOpportunity?.Progress[2]['Title']['fr'],
                            grTitleProgressThird: resultOpportunity?.Progress[2]['Title']['gr'],
                            isTitleProgressThird: resultOpportunity?.Progress[2]['Title']['is'],
                            roTitleProgressThird: resultOpportunity?.Progress[2]['Title']['ro'],
                            ruTitleProgressThird: resultOpportunity?.Progress[2]['Title']['ru'],
                            uaTitleProgressThird: resultOpportunity?.Progress[2]['Title']['ua'],
                            arTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['ar'],
                            enTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['en'],
                            esTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['es'],
                            frTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['fr'],
                            grTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['gr'],
                            isTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['is'],
                            roTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['ro'],
                            ruTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['ru'],
                            uaTitleProgressFourth: resultOpportunity?.Progress[3]['Title']['ua'],
                            progressBarValue: resultOpportunity?.Progress[0]['Value'],
                            progressBarSecondValue: resultOpportunity?.Progress[1]['Value'],
                            progressBarThirdValue: resultOpportunity?.Progress[2]['Value'],
                            progressBarFourthValue: resultOpportunity?.Progress[3]['Value']
                        }}
                                onSubmit={(values) => {
                                    const data = {
                                        Id: resultOpportunity?.Id, data: {
                                            title: {
                                                ar: values.arTitle,
                                                en: values.enTitle,
                                                es: values.esTitle,
                                                fr: values.frTitle,
                                                gr: values.grTitle,
                                                is: values.isTitle,
                                                ro: values.roTitle,
                                                ru: values.ruTitle,
                                                ua: values.uaTitle,
                                            },
                                            description: {
                                                ar: values.arDescription,
                                                en: values.enDescription,
                                                es: values.esDescription,
                                                fr: values.frDescription,
                                                gr: values.grDescription,
                                                is: values.isDescription,
                                                ro: values.roDescription,
                                                ru: values.ruDescription,
                                                ua: values.uaDescription,
                                            },
                                            progressBar: {
                                                ar: values.arTitleProgressFirst,
                                                en: values.enTitleProgressFirst,
                                                es: values.esTitleProgressFirst,
                                                fr: values.frTitleProgressFirst,
                                                gr: values.grTitleProgressFirst,
                                                is: values.isTitleProgressFirst,
                                                ro: values.roTitleProgressFirst,
                                                ru: values.ruTitleProgressFirst,
                                                ua: values.uaTitleProgressFirst,
                                            },
                                            progressBarSecond: {
                                                ar: values.arTitleProgressSecond,
                                                en: values.enTitleProgressSecond,
                                                es: values.esTitleProgressSecond,
                                                fr: values.frTitleProgressSecond,
                                                gr: values.grTitleProgressSecond,
                                                is: values.isTitleProgressSecond,
                                                ro: values.roTitleProgressSecond,
                                                ru: values.ruTitleProgressSecond,
                                                ua: values.uaTitleProgressSecond,
                                            },
                                            progressBarThird: {
                                                ar: values.arTitleProgressThird,
                                                en: values.enTitleProgressThird,
                                                es: values.esTitleProgressThird,
                                                fr: values.frTitleProgressThird,
                                                gr: values.grTitleProgressThird,
                                                is: values.isTitleProgressThird,
                                                ro: values.roTitleProgressThird,
                                                ru: values.ruTitleProgressThird,
                                                ua: values.uaTitleProgressThird,
                                            },
                                            progressBarFourth: {
                                                ar: values.arTitleProgressFourth,
                                                en: values.enTitleProgressFourth,
                                                es: values.esTitleProgressFourth,
                                                fr: values.frTitleProgressFourth,
                                                gr: values.grTitleProgressFourth,
                                                is: values.isTitleProgressFourth,
                                                ro: values.roTitleProgressFourth,
                                                ru: values.ruTitleProgressFourth,
                                                ua: values.uaTitleProgressFourth,
                                            },
                                            progressBarValue: values.progressBarValue,
                                            progressBarSecondValue: values.progressBarSecondValue,
                                            progressBarThirdValue: values.progressBarThirdValue,
                                            progressBarFourthValue: values.progressBarFourthValue
                                        }, token: token
                                    }

                                    // handleEditData(false);
                                    dispatch(resOpportunityEditOpportunityActions(data)).then(res => {
                                        toast.success("Successfully Saved", {
                                            position: toast.POSITION.TOP_CENTER,
                                        });
                                    });
                                }}
                        >
                            {({errors, touched, values, setFieldValue}) => (<Form>
                                    <div className="formContent">
                                        <span className="title-head-settings">Titlul</span>
                                        <div className="TitleSection">
                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="SA"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Saudi Arabia"
                        />
                      </span>
                                                <Field type="text" name="arTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="GB"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="United Kingdom"
                        />
                      </span>
                                                <Field type="text" name="enTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="ES"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Spain"
                        />
                      </span>
                                                <Field type="text" name="esTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="FR"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="France"
                        />
                      </span>
                                                <Field type="text" name="frTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="DE"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Germany"
                        />
                      </span>
                                                <Field type="text" name="grTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="IS"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Iceland"
                        />
                      </span>
                                                <Field type="text" name="isTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="RO"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Romania"
                        />
                      </span>
                                                <Field type="text" name="roTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="RU"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Russia"
                        />
                      </span>
                                                <Field type="text" name="ruTitle" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="ua"
                            style={{
                                fontSize: '2em', lineHeight: '2em',
                            }}
                            aria-label="Ukraine"
                        />
                      </span>
                                                <Field type="text" name="uaTitle" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <span className="title-head-settings">Descrierea</span>
                                        <div className="DescriptionSection">
                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('arDescription', data);
                                                    }}
                                                    data={values.arDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('enDescription', data);
                                                    }}
                                                    data={values.enDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('esDescription', data);
                                                    }}
                                                    data={values.esDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('frDescription', data);
                                                    }}
                                                    data={values.frDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('grDescription', data);
                                                    }}
                                                    data={values.grDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('isDescription', data);
                                                    }}
                                                    data={values.isDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('roDescription', data);
                                                    }}
                                                    data={values.roDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('ruDescription', data);
                                                    }}
                                                    data={values.ruDescription}
                                                />
                                            </div>

                                            <div className="form-input">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onInit={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue('uaDescription', data);
                                                    }}
                                                    data={values.uaDescription}
                                                />
                                            </div>
                                        </div>
                                        <span className="title-head-settings">Progress Bar Label</span>
                                        <div className="ProgressBar">
                                            <div className="form-input">
                                                <Field type="text" name="arTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="enTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="esTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="frTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="grTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="isTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="roTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="ruTitleProgressFirst" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="uaTitleProgressFirst" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <span className="title-head-settings">Progress Bar Label</span>
                                        <div className="ProgressBar">
                                            <div className="form-input">
                                                <Field type="text" name="arTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="enTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="esTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="frTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="grTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="isTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="roTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="ruTitleProgressSecond" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="uaTitleProgressSecond" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <span className="title-head-settings">Progress Bar Label</span>
                                        <div className="ProgressBar">
                                            <div className="form-input">
                                                <Field type="text" name="arTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="enTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="esTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="frTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="grTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="isTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="roTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="ruTitleProgressThird" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="uaTitleProgressThird" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <span className="title-head-settings">Progress Bar Label</span>
                                        <div className="ProgressBar">
                                            <div className="form-input">
                                                <Field type="text" name="arTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="enTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="esTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="frTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="grTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="isTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="roTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="ruTitleProgressFourth" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="uaTitleProgressFourth" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <span className="title-head-settings">Progress Bar Values</span>
                                        <div className="ProgressBar">
                                            <div className="form-input">
                                                <Field type="text" name="progressBarValue" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="progressBarSecondValue" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="progressBarThirdValue" placeholder="Title"/>
                                            </div>

                                            <div className="form-input">
                                                <Field type="text" name="progressBarFourthValue" placeholder="Title"/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="actions-button-section">
                                        <button type="submit">Save</button>
                                    </div>
                                </Form>)}
                        </Formik>
                    </div>
                </div>
                <ToastContainer autoClose={3000} />
            </div>

            <div className="opportunity-settings">
                <div className="opportunity-settings__heading">
                    <h3>Despre noi</h3>
                </div>

                <div className="opportunity-settings__content">
                    <div className="form-section">
                        <Formik initialValues={{
                            arTitleAbout: about?.Title['ar'],
                            enTitleAbout: about?.Title['en'],
                            esTitleAbout: about?.Title['es'],
                            frTitleAbout: about?.Title['fr'],
                            grTitleAbout: about?.Title['gr'],
                            isTitleAbout: about?.Title['is'],
                            roTitleAbout: about?.Title['ro'],
                            ruTitleAbout: about?.Title['ru'],
                            uaTitleAbout: about?.Title['ua'],
                            arDescriptionAbout: about?.Description['ar'],
                            enDescriptionAbout: about?.Description['en'],
                            esDescriptionAbout: about?.Description['es'],
                            frDescriptionAbout: about?.Description['fr'],
                            grDescriptionAbout: about?.Description['gr'],
                            isDescriptionAbout: about?.Description['is'],
                            roDescriptionAbout: about?.Description['ro'],
                            ruDescriptionAbout: about?.Description['ru'],
                            uaDescriptionAbout: about?.Description['ua'],
                            image: about?.Image ?? null,
                        }}
                                onSubmit={(values) => {
                                    const data = {
                                        Id: resultOpportunity?.Id, data: {
                                            title: {
                                                ar: values.arTitleAbout,
                                                en: values.enTitleAbout,
                                                es: values.esTitleAbout,
                                                fr: values.frTitleAbout,
                                                gr: values.grTitleAbout,
                                                is: values.isTitleAbout,
                                                ro: values.roTitleAbout,
                                                ru: values.ruTitleAbout,
                                                ua: values.uaTitleAbout,
                                            },
                                            description: {
                                                ar: values.arDescriptionAbout,
                                                en: values.enDescriptionAbout,
                                                es: values.esDescriptionAbout,
                                                fr: values.frDescriptionAbout,
                                                gr: values.grDescriptionAbout,
                                                is: values.isDescriptionAbout,
                                                ro: values.roDescriptionAbout,
                                                ru: values.ruDescriptionAbout,
                                                ua: values.uaDescriptionAbout,
                                            },
                                        }, token: token
                                    }

                                    // handleEditData(false);
                                    dispatch(aboutEditAboutActions(data)).then(res => {
                                        toast.success("Successfully Saved", {
                                            position: toast.POSITION.TOP_CENTER,
                                        });
                                    });
                                }}
                        >
                            {({errors, touched, values, setFieldValue}) => (<Form>
                                <div className="formContent">
                                    <span className="title-head-settings">Titlul</span>
                                    <div className="TitleSection">
                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="SA"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Saudi Arabia"
                            />
                          </span>
                                            <Field type="text" name="arTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="GB"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="United Kingdom"
                            />
                          </span>
                                            <Field type="text" name="enTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ES"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Spain"
                            />
                          </span>
                                            <Field type="text" name="esTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="FR"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="France"
                            />
                          </span>
                                            <Field type="text" name="frTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="DE"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Germany"
                            />
                          </span>
                                            <Field type="text" name="grTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="IS"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Iceland"
                            />
                          </span>
                                            <Field type="text" name="isTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RO"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Romania"
                            />
                          </span>
                                            <Field type="text" name="roTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RU"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Russia"
                            />
                          </span>
                                            <Field type="text" name="ruTitleAbout" placeholder="Title"/>
                                        </div>

                                        <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ua"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Ukraine"
                            />
                          </span>
                                            <Field type="text" name="uaTitleAbout" placeholder="Title"/>
                                        </div>
                                    </div>
                                    <span className="title-head-settings">Descrierea</span>
                                    <div className="DescriptionSection">
                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('arDescriptionAbout', data);
                                                }}
                                                data={values.arDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('enDescriptionAbout', data);
                                                }}
                                                data={values.enDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('esDescriptionAbout', data);
                                                }}
                                                data={values.esDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('frDescriptionAbout', data);
                                                }}
                                                data={values.frDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('grDescriptionAbout', data);
                                                }}
                                                data={values.grDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('isDescriptionAbout', data);
                                                }}
                                                data={values.isDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('roDescriptionAbout', data);
                                                }}
                                                data={values.roDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('ruDescriptionAbout', data);
                                                }}
                                                data={values.ruDescriptionAbout}
                                            />
                                        </div>

                                        <div className="form-input">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFieldValue('uaDescriptionAbout', data);
                                                }}
                                                data={values.uaDescriptionAbout}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-message">
                                        <Dropzone image={values.image} onFileSelect={(file => setFieldValue('image', file))} />
                                    </div>
                                </div>


                                <div className="actions-button-section">
                                    <button type="submit">Save</button>
                                </div>
                            </Form>)}
                        </Formik>
                    </div>
                </div>
                <ToastContainer autoClose={3000} />
            </div>
        </section>
    );
}

export default HomeSettings;
