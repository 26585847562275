import {createSlice} from "@reduxjs/toolkit";
import {settingGetSettingActions, settingEditSettingActions} from "../actions/settingApi";

const settingReducer = createSlice({
    name: 'setting',
    initialState: {
        setting: null,
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(settingGetSettingActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(settingGetSettingActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.setting = payload;
                }
            })

            .addCase(settingGetSettingActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(settingEditSettingActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(settingEditSettingActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.setting = payload.data
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(settingEditSettingActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })
    }
});

export default settingReducer.reducer;
