import React, {useMemo, useState} from 'react';
import Pagination from "components/Pagination/Pagination";
import {useSelector} from "react-redux";
import TrainerCertificateItem from "components/Profiles/MyCertifacte/TrainerCertificateItem";

const TrainerCertificate = () => {
  let PageSize = 3;
  const certificates = useSelector(state => state.certificate.certificate);

  const [currentPage, setCurrentPage] = useState(1);

  const MyCertificats = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return certificates.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

    return (
        <section className="trainer-certificate-section">
          {MyCertificats.map(certificate => <TrainerCertificateItem key={certificate.id} certificate={certificate} />)}

          <Pagination
              currentPage={currentPage}
              totalCount={certificates.length}
              pageSize={PageSize}
              template="template-two"
              onPageChange={page => setCurrentPage(page)}
          />
        </section>
    );
}

export default TrainerCertificate;
