import {createSlice} from "@reduxjs/toolkit";

const interestsReducer = createSlice({
    name: 'interests',
    initialState: {
        interests: [
            {
                id: 0,
                fullName: 'Jamie Dimon',
                job: 'Chairman and CEO of JP Morgan Chace & Co',
                followers: '1395216',
                image: '',
            },
            {
                id: 1,
                fullName: 'Jamie Dimon',
                job: 'Chairman and CEO of JP Morgan Chace & Co',
                followers: '1395216',
                image: '',
            },
            {
                id: 2,
                fullName: 'Jamie Dimon',
                job: 'Chairman and CEO of JP Morgan Chace & Co',
                followers: '1395216',
                image: '',
            },
            {
                id: 3,
                fullName: 'Jamie Dimon',
                job: 'Chairman and CEO of JP Morgan Chace & Co',
                followers: '1395216',
                image: '',
            },
        ]
    },
    reducers: {
        editInterestsItem: (state,action) => {

        },

        addNewInterests: (state, action) => {
        },

        unfollowing: (state, action) => {
            state.interests = state.interests.filter(item => item.id !== action.payload.id)
        }
    }
});

export default interestsReducer.reducer;
export const { editInterestsItem, addNewInterests, unfollowing } = interestsReducer.actions
