import React, {useEffect, useMemo, useState} from 'react';
import CreateQuiz from "components/Profiles/MyQuiz/CreateQuiz";



const QuizList = (props) => {
  return (
      <CreateQuiz />
  );
}

export default QuizList;
