import React, {useEffect, useState} from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Dropzone from "components/Dropzone/Dropzone";
import {useDispatch, useSelector} from "react-redux";
import {advisersDeleteActions, advisersEditActions} from "../../../../../redux/actions/advisersApi";
import {useParams, useNavigate} from "react-router-dom";

const AdvisersEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const advisers = useSelector(state => state.advisers.advisers);
  const auth = useSelector(state => state.auth);
  const [token, setToken] = useState(auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if token is null and set it from localStorage if available
    if (token === null) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }

  }, [token]);

  const item = advisers.filter(item => item.Id === parseInt(id))[0];

  const SignupSchema = Yup.object({
    name: Yup.string()
        .min(2,'Name must be 2 characters or more')
        .max(150,'Name must be 150 characters or less')
        .required('Name is a required field'),
    surname: Yup.string()
        .min(2,'surname must be 2 characters or more')
        .max(150,'Surname must be 150 characters or less')
        .required('Surname is a required field'),
  });

  const handleClose = () => {
    navigate('/dashboard/advisers');
  }

  const handleRemove = () => {
    dispatch(advisersDeleteActions({Id: id, token: token})).then(navigate('/dashboard/advisers'));
  }

  return (
      <section className="add-article">
        <h2 className="title-head">Editeaza Consultantul</h2>

        <section className="profile-form-section">
          <Formik  initialValues={
            {
              name: item?.Name ?? '',
              surname: item?.Surname ?? '',
              phone: item?.Phone ?? '',
              image: item?.Image ?? null,
              facebookUrl: item?.FacebookUrl ?? '',
              twitterUrl: item?.TwitterUrl ?? '',
              linkedInUrl: item?.LinkedInUrl ?? '',
              pinterestUrl: item?.PinterestUrl ?? '',
              youtubeUrl: item?.YoutubeUrl ?? '',
              tiktokUrl: item?.TikTokUrl ?? '',
            }
          }
                   validationSchema={SignupSchema}
                   onSubmit={(values) => {
                     const data = {
                       Id: id,
                       data: values,
                       token: token
                     }

                     // handleEditData(false);
                     dispatch(advisersEditActions(data)).then(navigate('/dashboard/advisers'));
                   }}
          >
            {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className="form-input">
                    <Field type="text" name="name" placeholder="Name" />
                    <ErrorMessage name="name" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="surname" placeholder="Surname" />
                    <ErrorMessage name="surname" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="phone" placeholder="Phone" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="facebookUrl" placeholder="Facebook Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="twitterUrl" placeholder="Twitter Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="linkedInUrl" placeholder="LinkedIn Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="pinterestUrl" placeholder="Pinterest Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="youtubeUrl" placeholder="Youtube Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="tiktokUrl" placeholder="TikTok Url" />
                  </div>

                  <div className="form-message">
                    <Dropzone image={values.image} onFileSelect={(file => setFieldValue('image', file))} />
                  </div>

                  <div className="actions-button-section">
                    <button onClick={() => handleClose()}>Close</button>
                    <button type="submit">Save</button>
                    <button onClick={() => handleRemove()}>Delete</button>
                  </div>
                </Form>
            )}
          </Formik>
        </section>
      </section>
  );
}

export default AdvisersEdit;
