import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus, faSave} from "@fortawesome/free-solid-svg-icons";

const AboutMe = () => {
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [locality, setLocality] = useState('');
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [domain, setDomain] = useState('');
    return (
        <section className="about-me">
           <div className="header-about-me">
               <span className="title-form">Date Trainer</span>
               <span className="edit-form">Editare <span className="edit-icon"><FontAwesomeIcon icon={faPencil} /></span></span>
           </div>
           <div className="about-me-form">
               <input type="text" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
               <input type="text" name="fullName" placeholder="Nume Prenume" value={fullName} onChange={(e) => setFullName(e.target.value)} />
               <input type="text" name="locality" placeholder="Localitatea" value={locality} onChange={(e) => setLocality(e.target.value)} />
               <input type="text" name="address" placeholder="Adresa" value={address} onChange={(e) => setAddress(e.target.value)} />
               <input type="text" name="description" placeholder="Descriere" value={description} onChange={(e) => setDescription(e.target.value)} />
               <input type="text" name="domain" placeholder="Domeniu" value={domain} onChange={(e) => setDomain(e.target.value)} />

               <div className="upload-files">
                   <label htmlFor="files">Incarca documente confermative <span className="upload-icon"><FontAwesomeIcon icon={faPlus} /></span></label>
                   <input type="file" multiple id="files" name="files" />
               </div>
               <button className="save-button"><FontAwesomeIcon icon={faSave} /> Salveaza</button>
           </div>
        </section>
    );
}

export default AboutMe;
