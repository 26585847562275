import React, {forwardRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import "./DatePicker.scss";

const DatePickerInput = (props) => {
    const {text, onChangeDateTo, onChangeDateFrom, valueDateTo, valueDateFrom, className} = props;

    const handleChangeDateTo = data => onChangeDateTo(data);

    const handleChangeDateFrom = data => onChangeDateFrom(data);

  const StyleDataComponent = forwardRef(({value, onClick}, ref) => (
      <span className="data-input" onClick={onClick} ref={ref}>
           <span className="data-icon">
            <FontAwesomeIcon icon={faCalendarDays}/>
           </span>
           <span className="data-value">
               <span className="data-value-year">
                  <Moment local date={value} format="dddd, MMMM"/>
               </span>
               <span className="data-value-day">
                   <Moment local date={value} format="D, YYYY"/>
               </span>
           </span>
       </span>
  ));

    return (
        <div className={`date-pickers-section ${className}`}>
          { text !== '' && text !== undefined ? <label>{text}</label> : <></> }
          <div className="date-pickers">
            <DatePicker
                selected={valueDateTo}
                onChange={(date) => handleChangeDateTo(date)}
                customInput={<StyleDataComponent/>}
            />
            <div className="break"></div>
            <DatePicker
                selected={valueDateFrom}
                onChange={(date) => handleChangeDateFrom(date)}
                customInput={<StyleDataComponent/>}
            />
          </div>
        </div>
    );
}

export default DatePickerInput;
