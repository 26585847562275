import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./template/layout/Main";
import Home from "./template/views/Home";
import CreatCV from "./template/views/CreatCV";
import Vacancies from "./template/views/Vacancies";
import About from "./template/views/About";
import Login from "./template/views/Login";
import Training from "./template/views/Training";
import Coaching from "./template/views/Coaching";
import RegisterEmployer from "./template/views/RegisterEmployer";
import RegisterEmployee from "./template/views/RegisterEmployee";
import AddVacancy from "./template/views/AddVacancy";
import JobApplications from "./template/views/JobApplications";
import Quiz from "./template/views/Quiz";
import Contact from "./template/views/Contact";
import Registration from "./template/views/Registration";
import FindJob from "./template/views/FindJob";
import Rules from "./template/views/Rules";
import Agreement from "./template/views/Agreement";
import ErrorPage from "./template/views/ErrorPage";
import Dashboard from "./template/layout/Dashborad";
import Profile from "./template/views/Dashboard/Profile";
import InterviewsOffers from "./template/views/Dashboard/User/InterviewsOffers";
import Applied from "./template/views/Dashboard/User/Applied";
import MyCv from "./template/views/Dashboard/User/MyCV";
import MyCareer from "./template/views/Dashboard/User/MyCareer";
import MyCourse from "./template/views/Dashboard/User/MyCourse";
import MyQuiz from "./template/views/Dashboard/User/MyQuiz";
import MyCertificate from "./template/views/Dashboard/User/MyCertificate";
import MyReviews from "./template/views/Dashboard/User/MyReviews";
import Banking from "./template/views/Dashboard/User/Banking";
import Employers from "./template/views/Dashboard/Admin/Employers";
import Blog from "./template/views/Dashboard/Admin/Blog/Blog";
import BlogEdit from "./template/views/Dashboard/Admin/Blog/BlogEdit";
import BlogAdd from "./template/views/Dashboard/Admin/Blog/BlogAdd";
import Opportunity from "./template/views/Dashboard/Admin/Opportunity/Opportunity";
import OpportunityEdit from "./template/views/Dashboard/Admin/Opportunity/OpportunityEdit";
import OpportunityAdd from "./template/views/Dashboard/Admin/Opportunity/OpportunityAdd";
import Jobs from "./template/views/Dashboard/Admin/Jobs";
import Trainers from "./template/views/Dashboard/Admin/Trainers";
import Courses from "./template/views/Dashboard/Admin/Courses/Courses";
import Settings from "./template/views/Dashboard/Admin/Settings";
import Candidates from "./template/views/Dashboard/Admin/Candidates";
import CVLists from "./template/views/Dashboard/Admin/CvList";
import BlogPage from "./template/views/Blog/BlogPage";
import BlogViewPage from "./template/views/Blog/BlogViewPage";
import HowItWork from "./template/views/Dashboard/Admin/HowItWork/HowItWork";
import HowItWorkEdit from "./template/views/Dashboard/Admin/HowItWork/HowItWorkEdit";
import HomeSettings from "./template/views/Dashboard/Admin/HomeSettings";
import Dictionary from "./template/views/Dashboard/Admin/Dictionary";
import InitiativeCompany from "./template/views/Dashboard/Admin/InitiativeCompany/InitiativeCompany";
import InitiativeCompanyEdit from "./template/views/Dashboard/Admin/InitiativeCompany/InitiativeCompanyEdit";
import Menu from "./template/views/Dashboard/Admin/Menu/Menu";
import MenuEdit from "./template/views/Dashboard/Admin/Menu/MenuEdit";
import Advisers from "./template/views/Dashboard/Admin/Advisers/Advisers";
import AdvisersAdd from "./template/views/Dashboard/Admin/Advisers/AdvisersAdd";
import AdvisersEdit from "./template/views/Dashboard/Admin/Advisers/AdvisersEdit";
import AppliedInterview from "./template/views/Dashboard/Employer/AppliedInterview";
import ActiveJobs from "./template/views/Dashboard/Employer/JobActive/ActiveJobs";
import InterviewsEmployer from "./template/views/Dashboard/Employer/InterviewsEmployer";
import OffersJobs from "./template/views/Dashboard/Employer/OffersJobs";
import PracticAndCareer from "./template/views/Dashboard/Employer/PracticAndCareer";
import Reviews from "./template/views/Dashboard/Employer/Reviews";
import MyStatistic from "./template/views/Dashboard/Employer/MyStatistic";
import AboutCompany from "./template/views/Dashboard/Employer/AboutCompany";
import RegistrationCourse from "./template/views/Dashboard/Trainer/RegistrationCourse";
import ActiveCourse from "./template/views/Dashboard/Trainer/ActiveCourse";
import QuizList from "./template/views/Dashboard/Trainer/QuizList";
import Graduates from "./template/views/Dashboard/Trainer/Graduates";
import InterviewReady from "./template/views/Dashboard/Trainer/InterviewReady";
import PersonalCoaching from "./template/views/Dashboard/Trainer/PersonalCoaching";
import CollaborationOffers from "./template/views/Dashboard/Trainer/CollaborationOffers";
import SuccessfulHires from "./template/views/Dashboard/Trainer/SuccessfulHires";
import TrainerCertificate from "./template/views/Dashboard/Trainer/TrainerCertificate";
import AboutMe from "./template/views/Dashboard/Trainer/AboutMe";
import JobActiveAdd from "./template/views/Dashboard/Employer/JobActive/JobActiveAdd";
import JobActiveEdit from "./template/views/Dashboard/Employer/JobActive/JobActiveEdit";
import ForgotPassword from "./template/views/ForgotPassword";
import ResetPassword from "./template/views/ResetPassword";
import TrainingDetail from "./template/views/TrainingDetail";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route element={<Main />}>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/creat-cv"} element={<CreatCV />} errorElement={<ErrorPage />} />
                    <Route path={"/vacancies"} element={<Vacancies />} errorElement={<ErrorPage />} />
                    <Route path={"/about-us"} element={<About />} errorElement={<ErrorPage />} />
                    <Route path={"/login"} element={<Login />} errorElement={<ErrorPage />} />
                    <Route path={"/forgot-password"} element={<ForgotPassword /> } errorElement={<ErrorPage /> }/>
                    <Route path={"/reset-password/:token"} element={<ResetPassword /> } errorElement={<ErrorPage />} />
                    <Route path={"/courses"} element={<Training />} errorElement={<ErrorPage />} />
                    <Route path={"/courses/:courseTitle"} element={<TrainingDetail />} errorElement={<ErrorPage />} />
                    <Route path={"/coaching"} element={<Coaching />} errorElement={<ErrorPage />} />
                    <Route path={"/register/employer"} element={<RegisterEmployer />} errorElement={<ErrorPage />} />
                    <Route path={"/register/cv"} element={<RegisterEmployee />} errorElement={<ErrorPage />} />
                    <Route path={"/add-vacancy"} element={<AddVacancy />} errorElement={<ErrorPage />} />
                    <Route path={"/job-applications"} element={<JobApplications />} errorElement={<ErrorPage />} />
                    <Route path={"/quiz"} element={<Quiz />} errorElement={<ErrorPage />} />
                    <Route path={"/contact-us"} element={<Contact/>} errorElement={<ErrorPage />} />
                    <Route path={"/registration"} element={<Registration /> } errorElement={<ErrorPage />} />
                    <Route path={"/find-a-job"} element={<FindJob />} errorElement={<ErrorPage />} />
                    <Route path={"/rules"} element={<Rules /> } errorElement={<ErrorPage />} />
                    <Route path={"/agreement"} element={<Agreement />} errorElement={<ErrorPage />} />
                    <Route path={"/blog"} element={<BlogPage />} errorElement={<ErrorPage />} />
                    <Route path={"/blog/:blogTitle/:blogId"} element={<BlogViewPage />} errorElement={<ErrorPage />} />
                </Route>

                <Route element={<Dashboard />}>
                    <Route path={"/dashboard"} errorElement={<ErrorPage />} />
                    {/* Dashboard User Routes */}
                    <Route path={"/dashboard/user/interviews"} element={<InterviewsOffers />} />
                    <Route path={"/dashboard/user/jobs"} element={<Applied />} />
                    <Route path={"/dashboard/user/my-cv"} element={<MyCv />} />
                    <Route path={"/dashboard/user/profile"} element={<Profile />} />
                    <Route path={"/dashboard/user/my-career"} element={<MyCareer />} />
                    <Route path={"/dashboard/courses"} element={<MyCourse />}/>
                    <Route path={"/dashboard/my-quizzes"} element={<MyQuiz />} />
                    <Route path={"/dashboard/my-certificates"} element={<MyCertificate />} />
                    <Route path={"/dashboard/user/reviews"} element={<MyReviews />} />
                    <Route path={"/dashboard/banking"} element={<Banking />} />
                    {/* Dashboard Admin Routes */}
                    <Route path={'/dashboard/employers'} element={<Employers />} />
                    <Route path={'/dashboard/blog'} element={<Blog /> } />
                    <Route path={'/dashboard/blog/add'} element={<BlogAdd /> } />
                    <Route path={'/dashboard/blog/:id'} element={<BlogEdit /> } />

                    <Route path={'/dashboard/advisers'} element={<Advisers /> } />
                    <Route path={'/dashboard/advisers/add'} element={<AdvisersAdd /> } />
                    <Route path={'/dashboard/advisers/:id'} element={<AdvisersEdit /> } />

                    <Route path={'/dashboard/opportunity'} element={<Opportunity /> } />
                    <Route path={'/dashboard/opportunity/add'} element={<OpportunityAdd /> } />
                    <Route path={'/dashboard/opportunity/:id'} element={<OpportunityEdit /> } />
                    <Route path={'/dashboard/job'} element={<Jobs />} />
                    <Route path={'/dashboard/trainers'} element={<Trainers />} />
                    <Route path={'/dashboard/courses'} element={<Courses />} />
                    <Route path={'/dashboard/settings'} element={<Settings />} />
                    <Route path={'/dashboard/cv-lists'} element={<CVLists /> } />
                    <Route path={'/dashboard/candidates'} element={<Candidates />} />
                    <Route path={'/dashboard/how-it-work'} element={<HowItWork />} />
                    <Route path={'/dashboard/how-it-work/:id'} element={<HowItWorkEdit />} />
                    <Route path={'/dashboard/menu'} element={<Menu />} />
                    <Route path={'/dashboard/menu/:id'} element={<MenuEdit />} />
                    <Route path={'/dashboard/home-settings'} element={<HomeSettings /> } />
                    <Route path={'/dashboard/dictionary'} element={<Dictionary />} />
                    <Route path={'/dashboard/initiative-company'} element={<InitiativeCompany />} />
                    <Route path={'/dashboard/initiative-company/:id'} element={<InitiativeCompanyEdit />} />
                    {/* Dashboard Employer Routes */}
                    <Route path={'/dashboard/applied-interview'} element={<AppliedInterview /> } />
                    <Route path={'/dashboard/job-active'} element={<ActiveJobs /> } />
                    <Route path={'/dashboard/job/add'} element={<JobActiveAdd /> } />
                    <Route path={'/dashboard/job/:id'} element={<JobActiveEdit /> } />
                    <Route path={'/dashboard/interview'} element={<InterviewsEmployer /> } />
                    <Route path={'/dashboard/offers-and-jobs'} element={<OffersJobs /> } />
                    <Route path={'/dashboard/practic-and-career'} element={<PracticAndCareer /> } />
                    <Route path={'/dashboard/reviews'} element={<Reviews /> } />
                    <Route path={'/dashboard/my-statistic'} element={<MyStatistic /> } />
                    <Route path={'/dashboard/about-company'} element={<AboutCompany /> } />
                    {/* Dashboard Trainer Routes */}
                    <Route path={'/dashboard/registration-course'} element={<RegistrationCourse /> } />
                    <Route path={'/dashboard/active-course'} element={<ActiveCourse /> } />
                    <Route path={'/dashboard/quiz'} element={<QuizList /> } />
                    <Route path={'/dashboard/graduates'} element={ <Graduates /> } />
                    <Route path={'/dashboard/interview-ready'} element={<InterviewReady /> } />
                    <Route path={'/dashboard/personal-coaching'} element={<PersonalCoaching /> } />
                    <Route path={'/dashboard/collaboration-offers'} element={<CollaborationOffers /> } />
                    <Route path={'/dashboard/successful-hires'} element={<SuccessfulHires /> } />
                    <Route path={'/dashboard/certificate'} element={<TrainerCertificate />} />
                    <Route path={'/dashboard/about-me'} element={<AboutMe /> } />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
