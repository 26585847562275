import React, {useEffect, useState} from 'react';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {Field, Formik, Form} from 'formik';
import ReactCountryFlag from "react-country-flag";
import {useDispatch, useSelector} from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {toast, ToastContainer} from "react-toastify";
import Dropzone from "../../../../components/Dropzone/Dropzone";
import {editTranslation, getTranslation} from "../../../../service/TranslationService";
import Loader from "../../../../components/Loader/Loader";


const Dictionary = () => {
    const [translation, setTranslation] = useState(null);
    const [openTab, setOpenTab] = useState(1);
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const dispatch = useDispatch();

    useEffect(() => {
        // Check if token is null and set it from localStorage if available
        if (token === null) {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                setToken(storedToken);
            }
        }
        getTranslation().then(res => setTranslation(res));
    }, [token]);

    return (
        <>
            { translation && <section className="profile-settings">
                <div className="profile-settings-dictionary">
                    <div className="profile-settings-dictionary__heading">
                        <h3>Dictionary</h3>
                    </div>

                    <div className="profile-settings-dictionary__content">
                        <div className="flex flex-wrap">
                            <div className="w-full tabs-dictionary">
                                <ul className="flex list-none flex-wrap flex-row" role="tablist">
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 1 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(1);
                                           }}
                                           data-toggle="tab"
                                           href="#link1"
                                           role="tablist"
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 2 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(2);
                                           }}
                                           data-toggle="tab"
                                           href="#link2"
                                           role="tablist"
                                        >
                                            About
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 3 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(3);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Joburi
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 4 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(4);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Traininguri
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 5 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(5);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Couch de Cariera
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 6 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(6);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Teste
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 7 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(7);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Contact
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 8 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(8);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Footer
                                        </a>
                                    </li>
                                    <li className="flex-auto text-center">
                                        <a className={openTab === 9 ? 'active' : ''}
                                           onClick={e => {
                                               e.preventDefault();
                                               setOpenTab(9);
                                           }}
                                           data-toggle="tab"
                                           href="#link3"
                                           role="tablist"
                                        >
                                            Rules Page
                                        </a>
                                    </li>
                                </ul>
                                <div className="relative flex flex-col min-w-0 break-words w-full overflow-hidden">
                                    <div className="flex-auto">
                                        <div className="tab-content tab-space">
                                            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                                <div className="form-section">
                                                    <Formik initialValues={{
                                                        arHeader: translation?.ar['Home']['Header'],
                                                        enHeader: translation?.en['Home']['Header'],
                                                        esHeader: translation?.es['Home']['Header'],
                                                        frHeader: translation?.fr['Home']['Header'],
                                                        grHeader: translation?.gr['Home']['Header'],
                                                        isHeader: translation?.is['Home']['Header'],
                                                        roHeader: translation?.ro['Home']['Header'],
                                                        ruHeader: translation?.ru['Home']['Header'],
                                                        uaHeader: translation?.ua['Home']['Header'],

                                                        arHeaderSecond: translation?.ar['Home']['HeaderSecond'],
                                                        enHeaderSecond: translation?.en['Home']['HeaderSecond'],
                                                        esHeaderSecond: translation?.es['Home']['HeaderSecond'],
                                                        frHeaderSecond: translation?.fr['Home']['HeaderSecond'],
                                                        grHeaderSecond: translation?.gr['Home']['HeaderSecond'],
                                                        isHeaderSecond: translation?.is['Home']['HeaderSecond'],
                                                        roHeaderSecond: translation?.ro['Home']['HeaderSecond'],
                                                        ruHeaderSecond: translation?.ru['Home']['HeaderSecond'],
                                                        uaHeaderSecond: translation?.ua['Home']['HeaderSecond'],

                                                        arFavouriteEmployer: translation?.ar['Home']['favouriteEmployer'],
                                                        enFavouriteEmployer: translation?.en['Home']['favouriteEmployer'],
                                                        esFavouriteEmployer: translation?.es['Home']['favouriteEmployer'],
                                                        frFavouriteEmployer: translation?.fr['Home']['favouriteEmployer'],
                                                        grFavouriteEmployer: translation?.gr['Home']['favouriteEmployer'],
                                                        isFavouriteEmployer: translation?.is['Home']['favouriteEmployer'],
                                                        roFavouriteEmployer: translation?.ro['Home']['favouriteEmployer'],
                                                        ruFavouriteEmployer: translation?.ru['Home']['favouriteEmployer'],
                                                        uaFavouriteEmployer: translation?.ua['Home']['favouriteEmployer'],

                                                        arFavouriteJob: translation?.ar['Home']['favouriteJob'],
                                                        enFavouriteJob: translation?.en['Home']['favouriteJob'],
                                                        esFavouriteJob: translation?.es['Home']['favouriteJob'],
                                                        frFavouriteJob: translation?.fr['Home']['favouriteJob'],
                                                        grFavouriteJob: translation?.gr['Home']['favouriteJob'],
                                                        isFavouriteJob: translation?.is['Home']['favouriteJob'],
                                                        roFavouriteJob: translation?.ro['Home']['favouriteJob'],
                                                        ruFavouriteJob: translation?.ru['Home']['favouriteJob'],
                                                        uaFavouriteJob: translation?.ua['Home']['favouriteJob'],

                                                        arHowItWork: translation?.ar['Home']['howItWorkTitle'],
                                                        enHowItWork: translation?.en['Home']['howItWorkTitle'],
                                                        esHowItWork: translation?.es['Home']['howItWorkTitle'],
                                                        frHowItWork: translation?.fr['Home']['howItWorkTitle'],
                                                        grHowItWork: translation?.gr['Home']['howItWorkTitle'],
                                                        isHowItWork: translation?.is['Home']['howItWorkTitle'],
                                                        roHowItWork: translation?.ro['Home']['howItWorkTitle'],
                                                        ruHowItWork: translation?.ru['Home']['howItWorkTitle'],
                                                        uaHowItWork: translation?.ua['Home']['howItWorkTitle'],

                                                        arOurBlog: translation?.ar['Home']['ourBlogTitle'],
                                                        enOurBlog: translation?.en['Home']['ourBlogTitle'],
                                                        esOurBlog: translation?.es['Home']['ourBlogTitle'],
                                                        frOurBlog: translation?.fr['Home']['ourBlogTitle'],
                                                        grOurBlog: translation?.gr['Home']['ourBlogTitle'],
                                                        isOurBlog: translation?.is['Home']['ourBlogTitle'],
                                                        roOurBlog: translation?.ro['Home']['ourBlogTitle'],
                                                        ruOurBlog: translation?.ru['Home']['ourBlogTitle'],
                                                        uaOurBlog: translation?.ua['Home']['ourBlogTitle'],

                                                        arSocialNetwork: translation?.ar['Home']['socialNetworkTitle'],
                                                        enSocialNetwork: translation?.en['Home']['socialNetworkTitle'],
                                                        esSocialNetwork: translation?.es['Home']['socialNetworkTitle'],
                                                        frSocialNetwork: translation?.fr['Home']['socialNetworkTitle'],
                                                        grSocialNetwork: translation?.gr['Home']['socialNetworkTitle'],
                                                        isSocialNetwork: translation?.is['Home']['socialNetworkTitle'],
                                                        roSocialNetwork: translation?.ro['Home']['socialNetworkTitle'],
                                                        ruSocialNetwork: translation?.ru['Home']['socialNetworkTitle'],
                                                        uaSocialNetwork: translation?.ua['Home']['socialNetworkTitle'],

                                                        arBtnSearch: translation?.ar['Home']['btnSearch'],
                                                        enBtnSearch: translation?.en['Home']['btnSearch'],
                                                        esBtnSearch: translation?.es['Home']['btnSearch'],
                                                        frBtnSearch: translation?.fr['Home']['btnSearch'],
                                                        grBtnSearch: translation?.gr['Home']['btnSearch'],
                                                        isBtnSearch: translation?.is['Home']['btnSearch'],
                                                        roBtnSearch: translation?.ro['Home']['btnSearch'],
                                                        ruBtnSearch: translation?.ru['Home']['btnSearch'],
                                                        uaBtnSearch: translation?.ua['Home']['btnSearch'],

                                                        arBtnJob: translation?.ar['Home']['btnJobMyArea'],
                                                        enBtnJob: translation?.en['Home']['btnJobMyArea'],
                                                        esBtnJob: translation?.es['Home']['btnJobMyArea'],
                                                        frBtnJob: translation?.fr['Home']['btnJobMyArea'],
                                                        grBtnJob: translation?.gr['Home']['btnJobMyArea'],
                                                        isBtnJob: translation?.is['Home']['btnJobMyArea'],
                                                        roBtnJob: translation?.ro['Home']['btnJobMyArea'],
                                                        ruBtnJob: translation?.ru['Home']['btnJobMyArea'],
                                                        uaBtnJob: translation?.ua['Home']['btnJobMyArea'],

                                                        arBtnReadMore: translation?.ar['Home']['btnReadMore'],
                                                        enBtnReadMore: translation?.en['Home']['btnReadMore'],
                                                        esBtnReadMore: translation?.es['Home']['btnReadMore'],
                                                        frBtnReadMore: translation?.fr['Home']['btnReadMore'],
                                                        grBtnReadMore: translation?.gr['Home']['btnReadMore'],
                                                        isBtnReadMore: translation?.is['Home']['btnReadMore'],
                                                        roBtnReadMore: translation?.ro['Home']['btnReadMore'],
                                                        ruBtnReadMore: translation?.ru['Home']['btnReadMore'],
                                                        uaBtnReadMore: translation?.ua['Home']['btnReadMore'],

                                                        arBtnLoadMore: translation?.ar['Home']['btnLoadMore'],
                                                        enBtnLoadMore: translation?.en['Home']['btnLoadMore'],
                                                        esBtnLoadMore: translation?.es['Home']['btnLoadMore'],
                                                        frBtnLoadMore: translation?.fr['Home']['btnLoadMore'],
                                                        grBtnLoadMore: translation?.gr['Home']['btnLoadMore'],
                                                        isBtnLoadMore: translation?.is['Home']['btnLoadMore'],
                                                        roBtnLoadMore: translation?.ro['Home']['btnLoadMore'],
                                                        ruBtnLoadMore: translation?.ru['Home']['btnLoadMore'],
                                                        uaBtnLoadMore: translation?.ua['Home']['btnLoadMore'],

                                                        arBtnShowLess: translation?.ar['Home']['btnShowLess'],
                                                        enBtnShowLess: translation?.en['Home']['btnShowLess'],
                                                        esBtnShowLess: translation?.es['Home']['btnShowLess'],
                                                        frBtnShowLess: translation?.fr['Home']['btnShowLess'],
                                                        grBtnShowLess: translation?.gr['Home']['btnShowLess'],
                                                        isBtnShowLess: translation?.is['Home']['btnShowLess'],
                                                        roBtnShowLess: translation?.ro['Home']['btnShowLess'],
                                                        ruBtnShowLess: translation?.ru['Home']['btnShowLess'],
                                                        uaBtnShowLess: translation?.ua['Home']['btnShowLess'],

                                                        arBtnAllProject: translation?.ar['Home']['btnSeeAllProject'],
                                                        enBtnAllProject: translation?.en['Home']['btnSeeAllProject'],
                                                        esBtnAllProject: translation?.es['Home']['btnSeeAllProject'],
                                                        frBtnAllProject: translation?.fr['Home']['btnSeeAllProject'],
                                                        grBtnAllProject: translation?.gr['Home']['btnSeeAllProject'],
                                                        isBtnAllProject: translation?.is['Home']['btnSeeAllProject'],
                                                        roBtnAllProject: translation?.ro['Home']['btnSeeAllProject'],
                                                        ruBtnAllProject: translation?.ru['Home']['btnSeeAllProject'],
                                                        uaBtnAllProject: translation?.ua['Home']['btnSeeAllProject'],
                                                    }}
                                                            onSubmit={(values) => {
                                                                const data = {
                                                                    data: [
                                                                        {
                                                                           group: 'Home',
                                                                           key: 'Header',
                                                                           text: {
                                                                               ar: values.arHeader,
                                                                               en: values.enHeader,
                                                                               es: values.esHeader,
                                                                               fr: values.frHeader,
                                                                               gr: values.grHeader,
                                                                               is: values.isHeader,
                                                                               ro: values.roHeader,
                                                                               ru: values.ruHeader,
                                                                               ua: values.uaHeader
                                                                           }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'HeaderSecond',
                                                                            text: {
                                                                                ar: values.arHeaderSecond,
                                                                                en: values.enHeaderSecond,
                                                                                es: values.esHeaderSecond,
                                                                                fr: values.frHeaderSecond,
                                                                                gr: values.grHeaderSecond,
                                                                                is: values.isHeaderSecond,
                                                                                ro: values.roHeaderSecond,
                                                                                ru: values.ruHeaderSecond,
                                                                                ua: values.uaHeaderSecond
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'favouriteEmployer',
                                                                            text: {
                                                                                ar: values.arFavouriteEmployer,
                                                                                en: values.enFavouriteEmployer,
                                                                                es: values.esFavouriteEmployer,
                                                                                fr: values.frFavouriteEmployer,
                                                                                gr: values.grFavouriteEmployer,
                                                                                is: values.isFavouriteEmployer,
                                                                                ro: values.roFavouriteEmployer,
                                                                                ru: values.ruFavouriteEmployer,
                                                                                ua: values.uaFavouriteEmployer
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'favouriteJob',
                                                                            text: {
                                                                                ar: values.arFavouriteJob,
                                                                                en: values.enFavouriteJob,
                                                                                es: values.esFavouriteJob,
                                                                                fr: values.frFavouriteJob,
                                                                                gr: values.grFavouriteJob,
                                                                                is: values.isFavouriteJob,
                                                                                ro: values.roFavouriteJob,
                                                                                ru: values.ruFavouriteJob,
                                                                                ua: values.uaFavouriteJob
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'howItWorkTitle',
                                                                            text: {
                                                                                ar: values.arHowItWork,
                                                                                en: values.enHowItWork,
                                                                                es: values.esHowItWork,
                                                                                fr: values.frHowItWork,
                                                                                gr: values.grHowItWork,
                                                                                is: values.isHowItWork,
                                                                                ro: values.roHowItWork,
                                                                                ru: values.ruHowItWork,
                                                                                ua: values.uaHowItWork
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'ourBlogTitle',
                                                                            text: {
                                                                                ar: values.arOurBlog,
                                                                                en: values.enOurBlog,
                                                                                es: values.esOurBlog,
                                                                                fr: values.frOurBlog,
                                                                                gr: values.grOurBlog,
                                                                                is: values.isOurBlog,
                                                                                ro: values.roOurBlog,
                                                                                ru: values.ruOurBlog,
                                                                                ua: values.uaOurBlog
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'socialNetworkTitle',
                                                                            text: {
                                                                                ar: values.arSocialNetwork,
                                                                                en: values.enSocialNetwork,
                                                                                es: values.esSocialNetwork,
                                                                                fr: values.frSocialNetwork,
                                                                                gr: values.grSocialNetwork,
                                                                                is: values.isSocialNetwork,
                                                                                ro: values.roSocialNetwork,
                                                                                ru: values.ruSocialNetwork,
                                                                                ua: values.uaSocialNetwork
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'btnSearch',
                                                                            text: {
                                                                                ar: values.arBtnSearch,
                                                                                en: values.enBtnSearch,
                                                                                es: values.esBtnSearch,
                                                                                fr: values.frBtnSearch,
                                                                                gr: values.grBtnSearch,
                                                                                is: values.isBtnSearch,
                                                                                ro: values.roBtnSearch,
                                                                                ru: values.ruBtnSearch,
                                                                                ua: values.uaBtnSearch
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'btnJobMyArea',
                                                                            text: {
                                                                                ar: values.arBtnJob,
                                                                                en: values.enBtnJob,
                                                                                es: values.esBtnJob,
                                                                                fr: values.frBtnJob,
                                                                                gr: values.grBtnJob,
                                                                                is: values.isBtnJob,
                                                                                ro: values.roBtnJob,
                                                                                ru: values.ruBtnJob,
                                                                                ua: values.uaBtnJob
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'btnReadMore',
                                                                            text: {
                                                                                ar: values.arBtnReadMore,
                                                                                en: values.enBtnReadMore,
                                                                                es: values.esBtnReadMore,
                                                                                fr: values.frBtnReadMore,
                                                                                gr: values.grBtnReadMore,
                                                                                is: values.isBtnReadMore,
                                                                                ro: values.roBtnReadMore,
                                                                                ru: values.ruBtnReadMore,
                                                                                ua: values.uaBtnReadMore
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'btnLoadMore',
                                                                            text: {
                                                                                ar: values.arBtnLoadMore,
                                                                                en: values.enBtnLoadMore,
                                                                                es: values.esBtnLoadMore,
                                                                                fr: values.frBtnLoadMore,
                                                                                gr: values.grBtnLoadMore,
                                                                                is: values.isBtnLoadMore,
                                                                                ro: values.roBtnLoadMore,
                                                                                ru: values.ruBtnLoadMore,
                                                                                ua: values.uaBtnLoadMore
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'btnShowLess',
                                                                            text: {
                                                                                ar: values.arBtnShowLess,
                                                                                en: values.enBtnShowLess,
                                                                                es: values.esBtnShowLess,
                                                                                fr: values.frBtnShowLess,
                                                                                gr: values.grBtnShowLess,
                                                                                is: values.isBtnShowLess,
                                                                                ro: values.roBtnShowLess,
                                                                                ru: values.ruBtnShowLess,
                                                                                ua: values.uaBtnShowLess
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Home',
                                                                            key: 'btnSeeAllProject',
                                                                            text: {
                                                                                ar: values.arBtnAllProject,
                                                                                en: values.enBtnAllProject,
                                                                                es: values.esBtnAllProject,
                                                                                fr: values.frBtnAllProject,
                                                                                gr: values.grBtnAllProject,
                                                                                is: values.isBtnAllProject,
                                                                                ro: values.roBtnAllProject,
                                                                                ru: values.ruBtnAllProject,
                                                                                ua: values.uaBtnAllProject
                                                                            }
                                                                        },
                                                                    ],
                                                                    token: token
                                                                }

                                                                // handleEditData(false);
                                                                editTranslation(data).then(res => {
                                                                    toast.success("Successfully Saved", {
                                                                        position: toast.POSITION.TOP_CENTER,
                                                                    });
                                                                });
                                                            }}
                                                    >
                                                        {({errors, touched, values, setFieldValue}) => (<Form>
                                                            <div className="formContent">
                                                                <span className="title-head-settings">Header main Title</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="SA"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Saudi Arabia"
                            />
                          </span>
                                                                        <Field type="text" name="arHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="GB"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="United Kingdom"
                            />
                          </span>
                                                                        <Field type="text" name="enHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ES"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Spain"
                            />
                          </span>
                                                                        <Field type="text" name="esHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="FR"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="France"
                            />
                          </span>
                                                                        <Field type="text" name="frHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="DE"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Germany"
                            />
                          </span>
                                                                        <Field type="text" name="grHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="IS"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Iceland"
                            />
                          </span>
                                                                        <Field type="text" name="isHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RO"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Romania"
                            />
                          </span>
                                                                        <Field type="text" name="roHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RU"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Russia"
                            />
                          </span>
                                                                        <Field type="text" name="ruHeader"
                                                                               placeholder="Header"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ua"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Ukraine"
                            />
                          </span>
                                                                        <Field type="text" name="uaHeader"
                                                                               placeholder="Header"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Header other Title</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaHeaderSecond"
                                                                               placeholder="HeaderSecond"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Title favourite Employer</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaFavouriteEmployer"
                                                                               placeholder="FavouriteEmployer"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Title favourite Job</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaFavouriteJob"
                                                                               placeholder="FavouriteJob"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Title How It Work</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaHowItWork"
                                                                               placeholder="HowItWork"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Our Blog Title</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaOurBlog"
                                                                               placeholder="OurBlog"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Social Network Title</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaSocialNetwork"
                                                                               placeholder="SocialNetwork"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Label Button Search</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaBtnSearch"
                                                                               placeholder="BtnSearch"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Label Button Job my Area</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaBtnJob"
                                                                               placeholder="BtnJob"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Label Button Read More</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaBtnReadMore"
                                                                               placeholder="BtnReadMore"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Label Button Load More</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaBtnLoadMore"
                                                                               placeholder="BtnLoadMore"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Label Button Show Less</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaBtnShowLess"
                                                                               placeholder="BtnShowLess"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Label Button Show all projects</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaBtnAllProject"
                                                                               placeholder="BtnAllProject"/>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="actions-button-section">
                                                                <button type="submit">Save</button>
                                                            </div>
                                                        </Form>)}
                                                    </Formik>
                                                </div>
                                            </div>
                                            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                                <div className="form-section">
                                                    <Formik initialValues={{
                                                        arMotto: translation?.ar['About']['Motto'],
                                                        enMotto: translation?.en['About']['Motto'],
                                                        esMotto: translation?.es['About']['Motto'],
                                                        frMotto: translation?.fr['About']['Motto'],
                                                        grMotto: translation?.gr['About']['Motto'],
                                                        isMotto: translation?.is['About']['Motto'],
                                                        roMotto: translation?.ro['About']['Motto'],
                                                        ruMotto: translation?.ru['About']['Motto'],
                                                        uaMotto: translation?.ua['About']['Motto'],

                                                        arAboutTitle: translation?.ar['About']['Title'],
                                                        enAboutTitle: translation?.en['About']['Title'],
                                                        esAboutTitle: translation?.es['About']['Title'],
                                                        frAboutTitle: translation?.fr['About']['Title'],
                                                        grAboutTitle: translation?.gr['About']['Title'],
                                                        isAboutTitle: translation?.is['About']['Title'],
                                                        roAboutTitle: translation?.ro['About']['Title'],
                                                        ruAboutTitle: translation?.ru['About']['Title'],
                                                        uaAboutTitle: translation?.ua['About']['Title'],

                                                        arAboutDescription: translation?.ar['About']['Description'],
                                                        enAboutDescription: translation?.en['About']['Description'],
                                                        esAboutDescription: translation?.es['About']['Description'],
                                                        frAboutDescription: translation?.fr['About']['Description'],
                                                        grAboutDescription: translation?.gr['About']['Description'],
                                                        isAboutDescription: translation?.is['About']['Description'],
                                                        roAboutDescription: translation?.ro['About']['Description'],
                                                        ruAboutDescription: translation?.ru['About']['Description'],
                                                        uaAboutDescription: translation?.ua['About']['Description'],

                                                        arAboutDescriptionSecond: translation?.ar['About']['DescriptionSecond'],
                                                        enAboutDescriptionSecond: translation?.en['About']['DescriptionSecond'],
                                                        esAboutDescriptionSecond: translation?.es['About']['DescriptionSecond'],
                                                        frAboutDescriptionSecond: translation?.fr['About']['DescriptionSecond'],
                                                        grAboutDescriptionSecond: translation?.gr['About']['DescriptionSecond'],
                                                        isAboutDescriptionSecond: translation?.is['About']['DescriptionSecond'],
                                                        roAboutDescriptionSecond: translation?.ro['About']['DescriptionSecond'],
                                                        ruAboutDescriptionSecond: translation?.ru['About']['DescriptionSecond'],
                                                        uaAboutDescriptionSecond: translation?.ua['About']['DescriptionSecond'],

                                                        arAboutTitleInitiative: translation?.ar['About']['TitleInitiative'],
                                                        enAboutTitleInitiative: translation?.en['About']['TitleInitiative'],
                                                        esAboutTitleInitiative: translation?.es['About']['TitleInitiative'],
                                                        frAboutTitleInitiative: translation?.fr['About']['TitleInitiative'],
                                                        grAboutTitleInitiative: translation?.gr['About']['TitleInitiative'],
                                                        isAboutTitleInitiative: translation?.is['About']['TitleInitiative'],
                                                        roAboutTitleInitiative: translation?.ro['About']['TitleInitiative'],
                                                        ruAboutTitleInitiative: translation?.ru['About']['TitleInitiative'],
                                                        uaAboutTitleInitiative: translation?.ua['About']['TitleInitiative'],

                                                        arAboutDescriptionInitiative: translation?.ar['About']['DescriptionInitiative'],
                                                        enAboutDescriptionInitiative: translation?.en['About']['DescriptionInitiative'],
                                                        esAboutDescriptionInitiative: translation?.es['About']['DescriptionInitiative'],
                                                        frAboutDescriptionInitiative: translation?.fr['About']['DescriptionInitiative'],
                                                        grAboutDescriptionInitiative: translation?.gr['About']['DescriptionInitiative'],
                                                        isAboutDescriptionInitiative: translation?.is['About']['DescriptionInitiative'],
                                                        roAboutDescriptionInitiative: translation?.ro['About']['DescriptionInitiative'],
                                                        ruAboutDescriptionInitiative: translation?.ru['About']['DescriptionInitiative'],
                                                        uaAboutDescriptionInitiative: translation?.ua['About']['DescriptionInitiative'],

                                                        arAboutTitleAdvisers: translation?.ar['About']['TitleAdvisers'],
                                                        enAboutTitleAdvisers: translation?.en['About']['TitleAdvisers'],
                                                        esAboutTitleAdvisers: translation?.es['About']['TitleAdvisers'],
                                                        frAboutTitleAdvisers: translation?.fr['About']['TitleAdvisers'],
                                                        grAboutTitleAdvisers: translation?.gr['About']['TitleAdvisers'],
                                                        isAboutTitleAdvisers: translation?.is['About']['TitleAdvisers'],
                                                        roAboutTitleAdvisers: translation?.ro['About']['TitleAdvisers'],
                                                        ruAboutTitleAdvisers: translation?.ru['About']['TitleAdvisers'],
                                                        uaAboutTitleAdvisers: translation?.ua['About']['TitleAdvisers'],

                                                        arAboutDescriptionAdvisers: translation?.ar['About']['DescriptionAdvisers'],
                                                        enAboutDescriptionAdvisers: translation?.en['About']['DescriptionAdvisers'],
                                                        esAboutDescriptionAdvisers: translation?.es['About']['DescriptionAdvisers'],
                                                        frAboutDescriptionAdvisers: translation?.fr['About']['DescriptionAdvisers'],
                                                        grAboutDescriptionAdvisers: translation?.gr['About']['DescriptionAdvisers'],
                                                        isAboutDescriptionAdvisers: translation?.is['About']['DescriptionAdvisers'],
                                                        roAboutDescriptionAdvisers: translation?.ro['About']['DescriptionAdvisers'],
                                                        ruAboutDescriptionAdvisers: translation?.ru['About']['DescriptionAdvisers'],
                                                        uaAboutDescriptionAdvisers: translation?.ua['About']['DescriptionAdvisers'],
                                                    }}
                                                            onSubmit={(values) => {
                                                                const data = {
                                                                    data: [
                                                                        {
                                                                            group: 'About',
                                                                            key: 'Motto',
                                                                            text: {
                                                                                ar: values.arMotto,
                                                                                en: values.enMotto,
                                                                                es: values.esMotto,
                                                                                fr: values.frMotto,
                                                                                gr: values.grMotto,
                                                                                is: values.isMotto,
                                                                                ro: values.roMotto,
                                                                                ru: values.ruMotto,
                                                                                ua: values.uaMotto
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'Title',
                                                                            text: {
                                                                                ar: values.arAboutTitle,
                                                                                en: values.enAboutTitle,
                                                                                es: values.esAboutTitle,
                                                                                fr: values.frAboutTitle,
                                                                                gr: values.grAboutTitle,
                                                                                is: values.isAboutTitle,
                                                                                ro: values.roAboutTitle,
                                                                                ru: values.ruAboutTitle,
                                                                                ua: values.uaAboutTitle
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'Description',
                                                                            text: {
                                                                                ar: values.arAboutDescription,
                                                                                en: values.enAboutDescription,
                                                                                es: values.esAboutDescription,
                                                                                fr: values.frAboutDescription,
                                                                                gr: values.grAboutDescription,
                                                                                is: values.isAboutDescription,
                                                                                ro: values.roAboutDescription,
                                                                                ru: values.ruAboutDescription,
                                                                                ua: values.uaAboutDescription
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'DescriptionSecond',
                                                                            text: {
                                                                                ar: values.arAboutDescriptionSecond,
                                                                                en: values.enAboutDescriptionSecond,
                                                                                es: values.esAboutDescriptionSecond,
                                                                                fr: values.frAboutDescriptionSecond,
                                                                                gr: values.grAboutDescriptionSecond,
                                                                                is: values.isAboutDescriptionSecond,
                                                                                ro: values.roAboutDescriptionSecond,
                                                                                ru: values.ruAboutDescriptionSecond,
                                                                                ua: values.uaAboutDescriptionSecond
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'TitleInitiative',
                                                                            text: {
                                                                                ar: values.arAboutTitleInitiative,
                                                                                en: values.enAboutTitleInitiative,
                                                                                es: values.esAboutTitleInitiative,
                                                                                fr: values.frAboutTitleInitiative,
                                                                                gr: values.grAboutTitleInitiative,
                                                                                is: values.isAboutTitleInitiative,
                                                                                ro: values.roAboutTitleInitiative,
                                                                                ru: values.ruAboutTitleInitiative,
                                                                                ua: values.uaAboutTitleInitiative
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'DescriptionInitiative',
                                                                            text: {
                                                                                ar: values.arAboutDescriptionInitiative,
                                                                                en: values.enAboutDescriptionInitiative,
                                                                                es: values.esAboutDescriptionInitiative,
                                                                                fr: values.frAboutDescriptionInitiative,
                                                                                gr: values.grAboutDescriptionInitiative,
                                                                                is: values.isAboutDescriptionInitiative,
                                                                                ro: values.roAboutDescriptionInitiative,
                                                                                ru: values.ruAboutDescriptionInitiative,
                                                                                ua: values.uaAboutDescriptionInitiative
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'TitleAdvisers',
                                                                            text: {
                                                                                ar: values.arAboutTitleAdvisers,
                                                                                en: values.enAboutTitleAdvisers,
                                                                                es: values.esAboutTitleAdvisers,
                                                                                fr: values.frAboutTitleAdvisers,
                                                                                gr: values.grAboutTitleAdvisers,
                                                                                is: values.isAboutTitleAdvisers,
                                                                                ro: values.roAboutTitleAdvisers,
                                                                                ru: values.ruAboutTitleAdvisers,
                                                                                ua: values.uaAboutTitleAdvisers
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'About',
                                                                            key: 'DescriptionAdvisers',
                                                                            text: {
                                                                                ar: values.arAboutDescriptionAdvisers,
                                                                                en: values.enAboutDescriptionAdvisers,
                                                                                es: values.esAboutDescriptionAdvisers,
                                                                                fr: values.frAboutDescriptionAdvisers,
                                                                                gr: values.grAboutDescriptionAdvisers,
                                                                                is: values.isAboutDescriptionAdvisers,
                                                                                ro: values.roAboutDescriptionAdvisers,
                                                                                ru: values.ruAboutDescriptionAdvisers,
                                                                                ua: values.uaAboutDescriptionAdvisers
                                                                            }
                                                                        },
                                                                    ],
                                                                    token: token
                                                                }

                                                                // handleEditData(false);
                                                                editTranslation(data).then(res => {
                                                                    toast.success("Successfully Saved", {
                                                                        position: toast.POSITION.TOP_CENTER,
                                                                    });
                                                                });
                                                            }}
                                                    >
                                                        {({errors, touched, values, setFieldValue}) => (<Form>
                                                            <div className="formContent">
                                                                <span className="title-head-settings">Title</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="SA"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Saudi Arabia"
                            />
                          </span>
                                                                        <Field type="text" name="arAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="GB"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="United Kingdom"
                            />
                          </span>
                                                                        <Field type="text" name="enAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ES"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Spain"
                            />
                          </span>
                                                                        <Field type="text" name="esAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="FR"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="France"
                            />
                          </span>
                                                                        <Field type="text" name="frAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="DE"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Germany"
                            />
                          </span>
                                                                        <Field type="text" name="grAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="IS"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Iceland"
                            />
                          </span>
                                                                        <Field type="text" name="isAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RO"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Romania"
                            />
                          </span>
                                                                        <Field type="text" name="roAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RU"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Russia"
                            />
                          </span>
                                                                        <Field type="text" name="ruAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ua"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Ukraine"
                            />
                          </span>
                                                                        <Field type="text" name="uaAboutTitle"
                                                                               placeholder="AboutTitle"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Motto</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arMotto', data);
                                                                            }}
                                                                            data={values.arMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enMotto', data);
                                                                            }}
                                                                            data={values.enMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esMotto', data);
                                                                            }}
                                                                            data={values.esMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frMotto', data);
                                                                            }}
                                                                            data={values.frMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grMotto', data);
                                                                            }}
                                                                            data={values.grMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isMotto', data);
                                                                            }}
                                                                            data={values.isMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roMotto', data);
                                                                            }}
                                                                            data={values.roMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruMotto', data);
                                                                            }}
                                                                            data={values.ruMotto}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaMotto', data);
                                                                            }}
                                                                            data={values.uaMotto}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Description</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arAboutDescription', data);
                                                                            }}
                                                                            data={values.arAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enAboutDescription', data);
                                                                            }}
                                                                            data={values.enAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esAboutDescription', data);
                                                                            }}
                                                                            data={values.esAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frAboutDescription', data);
                                                                            }}
                                                                            data={values.frAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grAboutDescription', data);
                                                                            }}
                                                                            data={values.grAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isAboutDescription', data);
                                                                            }}
                                                                            data={values.isAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roAboutDescription', data);
                                                                            }}
                                                                            data={values.roAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruAboutDescription', data);
                                                                            }}
                                                                            data={values.ruAboutDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaAboutDescription', data);
                                                                            }}
                                                                            data={values.uaAboutDescription}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Description Second</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.arAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.enAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.esAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.frAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.grAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.isAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.roAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.ruAboutDescriptionSecond}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaAboutDescriptionSecond', data);
                                                                            }}
                                                                            data={values.uaAboutDescriptionSecond}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Title initiative section</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaAboutTitleInitiative"
                                                                               placeholder="AboutTitleInitiative"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Description initiative section</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.arAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.enAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.esAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.frAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.grAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.isAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.roAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.ruAboutDescriptionInitiative}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaAboutDescriptionInitiative', data);
                                                                            }}
                                                                            data={values.uaAboutDescriptionInitiative}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Title Advisers section</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                                                                        <Field type="text" name="arAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="enAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="esAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="frAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="grAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="isAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="roAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="ruAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <Field type="text" name="uaAboutTitleAdvisers"
                                                                               placeholder="AboutTitleAdvisers"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Description Advisers section</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.arAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.enAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.esAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.frAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.grAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.isAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.roAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.ruAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaAboutDescriptionAdvisers', data);
                                                                            }}
                                                                            data={values.uaAboutDescriptionAdvisers}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="actions-button-section">
                                                                <button type="submit">Save</button>
                                                            </div>
                                                        </Form>)}
                                                    </Formik>
                                                </div>
                                            </div>
                                            <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                            </div>
                                            <div className={openTab === 8 ? "block" : "hidden"} id="link3">
                                                <div className="form-section">
                                                    <Formik initialValues={{
                                                        arCreatedBy: translation?.ar['Footer']['createdBy'],
                                                        enCreatedBy: translation?.en['Footer']['createdBy'],
                                                        esCreatedBy: translation?.es['Footer']['createdBy'],
                                                        frCreatedBy: translation?.fr['Footer']['createdBy'],
                                                        grCreatedBy: translation?.gr['Footer']['createdBy'],
                                                        isCreatedBy: translation?.is['Footer']['createdBy'],
                                                        roCreatedBy: translation?.ro['Footer']['createdBy'],
                                                        ruCreatedBy: translation?.ru['Footer']['createdBy'],
                                                        uaCreatedBy: translation?.ua['Footer']['createdBy'],

                                                        arFooterDescription: translation?.ar['Footer']['Description'],
                                                        enFooterDescription: translation?.en['Footer']['Description'],
                                                        esFooterDescription: translation?.es['Footer']['Description'],
                                                        frFooterDescription: translation?.fr['Footer']['Description'],
                                                        grFooterDescription: translation?.gr['Footer']['Description'],
                                                        isFooterDescription: translation?.is['Footer']['Description'],
                                                        roFooterDescription: translation?.ro['Footer']['Description'],
                                                        ruFooterDescription: translation?.ru['Footer']['Description'],
                                                        uaFooterDescription: translation?.ua['Footer']['Description'],

                                                        arCopyDescription: translation?.ar['Footer']['copyDescription'],
                                                        enCopyDescription: translation?.en['Footer']['copyDescription'],
                                                        esCopyDescription: translation?.es['Footer']['copyDescription'],
                                                        frCopyDescription: translation?.fr['Footer']['copyDescription'],
                                                        grCopyDescription: translation?.gr['Footer']['copyDescription'],
                                                        isCopyDescription: translation?.is['Footer']['copyDescription'],
                                                        roCopyDescription: translation?.ro['Footer']['copyDescription'],
                                                        ruCopyDescription: translation?.ru['Footer']['copyDescription'],
                                                        uaCopyDescription: translation?.ua['Footer']['copyDescription'],

                                                    }}
                                                            onSubmit={(values) => {
                                                                const data = {
                                                                    data: [
                                                                        {
                                                                            group: 'Footer',
                                                                            key: 'createdBy',
                                                                            text: {
                                                                                ar: values.arCreatedBy,
                                                                                en: values.enCreatedBy,
                                                                                es: values.esCreatedBy,
                                                                                fr: values.frCreatedBy,
                                                                                gr: values.grCreatedBy,
                                                                                is: values.isCreatedBy,
                                                                                ro: values.roCreatedBy,
                                                                                ru: values.ruCreatedBy,
                                                                                ua: values.uaCreatedBy
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Footer',
                                                                            key: 'copyDescription',
                                                                            text: {
                                                                                ar: values.arCopyDescription,
                                                                                en: values.enCopyDescription,
                                                                                es: values.esCopyDescription,
                                                                                fr: values.frCopyDescription,
                                                                                gr: values.grCopyDescription,
                                                                                is: values.isCopyDescription,
                                                                                ro: values.roCopyDescription,
                                                                                ru: values.ruCopyDescription,
                                                                                ua: values.uaCopyDescription
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Footer',
                                                                            key: 'Description',
                                                                            text: {
                                                                                ar: values.arFooterDescription,
                                                                                en: values.enFooterDescription,
                                                                                es: values.esFooterDescription,
                                                                                fr: values.frFooterDescription,
                                                                                gr: values.grFooterDescription,
                                                                                is: values.isFooterDescription,
                                                                                ro: values.roFooterDescription,
                                                                                ru: values.ruFooterDescription,
                                                                                ua: values.uaFooterDescription
                                                                            }
                                                                        }
                                                                    ],
                                                                    token: token
                                                                }

                                                                // handleEditData(false);
                                                                editTranslation(data).then(res => {
                                                                    toast.success("Successfully Saved", {
                                                                        position: toast.POSITION.TOP_CENTER,
                                                                    });
                                                                });
                                                            }}
                                                    >
                                                        {({errors, touched, values, setFieldValue}) => (<Form>
                                                            <div className="formContent">
                                                                <span className="title-head-settings">Created By</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="SA"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Saudi Arabia"
                            />
                          </span>
                                                                        <Field type="text" name="arCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="GB"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="United Kingdom"
                            />
                          </span>
                                                                        <Field type="text" name="enCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ES"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Spain"
                            />
                          </span>
                                                                        <Field type="text" name="esCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="FR"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="France"
                            />
                          </span>
                                                                        <Field type="text" name="frCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="DE"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Germany"
                            />
                          </span>
                                                                        <Field type="text" name="grCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="IS"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Iceland"
                            />
                          </span>
                                                                        <Field type="text" name="isCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RO"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Romania"
                            />
                          </span>
                                                                        <Field type="text" name="roCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RU"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Russia"
                            />
                          </span>
                                                                        <Field type="text" name="ruCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ua"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Ukraine"
                            />
                          </span>
                                                                        <Field type="text" name="uaCreatedBy"
                                                                               placeholder="CreatedBy"/>
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Description</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arFooterDescription', data);
                                                                            }}
                                                                            data={values.arFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enFooterDescription', data);
                                                                            }}
                                                                            data={values.enFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esFooterDescription', data);
                                                                            }}
                                                                            data={values.esFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frFooterDescription', data);
                                                                            }}
                                                                            data={values.frFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grFooterDescription', data);
                                                                            }}
                                                                            data={values.grFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isFooterDescription', data);
                                                                            }}
                                                                            data={values.isFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roFooterDescription', data);
                                                                            }}
                                                                            data={values.roFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruFooterDescription', data);
                                                                            }}
                                                                            data={values.ruFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaFooterDescription', data);
                                                                            }}
                                                                            data={values.uaFooterDescription}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Copyright Description</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arCopyDescription', data);
                                                                            }}
                                                                            data={values.arCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enCopyDescription', data);
                                                                            }}
                                                                            data={values.enCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esCopyDescription', data);
                                                                            }}
                                                                            data={values.esCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frCopyDescription', data);
                                                                            }}
                                                                            data={values.frCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grCopyDescription', data);
                                                                            }}
                                                                            data={values.grCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isCopyDescription', data);
                                                                            }}
                                                                            data={values.isCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roCopyDescription', data);
                                                                            }}
                                                                            data={values.roCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruCopyDescription', data);
                                                                            }}
                                                                            data={values.ruCopyDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaCopyDescription', data);
                                                                            }}
                                                                            data={values.uaCopyDescription}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="actions-button-section">
                                                                <button type="submit">Save</button>
                                                            </div>
                                                        </Form>)}
                                                    </Formik>
                                                </div>
                                            </div>
                                            <div className={openTab === 9 ? "block" : "hidden"} id="link3">
                                                <div className="form-section">
                                                    <Formik initialValues={{
                                                        arPoint: translation.ar['Rules']['Point'] ?? null,
                                                        enPoint: translation?.en['Rules']['Point'],
                                                        esPoint: translation?.es['Rules']['Point'],
                                                        frPoint: translation?.fr['Rules']['Point'],
                                                        grPoint: translation?.gr['Rules']['Point'],
                                                        isPoint: translation?.is['Rules']['Point'],
                                                        roPoint: translation?.ro['Rules']['Point'],
                                                        ruPoint: translation?.ru['Rules']['Point'],
                                                        uaPoint: translation?.ua['Rules']['Point'],

                                                        arGeneral: translation?.ar['Rules']['General'],
                                                        enGeneral: translation?.en['Rules']['General'],
                                                        esGeneral: translation?.es['Rules']['General'],
                                                        frGeneral: translation?.fr['Rules']['General'],
                                                        grGeneral: translation?.gr['Rules']['General'],
                                                        isGeneral: translation?.is['Rules']['General'],
                                                        roGeneral: translation?.ro['Rules']['General'],
                                                        ruGeneral: translation?.ru['Rules']['General'],
                                                        uaGeneral: translation?.ua['Rules']['General'],

                                                        arGeneralRules: translation?.ar['Rules']['GeneralRules'],
                                                        enGeneralRules: translation?.en['Rules']['GeneralRules'],
                                                        esGeneralRules: translation?.es['Rules']['GeneralRules'],
                                                        frGeneralRules: translation?.fr['Rules']['GeneralRules'],
                                                        grGeneralRules: translation?.gr['Rules']['GeneralRules'],
                                                        isGeneralRules: translation?.is['Rules']['GeneralRules'],
                                                        roGeneralRules: translation?.ro['Rules']['GeneralRules'],
                                                        ruGeneralRules: translation?.ru['Rules']['GeneralRules'],
                                                        uaGeneralRules: translation?.ua['Rules']['GeneralRules'],

                                                        arRulesPublished: translation?.ar['Rules']['RulesPublished'],
                                                        enRulesPublished: translation?.en['Rules']['RulesPublished'],
                                                        esRulesPublished: translation?.es['Rules']['RulesPublished'],
                                                        frRulesPublished: translation?.fr['Rules']['RulesPublished'],
                                                        grRulesPublished: translation?.gr['Rules']['RulesPublished'],
                                                        isRulesPublished: translation?.is['Rules']['RulesPublished'],
                                                        roRulesPublished: translation?.ro['Rules']['RulesPublished'],
                                                        ruRulesPublished: translation?.ru['Rules']['RulesPublished'],
                                                        uaRulesPublished: translation?.ua['Rules']['RulesPublished'],

                                                        arRequirements: translation?.ar['Rules']['Requirements'],
                                                        enRequirements: translation?.en['Rules']['Requirements'],
                                                        esRequirements: translation?.es['Rules']['Requirements'],
                                                        frRequirements: translation?.fr['Rules']['Requirements'],
                                                        grRequirements: translation?.gr['Rules']['Requirements'],
                                                        isRequirements: translation?.is['Rules']['Requirements'],
                                                        roRequirements: translation?.ro['Rules']['Requirements'],
                                                        ruRequirements: translation?.ru['Rules']['Requirements'],
                                                        uaRequirements: translation?.ua['Rules']['Requirements'],

                                                        arRulesVip: translation?.ar['Rules']['RulesVip'],
                                                        enRulesVip: translation?.en['Rules']['RulesVip'],
                                                        esRulesVip: translation?.es['Rules']['RulesVip'],
                                                        frRulesVip: translation?.fr['Rules']['RulesVip'],
                                                        grRulesVip: translation?.gr['Rules']['RulesVip'],
                                                        isRulesVip: translation?.is['Rules']['RulesVip'],
                                                        roRulesVip: translation?.ro['Rules']['RulesVip'],
                                                        ruRulesVip: translation?.ru['Rules']['RulesVip'],
                                                        uaRulesVip: translation?.ua['Rules']['RulesVip'],

                                                        arRequirementsText: translation?.ar['Rules']['RequirementsText'],
                                                        enRequirementsText: translation?.en['Rules']['RequirementsText'],
                                                        esRequirementsText: translation?.es['Rules']['RequirementsText'],
                                                        frRequirementsText: translation?.fr['Rules']['RequirementsText'],
                                                        grRequirementsText: translation?.gr['Rules']['RequirementsText'],
                                                        isRequirementsText: translation?.is['Rules']['RequirementsText'],
                                                        roRequirementsText: translation?.ro['Rules']['RequirementsText'],
                                                        ruRequirementsText: translation?.ru['Rules']['RequirementsText'],
                                                        uaRequirementsText: translation?.ua['Rules']['RequirementsText'],

                                                        arCV: translation?.ar['Rules']['CV'],
                                                        enCV: translation?.en['Rules']['CV'],
                                                        esCV: translation?.es['Rules']['CV'],
                                                        frCV: translation?.fr['Rules']['CV'],
                                                        grCV: translation?.gr['Rules']['CV'],
                                                        isCV: translation?.is['Rules']['CV'],
                                                        roCV: translation?.ro['Rules']['CV'],
                                                        ruCV: translation?.ru['Rules']['CV'],
                                                        uaCV: translation?.ua['Rules']['CV'],

                                                        arCVACCES: translation?.ar['Rules']['CVACCES'],
                                                        enCVACCES: translation?.en['Rules']['CVACCES'],
                                                        esCVACCES: translation?.es['Rules']['CVACCES'],
                                                        frCVACCES: translation?.fr['Rules']['CVACCES'],
                                                        grCVACCES: translation?.gr['Rules']['CVACCES'],
                                                        isCVACCES: translation?.is['Rules']['CVACCES'],
                                                        roCVACCES: translation?.ro['Rules']['CVACCES'],
                                                        ruCVACCES: translation?.ru['Rules']['CVACCES'],
                                                        uaCVACCES: translation?.ua['Rules']['CVACCES'],

                                                        arVISA: translation?.ar['Rules']['VISA'],
                                                        enVISA: translation?.en['Rules']['VISA'],
                                                        esVISA: translation?.es['Rules']['VISA'],
                                                        frVISA: translation?.fr['Rules']['VISA'],
                                                        grVISA: translation?.gr['Rules']['VISA'],
                                                        isVISA: translation?.is['Rules']['VISA'],
                                                        roVISA: translation?.ro['Rules']['VISA'],
                                                        ruVISA: translation?.ru['Rules']['VISA'],
                                                        uaVISA: translation?.ua['Rules']['VISA'],

                                                    }}
                                                            onSubmit={(values) => {
                                                                const data = {
                                                                    data: [
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'Point',
                                                                            text: {
                                                                                ar: values.arPoint,
                                                                                en: values.enPoint,
                                                                                es: values.esPoint,
                                                                                fr: values.frPoint,
                                                                                gr: values.grPoint,
                                                                                is: values.isPoint,
                                                                                ro: values.roPoint,
                                                                                ru: values.ruPoint,
                                                                                ua: values.uaPoint
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'General',
                                                                            text: {
                                                                                ar: values.arGeneral,
                                                                                en: values.enGeneral,
                                                                                es: values.esGeneral,
                                                                                fr: values.frGeneral,
                                                                                gr: values.grGeneral,
                                                                                is: values.isGeneral,
                                                                                ro: values.roGeneral,
                                                                                ru: values.ruGeneral,
                                                                                ua: values.uaGeneral
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'GeneralRules',
                                                                            text: {
                                                                                ar: values.arGeneralRules,
                                                                                en: values.enGeneralRules,
                                                                                es: values.esGeneralRules,
                                                                                fr: values.frGeneralRules,
                                                                                gr: values.grGeneralRules,
                                                                                is: values.isGeneralRules,
                                                                                ro: values.roGeneralRules,
                                                                                ru: values.ruGeneralRules,
                                                                                ua: values.uaGeneralRules
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'RulesPublished',
                                                                            text: {
                                                                                ar: values.arRulesPublished,
                                                                                en: values.enRulesPublished,
                                                                                es: values.esRulesPublished,
                                                                                fr: values.frRulesPublished,
                                                                                gr: values.grRulesPublished,
                                                                                is: values.isRulesPublished,
                                                                                ro: values.roRulesPublished,
                                                                                ru: values.ruRulesPublished,
                                                                                ua: values.uaRulesPublished
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'Requirements',
                                                                            text: {
                                                                                ar: values.arRequirements,
                                                                                en: values.enRequirements,
                                                                                es: values.esRequirements,
                                                                                fr: values.frRequirements,
                                                                                gr: values.grRequirements,
                                                                                is: values.isRequirements,
                                                                                ro: values.roRequirements,
                                                                                ru: values.ruRequirements,
                                                                                ua: values.uaRequirements
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'RulesVip',
                                                                            text: {
                                                                                ar: values.arRulesVip,
                                                                                en: values.enRulesVip,
                                                                                es: values.esRulesVip,
                                                                                fr: values.frRulesVip,
                                                                                gr: values.grRulesVip,
                                                                                is: values.isRulesVip,
                                                                                ro: values.roRulesVip,
                                                                                ru: values.ruRulesVip,
                                                                                ua: values.uaRulesVip
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'RequirementsText',
                                                                            text: {
                                                                                ar: values.arRequirementsText,
                                                                                en: values.enRequirementsText,
                                                                                es: values.esRequirementsText,
                                                                                fr: values.frRequirementsText,
                                                                                gr: values.grRequirementsText,
                                                                                is: values.isRequirementsText,
                                                                                ro: values.roRequirementsText,
                                                                                ru: values.ruRequirementsText,
                                                                                ua: values.uaRequirementsText
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'CV',
                                                                            text: {
                                                                                ar: values.arCV,
                                                                                en: values.enCV,
                                                                                es: values.esCV,
                                                                                fr: values.frCV,
                                                                                gr: values.grCV,
                                                                                is: values.isCV,
                                                                                ro: values.roCV,
                                                                                ru: values.ruCV,
                                                                                ua: values.uaCV
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'CVACCES',
                                                                            text: {
                                                                                ar: values.arCVACCES,
                                                                                en: values.enCVACCES,
                                                                                es: values.esCVACCES,
                                                                                fr: values.frCVACCES,
                                                                                gr: values.grCVACCES,
                                                                                is: values.isCVACCES,
                                                                                ro: values.roCVACCES,
                                                                                ru: values.ruCVACCES,
                                                                                ua: values.uaCVACCES
                                                                            }
                                                                        },
                                                                        {
                                                                            group: 'Rules',
                                                                            key: 'VISA',
                                                                            text: {
                                                                                ar: values.arVISA,
                                                                                en: values.enVISA,
                                                                                es: values.esVISA,
                                                                                fr: values.frVISA,
                                                                                gr: values.grVISA,
                                                                                is: values.isVISA,
                                                                                ro: values.roVISA,
                                                                                ru: values.ruVISA,
                                                                                ua: values.uaVISA
                                                                            }
                                                                        }
                                                                    ],
                                                                    token: token
                                                                }

                                                                // handleEditData(false);
                                                                editTranslation(data).then(res => {
                                                                    toast.success("Successfully Saved", {
                                                                        position: toast.POSITION.TOP_CENTER,
                                                                    });
                                                                });
                                                            }}
                                                    >
                                                        {({errors, touched, values, setFieldValue}) => (<Form>
                                                            <div className="formContent">
                                                                <span className="title-head-settings">Rules</span>
                                                                <div className="TitleSection">
                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="SA"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Saudi Arabia"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arPoint', data);
                                                                            }}
                                                                            data={values.arPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="GB"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="United Kingdom"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enPoint', data);
                                                                            }}
                                                                            data={values.enPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ES"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Spain"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esPoint', data);
                                                                            }}
                                                                            data={values.esPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="FR"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="France"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frPoint', data);
                                                                            }}
                                                                            data={values.frPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="DE"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Germany"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grPoint', data);
                                                                            }}
                                                                            data={values.grPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="IS"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Iceland"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isPoint', data);
                                                                            }}
                                                                            data={values.isPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RO"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Romania"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roPoint', data);
                                                                            }}
                                                                            data={values.roPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="RU"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Russia"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruPoint', data);
                                                                            }}
                                                                            data={values.ruPoint}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                          <span className="icon">
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode="ua"
                                style={{
                                    fontSize: '2em', lineHeight: '2em',
                                }}
                                aria-label="Ukraine"
                            />
                          </span>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaPoint', data);
                                                                            }}
                                                                            data={values.uaPoint}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">General</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arGeneral', data);
                                                                            }}
                                                                            data={values.arGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enGeneral', data);
                                                                            }}
                                                                            data={values.enGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esGeneral', data);
                                                                            }}
                                                                            data={values.esGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frGeneral', data);
                                                                            }}
                                                                            data={values.frGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grGeneral', data);
                                                                            }}
                                                                            data={values.grGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isGeneral', data);
                                                                            }}
                                                                            data={values.isFooterDescription}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roGeneral', data);
                                                                            }}
                                                                            data={values.roGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruGeneral', data);
                                                                            }}
                                                                            data={values.ruGeneral}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaGeneral', data);
                                                                            }}
                                                                            data={values.uaGeneral}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Reguli generale</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arGeneralRules', data);
                                                                            }}
                                                                            data={values.arGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enGeneralRules', data);
                                                                            }}
                                                                            data={values.enGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esGeneralRules', data);
                                                                            }}
                                                                            data={values.esGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frGeneralRules', data);
                                                                            }}
                                                                            data={values.frGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grGeneralRules', data);
                                                                            }}
                                                                            data={values.grGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isGeneralRules', data);
                                                                            }}
                                                                            data={values.isGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roGeneralRules', data);
                                                                            }}
                                                                            data={values.roGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruGeneralRules', data);
                                                                            }}
                                                                            data={values.ruGeneralRules}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaGeneralRules', data);
                                                                            }}
                                                                            data={values.uaGeneralRules}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Reguli de publicare a anunțurilor</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arRulesPublished', data);
                                                                            }}
                                                                            data={values.arRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enRulesPublished', data);
                                                                            }}
                                                                            data={values.enRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esRulesPublished', data);
                                                                            }}
                                                                            data={values.esRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frRulesPublished', data);
                                                                            }}
                                                                            data={values.frRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grRulesPublished', data);
                                                                            }}
                                                                            data={values.grRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isRulesPublished', data);
                                                                            }}
                                                                            data={values.isRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roRulesPublished', data);
                                                                            }}
                                                                            data={values.roRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruRulesPublished', data);
                                                                            }}
                                                                            data={values.ruRulesPublished}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaRulesPublished', data);
                                                                            }}
                                                                            data={values.uaRulesPublished}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Cerințe obligatorii față de titlul anunțului</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arRequirements', data);
                                                                            }}
                                                                            data={values.arRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enRequirements', data);
                                                                            }}
                                                                            data={values.enRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esRequirements', data);
                                                                            }}
                                                                            data={values.esRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frRequirements', data);
                                                                            }}
                                                                            data={values.frRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grRequirements', data);
                                                                            }}
                                                                            data={values.grRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isRequirements', data);
                                                                            }}
                                                                            data={values.isRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roRequirements', data);
                                                                            }}
                                                                            data={values.roRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruRequirements', data);
                                                                            }}
                                                                            data={values.ruRequirements}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaRequirements', data);
                                                                            }}
                                                                            data={values.uaRequirements}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Reguli de publicare a anunturilor VIP</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arRulesVip', data);
                                                                            }}
                                                                            data={values.arRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enRulesVip', data);
                                                                            }}
                                                                            data={values.enRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esRulesVip', data);
                                                                            }}
                                                                            data={values.esRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frRulesVip', data);
                                                                            }}
                                                                            data={values.frRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grRulesVip', data);
                                                                            }}
                                                                            data={values.grRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isRulesVip', data);
                                                                            }}
                                                                            data={values.isRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roRulesVip', data);
                                                                            }}
                                                                            data={values.roRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruRulesVip', data);
                                                                            }}
                                                                            data={values.ruRulesVip}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaRulesVip', data);
                                                                            }}
                                                                            data={values.uaRulesVip}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">Cerințe obligatorii față de textul anunțului</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arRequirementsText', data);
                                                                            }}
                                                                            data={values.arRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enRequirementsText', data);
                                                                            }}
                                                                            data={values.enRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esRequirementsText', data);
                                                                            }}
                                                                            data={values.esRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frRequirementsText', data);
                                                                            }}
                                                                            data={values.frRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grRequirementsText', data);
                                                                            }}
                                                                            data={values.grRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isRequirementsText', data);
                                                                            }}
                                                                            data={values.isRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roRequirementsText', data);
                                                                            }}
                                                                            data={values.roRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruRequirementsText', data);
                                                                            }}
                                                                            data={values.ruRequirementsText}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaRequirementsText', data);
                                                                            }}
                                                                            data={values.uaRequirementsText}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">CV</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arCV', data);
                                                                            }}
                                                                            data={values.arCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enCV', data);
                                                                            }}
                                                                            data={values.enCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esCV', data);
                                                                            }}
                                                                            data={values.esCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frCV', data);
                                                                            }}
                                                                            data={values.frCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grCV', data);
                                                                            }}
                                                                            data={values.grCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isCV', data);
                                                                            }}
                                                                            data={values.isCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roCV', data);
                                                                            }}
                                                                            data={values.roCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruCV', data);
                                                                            }}
                                                                            data={values.ruCV}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaCV', data);
                                                                            }}
                                                                            data={values.uaCV}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">CV ACCESS</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arCVACCES', data);
                                                                            }}
                                                                            data={values.arCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enCVACCES', data);
                                                                            }}
                                                                            data={values.enCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esCVACCES', data);
                                                                            }}
                                                                            data={values.esCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frCVACCES', data);
                                                                            }}
                                                                            data={values.frCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grCVACCES', data);
                                                                            }}
                                                                            data={values.grCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isCVACCES', data);
                                                                            }}
                                                                            data={values.isCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roCVACCES', data);
                                                                            }}
                                                                            data={values.roCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruCVACCES', data);
                                                                            }}
                                                                            data={values.ruCVACCES}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaCVACCES', data);
                                                                            }}
                                                                            data={values.uaCVACCES}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <span className="title-head-settings">VISA</span>
                                                                <div className="DescriptionSection">
                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('arVISA', data);
                                                                            }}
                                                                            data={values.arVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('enVISA', data);
                                                                            }}
                                                                            data={values.enVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('esVISA', data);
                                                                            }}
                                                                            data={values.esVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('frVISA', data);
                                                                            }}
                                                                            data={values.frVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('grVISA', data);
                                                                            }}
                                                                            data={values.grVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('isVISA', data);
                                                                            }}
                                                                            data={values.isVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('roVISA', data);
                                                                            }}
                                                                            data={values.roVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('ruVISA', data);
                                                                            }}
                                                                            data={values.ruVISA}
                                                                        />
                                                                    </div>

                                                                    <div className="form-input">
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            onInit={editor => {
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('uaVISA', data);
                                                                            }}
                                                                            data={values.uaVISA}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="actions-button-section">
                                                                <button type="submit">Save</button>
                                                            </div>
                                                        </Form>)}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer autoClose={3000} />
            </section>}
            { !translation && <Loader />}
        </>
    );
}

export default Dictionary;
