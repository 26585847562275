import React, {useEffect, useState} from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {editMenuActions} from "../../../../../redux/actions/menuApi";

const MenuEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const menu = useSelector(state => state.menu.menu);
  const auth = useSelector(state => state.auth);
  const [token, setToken] = useState(auth.token);
  const dispatch = useDispatch();

  useEffect(() => {

    // Check if token is null and set it from localStorage if available
    if (token === null) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }

  }, [token]);

  const item = menu.filter(item => item.Id === parseInt(id))[0];

  const SignupSchema = Yup.object({
    arTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    enTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    esTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    frTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    grTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    isTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    roTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    ruTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
    uaTitle: Yup.string()
        .min(2,'Label must be 2 characters or more')
        .max(150,'Label must be 150 characters or less')
        .required('Label is a required field'),
  });

  const handleClose = () => {
    navigate('/dashboard/menu');
  }

  return (
      <section className="profile-settings">
        <div className="profile-settings-dictionary__heading">
          <h3>Menu</h3>
        </div>

        <section className="profile-settings-dictionary__content addBorder">
           <div className="form-section">
             <Formik  initialValues={
               {
                 hide: item?.Hide,
                 arTitle: item?.Label['ar'],
                 enTitle: item?.Label['en'],
                 esTitle: item?.Label['es'],
                 frTitle: item?.Label['fr'],
                 grTitle: item?.Label['gr'],
                 isTitle: item?.Label['is'],
                 roTitle: item?.Label['ro'],
                 ruTitle: item?.Label['ru'],
                 uaTitle: item?.Label['ua'],
               }
             }
                      validationSchema={SignupSchema}
                      onSubmit={(values) => {
                        const data = {
                          Id: id,
                          data: {
                            hide: values.hide,
                            label: {
                              ar: values.arTitle,
                              en: values.enTitle,
                              es: values.esTitle,
                              fr: values.frTitle,
                              gr: values.grTitle,
                              is: values.isTitle,
                              ro: values.roTitle,
                              ru: values.ruTitle,
                              ua: values.uaTitle,
                            },
                          },
                          token: token
                        }

                        // handleEditData(false);
                        dispatch(editMenuActions(data)).then(navigate('/dashboard/menu'));
                      }}
             >
               {({ errors, touched, values, setFieldValue }) => (
                   <Form>
                     <div className="formContent">
                       <div className="TitleSection">
                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="SA"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Saudi Arabia"
                        />
                      </span>
                           <Field type="text" name="arTitle" placeholder="Title" />
                           <ErrorMessage name="arTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="GB"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="United Kingdom"
                        />
                      </span>
                           <Field type="text" name="enTitle" placeholder="Title" />
                           <ErrorMessage name="entitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="ES"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Spain"
                        />
                      </span>
                           <Field type="text" name="esTitle" placeholder="Title" />
                           <ErrorMessage name="esTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="FR"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="France"
                        />
                      </span>
                           <Field type="text" name="frTitle" placeholder="Title" />
                           <ErrorMessage name="frTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="DE"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Germany"
                        />
                      </span>
                           <Field type="text" name="grTitle" placeholder="Title" />
                           <ErrorMessage name="grTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="IS"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Iceland"
                        />
                      </span>
                           <Field type="text" name="isTitle" placeholder="Title" />
                           <ErrorMessage name="isTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="RO"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Romania"
                        />
                      </span>
                           <Field type="text" name="roTitle" placeholder="Title" />
                           <ErrorMessage name="roTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="RU"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Russia"
                        />
                      </span>
                           <Field type="text" name="ruTitle" placeholder="Title" />
                           <ErrorMessage name="ruTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>

                         <div className="form-input">
                      <span className="icon">
                        <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="ua"
                            style={{
                              fontSize: '2em',
                              lineHeight: '2em',
                            }}
                            aria-label="Ukraine"
                        />
                      </span>
                           <Field type="text" name="uaTitle" placeholder="Title" />
                           <ErrorMessage name="uaTitle" render={msg => <div className="error-message">{msg}</div>} />
                         </div>
                       </div>
                     </div>


                     <div className="radio-group-field">
                       <div role="group" aria-labelledby="my-radio-group">
                         <label>
                           <Field type="radio" name="hide" value="0" checked={values.hide == 0}/>
                           Hide
                         </label>
                         <label>
                           <Field type="radio" name="hide" value="1" checked={values.hide == 1}/>
                           Visible
                         </label>
                       </div>
                     </div>

                     <div className="actions-button-section">
                       <button onClick={() => handleClose()} className="mr-2">Close</button>
                       <button type="submit">Save</button>
                     </div>
                   </Form>
               )}
             </Formik>
           </div>
        </section>
      </section>
  );
}

export default MenuEdit;
