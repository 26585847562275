import {apiClient} from "./index";

export const getBlogs = (params) => {
    return apiClient.get('/api/blog',{params}).then(response => response.data);
}

export const getInfoBlog = (id) => {
    return apiClient.get(`/api/blog/${id}`);
}

export const addBlog = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/blog',data);
}

export const editBlog = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/blog/${Id}?_method=PUT`,data);
}

export const deleteBlog = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/blog/${Id}`);
}
