import React, {useMemo, useState} from 'react';
import {flexRender, getCoreRowModel, useReactTable, getSortedRowModel} from "@tanstack/react-table";
import Pagination from "../Pagination/Pagination";
import "./Table.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";

const BasicTable = (props) => {
    const {basic_column, data, className, enableSorting, templateName} = props;
    // let pageSize = perPage ?? 100;
    // const [currentPage, setCurrentPage] = useState(1);
     const [rowSelection, setRowSelection] = useState({});
    const [sorting, setSorting] = useState([]);
    // const data = useMemo(() => {
    //     const firstPageIndex = (currentPage - 1) * pageSize;
    //     const lastPageIndex = firstPageIndex + pageSize;
    //     return date.slice(firstPageIndex, lastPageIndex);
    // }, [currentPage]);

    const columns = useMemo(() => basic_column, []);


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection,
            sorting
        },
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        enableRowSelection: true,
        enableSorting: enableSorting ?? false,
    });


    return (
        <>
            <div className="table-main-section">
                <table className={`main-table-class ${className}`}>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} {...{
                                    className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                }}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    {{
                                        asc: <FontAwesomeIcon icon={faArrowUp}/>,
                                        desc: <FontAwesomeIcon icon={faArrowDown}/>,
                                    }[header.column.getIsSorted()] ?? null}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>


            {/*<Pagination*/}
            {/*    currentPage={currentPage}*/}
            {/*    totalCount={date.length}*/}
            {/*    pageSize={pageSize}*/}
            {/*    template={templateName ?? "template-two"}*/}
            {/*    onPageChange={page => setCurrentPage(page)}*/}
            {/*/>*/}
        </>

    );
}

export default BasicTable;
