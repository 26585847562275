import React, {useMemo} from 'react';

export const usePagination = ({
                                  totalCount,
                                  pageSize,
                                  siblingCount = 1,
                                  currentPage
}) => {
    const range = (start, end) => {
        let length = end - start + 1;
        /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        const totalPageNumbers = siblingCount + 3;

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }
        //
        // const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        // const rightSiblingIndex = Math.min(
        //     currentPage + siblingCount,
        //     totalPageCount
        // );

    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
}
