import React, {useState} from 'react';
import './quizz.scss';
import EditQuizz from "../Profiles/MyQuiz/EditQuizz";

const QuizItem = ({quiz, addQuiz, editQuizz, closeQuizz}) => {
    const [edit, setEdit] = useState(false);
    const handleEdit = (value) => {
        editQuizz(value);
        setEdit(value);
        addQuiz(false);
    }

    const handleCloseQuizz = (value) => {
        closeQuizz(value);
        editQuizz(value);
        setEdit(value);
        addQuiz(value);
    }

    return (
        <>
            {
                edit ? <EditQuizz closeQuizzItem={handleCloseQuizz} quiz={quiz} editQuizItem={handleCloseQuizz}/> : <div className="quiz-item">
                    <div className="quiz-item-main-section">
                        <div className="head-main-section">
                            <span className="quiz-title">{quiz.Title}</span>
                            <span className="quiz-category">{`Domeniu: ${quiz.Category}`}</span>
                            <span className="quiz-data">Data: 12.04.2023</span>
                        </div>
                        <div className="description-quiz">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                        </div>
                    </div>
                    <div className="quiz-item-info-section">
                        <div className="break"></div>
                        <div className="noOfQuiz">
                            <span>Intrebari: {quiz.Quiz.length}</span>
                            <span>Variante de raspuns: {quiz.Quiz.length * 4}</span>
                            <span>Text: 22</span>
                        </div>

                        <div className="quiz-actions">
                            <span className="quiz-button" onClick={() => handleEdit(true)}>View / Editing Test</span>
                            <span className="quiz-button">Public</span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default QuizItem;
