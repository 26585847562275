import {createSlice} from "@reduxjs/toolkit";
import {
    certificateGetCertificatesActions,
    certificateEditActions,
    certificateCreateActions,
    certificateDeleteActions
} from "../actions/certificateApi";

const certificateReducer = createSlice({
    name: 'certificate',
    initialState: {
        certificate: [],
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(certificateGetCertificatesActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(certificateGetCertificatesActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.certificate = payload
                }
            })

            .addCase(certificateGetCertificatesActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(certificateEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(certificateEditActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.certificate.map(item => {
                        if(item.Id === payload.data.Id) {
                            item.Title = payload.data.Title;
                            item.Image = payload.data.Image;
                        }
                    });
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(certificateEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(certificateCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(certificateCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.certificate = [...state.certificate, {...payload}]
                }
            })

            .addCase(certificateCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(certificateDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(certificateDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(certificateDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default certificateReducer.reducer;
