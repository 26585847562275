import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding, faCertificate, faChartSimple, faCheckDouble, faDollarSign,
  faMessage, faRightFromBracket,
  faStar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../redux/reducer/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {jobGetJobsActions} from "../../redux/actions/jobsApi";

const SidebarEmployer = ({ user, company }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, error, jobs} = useSelector(state => state.jobs);
  const [page, setPage] = useState('');

  useEffect(() => {
    if(company){
      if(company.Id){
        dispatch(jobGetJobsActions({expand: 'user,applications,applications.user,applications.guest', 'company_id': company?.Id}));
      }
    }
  },[company])

  const logoutHandle = () => {
    dispatch(logout());
    navigate("/login");
  }

    return (
        <>
          <div className="sidebar employer">
            <div className="sidebar__profile">
              <div className="sidebar__profile__user">
                <h1>{company?.Title ?? 'Company Name'}</h1>
                <div className="profile-image">
                  <img
                      src={company?.Logo ?? 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'}
                      className="border-1 shadow-sm border-white border- rounded-full w-full h-full object-cover"
                      alt="Full Name"/>
                </div>
                <span className="block user_name">Slogan</span>
                <span className="block country">Moldova</span>
              </div>


              <div className="sidebar__profile__data">
                <span>Aplicari: {company?.NrOfApplications ?? 0}</span>
                <span>Joburi: {company?.NrOfJobs ?? 0}</span>
                <span>Cursuri: 35</span>
                <span>Angajari: 2</span>
              </div>
            </div>

            <div className="sidebar__menu">
              <div className="sidebar__menu__items">
                <NavLink to={"/dashboard/applied-interview"} className="item-menu" onClick={() => setPage('applied-interview')}><span><FontAwesomeIcon icon={faCircleCheck}/> Aplicari</span></NavLink>
                <NavLink to={"/dashboard/job-active"} className="item-menu" onClick={() => setPage('job-active')}><span><FontAwesomeIcon icon={faStar}/> Joburi active</span></NavLink>
                <NavLink to={"/dashboard/interview"} className="item-menu" onClick={() => setPage('interview')}><span><FontAwesomeIcon icon={faUserCheck}/> Interviuri</span></NavLink>
                {/*<NavLink to={"/dashboard/offers-and-jobs"} className="item-menu" onClick={() => setPage('offers-and-jobs')}><span><FontAwesomeIcon icon={faMoneyCheck}/> Oferte si angajari</span></NavLink>*/}
                {/*<NavLink to={"/dashboard/user/profile"} className="item-menu"><span><FontAwesomeIcon icon={faFingerprint}/> Informație personală</span></NavLink>*/}
                {/*<NavLink to={"/dashboard/practic-and-career"} className="item-menu"><span><FontAwesomeIcon icon={faBriefcase}/>Practica si carieri</span></NavLink>*/}
                {/*<NavLink to={"/dashboard/courses"} className="item-menu"><span><FontAwesomeIcon icon={faBook}/>Cursuri</span></NavLink>*/}
                <NavLink to={"/dashboard/my-quizzes"} className="item-menu"><span><FontAwesomeIcon icon={faCheckDouble}/>Teste</span></NavLink>
                <NavLink to={"/dashboard/my-certificates"} className="item-menu"><span><FontAwesomeIcon icon={faCertificate}/>Certificari</span></NavLink>
                <NavLink to={"/dashboard/reviews"} className="item-menu"><span><FontAwesomeIcon icon={faMessage}/>Recenzii</span></NavLink>
                <NavLink to={"/dashboard/banking"} className="item-menu"><span><FontAwesomeIcon icon={faDollarSign}/>Rechizite bancare</span></NavLink>
                <NavLink to={"/dashboard/my-statistic"} className="item-menu" onClick={() => setPage('my-statistic')}><span><FontAwesomeIcon icon={faChartSimple}/>Statistica</span></NavLink>
                <NavLink to={"/dashboard/about-company"} className="item-menu"><span><FontAwesomeIcon icon={faBuilding}/>Date companie</span></NavLink>
              </div>
              <div className='item-menu item-menu-exit' onClick={() => logoutHandle()}><FontAwesomeIcon icon={faRightFromBracket}/>Exit</div>
            </div>
          </div>
        </>
    );
}

export default SidebarEmployer;
