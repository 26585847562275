import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {userInfoActions} from "../../../../redux/actions/userApi";
import Loader from "components/Loader/Loader";
import {CV} from "components/Profiles";

const MyCv = () => {
  const dispatch = useDispatch();
  const {loading, error, user} = useSelector(state => state.user);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if((token === null || token === undefined) || (error)){
      window.location.href = '/';
      return;
    } else if(user.length === 0){
      dispatch(userInfoActions(token));
    }
  }, [token, dispatch, error]);
    return (
        <>
          {loading && <Loader />}
          {!loading && <CV user={user}/>}
        </>
    );
}

export default MyCv;
