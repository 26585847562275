import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretRight, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";


const Header = () => {
    const { t, i18n } = useTranslation();
    const {menu} = useSelector(state => state.menu);
    const [active, setActive] = useState('');
    const token = localStorage.getItem('token');
    const tokenExpire = localStorage.getItem('expire');

    const onChangeLanguage = (e) => {
       i18n.changeLanguage(e.target.value);
    }

    useEffect(() => {
        for(let i = 0; i < document.getElementsByClassName('lang').length; i++) {
            const element = document.getElementsByClassName('lang')[i];
            if(element.value === i18n.language){
                element.setAttribute('selected',"true");
            }
        }
    })

    return (
        <header className="site-header">
           <div className="container">
                       <Link className="logo" to={"/"} onClick={() => setActive('')} ><img src={ require('../../assets/images/logoMain.png') } alt="Logo"/></Link>

                       <nav>
                           <div className="main-nav">
                               <ul>
                                   {menu[0]?.Hide === 1 &&
                                       <li onClick={() => setActive('')} className={ active === '' ? "active":"" }><Link to={"/"}><span data-hover={menu[0]?.Label[i18n.language]}>{menu[0]?.Label[i18n.language]}</span></Link></li>
                                   }
                                   {
                                       menu[1]?.Hide === 1 &&
                                       <li onClick={() => setActive('about')} className={ active === 'about' ? "active":"" }><Link to={"/about-us"}><span data-hover={menu[1]?.Label[i18n.language]}>{menu[1]?.Label[i18n.language]}</span></Link></li>
                                   }
                                   {
                                       menu[2]?.Hide === 1 &&
                                       <li onClick={() => setActive('vacancies')} className={ active === 'vacancies' ? "active":"" }><Link to={"/vacancies"}><span data-hover={menu[2]?.Label[i18n.language]}>{menu[2]?.Label[i18n.language]}</span></Link></li>
                                   }
                                   {
                                       menu[3]?.Hide === 1 &&
                                       <li onClick={() => setActive('courses')} className={ active === 'courses' ? "dropdown main-menu active":"dropdown main-menu" }><Link to={"/courses"}>
                                           <span data-hover={menu[3]?.Label[i18n.language]}>{menu[3]?.Label[i18n.language]}
                                               {/*<FontAwesomeIcon className="caretDown" icon={faCaretDown} /><FontAwesomeIcon className="caretRight" icon={faCaretRight} />*/}
                                       </span></Link>
                                           {/*<ul>*/}
                                           {/*    <li className="sub-menu dropdown dropdown-lv2"><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.label')}<FontAwesomeIcon className="caretRight" icon={faCaretRight} /></Link>*/}
                                           {/*        <ul>*/}
                                           {/*            <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.sub-menu-1-1')}</Link></li>*/}
                                           {/*            <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.sub-menu-1-2')}</Link></li>*/}
                                           {/*            <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.sub-menu-1-3')}</Link></li>*/}
                                           {/*            <li className="sub-sub-menu dropdown dropdown-lv3"><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.sub-menu-1-4.label')}<FontAwesomeIcon className="caretRight" icon={faCaretRight} /></Link>*/}
                                           {/*                <ul>*/}
                                           {/*                    <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.sub-menu-1-4.sub-menu-1-4-1')}</Link></li>*/}
                                           {/*                    <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-1.sub-menu-1-4.sub-menu-1-4-2')}</Link></li>*/}
                                           {/*                </ul>*/}
                                           {/*            </li>*/}
                                           {/*        </ul>*/}
                                           {/*    </li>*/}
                                           {/*    <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-2')}</Link></li>*/}
                                           {/*    <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-3')}</Link></li>*/}
                                           {/*    <li className="sub-menu dropdown dropdown-lv2"><Link to="">{t('mainMenu.main-menu-4.sub-menu-4.label')}<FontAwesomeIcon className="caretRight" icon={faCaretRight} /></Link>*/}
                                           {/*        <ul>*/}
                                           {/*            <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-4.sub-menu-4-1')}</Link></li>*/}
                                           {/*            <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-4.sub-menu-4-2')}</Link></li>*/}
                                           {/*            <li><Link to="">{t('mainMenu.main-menu-4.sub-menu-4.sub-menu-4-3')}</Link></li>*/}
                                           {/*        </ul>*/}
                                           {/*    </li>*/}
                                           {/*</ul>*/}
                                       </li>
                                   }
                                   {
                                       menu[4]?.Hide === 1 &&
                                       <li onClick={() => setActive('coaching')} className={ active === 'coaching' ? "active":"" }><Link to={"/coaching"}><span data-hover={menu[4]?.Label[i18n.language]}>{menu[4]?.Label[i18n.language]}</span></Link></li>
                                   }
                                   {
                                       menu[5]?.Hide === 1 &&
                                       <li onClick={() => setActive('quiz')} className={ active === 'quiz' ? "active" : ""}><Link to={"/quiz"}><span data-hover={menu[5]?.Label[i18n.language]}>{menu[5]?.Label[i18n.language]}</span></Link></li>
                                   }
                                   {
                                       menu[5]?.Hide === 1 &&
                                       <li onClick={() => setActive('contact')} className={ active === 'contact' ? "active" : ""}><Link to={"/contact-us"}><span data-hover={menu[6]?.Label[i18n.language]}>{menu[6]?.Label[i18n.language]}</span></Link></li>
                                   }
                                   {
                                       (token !== null && (tokenExpire * 1000 < Date.now())) ? <li onClick={() => setActive('profile')} className={ active === 'profile' ? "active" : ""}><Link to={"/dashboard"}><span data-hover={t('mainMenu.main-menu-8')}>{t('mainMenu.main-menu-8')}</span></Link></li> : <></>
                                   }

                               </ul>
                               <div className="second_menu">
                                   <select className='language rounded-full' data-flag="true" onChange={onChangeLanguage}>
                                       <option value="ro" className="lang">RO</option>
                                       <option value="ru" className="lang">RU</option>
                                       <option value="en" className="lang">EN</option>
                                       {/*<option value="es" className="lang">ES</option>*/}
                                       {/*<option value="fr" className="lang">FR</option>*/}
                                       {/*<option value="gr" className="lang">GR</option>*/}
                                       <option value="ua" className="lang">UA</option>
                                       {/*<option value="ar" className="lang">AR</option>*/}
                                       {/*<option value="is" className="lang">IS</option>*/}
                                   </select>

                                   {/*<Link to={"/login"} className="getIn">{t('Header.header-link-1')}</Link>*/}
                                   <Link to={"/login"} className="getIn">{t('Header.btnGetIn')}</Link>
                                   {/*<Link to={"/creat-cv"} className="btn btn-reverse">{t('Header.header-link-2')}</Link>*/}
                                   {/*<Link to={"/creat-cv"} className="btn btn-reverse">Creare CV</Link>*/}
                               </div>
                           </div>

                           <div className="mobile-sub" style={{display: 'none', }}>
                               <a className="back-to-main"><FontAwesomeIcon className="caretLeft" icon={faCaretLeft} /> Back to the main
                                   menu</a><br />
                               <div className="list"></div>
                           </div>
                           <div className="mobile-sub-menu" style={{display: 'none', }}>
                               <a className="back-submenu"><FontAwesomeIcon className="caretLeft" icon={faCaretLeft} /> Back to the main
                                   menu</a><br />
                               <div className="list"></div>
                           </div>

                           <div className="mobile-sub-sub-menu" style={{display: 'none', }}>
                               <a className="back-sub-submenu"><FontAwesomeIcon className="caretLeft" icon={faCaretLeft} /> Back to the main
                                   menu</a><br />
                               <div className="list"></div>
                           </div>
                       </nav>
                       <a id="mobile-open-menu" className="new-mobile-open">
                                        <span className="menu mini-burger-link open">
                                            <span className="hamburger"></span>
                                        </span>
                       </a>
           </div>
           <div className="gradient-header"></div>
        </header>
    )
}

export default Header;
