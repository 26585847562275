import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCards, editCard, addCard, deleteCard} from "../../service/CardService";

export const cardGetCardActions = createAsyncThunk(
    'card/cardGetCardActions',
    async (params = {}) => {
        try {
            return await getCards(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const cardEditActions = createAsyncThunk(
    'card/cardEditActions',
    async({Id, data, token}, thunkAPI) => {
        try{
            return await editCard(Id, data, token).then(response => response.data.data);
        } catch (error) {
            return error.message;
        }
    }
)

export const cardCreateActions = createAsyncThunk(
    'card/cardCreateActions',
    async({data, token}) => {
        try{
            return await addCard(data, token).then(response => response.data.data).catch(error => error.response.data);
        } catch (error) {
            return error.message
        }
    }
)

export const cardDeleteActions = createAsyncThunk(
    'card/cardDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteCard(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
