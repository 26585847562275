import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Training = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const {courses} = useSelector((state) => state.course);

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const submitTraining = () => {
        if(value !== ''){
            navigate('/courses/'+value);
        }
    }

    return (
       <main className="training-page">
           <section className="main-training container flex flex-wrap">
               <div className="main-training__details">
                  <h1 className="title">Curs educație financiară și antreprenorială</h1>
                  <span className="description">Nou în domeniul antreprenoriatului? Începe acum să afle bazele antreprenoriatului care te pot ajuta pentru ați realiza visurile.</span>
                   <form>
                       <div className="selectCourse">
                           <select name="quizz" onChange={handleChange}>
                               <option value="">Selecteaza cursul cautat</option>
                               { courses.map(course => <option value={course.slug} key={course.id}>{course.title}</option>) }
                           </select>
                           <button className="start" onClick={() => submitTraining()}><FontAwesomeIcon icon={faPlay} />Start</button>
                       </div>
                   </form>
               </div>
               <div className="main-training__image">
                   <div className="image_block">
                       <img src={require("assets/images/shadow.png")} className="w-full h-full object-cover" alt="image block" />
                   </div>
                   <div className="image_block">
                       <img src={require("assets/images/courses.jpg")} className="w-full h-full object-cover" alt="image block" />
                   </div>
               </div>
           </section>

           <section className="interesting-course container">
               <h3 className="title-head">Cursuri ce te-ar putea interesa</h3>

               <div className="course-content">
                   <div className="item-category-course">
                       <div className="image">
                           <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                       </div>
                       <div className="content">
                           <p>Limbi strainie</p>
                           <div className="break"></div>
                           <p>38 anunturi</p>
                       </div>
                   </div>
                   <div className="item-category-course">
                       <div className="image">
                           <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                       </div>
                       <div className="content">
                           <p>IT & WebDesign</p>
                           <div className="break"></div>
                           <p>43 anunturi</p>
                       </div>
                   </div>
                   <div className="item-category-course">
                       <div className="image">
                           <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                       </div>
                       <div className="content">
                           <p>Economie si Marketing</p>
                           <div className="break"></div>
                           <p>383 anunturi</p>
                       </div>
                   </div>
                   <div className="item-category-course ">
                       <div className="image">
                           <img src={require('../../assets/images/1500-01.jpg')} alt="Limbi straine" className="w-full h-full object-cover"/>
                       </div>
                       <div className="content">
                           <p>Dezvoltare personala</p>
                           <div className="break"></div>
                           <p>79 anunturi</p>
                       </div>
                   </div>
               </div>
           </section>
       </main>
    );
}

export default Training;
