import {apiClient} from "./index";

export const getBanks = (params) => {
    return apiClient.get('/api/bank',{params}).then(response => response.data);
}

export const addBank = ({data, token}) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/bank',data);
}

export const editBank = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/bank/${Id}?_method=PUT`,data.data);
}

export const deleteBank = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/bank/${Id}`);
}
