import {apiClient} from "./index";

export const getAbout = (params) => {
    return apiClient.get('/api/about',{params}).then(response => response.data);
}

export const addAbout = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/about',data);
}

export const editAbout = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/about/${Id}?_method=PUT`,data);
}

export const deleteAbout = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/about/${Id}`);
}
