export function convertFile2Base64(file) {
    if (!file) return Promise.reject();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = error => reject(error);
    });
}
