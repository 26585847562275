import React, {useState} from 'react';
import Multiselect from 'multiselect-react-dropdown';
import Dropdown from "react-dropdown";


const RegisterEmployer = () => {
    const [organizationName, setOrganizationName] = useState();
    const [radius, setRadius] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [telephone, setTelephone] = useState();
    const [address, setAddress] = useState();
    const [locality, setLocality] = useState();
    const typeOfActivity = [
        {
            id: 0,
            name: 'Activity 1'
        },
        {
            id: 1,
            name: 'Activity 2'
        },
        {
            id: 2,
            name: 'Activity 3'
        },
        {
            id: 3,
            name: 'Activity 4'
        },
    ];

    const skills = [
        {
            id: 0,
            name: 'Aptitudini'
        },
        {
            id: 1,
            name: 'Aptitudini 2'
        },
        {
            id: 2,
            name: 'Aptitudini 3'
        },
        {
            id: 3,
            name: 'Aptitudini 4'
        },
    ];

    const onSelectActivity = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const onSelectSkills = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <main className="register-employer">
           <div className="container">
               <div className="mt-5 md:col-span-2 md:mt-0">
                   <form>
                       <div className="overflow-hidden shadow sm:rounded-md">
                           <div className="bg-white px-4 py-5 sm:p-6">
                               <div className="grid grid-cols-6 gap-6">
                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="organizationName" className="block text-sm font-medium text-gray-700">
                                           Numele Organizatiei <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                       </label>
                                       <input
                                           id="organizationName"
                                           name="organizationName"
                                           autoComplete="organizationName"
                                           type="text"
                                           value={organizationName}
                                           onChange={e => setOrganizationName(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                           Adresa de e-mail <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                       </label>
                                       <input
                                           id="email"
                                           name="email"
                                           autoComplete="email"
                                           type="email"
                                           value={email}
                                           onChange={e => setEmail(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                           Adresa
                                       </label>
                                       <input
                                           id="address"
                                           name="address"
                                           autoComplete="address"
                                           type="address"
                                           value={address}
                                           onChange={e=> setAddress(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">
                                           Persoana de contact <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                       </label>
                                       <input
                                           id="telephone"
                                           name="telephone"
                                           autoComplete="telephone"
                                           type="text"
                                           value={telephone}
                                           onChange={e=> setTelephone(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">
                                           Persoana alternativă de contact
                                       </label>
                                       <input
                                           id="mobile"
                                           name="mobile"
                                           autoComplete="mobile"
                                           value={mobile}
                                           onChange={e=> setMobile(e.target.value)}
                                           type="text"
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="activity" className="block text-sm font-medium text-gray-700">
                                           Tipul de activitate
                                       </label>
                                       <Multiselect
                                           id="activity"
                                           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                           options={typeOfActivity} // Options to display in the dropdown
                                           // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                           onSelect={onSelectActivity} // Function will trigger on select event
                                           displayValue="name" // Property name to display in the dropdown options
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="skills" className="block text-sm font-medium text-gray-700">
                                           Schilurile solicitate în activitate
                                       </label>
                                       <Multiselect
                                           id="skills"
                                           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                           options={skills} // Options to display in the dropdown
                                           // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                           onSelect={onSelectSkills} // Function will trigger on select event
                                           displayValue="name" // Property name to display in the dropdown options
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-6">
                                       <div className="grid grid-cols-6 gap-6">
                                           <div className="col-span-6 sm:col-span-3">
                                               <label className="inline-flex relative items-center cursor-pointer">
                                                   <input type="checkbox" value="" className="sr-only peer" />
                                                   <div
                                                       className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                   <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Retea</span>
                                               </label>
                                           </div>

                                           <div className="col-span-6 sm:col-span-3">
                                               <label htmlFor="locality" className="block text-sm font-medium text-gray-700">
                                                   Localitatea (raionul sau oraşul, centrala în caz de rețea)
                                               </label>
                                               <input
                                                   id="locality"
                                                   name="locality"
                                                   autoComplete="locality"
                                                   value={locality}
                                                   onChange={e=> setLocality(e.target.value)}
                                                   type="text"
                                                   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                               />
                                           </div>
                                       </div>
                                   </div>

                                   <div className="col-span-6 sm:col-span-6">
                                       <div className="grid grid-cols-6 gap-6">
                                           <div className="col-span-6 sm:col-span-3">
                                               <label className="inline-flex relative items-center cursor-pointer">
                                                   <input type="checkbox" value="" className="sr-only peer" />
                                                   <div
                                                       className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                   <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Oferirea de transport</span>
                                               </label>
                                           </div>

                                           <div className="col-span-6 sm:col-span-3">
                                               <label htmlFor="radius" className="block text-sm font-medium text-gray-700">
                                                   Raza de proximitate (pentru notificări) transport dacă DA anterior
                                               </label>
                                               <select
                                                   id="radius"
                                                   name="radius"
                                                   autoComplete="radius"
                                                   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                               >
                                                   <option>0-5km</option>
                                                   <option>5 - 10 km</option>
                                                   <option>0 - 25 km</option>
                                                   <option>25 - 50 km</option>
                                                   <option>50 - 150 km</option>
                                               </select>
                                           </div>
                                       </div>
                                   </div>

                                   <div className="col-span-6 sm:col-span6">
                                       <div>
                                           <label className="block text-sm font-medium text-gray-700">Logo</label>
                                           <div className="mt-1 flex items-center">
                      <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                                               <button
                                                   type="button"
                                                   className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                               >
                                                   Change
                                               </button>
                                           </div>
                                       </div>

                                       <div>
                                           <label className="block text-sm font-medium text-gray-700">Cover logo</label>
                                           <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                               <div className="space-y-1 text-center">
                                                   <svg
                                                       className="mx-auto h-12 w-12 text-gray-400"
                                                       stroke="currentColor"
                                                       fill="none"
                                                       viewBox="0 0 48 48"
                                                       aria-hidden="true"
                                                   >
                                                       <path
                                                           d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                           strokeWidth={2}
                                                           strokeLinecap="round"
                                                           strokeLinejoin="round"
                                                       />
                                                   </svg>
                                                   <div className="flex text-sm text-gray-600">
                                                       <label
                                                           htmlFor="file-upload"
                                                           className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                       >
                                                           <span>Upload a file</span>
                                                           <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                       </label>
                                                       <p className="pl-1">or drag and drop</p>
                                                   </div>
                                                   <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                               </div>
                                           </div>
                                       </div>

                                       <div>
                                           <label className="block text-sm font-medium text-gray-700">Incarca documente confirmative</label>
                                           <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                               <div className="space-y-1 text-center">
                                                   <svg
                                                       className="mx-auto h-12 w-12 text-gray-400"
                                                       stroke="currentColor"
                                                       fill="none"
                                                       viewBox="0 0 48 48"
                                                       aria-hidden="true"
                                                   >
                                                       <path
                                                           d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                           strokeWidth={2}
                                                           strokeLinecap="round"
                                                           strokeLinejoin="round"
                                                       />
                                                   </svg>
                                                   <div className="flex text-sm text-gray-600">
                                                       <label
                                                           htmlFor="file-upload"
                                                           className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                       >
                                                           <span>Upload a file</span>
                                                           <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                       </label>
                                                       <p className="pl-1">or drag and drop</p>
                                                   </div>
                                                   <p className="text-xs text-gray-500">PDF, JPEG, JPG, DOC, DOCX, XLS</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>

                                   <div className="col-span-6 sm:col-span6">
                                       <div>
                                           <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                               Descriere
                                           </label>
                                           <div className="mt-1">
                                              <textarea
                                                  id="about"
                                                  name="about"
                                                  rows={5}
                                                  style={{paddingLeft: '10px'}}
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                  placeholder="Descriere"
                                                  defaultValue={''}
                                              />
                                           </div>
                                       </div>

                                       <div className="flex items-start agree">
                                           <div className="flex h-5 items-center">
                                               <input
                                                   id="comments"
                                                   name="comments"
                                                   type="checkbox"
                                                   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                               />
                                           </div>
                                           <div className="ml-3 text-sm">
                                               <label htmlFor="comments" className="font-medium text-gray-700">
                                                   <p className="text-gray-500">Am făcut cunostința și sunt deacord cu termenii și condițiile pentru prelucrarea datelor personale și cu regulile site-ului</p>
                                               </label>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                               <button
                                   type="submit"
                                   onClick={handleSubmit}
                                   className="btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                               >
                                   Register
                               </button>
                           </div>
                       </div>
                   </form>
               </div>
           </div>
        </main>
    )
}

export default RegisterEmployer;
