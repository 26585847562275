import {createAsyncThunk} from "@reduxjs/toolkit";
import {getHowWork, editHowWork} from "../../service/HowWorkService";

export const howGetWorkActions = createAsyncThunk(
    'how/howGetWorkActions',
    async (params = {}) => {
        try {
            return await getHowWork(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const howEditWorkActions = createAsyncThunk(
    'how/howEditWorkActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editHowWork(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)
