import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

import Loader from "components/Loader/Loader";
import {getInitiativeCompanyActions} from "../../../../../redux/actions/initiativeCompanyApi";

const InitiativeCompany = () => {
    const dispatch = useDispatch();
    const {loading, error, initiative} = useSelector(state => state.initiative);

    useEffect(() => {
        dispatch(getInitiativeCompanyActions());
    },[dispatch])

    const basicColumArticlesList = [
        {
            header: 'Title',
            accessorKey: 'Title',
            enableSorting: false,
            cell: (info) => <div>{JSON.stringify(info.getValue())}</div>
        },
        {
            header: 'Description',
            accessorKey: 'Description',
            enableSorting: false,
            cell: (info) => <div>{JSON.stringify(info.getValue())}</div>
        },
        {
            header: 'Logo',
            accessorKey: 'Image',
            enableSorting: false,
            cell: (info) => <div className="table-image"><img src={info.getValue()} alt={info.getValue()}/></div>
        },
        {
            header: '',
            accessorKey: 'Id',
            enableSorting: false,
            cell: (info) => <div className="table-download"><Link to={`/dashboard/initiative-company/${info.getValue()}`}><FontAwesomeIcon icon={faPencil} /></Link></div>
        }
    ];

    return (
        <>
            {/*{error && <p>Something went wrong</p>}*/}
            { loading && <Loader /> }
            {!loading &&
                <section className="table-articlesList">
                    <BasicTable basic_column={basicColumArticlesList} enableSorting={true} data={initiative} className="basic-table-JobsList clientColor2"/>
                </section>
            }
        </>
    );
}

export default InitiativeCompany;
