import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { useFormInputValidation } from "react-form-input-validation";
import './ContactForm.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const ContactForm = () => {
    const [errorsForm, setErrorsForm] = useState();

    const [fields, errors, form] = useFormInputValidation({
        email: "",
        telephone: "",
        name: "",
        message: ""

    }, {
        email: "required|email",
        telephone: "required",
        name: "required",
        message: "required"
    });

    const handleSubmit = async (event) => {
         const isValid = await form.validate(event);

        if (isValid) {
            // if(fields.email === 'mihail.stepan@gmail.com' && fields.password === 'Qwerty1!'){
            //     return navigate('/profile');
            // } else {
            //     setErrorsForm('Please enter a valid email address or password.')
            // }
        }
    }

    return (
                <form noValidate autoComplete="off" className="contactForm">
                    <div className="form-field">
                        <input
                            type="text"
                            name="name"
                            value={fields.name}
                            onBlur={form.handleBlurEvent}
                            onChange={form.handleChangeEvent}
                            data-attribute-name="Name"
                            placeholder="Nume"
                            className="block w-full"
                        />

                        {errors.name ? <p className="filled_error_help">{errors.name}</p> : "" }
                    </div>
                    <div className="form-field">
                        <input
                            type="text"
                            name="telephone"
                            value={fields.telephone}
                            onBlur={form.handleBlurEvent}
                            onChange={form.handleChangeEvent}
                            data-attribute-name="Telephone"
                            placeholder="Telefon"
                            className="block w-full"
                        />
                        {errors.telephone ? <p className="filled_error_help">{errors.telephone}</p> : "" }
                    </div>
                    <div className="form-field">
                        <input
                            type="email"
                            name="email"
                            value={fields.email}
                            onBlur={form.handleBlurEvent}
                            onChange={form.handleChangeEvent}
                            data-attribute-name="Email"
                            placeholder="E-mail"
                            className="block w-full"
                        />


                        {errors.email ? <p className="filled_error_help">{errors.email}</p> : "" }
                    </div>
                    <div className="form-field">
                        <textarea
                          id="message"
                          name="message"
                          rows={5}
                          onBlur={form.handleBlurEvent}
                          onChange={form.handleChangeEvent}
                          className="block w-full"
                          placeholder="Message"
                        />

                        {errors.message ? <p className="filled_error_help">{errors.message}</p> : "" }
                    </div>
                    {errorsForm ? <p className="filled_error_help">{errorsForm}</p> : "" }

                    <div className="form-field text-center">
                        <button onClick={handleSubmit} className="btn-form">Trimite</button>
                    </div>
                </form>
    )
}

export default ContactForm;
