import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import EducationItem from "./EducationItem";
import EducationAdd from "./EducationAdd";
import './education.scss';

const EducationComponent = (props) => {
    const education = useSelector(state => state.education.education);
    const [editButton, setEditButton] = useState(false);
    const [addButton, setAddButton] = useState(false);

    const handleChange = (value) => {
        setEditButton(value);
    }

    const handleChangeAdd = (value) => {
        setAddButton(value);
    }

    return (
        <section className="education-section">
            <div className="education-section-head">
                <span className="title">Education</span>
                <div className="actions">
                    <span className="education-action-button" onClick={() => setAddButton(true)}><FontAwesomeIcon icon={faPlus} /></span>
                    <span className="education-action-button" onClick={() => setEditButton(true)}><FontAwesomeIcon icon={faPencil} /></span>
                </div>
            </div>
            <div className="my-education-list">
                {education &&  education.map(item => <EducationItem key={item.Id} education={item} editButton={editButton} editButtonItem={handleChange} />)}
                {addButton ? <div className="education-item"><EducationAdd addButtonItem={handleChangeAdd} /></div> : <></>}
            </div>
        </section>
    )
}

export default EducationComponent;
