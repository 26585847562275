import React from "react";
import Slider from "react-slick";
import './CvTestimonialSlider.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight, faChevronLeft, faXmark} from "@fortawesome/free-solid-svg-icons";

const CvTestimonialSlider = (props) => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 2,
            variableWidth: true,
            autoplay: true,
            className: "slider variable-width",
            nextArrow: <FontAwesomeIcon icon={faChevronRight} className="iconSlide"/>,
            prevArrow: <FontAwesomeIcon icon={faChevronLeft}  className="iconSlide"/>,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                        centerMode: false,
                    }
                }
            ]
        };

        const cvTestimonials = [
            {
                id: 0,
                comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            },
            {
                id: 1,
                comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            },
            {
                id: 2,
                comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            },
            {
                id: 3,
                comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            },
            {
                id: 4,
                comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            }
        ]
        return (
                <Slider {...settings}>
                    { cvTestimonials.map(testimonial => (
                        <div className="cv-testimonial-item" key={testimonial.id} style={{width: 392}}>
                            <div className="cv-content">
                                <div className="comment">{testimonial.comment}</div>
                                <div className="close"><FontAwesomeIcon icon={faXmark} /></div>
                            </div>
                            <div className="cv-testimonial-get-started">Get Started</div>
                        </div>
                    ))}
                </Slider>
        );
}

export default CvTestimonialSlider;
