import React, { useState } from "react";
import axios from "axios";

const BASE_URL = "https://nominatim.openstreetmap.org/search?";
const params = {
    q: "",
    format: "json",
    addressdetails: "addressdetails",
};

const Search = (props) => {
    const { selectPosition, setSelectPosition } = props;
    const [searchText, setSearchText] = useState("");
    const [listPlace, setListPlace] = useState([]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    <input
                        type="text"
                        style={{ width: "100%" }}
                        value={searchText}
                        onChange={(event) => {
                            setSearchText(event.target.value);
                        }}
                    />
                </div>
                <div
                    style={{ display: "flex", alignItems: "center", padding: "0px 20px" }}
                >
                    <button
                        onClick={() => {
                            // Search
                            const params = {
                                q: searchText,
                                format: "json",
                                addressdetails: 1,
                                polygon_geojson: 0,
                            };
                            const queryString = new URLSearchParams(params).toString();
                            const requestOptions = {
                                method: "GET",
                                redirect: "follow",
                            };
                            axios.get(`${BASE_URL}${queryString}`, requestOptions)
                                .then((result) => {
                                    setListPlace(result.data);
                                })
                                .catch((err) => console.log("err: ", err));
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>
            <div>
                <ul>
                    {listPlace.map((item) => {
                        return (
                            <li key={item?.place_id}>
                                <a onClick={() => {
                                        setSelectPosition(item);
                                    }}
                                >
                                        <img
                                            src="./placeholder.png"
                                            alt="Placeholder"
                                            style={{ width: 38, height: 38 }}
                                        />
                                    { item?.display_name}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Search;
