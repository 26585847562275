import {createAsyncThunk} from "@reduxjs/toolkit";

import authService from "../../service/AuthService";
import {addAllCareer} from "../reducer/careerReducer";
import {addAllEducation} from "../reducer/educationReducer";

export const userInfoActions = createAsyncThunk(
    'user/userInfoActions',
    async (params, thunkAPI) => {
        try {
            const {dispatch} = thunkAPI;
            return await authService.getAuth(params).then(response => {
                if (response?.status === 200) {
                    dispatch(addAllCareer(response.data.data[0]?.Experiences));
                    dispatch(addAllEducation(response.data.data[0]?.Educations));
                    return response.data.data[0];
                } else {
                    return response.data?.error_message;
                }
            }).catch(error => error.response.data);
        } catch (error) {
            return error.message;
        }
    }
);

export const userUpdateInfoActions = createAsyncThunk(
    'user/userUpdateInfoActions',
    async ({data, token}, thunkAPI) => {
        try {
            const {dispatch} = thunkAPI;
            return await authService.updateUser(data, token).then(response => {
                if (response?.status === 200) {
                    dispatch(userInfoActions(token));
                    return true;
                } else {
                    return response.data?.error_message;
                }
            }).catch(error => error.response.data);
        } catch (error) {
            return error.message;
        }
    }
);
