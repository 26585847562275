import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {sortArrayByField} from "../../utils/other.util";
import {friendlyURL} from "../../utils/string.util";
import {faFacebookF, faInstagram, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Parser} from "html-to-react";
import {useDispatch, useSelector} from "react-redux";
import {settingGetSettingActions} from "../../redux/actions/settingApi";

const Footer = (props) => {
    const {t} = useTranslation();
    const {category} = props;
    const dispatch = useDispatch();
    const [categoryBlog, setCategoryBlog] = useState('');
    const {social} = useSelector(state => state.socialMedia);
    const {setting} = useSelector(state => state.setting);

    useEffect(() => {
        dispatch(settingGetSettingActions());
        const blogCat = category.filter(cat => cat.Type === 4);
       setCategoryBlog(blogCat);
    },[]);

    return (
         <section className="footer">
             {categoryBlog.length > 0 &&
                <div className="footer-menu-section">
                    {sortArrayByField(categoryBlog.slice(0,4),'Order').map(item =>
                         <div className="footer-menu" key={item.Id}>
                             <ul>
                                 <li><Link to={`blog?categoryId=${item.Id}`}>{item.Title}</Link></li>
                                 {item.Blogs.length > 0 && item.Blogs.slice(0,4).map(blog =>
                                     <li key={blog.Id}><Link to={`blog/${friendlyURL(blog.Title)}/${blog.Id}`}>{blog.Title}</Link></li>
                                 )}
                             </ul>
                         </div>
                    )}
             </div>
             }

             <div className="container footer-socials">
                 <div className="flex flex-wrap justify-center align-items-center section-address">
                     <a href=""><img src={require("assets/images/location.png")} alt={setting?.Address}/> {setting?.Address}</a>
                 </div>

                 <div className="flex flex-wrap justify-center align-items-center section-phone">
                     <a href={"tel:" + setting?.Phone}><img src={require("assets/images/phone.png")} alt={setting?.Phone}/> Detalii: {setting?.Phone}</a>
                 </div>

                 <div className="flex flex-wrap justify-center align-items-center section-mail">
                     <a href={"mailto:" + setting?.Email}><img src={require("assets/images/mail.png")} alt={setting?.Email}/> {setting?.Email}</a>
                 </div>

                 {
                     social &&
                         <div className="social-icons flex flex-wrap justify-center align-items-center">

                             {social?.LinkedInUrl && social?.LinkedInUrl !== null &&
                                 <div className="icon flex justify-center align-items-center">
                                     <a href={social.LinkedInUrl} target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                 </div>
                             }

                             {social?.FacebookUrl && social?.FacebookUrl !== null &&
                                 <div className="icon flex justify-center align-items-center">
                                     <a href={social.FacebookUrl} target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                                 </div>
                             }

                             {social?.InstagramUrl && social?.InstagramUrl !== null &&
                                 <div className="icon flex justify-center align-items-center">
                                     <a href={social.InstagramUrl} target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                                 </div>
                             }

                             {social?.YoutubeUrl && social?.YoutubeUrl !== null &&
                                 <div className="icon flex justify-center align-items-center">
                                     <a href={social.YoutubeUrl} target="_blank"><FontAwesomeIcon icon={faYoutube} /></a>
                                 </div>
                             }
                         </div>
                 }
             </div>

             <div className="container footer-created">
                 <span className="created">{t('Footer.createdBy')}</span>
                 <div className="footer-created-content">
                     <div className="footer-image">
                         <img src={setting?.Photo} alt="ixobit"/>
                     </div>
                     <div className="footer-image faclia">
                         <img className="mx-auto" src={setting?.PhotoSecond} alt="faclia"/>
                     </div>
                     <div className="footer-image">
                         <img src={setting?.PhotoThird} className="forta_vitala_logo" alt="forta vitala"/>
                     </div>
                     <div className="flex flex-row">
                         <p className="text-wrap block text-right">
                             {Parser().parse(t('Footer.Description'))}
                         </p>
                         <img src={setting?.PhotoFourth} className="object-contain" alt="undp" />
                     </div>
                 </div>
             </div>

             <div className="container footer-copy">
                 <div>
                     {Parser().parse(setting?.Copyright)}
                 </div>
                 <div>
                     <span>{t('Footer.copyDescription')}</span>
                 </div>
             </div>
         </section>
    )
}

export default Footer;
