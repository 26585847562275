import {apiClient} from "./index";

export const getSetting = (params) => {
    return apiClient.get('/api/settings',{params}).then(response => response.data);
}

export const addSetting = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/settings',data);
}

export const editSetting = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/settings/${Id}?_method=PUT`,data);
}

export const deleteSetting = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/settings/${Id}`);
}
