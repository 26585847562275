import React, {useState, useEffect, useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import "./dropzone.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const Dropzone = ({ image = null, args = {} , onFileSelect}) => {
  const [file, setFile] = useState(image);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      ...args,
    },
    onDrop: acceptedFiles => {
      const selectedFile = acceptedFiles[0];
      const previewURL = URL.createObjectURL(selectedFile);
      setFile({ file: selectedFile, preview: previewURL });

      const reader = new FileReader();
      reader.onload = () => {
        const transformedFile = reader.result; // The Base64 encoded file
        onFileSelect(transformedFile);
      };
      reader.readAsDataURL(selectedFile);
    },
  });

  const handleRemove = () => {
    setFile(null);
  }

  return (
      <>
      {!file &&
          <section className="dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag 'n drop some files here, or click to select files</p>
            </div>
      </section> }
        {file && (
            <aside className="thumbContainer">
              <div className="thumb" key={file.name}>
                <div className="thumbInner">
                  <img
                      src={file.preview ?? file}
                      className="img"
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                  />
                </div>
                <span className="removeImage" onClick={handleRemove}><FontAwesomeIcon icon={faXmark} /></span>
              </div>
            </aside>
        )}
      </>
  );
};

export default Dropzone;
