import React, {useRef} from 'react';
import Moment from 'react-moment';
import { useReactToPrint } from 'react-to-print';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faPrint, faTrophy} from "@fortawesome/free-solid-svg-icons";

const CertificateItem = (props) => {
    const {certificate} = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: certificate?.Title
    });

    return (
        <div className="certificate-item" ref={componentRef}>
            <div className="certificate-image">
                <img src={certificate.Image !== null ? certificate?.Image : require('@images/certificate-by-default.png')} alt={certificate?.Title}/>
            </div>
            <div className="certificate-content">
                <span className="title-certificate">{certificate?.Title}</span>
                <span className="certificate-data">{certificate?.CreatedAt}</span>

                <div className="certificate-actions">
                    <span className="filter">
                        <span>Quantity</span>
                        <select name="" id="">
                            <option>20</option>
                            <option>25</option>
                        </select>
                    </span>
                    <span className="print" onClick={() => handlePrint()}><FontAwesomeIcon icon={faPrint} /> Print</span>
                    <span className="certificate-view"><span>View details</span><FontAwesomeIcon icon={faAngleRight} /></span>

                    <div className="break"></div>
                </div>
                <div className="features">
                    { certificate.Tags && certificate.Tags.length > 0 && certificate?.Tags.map((feature, value) => <span key={value}>{feature}</span>)}
                </div>
                <div className="trophy">
                    <FontAwesomeIcon icon={faTrophy} />
                    <div className="trophy-content">
                        <span className="title-trophy">{certificate?.Title}</span>
                        <span className="description-trophy">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, laudantium quia! Accusantium ad corporis, deserunt dolorem doloribus excepturi, laboriosam modi nostrum, numquam odio officiis praesentium qui quidem repudiandae soluta voluptate.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CertificateItem;
