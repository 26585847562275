import {createSlice} from "@reduxjs/toolkit";
import {opportunityCreateActions, opportunityEditActions, opportunityGetOpportunitiesActions, opportunityDeleteActions} from "../actions/opportunityApi";

const opportunityReducer = createSlice({
    name: 'opportunity',
    initialState: {
        opportunity: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(opportunityGetOpportunitiesActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(opportunityGetOpportunitiesActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.opportunity = payload
                }
            })

            .addCase(opportunityGetOpportunitiesActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(opportunityEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(opportunityEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.loading = false;
                        state.error = payload.message;
                    } else {
                        state.opportunity.map(item => {
                            if(item.Id === payload.data.Id) {
                                item.Title = payload.data.Title;
                                item.Status = payload.data.Status;
                                item.AltImage = payload.data.AltImage;
                                item.Image = payload.data.Image;
                                item.MetaTitle = payload.data.MetaTitle;
                                item.MetaDescription = payload.data.MetaDescription;
                                item.Description = payload.data.Description
                            }
                        });
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(opportunityEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(opportunityCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(opportunityCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.opportunity = [...state.opportunity, {...payload}]
                }
            })

            .addCase(opportunityCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(opportunityDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(opportunityDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(opportunityDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })

    }
});

export default opportunityReducer.reducer;
