import {createSlice} from '@reduxjs/toolkit';
import {userInfoActions, userUpdateInfoActions} from "../actions/userApi";

const authReducer = createSlice({
    name: 'user',
    initialState: {
        user: [],
        reviews: [],
        company: [],
        accountBank: [],
        card: [],
        loading: false,
        error: null,
    },
    reducers: {
      updateCompany: (state, {payload}) => {
          state.company = payload
     }
    },

    extraReducers: (builder) => {
        builder
            .addCase(userInfoActions.pending, (state, action) => {
                state.loading = true;
                state.error = null
            })

            .addCase(userInfoActions.fulfilled, (state, {payload}) => {
               if(payload.status === false) {
                   localStorage.removeItem('token');
                   localStorage.removeItem('expireToken');
                   localStorage.removeItem('refreshToken');
                   state.loading = false;
                   state.user = [];
                   state.reviews = [];
                   state.company = [];
                   state.accountBank = [];
                   state.card = [];
                   state.error = payload.message;
               } else {
                   state.loading = false;
                   state.user = payload;
                   state.reviews = payload?.Reviews;
                   state.company = payload?.Company;
                   state.accountBank = payload?.AccountBank;
                   state.card = payload?.Card;
                   state.error = ''
               }
            })

            .addCase(userInfoActions.rejected, (state, action) => {
                state.loading = false;
                state.error = ''
            })

            .addCase(userUpdateInfoActions.pending, (state, action) => {
                state.loading = true;
                state.error = null
            })

            .addCase(userUpdateInfoActions.fulfilled, (state, {payload}) => {
                if(payload.success === false) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('expireToken');
                    localStorage.removeItem('refreshToken');
                    state.loading = false;
                    state.user = [];
                    state.reviews = [];
                    state.company = [];
                    state.accountBank = [];
                    state.bank = [];
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = ''
                }
            })

            .addCase(userUpdateInfoActions.rejected, (state, action) => {
                state.loading = false;
                state.error = ''
            })
    }
})

export default authReducer.reducer;
export const {updateCompany} = authReducer.actions
