import React, {useState} from 'react';
import Multiselect from "multiselect-react-dropdown";

const AddVacancy = () => {
    const [fullName, setFullName] = useState();
    const [email, setEmail] = useState();
    const [telephone, setTelephone] = useState();
    const [location, setLocation] = useState();
    const [conditionAdditional, setConditionAdditional] = useState();
    const [functions, setFunctions] = useState();
    const categoryJob = [
        {
            id: 0,
            name: 'Tîmplar'
        },
        {
            id: 1,
            name: 'Lăcătuș'
        },
        {
            id: 2,
            name: 'Educatoare'
        },
    ];

    const ability = [
        {
            id: 0,
            name: 'Abilitatea 1'
        },
        {
            id: 1,
            name: 'Abilitatea 2'
        },
        {
            id: 2,
            name: 'Abilitatea 3'
        },
    ];

    const socialPackage = [
        {
            id: 0,
            name: 'Social Package 1'
        },
        {
            id: 1,
            name: 'Social Package 2'
        },
        {
            id: 2,
            name: 'Social Package 3'
        },
    ];

    const Regions = [
        {
            value: 0,
            label: 'Chisinau'
        },
        {
            value: 1,
            label: 'Hancesti'
        },
        {
            value: 2,
            label: 'Orhei'
        },
        {
            value: 3,
            label: 'Cahul'
        },
    ];

    const onSelectCategoryJob = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const onSelectTypeOfAbility = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const onSelectTypeOfSocialPackage = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <main className="add-vacancy">
            <div className="container">
                <form>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="categoryJob"
                                               className="block text-sm font-medium text-gray-700">
                                            Categoria ofertei de muncă <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <Multiselect
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            id="categoryJob"
                                            options={categoryJob} // Options to display in the dropdown
                                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                            onSelect={onSelectCategoryJob} // Function will trigger on select event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="locality" className="block text-sm font-medium text-gray-700">
                                            Localitatea <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <select name="locality" id="locality"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                            <option>Orice locatie</option>
                                            <option>Chisinau</option>
                                            <option>Hancesti</option>
                                            <option>Orhei</option>
                                            <option>Ocnita</option>
                                            <option>Cahul</option>
                                        </select>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="ability" className="block text-sm font-medium text-gray-700">
                                            Abilități post
                                        </label>
                                        <Multiselect
                                            id="ability"
                                            name="ability"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            options={ability} // Options to display in the dropdown
                                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                            onSelect={onSelectTypeOfAbility} // Function will trigger on select event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="educationLevel"
                                               className="block text-sm font-medium text-gray-700">
                                            Nivelul de studii
                                        </label>
                                        <select
                                            id="educationLevel"
                                            name="educationLevel"
                                            autoComplete="educationLevel"
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option>Orice</option>
                                            <option>9 clase</option>
                                            <option>12 clase</option>
                                            <option>Școală profesională</option>
                                            <option>Colegiu</option>
                                            <option>Universitate</option>
                                            <option>Cursuri</option>
                                        </select>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="conditionAdditional"
                                               className="block text-sm font-medium text-gray-700">
                                            Condiții adiționale/specifice de muncă
                                        </label>
                                        <input
                                            id="conditionAdditional"
                                            name="conditionAdditional"
                                            autoComplete="conditionAdditional"
                                            type="text"
                                            value={conditionAdditional}
                                            onChange={e => setConditionAdditional(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="socialPackage"
                                               className="block text-sm font-medium text-gray-700">
                                            Pachetul social
                                        </label>
                                        <Multiselect
                                            id="socialPackage"
                                            name="socialPackage"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            options={socialPackage} // Options to display in the dropdown
                                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                            onSelect={onSelectTypeOfSocialPackage} // Function will trigger on select event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span6">
                                        <div>
                                            <label htmlFor="requirements"
                                                   className="block text-sm font-medium text-gray-700">
                                                Cerințele ofertei de muncă <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                            </label>
                                            <div className="mt-1">
                                              <textarea
                                                  id="requirements"
                                                  name="requirements"
                                                  rows={5}
                                                  style={{paddingLeft: '10px'}}
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                  placeholder="Cerințele ofertei de muncă"
                                                  defaultValue={''}
                                              />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="motivation"
                                                   className="block text-sm font-medium text-gray-700">
                                                Motivația aplicării
                                            </label>
                                            <div className="mt-1">
                                              <textarea
                                                  id="motivation"
                                                  name="motivation"
                                                  rows={5}
                                                  style={{paddingLeft: '10px'}}
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                  placeholder="Motivația aplicării"
                                                  defaultValue={''}
                                              />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-4 sm:col-span-1">
                                        <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                                            Varsta
                                        </label>
                                        <select name="age" id="age"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                            <option>Orice varsta</option>
                                            <option>16 - 18 ani</option>
                                            <option>20 - 25 ani</option>
                                            <option>30 - 55 ani</option>
                                        </select>
                                    </div>

                                    <div className="col-span-4 sm:col-span-1">
                                        <label htmlFor="language" className="block text-sm font-medium text-gray-700">
                                            Limba
                                        </label>
                                        <select name="language" id="language"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                            <option>Orice Limba</option>
                                            <option>Romana</option>
                                            <option>Rusa</option>
                                            <option>Engleza</option>
                                        </select>
                                    </div>

                                    <div className="col-span-4 sm:col-span-1">
                                        <label htmlFor="gen" className="block text-sm font-medium text-gray-700">
                                            Gen
                                        </label>
                                        <select name="gen" id="gen"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                            <option>Orice Gen</option>
                                            <option>Masculin</option>
                                            <option>Feminin</option>
                                        </select>
                                    </div>

                                    <div className="col-span-4 sm:col-span-1">
                                        <label htmlFor="posesion" className="block text-sm font-medium text-gray-700">
                                            Posesii
                                        </label>
                                        <select name="posesion" id="posesion"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                            <option>Orice Posesie</option>
                                            <option>Posesie</option>
                                            <option>posesion</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <h3>Date de contact (direct pe Ofertă)</h3>
                                <div className="grid grid-cols-6 gap-6">

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                                            Numele Prenume <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="fullName"
                                            name="fullName"
                                            autoComplete="fullName"
                                            type="text"
                                            value={fullName}
                                            onChange={e => setFullName(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            autoComplete="email"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                            Locatie <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="location"
                                            name="location"
                                            autoComplete="location"
                                            type="location"
                                            value={location}
                                            onChange={e => setLocation(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="function" className="block text-sm font-medium text-gray-700">
                                            Functie <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="function"
                                            name="function"
                                            autoComplete="function"
                                            type="text"
                                            value={functions}
                                            onChange={e => setFunctions(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">
                                            Numar de telefon <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="telephone"
                                            name="telephone"
                                            autoComplete="telephone"
                                            type="text"
                                            value={telephone}
                                            onChange={e => setTelephone(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn inline-flex justify-center rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm"
                            >
                                Publica Anuntul
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default AddVacancy;
