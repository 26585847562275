import React from 'react';
import BasicTable from "components/TableComponent/BasicTable";

const SuccessfulHires = () => {
    const data = [
        {
            id: 1,
            name: 'Radu',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 1,
        },
        {
            id: 2,
            name: 'Nicolae',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 0,
        },
        {
            id: 3,
            name: 'Radu',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 1,
        },
        {
            id: 4,
            name: 'Nicolae',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 0,
        },
        {
            id: 4,
            name: 'Radu',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 1,
        },
        {
            id: 5,
            name: 'Nicolae',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 0,
        },
        {
            id: 6,
            name: 'Radu',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 1,
        },
        {
            id: 7,
            name: 'Nicolae',
            surname: 'Rata',
            photo: 'career.jpeg',
            city: 'Moldova',
            data: '12.12.2023',
            dataFinished: '12.12.2024',
            job: 'Developer',
            company: 'Ixobit',
            employee: 0,
        }
    ]

    const basic_colum = [
        {
            id: 'select',
            header: ({ table }) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({ row }) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            header: 'Photo',
            accessorKey: 'photo',
            enableSorting: false,
            cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)} alt={info.getValue()}/></div>
        },
        {
            header: 'Nume',
            accessorKey: 'name',
        },
        {
            header: 'Prenume',
            accessorKey: 'surname'
        },
        {
            header: 'Tara',
            accessorKey: 'city'
        },
        {
            header: 'Data Inrolare',
            enableSorting: false,
            accessorKey: 'data',
        },
        {
            header: 'Data Finisher',
            enableSorting: false,
            accessorKey: 'dataFinished',
        },
        {
            header: 'Profesie',
            accessorKey: 'job'
        },
        {
            header: 'Companie',
            accessorKey: 'company',
        },
        {
            header: 'Angajat',
            id: 'employee',
            accessorFn: row => row.employee,
            cell: (info) => <div className="checked-employee">
                <input type="checkbox" value={1} onChange={() => alert(info.getValue())} defaultChecked={info.getValue() === 1} />
            </div>
        }
    ]

    function IndeterminateCheckbox({
                                       indeterminate,
                                       className = '',
                                       ...rest
                                   }) {
        const ref = React.useRef(!null);

        React.useEffect(() => {
            if (typeof indeterminate === 'boolean') {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
        )
    }

    return (
        <section className="successful-hires-section">
            <BasicTable basic_column={basic_colum} data={data} className="personal-coaching-table" pageSize={3} />
        </section>
    );
}

export default SuccessfulHires;
