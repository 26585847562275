import React from "react";
import {useTranslation} from "react-i18next";
import RegisterComponent  from 'components/Forms/RegisterComponent';

const Registration = () => {
    const {t} = useTranslation();

    return (
        <main className="register-page container">
            <section className="content-page">
                <h1 className="title">{t('Register.Title')}</h1>
                <RegisterComponent />
                <div className="circle"></div>
                <div className="circle"></div>
            </section>
        </main>
    )
}

export default Registration;
