import React from 'react';
import "./Accordion.scss";

const Accordion = (props) => {
    const { data } = props;

    return (
        <div className="lesson-list" key={data.id}>
            <h2 className="accordion-header" id={"heading" + data.id}>
                <button className="accordion-button collapsed relative focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+ data.id} aria-expanded="true"
                        aria-controls={"collapse"+ data.id}>
                    <div className="header-detail-course">
                        <div className="course-header-title">
                            <div className="count-lesson">{data.id + 1}</div>
                            <div>
                                <span className="courseTitle">{data.title}</span>
                                {data.subTitle ? <span className="detailCourse">{data.subTitle}</span>  : <></>}
                            </div>
                        </div>

                        <div className="symbol-plus">+</div>
                        <div className="symbol-min">-</div>
                    </div>
                </button>
            </h2>
            <div id={"collapse"+ data.id} className="header-detail-body accordion-collapse collapse" aria-labelledby={"heading" + data.id}
                 data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    {data.videoUrl ? <iframe src={data.videoUrl} height="400" width="60%"></iframe> : <></>}
                    {data.text? <span className="text">{data.text}</span> : <></>}
                </div>
            </div>
        </div>
    );
}

export default Accordion;
