import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import "./JobsComponent.scss";

const ItemOfferJob = (props) => {
    const [status, setStatus] = useState(props.CV.status);
    const classStatus = status === 'oferta' ? 'offers' : status === 'refuzuri' ?
        'refusal' : status === 'acceptari' ? 'accepted' : 'employee';

    const handleChange = (e) => {
        setStatus(e.target.value);
    }
    return (
        <div className="job-item">
          <div className={classStatus + ' job-item--data'}>
              <div className="personal-data">
                  <input type="checkbox" name="job" className="selectItem"/>
                  <div className="job-item--data-image">
                      <img src={require("@images/happy-young-man.png")} alt={props.CV.name}/>
                  </div>
                  <span className="cv-name">{props.CV.name}</span>
              </div>
              <div className="data-job">
                  {props.CV.data}
              </div>
          </div>
          <div className={classStatus + " job-item--actions"}>
              <div className="job-item--actions--firstSection">
                  <span className="action-btn"><FontAwesomeIcon icon={faComment} />Comentează</span>
                  <span className="action-btn"><FontAwesomeIcon icon={faDownload} />Descarcă</span>
              </div>

              <div className="job-item--actions--lastSection status">
                  <div className={classStatus + " icon"}></div>
                  <select className="tags-filter" name="" id="" onChange={(e) => handleChange(e)}>
                      <option value="">Statut Offerta</option>
                      <option value="oferta" selected={props.CV.status === 'oferta'}>Oferte</option>
                      <option value="refuzuri" selected={props.CV.status === 'refuzuri'}>Oferta Refuzata</option>
                      <option value="acceptari" selected={props.CV.status === 'acceptari'}>Oferta Acceptata</option>
                      <option value="angajari" selected={props.CV.status === 'angajari'}>Angajari</option>
                  </select>
              </div>
          </div>
        </div>
    );
}

export default ItemOfferJob;
