import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowUpShortWide,
  faBook, faCertificate, faCheckDouble, faDollarSign,
  faDoorOpen,
  faFingerprint, faMessage,
  faNewspaper, faRightFromBracket,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../redux/reducer/authReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";

const SidebarUser = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandle = () => {
    dispatch(logout());
    navigate("/login");
  }

    return (
        <div className="sidebar employee">
          <div className="sidebar__profile">
            <div className="sidebar__profile__user">
              <h1>Profilul Meu</h1>
              <div className="profile-image">
                <img
                    src={user.Image ?? 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'}
                    className="border-1 shadow-sm border-white border- rounded-full w-full h-full object-cover"
                    alt={user.Name + ' ' + user.Surname}/>
              </div>
              <span className="block user_name">{user.Name + ' ' + user.Surname}</span>
              <span className="block country">{user.Address.Country.Name}</span>
            </div>


            <div className="sidebar__profile__data">
              <span>Oferte: 12</span>
              <span>Joburi: {user.Jobs.length}</span>
              <span>Cursuri: 35</span>
              <span>Certificate: 2</span>
            </div>
          </div>

          <div className="sidebar__menu">
            <div className="sidebar__menu__items">
              <NavLink to={"/dashboard/user/interviews"} className="item-menu"><span><FontAwesomeIcon icon={faDoorOpen}/> Interviuri și oferte</span></NavLink>
              <NavLink to={"/dashboard/user/jobs"} className="item-menu"><span><FontAwesomeIcon icon={faStar}/> Joburi applicate</span></NavLink>
              <NavLink to={"/dashboard/user/my-cv"} className="item-menu"><span><FontAwesomeIcon icon={faNewspaper}/> CV-ul meu</span></NavLink>
              <NavLink to={"/dashboard/user/profile"} className="item-menu"><span><FontAwesomeIcon icon={faFingerprint}/> Informație personală</span></NavLink>
              <NavLink to={"/dashboard/user/my-career"} className="item-menu"><span><FontAwesomeIcon icon={faArrowUpShortWide}/>Carieră</span></NavLink>
              <NavLink to={"/dashboard/courses"} className="item-menu"><span><FontAwesomeIcon icon={faBook}/>Cursuri</span></NavLink>
              <NavLink to={"/dashboard/my-quizzes"} className="item-menu"><span><FontAwesomeIcon icon={faCheckDouble}/>Teste</span></NavLink>
              <NavLink to={"/dashboard/my-certificates"} className="item-menu"><span><FontAwesomeIcon icon={faCertificate}/>Certificari</span></NavLink>
              <NavLink to={"/dashboard/user/reviews"} className="item-menu"><span><FontAwesomeIcon icon={faMessage}/>Recenzii</span></NavLink>
              <NavLink to={"/dashboard/banking"} className="item-menu"><span><FontAwesomeIcon icon={faDollarSign}/>Rechizite bancare</span></NavLink>
            </div>
            <div className='item-menu item-menu-exit' onClick={() => logoutHandle()}><FontAwesomeIcon icon={faRightFromBracket}/>Exit</div>
          </div>
        </div>
    );
}

export default SidebarUser;
