import React from 'react';
import Moment from 'react-moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faDownload, faPrint, faTrophy} from "@fortawesome/free-solid-svg-icons";

const TrainerCertificateItem = (props) => {
    const {certificate} = props;

    return (
        <div className="certificate-item">
            <div className="certificate-item-head">
              <span className="title-course">Nume Curs</span>
            </div>
            <div className="certificate-item-head-second">
               <span className="data-certificate">Inceput: <span className="data-value"><Moment local date={certificate.data} format="DD MMM YYYY"/></span></span>
               <span className="data-certificate">Finalizat: <span className="data-value"><Moment local date={certificate.data} format="DD MMM YYYY"/></span></span>
            </div>

            <div className="certificate-main-content">
                <div className="certificate-image">
                    <img src={require('@images/certificate-by-default.png')} alt={certificate.titleCertificate}/>
                </div>

                <div className="certificate-content">
                    <div className="certificate-content-data">
                        <span>
                            Domeniu: <span className="certificate-value-data">Limbi straine</span>
                        </span>
                        <span>
                            Creat de: <span className="certificate-value-data">Limbi straine SRL</span>
                        </span>
                        <span>
                            Completate timp: <span className="certificate-value-data">2 saptamani</span>
                        </span>
                        <span>
                            Prerequisites: <span className="certificate-value-data">122</span>
                        </span>
                    </div>

                    <div className="certificate-actions">
                       <button className="download"><FontAwesomeIcon icon={faDownload} />Descarca</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainerCertificateItem
