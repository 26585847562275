import React, {useEffect, useState} from 'react';
import {faSave, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {editPersonalData} from "../../../redux/reducer/cvReducer";
import Image from "../../Image/Image";
import {userUpdateInfoActions} from "../../../redux/actions/userApi";

const PersonalInformationEdit = (props) => {
    const dispatch = useDispatch();
    const {editItem, user, background} = props;
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);

    const [formData, setFormData] = useState({
        name: user?.Name ?? '',
        surname: user?.Surname ?? '',
        email: user?.Email ?? '',
        telephone: user?.Phone ?? '',
        idnp: user?.IDNP ?? '',
        birthDate: new Date(user?.BirthDate),
        placeOfBirth: user?.PlaceOfBirth ?? '',
        address: user?.Address.YourAddress ?? '',
        addressCurrent: user?.Address.CurrentAddress ?? '',
        noOfChildren: user?.NoOfChildren ?? '',
        familySituation: user?.FamilySituation ?? '',
        image: user?.Image ?? null,
        backgroundImage: user.BackgroundImage ?? null,
    });

    const handleInputChangePersonalInfo = (e) => {
        const { name, value } = e.target;
        setFormData((formData) => ({
            ...formData,
            [name]: value
        }));
    };

    const handleInputChangePersonalImage = (name, value) => {
        setFormData((formData) => ({
            ...formData,
            [name]: value
        }));
    }

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    useEffect(() => {
        if (background !== null && background !== '' && background !== formData.backgroundImage) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                backgroundImage: background
            }));
        }
    },[background, setFormData])

    const handleUpdateData = () => {
        dispatch(userUpdateInfoActions({data: formData, token}));
    }


    return (
        <div className="edit-form">
            <div className="image-edit">
                <Image value={formData.image} onChange={(result) => handleInputChangePersonalImage('image',result)} withBase64={true}/>
            </div>
           <input type="text" name="name" value={formData.name} placeholder="Numele" onChange={handleInputChangePersonalInfo} />
           <input type="text" name="surname"  value={formData.surname} placeholder="Prenume" onChange={handleInputChangePersonalInfo} />
           <input type="text" value={user.Work?.Position} readOnly={true} />
           <input type="text" name="addressCurrent" value={formData.addressCurrent} placeholder="Locatia" onChange={handleInputChangePersonalInfo} />


            <div className="actions">
                <button onClick={() => { editItem(false); }}><FontAwesomeIcon icon={faXmark} />Cancel</button>
                <button onClick={ handleUpdateData }><FontAwesomeIcon icon={faSave} />Save</button>
            </div>
        </div>
    )
}

export default PersonalInformationEdit;
