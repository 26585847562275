import {createSlice} from "@reduxjs/toolkit";
import {
    advisersCreateActions,
    advisersEditActions,
    advisersGetAdvisersActions,
    advisersDeleteActions
} from "../actions/advisersApi";

const adviserReducer = createSlice({
    name: 'advisers',
    initialState: {
        advisers: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(advisersGetAdvisersActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(advisersGetAdvisersActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.advisers = payload
                }
            })

            .addCase(advisersGetAdvisersActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(advisersEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(advisersEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.loading = false;
                        state.error = payload.message;
                    } else {
                        state.advisers.map(item => {
                            if(item.Id === payload.data.Id) {
                                item.Name = payload.data.Name;
                                item.Surname = payload.data.Surname;
                                item.Phone = payload.data.Phone;
                                item.Image = payload.data.Image;
                                item.FacebookUrl = payload.data.FacebookUrl;
                                item.TwitterUrl = payload.data.TwitterUrl;
                                item.LinkedInUrl = payload.data.LinkedInUrl;
                                item.PinterestUrl = payload.data.PinterestUrl;
                                item.YoutubeUrl = payload.data.YoutubeUrl;
                                item.TikTokUrl = payload.data.TikTokUrl;
                            }
                        });
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(advisersEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(advisersCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(advisersCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.advisers = [...state.advisers, {...payload}]
                }
            })

            .addCase(advisersCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(advisersDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(advisersDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(advisersDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default adviserReducer.reducer;
