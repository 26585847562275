import {combineReducers, configureStore} from '@reduxjs/toolkit';
import appliedJobsReducer from "./reducer/appliedJobsReducer";
import quizzReducer from "./reducer/quizzReducer";
import careerReducer from "./reducer/careerReducer";
import certificateReducer from "./reducer/certificateReducer";
import educationReducer from './reducer/educationReducer';
import interestsReducer from './reducer/interestsReducer';
import authReducer from "./reducer/authReducer";
import cvReducer from "./reducer/cvReducer";
import {setupListeners} from "@reduxjs/toolkit/query/react";
import jobReducer from "./reducer/jobReducer";
import userReducer from "./reducer/userReducer";
import blogReducer from "./reducer/blogReducer";
import opportunityReducer from "./reducer/opportunityReducer";
import categoryReducer from "./reducer/categoryReducer";
import howWorkReducer from "./reducer/howWorkReducer";
import aboutReducer from "./reducer/aboutReducer";
import resultOpportunityReducer from "./reducer/resultOpportunityReducer";
import initiativeCompanyReducer from "./reducer/initiativeCompanyReducer";
import menuReducer from "./reducer/menuReducer";
import settingReducer from "./reducer/settingReducer";
import advisersReducer from "./reducer/advisersReducer";
import companyReducer from './reducer/companyReducer';
import courseReducer from "./reducer/courseReducer";
import socialMediaReducer from "./reducer/socialMediaReducer";
import bankReducer from "./reducer/bankReducer";
import cardReducer from "./reducer/cardReducer";
import accountBankReducer from "./reducer/accountBankReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    appliedJobs: appliedJobsReducer,
    quizz: quizzReducer,
    career: careerReducer,
    education: educationReducer,
    interests: interestsReducer,
    certificate: certificateReducer,
    company: companyReducer,
    course: courseReducer,
    jobs: jobReducer,
    user: userReducer,
    blog: blogReducer,
    opportunity: opportunityReducer,
    category: categoryReducer,
    howWork: howWorkReducer,
    initiative: initiativeCompanyReducer,
    menu: menuReducer,
    about: aboutReducer,
    resultOpportunity: resultOpportunityReducer,
    setting: settingReducer,
    advisers: advisersReducer,
    cv: cvReducer,
    socialMedia: socialMediaReducer,
    bank: bankReducer,
    card: cardReducer,
    accountBank: accountBankReducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware(),
})

setupListeners(store.dispatch);
