import React from 'react';
import CvTestimonialSlider from "../../TestimonialSlider/CvTestimonialSlider";

const CvSlider = () => {
    return (
        <>
          <CvTestimonialSlider />
        </>
    );
}

export default CvSlider;
