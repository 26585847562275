import React from 'react';

const Rules = () => {
    return (
      <main className="rules-page">
         <div className="container">
             <h1>Regulamentul site-ului</h1>
             <div className="shadow p-4 mt-4">
                 <ul className="rules-menu">
                     <li><a href="#gen">General</a></li>
                     <li><a href="#rul">Reguli generale</a></li>
                     <li><a href="#plat">Reguli de publicare a anunturilor privind posturile vacante parvenite de la utilizatori inregistrati:</a></li>
                     <li><a href="#tit">Cerinte obligatorii fata de titlul anuntului (denumirea postului vacant)</a></li>
                     <li><a href="#text">Cerinte obligatorii fata de textul anuntului</a></li>
                     <li><a href="#vip">Reguli de publicare a anunturilor VIP privind posturile vancate</a></li>
                     <li><a href="#cv">Reguli de publicare a CV-urilor</a></li>
                     <li><a href="#cvacces">Acces la baza de CV-uri</a></li>
                     <li><a href="#visa">Plata prin card bancar VISA si Mastercard</a></li>
                 </ul>
             </div>
             <div className="shadow p-4 my-5">
                 <h4 id="gen" className="text-2xl mb-2">General</h4>
                 <p>Înregistrându-se pe pagina web Rabota.md utilizatorul își exprimă angajamentul să respecte prevederile menționate în acordul utilizatorului și își asumă toate drepturile și obligațiile prevăzute de acesta în legătură cu utilizarea paginii web.</p>
                 <p>Înregistrându-se pe pagina web Rabota.md utilizatorul își exprimă acordul față de publicarea cu acces liber a datelor sale cu caracter personal.</p>
                 <p>Înregistrându-se pe site-ul Rabota.md, utilizatorul este de acord cu faptul ca el va recepționa expedieri informative din partea site-ului la adresa de e-mail, furnizată în timpul înregistrării.</p>
                 <p>Protecția datelor cu caracter personal ale utilizatorilor este asigurată în conformitate cu termenii și condițiile privind protecția datelor cu caracter personal și comerțul electronic.</p>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="rul" className="text-2xl mb-2">Reguli generale</h4>

                 <p>Pagina web Rabota.md este o resursă informațională care se premoderează. Toate anunțurile parvenite (privind posturile vacante, CV-urile) sunt în prealabil supuse moderării. Moderatorul are dreptul să nu admită anunțul spre publicare în cazul în care acesta nu corespunde rigorilor prezentului regulament. Administrația paginii web își rezervă dreptul de a nu admite anunțul spre publicare fără a fi nevoită sa ofere explicații.
                 Atât anunțurile publicate contra plată, cât și cele gratuite trebuie sa corespundă cerințelor impuse de administrația paginii web.
                 Administrația paginii web nu poartă nici o răspundere pentru acțiunile angajatorilor și celor aflați în căutarea unui loc de munca.
                 Administrația paginii web își rezervă dreptul de a modifica regulamentul existent.</p>
                 <p>Fiecare anunț privind un post vacant, parvenit pe pagina web Rabota.md, este supus moderării manuale. În acest mod se evită publicarea spamului și a anunțurilor dubioase.</p>

                 <strong>Este interzisă publicarea pe pagina web a anunțurilor care:</strong>
                 <ul>
                     <li>
                         <strong>
                         impun condiții, ce limitează drepturile celor aflați în căutarea unui loc de munca din punct de vedere al criteriilor de sex, vârsta, situație familială și altele, care nu au nici o legătură cu calitățile profesionale ale angajaților (conform prevederilor Legii Nr.121 din 25.05.2012 cu privire la asigurarea egalității);
                     </strong>
                     </li>
                     <li>anunțurile angajatorilor, care implica colectarea plăților de la candidați pentru angajare.</li>
                     <li>contravin legislației Republicii Moldova;</li>
                     <li>nu conțin informații despre posturile vacante (reprezentând de fapt publicitate, plângeri la adresa angajatorilor necinstiți etc.);</li>
                     <li>conțin înjurături sau cuvinte obscene;</li>
                     <li>conțin oferte de locuri de munca în străinătate fără a fi indicat numărul licenței firmei, care prestează servicii de angajare peste hotare;</li>
                     <li>au un evident caracter fraudulos;</li>
                     <li>nu conțin o denumire concretă a postului vacant și descrierea acestuia.</li>
                 </ul>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="plat" className="text-2xl mb-2">Reguli de publicare a anunțurilor privind posturile vacante parvenite de la utilizatorii înregistrați:</h4>

                 <p>Utilizatorii înregistrați au posibilitatea să publice gratuit trei anunțuri sau de trei ori să actualizeze (să ridice) anunțurile deja publicate în decursul unei săptămâni. Pentru fiecare anunț suplimentar sau pentru fiecare actualizare (ridicare) a unui anunț publicat se percepe plata în valoare de 1 leu.</p>
                     <p>Publicitatea cursurilor și trainingurilor, precum și anunțurile angajatorilor care oferă instruire contra plată, sunt publicate numai în rubrica Cursuri, Traininguri.</p>
                     <p>Numărul de anunțuri publicate și de actualizări ale anunțurilor nu este limitat.</p>
                     <p>Pentru publicarea unui anunț în rubricile „Locuri de munca in strainatate” se percepe plata în valoare de 5 lei. Totodată este necesar sa se țină cont de faptul că anunțurile privind ofertele de locuri de munca în străinătate pot fi publicate doar în rubrica respectivă („Locuri de munca in strainatate”).</p>
                     <p>În cazul încălcării repetate a regulamentului, administrația paginii web își rezervă dreptul de a șterge profilul personal al utilizatorului.</p>
                     <p>Numărul de telefon și adresa electronică indicate în profilul companiei sau în anunțul publicat sunt atribuite cabinetului personal și nu pot fi folosite de un alt utilizator înregistrat.</p>
                     <p>Este interzisă dublarea conturilor, adică plasarea anunțurilor identice ale uneia și aceleiași companii de pe conturi diferite în vederea obținerii bonusului.</p><br/>
                     <b>Suplinirea contului:<br/>
                         Contul poate fi suplinit în modul următor:</b><br/>

                    <p>Cu cardul bancar. Pentru a face acest lucru, faceți click pe butonul "Supliniți" din contul dvs. personal și selectați "Carduri bancare"</p>
                    <p>Prin intermediul oricărui terminal (pentru aceasta trebuie să găsiti logoul Rabota.md și să vă introduceți login-ul).</p>
                    <p>Prin virament (prin intermediul transferului bancar pe numele persoanei juridice, suma minima în acest caz fiind de 100 lei).</p><br/>

                    <p><b>Atenție!</b> La suplinirea contului prin card bancar sau prin terminal, factura nu se emite. Dacă aveți nevoie de factura, supliniți contul doar prin transfer bancar. Suma minima achitată prin transfer – 100 lei.</p>
                    <p> Prețurile indicate pe site, sunt actuale doar pentru companiile moldovenești. Pentru companiile străine prețul se discută individual.</p>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="tit">Cerințe obligatorii față de titlul anunțului (denumirea postului vacant):</h4>
                 <p>Titlul trebuie să conțină denumirea postului vacant la nominativ (de exemplu, "Продавец", "Contabil");</p>
                 <p>Titlul nu trebuie să conțină informații inutile (codul postului vacant, cerințe față de candidați etc.);</p>
                 <p>Este interzisă introducerea titlului postului vacant (rubrica ”Funcția”) cu majuscule sau prin transliterare, precum și în orice alt mod, ce ar îngreuna citirea lui.</p>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="text" className="text-2xl mb-2">Cerințe obligatorii față de textul anunțului:</h4>
                 <p>Textul anunțului trebuie sa corespundă cerințelor prezentului regulament;<br/>Este interzisă plasarea în anunțuri a informațiilor cu caracter publicitar și a link-urilor către alte pagini web.</p>
                 <br/><p>Decizia cu privire la publicarea anunțurilor se ia de către moderator. Prin urmare, în acest proces nu este exclus factorul uman. S-ar putea ca moderatorul din întâmplare sa nu observe un anunț, care a fost plasat cu încălcarea regulamentului. Astfel de cazuri pot fi semnalate de oricare utilizator prin intermediul rubricii «Contactează moderatorul». Un anunț publicat cu încălcarea regulamentului va fi blocat.</p>
                 <br/><p>Referitor la toate subiectele legate de publicarea anunturilor gratuite rugăm sa ne contactați prin intermediul rubricii «Contactează moderatorul»</p>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="vip" className="text-2xl mb-2">Reguli de publicare a anunturilor VIP privind posturile vacante:</h4>
                 <b>Elaborarea machetei</b><br/>
                 <p>Făcând comanda pentru publicarea unui anunț VIP compania urmeaza sa expedieze la adresa electronica rabota@rabota.md textul anunțului și logoul companiei sau doar textul dacă dorește să publice anunțul incognito. Ca răspuns el va primi machete anunțurilor spre aprobare. O macheta standard este elaborată în decurs de două ore.</p><br/>
                 <b>Macheta standard</b><br/>
                 <b>Macheta standard consta din următoarele elemente:</b><br/>

                 <ul>
                     <li>logoul (se plasează în colțul din stânga sus);</li>
                     <li>câmpul de sus (color sau alb) cu o frază introductiva (de exemplu: „Compania angajează …”), acest câmp poate lipsi;</li>
                     <li>denumirea postului vacant;</li>
                     <li>textul anunțului;</li>
                     <li>câmpul de jos (color sau alb) cu datele de contact (telefon, adresa electronica), acest câmp poate lipsi;</li>
                     <li>elementele machetei standard sunt amplasate în ordinea prezentată; câmpul de sus și cel de jos au aceeași culoare;</li>
                     <li>toate anunțurile parvenite de la o singură companie (un singur cabinet personal) vor fi publicate cu acelasi logo (sau fără el); publicarea acestor anunțuri cu logouri diferite nu se admite.</li>
                 </ul>

                 <b>Model de anunț în baza unei machete standard:</b><br/>

                 <div className="image">
                     <img src={require("../../assets/images/1500-02.jpg")} className="w-full h-full object-cover" alt="Image"/>
                 </div>

                 <b>Macheta nestandardizată</b><br/>
                 <p>De asemenea, sunt considerate nestandardizate anunțurile publicate în formă de link extern cu trecerea către o alta pagina web. Pentru publicarea acestor anunțuri se achită o plată suplimentară în mărime de 25% din valoarea publicării anunțului.</p>
                 <p>Anunțurile publicate în formă de imagine în format grafic elaborat de beneficiar se achita ca fiind standarde. Asemenea anunțuri nu pot fi redactate de către beneficiar de sine stătător din cabinetul personal.</p><br/>

                 <b>Model al unui astfel de anunț</b><br/>
                 <div className="image">
                     <img src={require("../../assets/images/1500-01.jpg")} className="w-full h-full object-cover" alt="image"/>
                 </div>
                 <p>Termenul de elaborare a machetei individuale se discută separat în funcție de complexitatea acesteia. După aprobarea de către client anunțurile sunt publicate, iar clientului i se expediază nota de plată. Anunțurile parvenite de la companiile din străinătate sunt publicate doar după efectuarea plății în avans.</p>
                 <br/><b>Gestionarea ulterioară a anunțurilor VIP de către utilizatori:</b><br/>
                 <p>După publicarea primului anunț VIP, compania primește datele necesare pentru accesarea profilului personal – login-ul și parola. Cabinetul personal oferă posibilitatea de a gestiona anunțurile, adică:</p>
                 <ul>
                     <li>a ridica anunțul și a seta ridicarea automată a acestuia;</li>
                     <li>a urmări pozițiile postului vacant pe pagina web;</li>
                     <li>a redacta anunțul (doar în cazul anunțurilor în baza machetei standard);</li>
                     <li>a publica anunțuri noi;</li>
                     <li>a anula publicarea.</li>
                 </ul>

                 <p>Tot în cabinetul personal ajung și CV-urile expediate de utilizatori direct de pe pagina web.</p><br/>

                 <p>Anunțul redactat de utilizator este supus verificării de către moderator. Moderarea anunțurilor parvenite între orele 9.00 și 18.00 în zilele de lucru se face în decursul unei ore. Anunțurile noi expediate moderatorului din cabinetul personal sunt publicate în decurs de două ore. Moderatorul are dreptul sa modifice textul anunțului în cazul în care acesta nu corespunde regulamentului.</p>

                 <b>Gestionarea anunțurilor VIP prin intermediul moderatorului</b><br/>

                 <p>Utilizatorul poate renunța la redactarea/adăugarea/ștergerea de sine stătător a anunțurilor, expediind solicitarea de operare a modificărilor respective la adresa electronică rabota@rabota.md. Solicitările parvenite între orele 9.00 și 18.00 în zilele de lucru vor fi îndeplinite în decursul de două ore. Se admite și redactarea anunțurilor prin telefon, dar în acest caz administrația paginii web nu poartă răspundere pentru corectitudinea modificărilor operate. Redactarea anunțurilor în baza unei machete nestandardizate se face doar prin intermediul moderatorului.</p><br/>

                 <b>Moderarea anunțurilor pe timp de noapte, în zilele de odihnă și de sărbătoare</b><br/>

                 <p>Moderarea (verificarea și publicarea) anunțurilor pe timp de noapte (după 18.00), in zilele de odihna și de sărbătoare se face în funcție de timpul disponibil.</p>

                 <b>Actualizarea (ridicarea) anunțurilor</b><br/>

                 <p>În decursul primei săptămâni anunțurile VIP sunt ridicate automat, de patru ori pe zi. Ulterior ridicarea anunțurilor cu actualizarea datei (atât pe pagina principală, cât și în rubrici) se efectuează de către beneficiar cu ajutorul butonului „Ridică în top” din cabinetul personal sau setând ridicarea automată cu programarea zilei și orei dorite. În decurs de 30 de zile se oferă 10 ridicari gratuite, plata pentru fiecare ridicare ulterioară fiind de 5 lei.</p>
                 <p>Odată cu publicarea primului anunț administrația paginii web oferă un bonus în mărime de 40 ridicari. În cazul prelungirii sau reluarii publicării bonusul nu se oferă. Contul poate fi suplinit prin intermediul oricărui terminal sau solicitând nota de plată.
                     Bonusurile se oferă la discreția administrației site-ului Rabota.md și pot fi anulate în orice moment.</p>

                 <b>Accesul utilizatorilor VIP la baza de date a CV-urilor</b><br/>
                 <p>O data la 30 de zile clienții VIP beneficiază de acces la datele de contact din 15 CV-uri din baza de date a CV-urilor. În urma expirarii perioadei de plasare a anunțurilor (perioada cand contul VIP este inactiv) accesul la baza de CV-uri se stopeaza, indiferent de cantitatea vizualizărilor contactelor în timpul perioadei active. <br/> Costurile de accesare a bazei de date a CV-urilor pot fi consultate la adresa: https://www.rabota.md/ro/prices/cv
                     Bonusurile se oferă la discreția administrației site-ului Rabota.md și pot fi anulate in orice moment.</p><br/>
                 <b>Traducerea anunțurilor</b><br/>

                 <p>Traducerea anunțurilor (in limbile rusă, română) se efectuează gratuit în cazul în care compania publică concomitent până la trei anunțuri.</p>
                 <b>Important</b><br/>
                 <p>Administrația paginii web nu poartă răspundere pentru numărul și calitatea CV-urilor expediate.</p>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="cv" className="text-2xl mb-2">Reguli de publicare a CV-urilor:</h4>

                 <ul>
                     <li>CV-urile pot fi publicate doar de utilizatorii înregistrați</li>
                     <li>Nu se admite publicarea CV-urilor care:
                       <ol>
                           <li>- nu conțin informații relevante despre cel aflat în căutarea unui loc de munca (reprezentând de fapt publicitate, oferte de marketing în rețea etc.);</li>
                           <li>- conțin injuraturi sau cuvinte obscene;</li>
                           <li>- propun servicii cu caracter criminal, sexual și alte servicii ilegale;</li>
                           <li>- au fost publicate in rubrica „Joburi”;</li>
                           <li>- conțin link-uri către alte resurse;</li>
                           <li>- aparțin unui grup de persoane sau companii care oferă anumite servicii.</li>
                       </ol>
                     </li>
                     <li>Se admite plasarea unui CV în mai multe categorii ce corespund postului vacant indicat.</li>
                     <li>Titlul CV-ului (rubrica „Funcția”) trebuie să corespundă rubricii, în care este publicat anunțul. Moderatorul are dreptul sa publice anunțul în alta rubrică decât cea indicată de utilizator.</li>
                     <li>Este interzisă includerea în CV a datelor cu caracter personal și a datelor de contact (nume, prenume, adresa electronica, adresa de domiciliu, numărul de telefon, link-uri către conturile de pe rețelele de socializare, contul Skype, etc.) în câmpurile de acces public („Responsabilități, funcții, performanțe”, „Competențe-cheie”, „Despre sine”).</li>
                 </ul>

                 <b>Reguli de includere a fotografiilor în CV</b><br/>

                 <p>În anunț poate fi inclusă doar fotografia personală a celui aflat în căutarea unui loc de munca.</p>
                 <b>Este interzisă publicarea fotografiilor în care:</b><br/>
                 <ul>
                     <li>nu se vede fața persoanei;</li>
                     <li>sunt prezente mai multe persoane;</li>
                     <li>nu apare persoana aflată în căutarea unui loc de munca (imagini din Internet, fotografii ale persoanelor publice, personajelor din desene animate, obiecte neînsuflețite);</li>
                     <li>persoana apare dezgolita.</li>
                 </ul>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="cvacces" className="text-2xl mb-2">Acces la baza de CV-uri:</h4>

                 <p>Toata informația indicată de către candidați în CV-urile plasate pe site-ul Rabota.md, se publică cu acces deschis, cu excepția datelor de contact/ datelor personale: nume și prenume, număr de contact, adresa de e-mail, adresa poștală, link-urile către alte pagini de socializare.</p>
                 <b>Posibilitatea de a accesa datele personale ale candidaților au doar:</b><br/>
                 <p>Utilizatorii care publică anunțuri VIP – pot deschide lunar datele de contact a 15 CV-uri. Acest bonus nu este cumulativ. Bonusurile neutilizate în luna curentă nu pot fi transferate pentru luna următoare.
                     Utilizatorii care au achitat accesul la baza de CV-uri: https://jobhub.md/resumes</p>

                 <p><b>Atenție!</b> Administrația site-ului nu duce răspundere pentru corectitudinea informației publicate în CV și pentru acțiunile candidatului. Datorită numărului mare de CV-uri care se publica pe site, administrația nu poate ține cont de actualitatea lor.</p>
                 <b>Confidențialitate</b><br/>
                 <p>Protecția datelor cu caracter personal ale utilizatorilor este asigurată în conformitate cu Politica de confidențialitate.</p>
             </div>

             <div className="shadow p-4 my-5">
                 <h4 id="visa" className="text-2xl mb-2">Plata prin card bancar VISA și Mastercard:</h4>

                 <p>Se accepta carduri bancare VISA și Mastercard. Plata minimă este de 10 lei.<br/>
                     Securitatea tranzacției este asigurată de protocolul https, prin criptarea tuturor datelor transmise.</p>

                 <b>Returnarea banilor</b><br/>

                 <p>Pentru returnarea banilor transferați, trebuie sa trimiteți o cerere la <a href="mailto:info@jobhub.md">info@jobhub.md</a>, specificand login-ul cu care va conectați la contul personal pe Rabota.md. Banii vor fi returnați pe cardul cu care a fost efectuat transferul. Pentru returnarea banilor transferați, trebuie sa trimiteți o cerere la rabota@rabota.md, specificand login-ul cu care vă conectați la contul personal pe Rabota.md. Banii vor fi returnați pe cardul cu care a fost efectuat transferul. Va fi returnată doar suma neutilizată.</p>
             </div>
         </div>
      </main>
    );
}

export default Rules;
