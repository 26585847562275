import {apiClient} from "./index";

export const getCategory = (params) => {
    return apiClient.get('/api/category',{params}).then(response => response.data);
}

export const addCategory = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/category',data);
}

export const editCategory = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/category/${Id}?_method=PUT`,data);
}

export const deleteCategory = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/category/${Id}`);
}
