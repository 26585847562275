import React from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import './Form.scss';
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import AuthService from "../../service/AuthService";
import {toast, ToastContainer} from "react-toastify";

const ForgotPasswordComponent = () => {
    const {t} = useTranslation();

    const SignupSchema = Yup.object({
        email: Yup.string()
            .email(t('Login.invalidEmail'))
            .min(2,t('Email.minCharacterError'))
            .max(100,t('Login.maxCharacterError'))
            .required(t('Login.errorRequiredEmail')),
    });

    return (
        <div className="login-component form-component">
            <Formik  initialValues={
                {
                    email: '',
                }
            }
                     validationSchema={SignupSchema}
                     onSubmit={(values) => {
                         const param = {
                             'email': values.email
                         }

                         AuthService.forgot(param).then(response => {
                             if(response.response?.data?.status === false){
                                 toast.error(response.response?.data?.message, {
                                     position: toast.POSITION.TOP_CENTER,
                                 });
                             } else {
                                 toast.success('Check your email', {
                                     position: toast.POSITION.TOP_CENTER,
                                 });
                             }
                         });
                     }}
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <Form className="Form loginForm" autoComplete="off">
                        <div className="form-input">
                            <Field type="text" name="email" placeholder={t('Login.Email')} />
                            <ErrorMessage name="email" render={msg => <span className="form-input-error">{msg}</span>} />
                        </div>

                        <button
                            type="submit"
                            className="form-button">
                            {t('Forgot.btnSend')}
                        </button>
                    </Form>
                )}
            </Formik>
            <ToastContainer autoClose={3000} />
        </div>
    )
}

export default ForgotPasswordComponent;
