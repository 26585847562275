import {apiClient} from "./index";

export const getAccountsBank = (params) => {
    return apiClient.get('/api/account-bank',{params}).then(response => response.data);
}

export const addAccountBank = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/account-bank',data);
}

export const editAccountBank = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/account-bank/${Id}?_method=PUT`,data.data);
}

export const deleteAccountBank = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/account-bank/${Id}`);
}
