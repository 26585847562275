import {apiClient} from "./index";

export const getMenu = (params) => {
    return apiClient.get('/api/menu',{params}).then(response => response.data);
}

export const addMenu = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/menu',data);
}

export const editMenu = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/menu/${Id}?_method=PUT`,data);
}

export const deleteMenu = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/menu/${Id}`);
}
