import React, {useEffect, useState} from 'react'
import {Link, useLocation, useSearchParams} from "react-router-dom";
import JobComponent from "components/Job/JobComponent";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../components/Loader/Loader";
import moment from 'moment';
import {jobGetJobsActions} from "../../redux/actions/jobsApi";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Vacancies = () => {
    const state = useLocation();
    const [queryParameters] = useSearchParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {loading, error, jobs} = useSelector(state => state.jobs);
    const [date, setDate] = useState(0);
    const [salary, setSalary] = useState(0);
    const [filter, setFilter] = useState({
        active: 1,
        category_id: queryParameters.get('category') ?? null,
        company_id: queryParameters.get('company') ?? null
    });

    const [type, setType] = useState([]);

    useEffect(() => {
        if(sessionStorage.getItem('country') !== undefined){
           setFilter({...filter,...{country: sessionStorage.getItem('country')}});
           dispatch(jobGetJobsActions(filter));
        }
    }, [setFilter]);

    useEffect(() => {
        dispatch(jobGetJobsActions(filter));
    },[filter])


    const handleChangeData = (event) => {
        const date = event.target.value;
        setDate(date);

        let param = {};

        const now = moment(); // get current time
        const dataTo = now.format('YYYY-MM-DD');

        if(sessionStorage.getItem('country') !== undefined){
            param = {...filter, ...{country: sessionStorage.getItem('country')}}
        }

        if(parseInt(event.target.value) !== 0){
            const dataFrom = now.subtract(event.target.value, 'days').format('YYYY-MM-DD');

            param = {...filter, ...{DateTo: dataTo, DateFrom: dataFrom}}
        } else {
            delete param.DateFrom;
            delete param.DateTo;
        }

        setFilter(param);
    }

    const handleChangeSalary = (event) => {
        const salaryValue = event.target.value;
        setSalary(salaryValue);

        const now = moment(); // get current time
        let param = {};
        const dataTo = now.format('YYYY-MM-DD');

        if(parseInt(event.target.value) !== 0){
            param = {...filter, ...{salary: event.target.value}}
        } else {
            delete param.salary;
        }

        setFilter(param);
    }

    const handleChangeType = async(event) => {
        const typeValue = event.target.value;
        let param = [];

        if (!type.includes(typeValue)) {
            param = [...type, ...typeValue];
            setType(param);
        } else {
            param = type.filter((item) => item !== typeValue);
            setType(param);
        }

        if(param){
          param = {...filter, ...{type: param}}
        }

        setFilter(param);
    };

    return (
        <main className="vacancies-page">
            <section className="container vacancies-content flex flex-wrap">
                <section className="content">
                    <h1 className="title">Vacancies</h1>
                    {error && <p>Something went wrong</p>}
                    { loading && <Loader /> }
                    { (!loading) &&
                        <>
                                { jobs.length > 0 ? jobs?.map((company) => <JobComponent key={company.Company.Id} company={company} />) :
                                <>
                                    <p>Nu sa Gasit nici un Job</p>
                                </>
                                }
                            {/*<div className="action">*/}
                            {/*    <Link to="" className="btn-load-more">Load More</Link>*/}
                            {/*</div>*/}
                        </>
                    }
                </section>

                <section className="sidebarMenu">
                     {/*<span className="getJobs">*/}
                     {/*    <label htmlFor="getJobEmail">Get New Jobs by email</label>*/}
                     {/*    <input id="getJobEmail" type="checkbox" className="switch" />*/}
                     {/*</span>*/}
                   <section className="filters">
                       <div className="filterToggle">
                           <FontAwesomeIcon icon={faSliders} className="fa-slider-icon" />
                       </div>
                       <div className='w-full filters-body'>
                           {/*<h2 className="filters-sub-title">*/}
                           {/*    <div className="icon"><img src={require("assets/images/filter-location.png")} /></div>Locations*/}
                           {/*</h2>*/}
                           {/*<div className="w-full filters-items">*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-1" type="radio" value="Near Me" name="location" />*/}
                           {/*        <label htmlFor="radio-1">Near Me</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-2" type="radio" value="Remote Job" name="location" />*/}
                           {/*        <label htmlFor="radio-2">Remote Job</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-3" type="radio" value="Exact Location" name="location" />*/}
                           {/*        <label htmlFor="radio-3">Exact Location</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-4" type="radio" value="3mi" name="location" />*/}
                           {/*        <label htmlFor="radio-4">Within 3 mi</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-5" type="radio" value="5mi" name="location" />*/}
                           {/*        <label htmlFor="radio-5">Within 5 mi</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-6" type="radio" value="10mi" name="location" />*/}
                           {/*        <label htmlFor="radio-6">Within 10 mi</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-7" type="radio" defaultChecked={true} value="15mi" name="location" />*/}
                           {/*        <label htmlFor="radio-7">Within 15 mi</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-8" type="radio" value="25mi" name="location" />*/}
                           {/*        <label htmlFor="radio-8">Within 25 mi</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-9" type="radio" value="50mi" name="location" />*/}
                           {/*        <label htmlFor="radio-9">Within 50 mi</label>*/}
                           {/*    </div>*/}
                           {/*</div>*/}

                           <h2 className="filters-sub-title">
                               <div className="icon"><img src={require("assets/images/filter-timer.png")}/></div>{t('Filter.titleByDate')}
                           </h2>
                           <div className="w-full filters-items">
                               <div className="filter-item">
                                   <input id="allTime" type="radio" value={0} defaultChecked={date === 0} onChange={(event) => handleChangeData(event)}  name="filterData"/>
                                   <label htmlFor="allTime">{t('Filter.allDate')}</label>
                               </div>
                               <div className="filter-item">
                                   <input id="24Hours" type="radio" value={1} defaultChecked={date === 1} onChange={(event) => handleChangeData(event)}  name="filterData" />
                                   <label htmlFor="24Hours">{t('Filter.lastDay')}</label>
                               </div>
                               <div className="filter-item">
                                   <input id="3Days" type="radio" value={3} defaultChecked={date === 3} onChange={(event) => handleChangeData(event)} name="filterData" />
                                   <label htmlFor="3Days">{t('Filter.lastThreeDay')}</label>
                               </div>
                               <div className="filter-item">
                                   <input id="7Days" type="radio" value={7} defaultChecked={date === 7} onChange={(event) => handleChangeData(event)}  name="filterData" />
                                   <label htmlFor="7Days">{t('Filter.lastSevenDay')}</label>
                               </div>
                           </div>

                           <h2 className="filters-sub-title">
                               <div className="icon"><img src={require("assets/images/filter-money.png")}/></div>{t('Filter.titleSalary')}
                           </h2>
                           <div className="w-full filters-items">
                               <div className="filter-item">
                                   <input id="anySalary" type="radio" defaultChecked={salary === 0}  value={0} onChange={(event) => handleChangeSalary(event)} name="salary" />
                                   <label htmlFor="anySalary">Any</label>
                               </div>
                               <div className="filter-item">
                                   <input id="50000k" type="radio" defaultChecked={salary === 50000} value={50000} onChange={(event) => handleChangeSalary(event)} name="salary" />
                                   <label htmlFor="50000k">Of 50k</label>
                               </div>
                               <div className="filter-item">
                                   <input id="25k" type="radio" defaultChecked={salary === 25000} value={25000} onChange={(event) => handleChangeSalary(event)} name="salary" />
                                   <label htmlFor="25k">Of 25k</label>
                               </div>

                               <div className="filter-item">
                                   <input id="10k" type="radio" defaultChecked={salary === 15000} value={15000} onChange={(event) => handleChangeSalary(event)} name="salary" />
                                   <label htmlFor="10k">Of 15k</label>
                               </div>

                               <div className="filter-item">
                                   <input id="10k" type="radio" defaultChecked={salary === 10000} value={10000} onChange={(event) => handleChangeSalary(event)} name="salary" />
                                   <label htmlFor="10k">Of 10k</label>
                               </div>

                               <div className="filter-item">
                                   <input id="10k" type="radio" defaultChecked={salary === 5000} value={5000} onChange={(event) => handleChangeSalary(event)} name="salary" />
                                   <label htmlFor="10k">Of 5k</label>
                               </div>
                           </div>

                           {/*<h2 className="filters-sub-title">*/}
                           {/*    <div className="icon"><img src={require("assets/images/filter-avatar.png")} /></div>Work Experience*/}
                           {/*</h2>*/}
                           {/*<div className="w-full filters-items">*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-1111" type="radio" defaultChecked={true} value="Any Experience" name="experience" />*/}
                           {/*        <label htmlFor="radio-1111">Any Experience</label>*/}
                           {/*    </div>*/}
                           {/*    <div className="filter-item">*/}
                           {/*        <input id="radio-1111" type="radio" value="Internship" name="experience" />*/}
                           {/*        <label htmlFor="radio-1111">Internship</label>*/}
                           {/*    </div>*/}
                           {/*</div>*/}

                           <h2 className="filters-sub-title">
                              <div className="icon"><img src={require("assets/images/filter-calendar.png")} /></div> {t('Filter.titleType')}
                           </h2>

                           <div className="w-full filters-items">
                               <div className="filter-item">
                                   <input id="radio-01" type="checkbox" defaultChecked={type.includes(1)} value={1} name="program" onChange={(event) => handleChangeType(event)}/>
                                   <label htmlFor="radio-01">{t('Filter.FullTime')}</label>
                               </div>
                               <div className="filter-item">
                                   <input id="radio-03" type="checkbox" defaultChecked={type.includes(2)} value={2} name="program" onChange={(event) => handleChangeType(event)} />
                                   <label htmlFor="radio-03">{t('Filter.PartTime')}</label>
                               </div>
                               <div className="filter-item">
                                   <input id="radio-02" type="checkbox" defaultChecked={type.includes(3)} value={3} name="program" onChange={(event) => handleChangeType(event)} />
                                   <label htmlFor="radio-02">{t('Filter.Temporary')}</label>
                               </div>
                               <div className="filter-item">
                                   <input id="radio-03" type="checkbox" defaultChecked={type.includes(4)} value={4} name="program" onChange={(event) => handleChangeType(event)} />
                                   <label htmlFor="radio-03">{t('Filter.Remote')}</label>
                               </div>
                           </div>
                       </div>
                   </section>
                </section>
            </section>
        </main>
    )
}

export default Vacancies;
