import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import CareerItem from "./CareerItem";
import CareerAdd from "./CareerAdd";
import './experience.scss';

const ExperienceComponent = (props) => {
    const career = useSelector(state => state.career.career);
    const {perPage} = props;
    const [editButton, setEditButton] = useState(false);
    const [addButton, setAddButton] = useState(false);
    const [loadMore, setLoadMore] = useState(false);

    const showMore = () =>
    {
        setLoadMore(prevCheck => !prevCheck);
    }

    let loadCount = career.length;

    if(perPage !== null && perPage > 0){
        loadCount = loadMore ? career.length : perPage;
    }

    const handleChange = (value) => {
        setEditButton(value);
    }

    const handleChangeAdd = (value) => {
        setAddButton(value);
    }

    return (
        <section className="career-section">
            <div className="career-section-head">
                <span className="title">Experience</span>
                <div className="actions">
                    <span className="career-action-button" onClick={() => setAddButton(true)}><FontAwesomeIcon icon={faPlus} /></span>
                    <span className="career-action-button" onClick={() => setEditButton(true)}><FontAwesomeIcon icon={faPencil} /></span>
                </div>
            </div>
            <div className="my-career">
                {career &&  career.slice(0, loadCount).map((item, value) => <CareerItem key={value} career={item} editButton={editButton} editButtonItem={handleChange} />)}
                {addButton ? <div className="career-item"><CareerAdd  addButtonItem={handleChangeAdd} /></div> : <></>}
            </div>
            {
                (perPage !== null && perPage > 0 && ((career.length - perPage) > 0)) ?
                  <div className="career-load-more">
                    <span onClick={showMore}>{!loadMore ? `Show all ${career.length - perPage} other experience` : `Show the first ${perPage} experience`}<FontAwesomeIcon icon={faChevronRight} /></span>
                  </div> : <></>
            }
        </section>
    )
}

export default ExperienceComponent;
