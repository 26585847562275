import React from 'react'
import EducationComponent from "../../EducationComponent/EducationComponent";

const EducationSection = () => {
    return (
        <EducationComponent />
    )
}

export default EducationSection;
