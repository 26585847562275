import React from 'react';
import {useNavigate, useParams,} from "react-router-dom";
import { useFormInputValidation } from "react-form-input-validation";
import AuthService from "../../service/AuthService";
import './Form.scss';
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";


const ResetPasswordComponent = () => {
    const {token} = useParams();
    const navigate = useNavigate();

    const {t} = useTranslation();
    const [fields, errors, form] = useFormInputValidation({
        token: "",
        password: "",
        password_confirmation: "",
    }, {
        token: "required",
        password: "required",
        password_confirmation: "required|same:password",
    });


    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = {
            'token': token,
            'password': fields.password,
            'confirm_password': fields.password_confirmation
        }

        AuthService.reset(params).then(response => {
           if (response.data.status === true){
               toast.success('Success', {
                   position: toast.POSITION.TOP_CENTER,
               });

               setTimeout(() => {
                   navigate('/login');
               }, 3000);

           } else {
               if(response.response.data.errors.password){
                   toast.error('The password field format is invalid', {
                       position: toast.POSITION.TOP_CENTER,
                   });
               } else if(response.response.data.errors.token){
                   toast.error('The token is invalid', {
                       position: toast.POSITION.TOP_CENTER,
                   });
               }
           }
        });
    }

    return (
        <div className="register-component form-component">
            <form noValidate autoComplete="off" className="Form">
                <div className="form-input">
                    <input
                        type="password"
                        name="password"
                        placeholder={t('Register.Password')}
                        value={fields.password}
                        onBlur={form.handleBlurEvent}
                        onChange={form.handleChangeEvent}
                        data-attribute-name="Password"
                    />
                    {errors.password ? <span className="form-input-error">{errors.password}</span> : "" }
                </div>

                <div className="form-input">
                    <input
                        type="password"
                        name="password_confirmation"
                        placeholder={t('Register.ConfirmPassword')}
                        value={fields.password_confirmation}
                        onBlur={form.handleBlurEvent}
                        onChange={form.handleChangeEvent}
                        data-attribute-name="Password Confirmation"
                    />
                    {errors.password_confirmation ? <span className="form-input-error">{errors.password_confirmation}</span> : "" }
                </div>

                <button
                    type="submit"
                    onClick={ e => handleSubmit(e)}
                    className="form-button">
                    {t('Forgot.btnSend')}
                </button>
            </form>
            <ToastContainer autoClose={3000} />
        </div>
    )
}

export default ResetPasswordComponent;
