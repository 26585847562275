import React, {useState} from 'react';
import {editCard} from "../../../../redux/reducer/cardReducer";
import {useDispatch} from "react-redux";

const AccountCard = (props) => {
   const dispatch = useDispatch();
   const {card, id, user} = props;
   const [nrCardFirst, setNrCardFirst] = useState(card?.code.slice(0,4) ?? '');
   const [nrCardSecond, setNrCardSecond] = useState(card?.code.slice(4,8) ?? '');
   const [nrCardThird, setNrCardThird] = useState(card?.code.slice(8,12) ?? '');
   const [nrCardFourth, setNrCardFourth] = useState(card?.code.slice(12,16) ?? '');

   const [month, setMonth] = useState(card.month);
   const [year, setYear] = useState(card.year);

    const setNrFirstCard = (value) => {
        setNrCardFirst(value);
        dispatch(editCard({
            id: card.id,
            code: value + '' + nrCardSecond + '' + nrCardThird + '' + nrCardFourth,
            user_id: card.user_id,
            month: card.month,
            year: card.year
        }));
    }

    const setNrSecondCard = (value) => {
        setNrCardSecond(value);
        dispatch(editCard({
            id: card.id,
            code: nrCardFirst + '' + value + '' + nrCardThird + '' + nrCardFourth,
            user_id: card.user_id,
            month: card.month,
            year: card.year
        }));
    }

    const setNrThirdCard = (value) => {
        setNrCardThird(value);
        dispatch(editCard({
            id: card.id,
            code: nrCardFirst + '' + nrCardSecond + '' + value + '' + nrCardFourth,
            user_id: card.user_id,
            month: card.month,
            year: card.year
        }));
    }

    const setNrFourthCard = (value) => {
        setNrCardFourth(value);
        dispatch(editCard({
            id: card.id,
            code: nrCardFirst + '' + nrCardSecond + '' + nrCardThird + '' + value,
            user_id: card.user_id,
            month: card.month,
            year: card.year
        }));
    }

    const setMonthCard = (value) => {
        setMonth(value);
        dispatch(editCard({
            id: card.id,
            code: nrCardFirst + '' + nrCardSecond + '' + nrCardThird + '' + nrCardFourth,
            user_id: card.user_id,
            month: value,
            year: card.year
        }));
    }

    const setYearCard = (value) => {
        setYear(value);
        dispatch(editCard({
            id: card.id,
            code: nrCardFirst + '' + nrCardSecond + '' + nrCardThird + '' + nrCardFourth,
            user_id: card.user_id,
            month: month,
            year: value
        }));
    }

   return (
       <div className="main-card">
           <span className="title-card"><input type="radio" value={id} defaultChecked={2} name="card"/>Cont de Card {id}</span>
           <div className="account-card">
               <div className="nr-card">
                   <input type="text" pattern="[0-9]*" maxLength={4} name="nr-card-1" value={nrCardFirst} placeholder="0000" onChange={(e) => setNrFirstCard(e.target.value) }/>
                   <input type="text" pattern="[0-9]*" maxLength={4} name="nr-card-1" value={nrCardSecond} placeholder="0000" onChange={(e) => setNrSecondCard(e.target.value) }/>
                   <input type="text" pattern="[0-9]*" maxLength={4} name="nr-card-1" value={nrCardThird} placeholder="0000" onChange={(e) => setNrThirdCard(e.target.value)} />
                   <input type="text" pattern="[0-9]*" maxLength={4} name="nr-card-1" value={nrCardFourth} placeholder="0000" onChange={(e) => setNrFourthCard(e.target.value)} />
               </div>
               <div className="expiryCard">
                   <input type="text" pattern="[0-9]*" maxLength={2} name="month" value={month} placeholder="00" onChange={(e) => setMonthCard(e.target.value) }/>
                   <input type="text" pattern="[0-9]*" maxLength={2} name="year" value={year} placeholder="00" onChange={(e) => setYearCard(e.target.value) }/>
               </div>
               <input type="text" name="name" value={user?.Name + ' ' + user?.Surname} placeholder="Nume Prenume" disabled className="full-name-of-card" />
           </div>
       </div>
   );
}

export default AccountCard;
