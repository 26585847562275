import React, {useState} from 'react';

import { Chart } from "react-google-charts";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import Input from "components/Filters/Input/Input";
import DatePickerInput from "components/Filters/DatePicker/DatePickerInput";
import BasicTable from "components/TableComponent/BasicTable";
import ProgressBar from "components/ProgressBar/ProgressBar";

const MyStatistic = () => {
  const [search, setSearch] = useState('');
  const [dataTo, setDataTo] = useState(new Date());
  const [dataFrom, setDataFrom] = useState(new Date());

  const handleChangeSearch = (value) => {
    setSearch(value);
  }

  const handleChangeDataTo = value => setDataTo(value);
  const handleChangeDataFrom = value => setDataFrom(value);

  const dataCourse = [{
    id: 1,
    name: 'Radu',
    surname: 'Rata',
    photo: 'career.jpeg',
    city: 'Moldova',
    email: 'radu.rata@gmail.com',
    data: '23/12/23',
    domain: 'IT',
    time: '12h 13min',
    language: 'RO',
    nameCourse: 'HTML',
    average: 'Bine'
  },
    {
      id: 2,
      name: 'Nicolae',
      surname: 'Rata',
      photo: 'career.jpeg',
      city: 'Moldova',
      email: 'radu.rata@gmail.com',
      data: '23/12/23',
      domain: 'IT',
      time: '12h 13min',
      language: 'RO',
      nameCourse: 'HTML',
      average: 'Satisfacator'
    },
    {
      id: 3,
      name: 'Anton',
      surname: 'Rata',
      photo: 'career.jpeg',
      city: 'Moldova',
      email: 'radu.rata@gmail.com',
      data: '23/12/23',
      domain: 'IT',
      time: '12h 13min',
      language: 'RO',
      nameCourse: 'HTML',
      average: 'Foarte Bine'
    }];

  const basicColumCourse = [
    {
      id: 'select',
      header: ({ table }) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
          </div>
      ),
      cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
            />
          </div>
      ),
    },
    {
      header: 'Photo',
      accessorKey: 'photo',
      enableSorting: false,
      cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)} alt={info.getValue()}/></div>
    },
    {
      header: 'Nume',
      accessorKey: 'name',
    },
    {
      header: 'Prenume',
      accessorKey: 'surname'
    },
    {
      header: 'Tara',
      accessorKey: 'city'
    },
    {
      header: 'Data inregistrari',
      enableSorting: false,
      accessorKey: 'data',
    },
    {
      header: 'Domeniu',
      accessorKey: 'domain'
    },
    {
      header: 'Limba',
      accessorKey: 'language'
    },
    {
      header: 'Curs',
      enableSorting: false,
      cell: ({ row }) =>
          <div className="actions-table">
            <button onClick={() => alert(row.id)} className="button-table enrole">Success</button>
          </div>
    }
  ];

  const basicColumPlatform = [
    {
      id: 'select',
      header: ({ table }) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
          </div>
      ),
      cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
            />
          </div>
      ),
    },
    {
      header: 'Photo',
      accessorKey: 'photo',
      enableSorting: false,
      cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)} alt={info.getValue()}/></div>
    },
    {
      header: 'Nume',
      accessorKey: 'name',
    },
    {
      header: 'Prenume',
      accessorKey: 'surname'
    },
    {
      header: 'Tara',
      accessorKey: 'city'
    },
    {
      header: 'Data inregistrari',
      enableSorting: false,
      accessorKey: 'data',
    },
    {
      header: 'Domeniu',
      accessorKey: 'domain'
    },
    {
      header: 'Limba',
      accessorKey: 'language'
    },
    {
      header: 'Timp',
      accessorKey: 'time',
      enableSorting: false,
      cell: (info) => <span className="hours">{info.getValue()}</span>
    }
  ];

  const basicColumCourseAverage = [
    {
      id: 'select',
      header: ({ table }) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
          </div>
      ),
      cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
            />
          </div>
      ),
    },
    {
      header: 'Nume curs',
      accessorKey: 'nameCourse',
    },
    {
      header: 'Tara',
      accessorKey: 'city'
    },
    {
      header: 'Data inregistrari',
      enableSorting: false,
      accessorKey: 'data',
    },
    {
      header: 'Domeniu',
      accessorKey: 'domain'
    },
    {
      header: 'Limba',
      accessorKey: 'language'
    },
    {
      header: 'Nota Medie',
      accessorKey: 'average',
      enableSorting: false,
      cell: (info) => <span className="average">{info.getValue()}</span>
    }
  ];

  const basicColumQuizzAverage = [
    {
      header: 'Nume test',
      accessorKey: 'nameCourse',
    },
    {
      header: 'Realizat',
      enableSorting: false,
      accessorKey: 'data',
    },
    {
      header: 'Domeniu',
      accessorKey: 'domain'
    },
    {
      header: 'Limba',
      accessorKey: 'language'
    },
    {
      header: 'Scorul',
      accessorKey: 'score'
    }
  ];

  const basicColumQuizzAverageTime = [
    {
      header: 'Nume test',
      accessorKey: 'nameCourse',
    },
    {
      header: 'Realizat',
      enableSorting: false,
      accessorKey: 'data',
    },
    {
      header: 'Domeniu',
      accessorKey: 'domain'
    },
    {
      header: 'Limba',
      accessorKey: 'language'
    },
    {
      header: 'Timpul',
      accessorKey: 'time'
    }
  ];

  function IndeterminateCheckbox({
                                   indeterminate,
                                   className = '',
                                   ...rest
                                 }) {
    const ref = React.useRef(!null);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
  }

  const dataChart = [
    ["Task", "Hours per Day"],
    ["Work", 15],
    ["Watch TV", 12],
    ["Sleep", 22],
    ["Sleep1", 35],
  ];

  const options = {
    is3D: true,
    pieHole: 0.4,
    legend: {position: 'bottom'},
    colors: ["#00BEFF","#FFB600","#2EC086","#F4478D"],
    slices: {
      12: { offset: 0.2 },
      15: { offset: 0.3 },
      22: { offset: 0.5 },
      35: { offset: 0.6 },
    },
  };

  return (
      <section className="statistics">
        <div className="trainer-statistic">
          <div className="profile-filter">
            <div className="filter-component">
              <span className="filter-component-training">Training</span>
            </div>
            <div className="filter-component">
              <Input placeholder="Search" id="KeyWord" text="Key Word" value={search}
                     onChangeInput={handleChangeSearch} icon={faPlus}/>
            </div>

            <div className="break"></div>
            <div className="filter-component">
              <DatePickerInput valueDateTo={dataTo} valueDateFrom={dataFrom}
                               onChangeDateTo={handleChangeDataTo} onChangeDateFrom={handleChangeDataFrom}/>
            </div>
          </div>
          <div className="table-statistic">
            <span className="trainer-statistic-title">Rata de finalizare curs</span>
            <BasicTable basic_column={basicColumCourse} data={dataCourse} className="trainer-statistics-finalization-course clientColor2" />
          </div>

          <div className="table-statistic">
            <span className="trainer-statistic-title">Timpul petrecut pe platforma</span>
            <BasicTable basic_column={basicColumPlatform} data={dataCourse} className="trainer-statistics-total-hours clientColor5" />
          </div>

          <div className="table-statistic">
            <span className="trainer-statistic-title">Evaluarile cursului</span>
            <BasicTable basic_column={basicColumCourseAverage} data={dataCourse} className="trainer-statistics-total-hours clientColor4" />
          </div>
          <div className="charts">
            <div className="chart">
              <Chart
                  chartType="PieChart"
                  data={dataChart}
                  options={options}
                  width={"100%"}
                  height={"400px"}
              />
            </div>
            <div className="chart">
              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "80%",
                      color: "tan"
                    }
                  ]}
              />

              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "65%",
                      color: "tan"
                    }
                  ]}
              />

              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "73%",
                      color: "tan"
                    }
                  ]}
              />

              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "84%",
                      color: "tan"
                    }
                  ]}
              />
            </div>
          </div>
        </div>
        <div className="quizz-statistic">
          <div className="profile-filter">
            <div className="filter-component">
              <span className="filter-component-training">Teste</span>
            </div>
            <div className="filter-component">
              <Input placeholder="Search" id="KeyWord" text="Key Word" value={search}
                     onChangeInput={handleChangeSearch} icon={faPlus}/>
            </div>

            <div className="break"></div>
            <div className="filter-component">
              <DatePickerInput valueDateTo={dataTo} valueDateFrom={dataFrom}
                               onChangeDateTo={handleChangeDataTo} onChangeDateFrom={handleChangeDataFrom}/>
            </div>
          </div>

          <div className="quizz-statistic-table">
            <div className="table-statistic">
              <span className="trainer-statistic-title">Scroll mediu la teste</span>
              <BasicTable basic_column={basicColumQuizzAverage} data={dataCourse} className="clientColor2" />
            </div>

            <div className="table-statistic">
              <span className="trainer-statistic-title">Timpul mediu pentru test</span>
              <BasicTable basic_column={basicColumQuizzAverageTime} data={dataCourse} className="clientColor5" />
            </div>
          </div>
          <div className="charts">
            <div className="chart">
              <Chart
                  chartType="PieChart"
                  data={dataChart}
                  options={options}
                  width={"100%"}
                  height={"400px"}
              />
            </div>
            <div className="chart">
              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "80%",
                      color: "tan"
                    }
                  ]}
              />

              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "65%",
                      color: "tan"
                    }
                  ]}
              />

              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "73%",
                      color: "tan"
                    }
                  ]}
              />

              <ProgressBar
                  label="Infografic"
                  visualParts={[
                    {
                      percentage: "84%",
                      color: "tan"
                    }
                  ]}
              />
            </div>
          </div>
        </div>
      </section>
  );
}

export default MyStatistic;
