import React, {useEffect, useState} from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Dropzone from "components/Dropzone/Dropzone";
import {useDispatch, useSelector} from "react-redux";
import { advisersCreateActions } from "../../../../../redux/actions/advisersApi";
import {useNavigate} from "react-router-dom";

const AdvisersAdd = () => {
  const auth = useSelector(state => state.auth);
  const [token, setToken] = useState(auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if(token === null){
      setToken(localStorage.getItem('token'));
    }
  },[token, setToken]);

  const handleClose = () => {
    navigate('/dashboard/advisers');
  }

  const SignupSchema = Yup.object({
    name: Yup.string()
        .min(2,'Name must be 2 characters or more')
        .max(150,'Name must be 150 characters or less')
        .required('Name is a required field'),
    surname: Yup.string()
        .min(2,'surname must be 2 characters or more')
        .max(150,'Surname must be 150 characters or less')
        .required('Surname is a required field'),
  });

  return (
      <section className="add-article">
        <h2 className="title-head">Adauga un nou Consultant</h2>

        <section className="profile-form-section">
          <Formik  initialValues={
            {
              name: '',
              surname: '',
              phone: '',
              image: null,
              facebookUrl: '',
              twitterUrl: '',
              linkedInUrl: '',
              pinterestUrl: '',
              youtubeUrl: '',
              tiktokUrl: '',
            }
          }
                   validationSchema={SignupSchema}
                   onSubmit={(values) => {
                     const data = {
                       data: values,
                       token: token
                     }

                     dispatch(advisersCreateActions(data)).then(navigate('/dashboard/advisers'));
                   }}
          >
            {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className="form-input">
                    <Field type="text" name="name" placeholder="Name" />
                    <ErrorMessage name="name" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="surname" placeholder="Surname" />
                    <ErrorMessage name="surname" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="phone" placeholder="Phone" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="facebookUrl" placeholder="Facebook Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="twitterUrl" placeholder="Twitter Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="linkedInUrl" placeholder="LinkedIn Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="pinterestUrl" placeholder="Pinterest Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="youtubeUrl" placeholder="Youtube Url" />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="tiktokUrl" placeholder="TikTok Url" />
                  </div>

                  <div className="form-message">
                    <Dropzone image={values.image} onFileSelect={(file => setFieldValue('image', file))} />
                  </div>

                  <div className="actions-button-section">
                    <button onClick={() => handleClose()}>Close</button>
                    <button type="submit">Save</button>
                  </div>
                </Form>
            )}
          </Formik>
        </section>
      </section>
  );
}

export default AdvisersAdd;
