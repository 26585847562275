import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCoins, faLocationArrow, faLaptop, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import "./Job.scss";
import ApplicationService from "../../service/ApplicationService";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PopUpAppliedAtJob from "../PopUp/PopUpAppliedAtJob";
import {Parser} from "html-to-react";
import {useDispatch, useSelector} from "react-redux";
import {isObject} from "formik";

const JobComponent = (props) => {
    const {Company, Jobs} = props.company;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [jobId, setJobId] = useState(false);
    const {user} = useSelector(state => state.user);
    const token = localStorage.getItem('token');
    const tokenExpire = localStorage.getItem('expireToken');
    const currency = ['EUR','USD','MDL'];

    if(tokenExpire * 1000 < Date.now()){
        localStorage.removeItem('userId');
    }

    const friendlyUrl = (url) => {

        // make the url lowercase
        let encodedUrl = url.toString().toLowerCase();

        // replace & with and
        encodedUrl = encodedUrl.split(/\&+/).join("-and-");

        // remove invalid characters
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

        // remove duplicates
        encodedUrl = encodedUrl.split(/-+/).join("-");

        // trim leading & trailing characters
        encodedUrl = encodedUrl.trim('-');

        return encodedUrl;
    }

    const applyToJob = (id, jobId, companyId) => {
        ApplicationService.applyToJob({
            user_id: id,
            job_id: jobId,
            company_id: companyId
        }).then(res => {
            toast.success("Ati aplicat cu success la job", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).catch(e => toast.warn(e.error, {
            position: toast.POSITION.TOP_CENTER,
        }));
    }

    const handleCloseModal = (value) => {
        setShowModal(value);
    }

    return (
         <div className="job-component">
             <Link to={"/"} className="flex flex-wrap align-items-center">
                <div className="logoCompany">
                    {
                        Company.Logo !== null ? <img src={Company.Logo} alt={Company.Title} className="w-full h-full object-cover"/> : <></>
                    }
                </div>
                <span className="companyDetails">
                    <span className="companyName">{Company.Title}</span>
                    <span className="data">{Company.CreatedAt}</span>
                </span>
             </Link>
             <div className="accordion" id="job">
                 { Jobs && Jobs.map((itemJob) =>
                     <div className="job-item" key={itemJob.Id}>
                         <h2 className="job-item-header" id={"heading" + friendlyUrl(Company.Title) + itemJob.Id}>
                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+ friendlyUrl(Company.Title) + itemJob.Id} aria-expanded="true"
                                     aria-controls={"collapse"+ friendlyUrl(Company.Title) + itemJob.Id}>
                                 <div className="circle"></div>{itemJob.Title}<span className="action-plus">+</span><span className="action-min">-</span>
                             </button>
                         </h2>
                         <div id={"collapse"+ friendlyUrl(Company.Title) + itemJob.Id} className="accordion-collapse collapse" aria-labelledby={"heading" + friendlyUrl(Company.Title) + itemJob.Id}
                              data-bs-parent="#accordionExample">
                             <div className="job-item-body flex flex-wrap">
                                 <div className="body-content">
                                     <div className="body-details flex flex-wrap">
                                       <span className="detailItem block">
                                          <FontAwesomeIcon icon={faCoins} className="iconStyle"/>{itemJob.Salary} {currency[itemJob.Currency - 1]}
                                          <span className="verticalLine"></span>
                                       </span>
                                         <span className="detailItem block">
                                            <FontAwesomeIcon icon={faLaptop} className="iconStyle" />{itemJob.Type.Type}
                                            <span className="verticalLine"></span>
                                          </span>
                                         <span className="detailItem block">
                                           <FontAwesomeIcon icon={faLocationArrow} className="iconStyle"/>{itemJob.Country}
                                           <span className="verticalLine"></span>
                                       </span>
                                       <span className="detailItem block">
                                           <FontAwesomeIcon icon={faBriefcase} className="iconStyle" />{Company.Title}
                                       </span>
                                     </div>
                                     <div className="job-description">
                                         {Parser().parse(itemJob.Description)}
                                     </div>
                                     <span className="block flex flex-wrap align-items-center">
                                         <span className="quality block">quality</span>
                                          {/*{itemJob.qualities.map(itemQuality => <span className="quality block">{itemQuality}</span>)}*/}
                                     </span>
                                 </div>
                                     <div className="body-actions">
                                        <button className="btn">Testeaza</button>

                                         {user.Id !== undefined ? <button className="btn" onClick={() => applyToJob(user?.Id, itemJob.Id, Company.Id)}>Aplica</button> : <button className="btn" onClick={() => { setShowModal(true); setJobId(itemJob.Id) }}>Aplica</button> }
                                     </div>
                             </div>
                         </div>
                     </div>
                 )}
                 <ToastContainer autoClose={2000} />
             </div>
             { showModal ? <PopUpAppliedAtJob showModal={handleCloseModal} jobId={jobId} companyId={Company.Id}/> : null }

         </div>
    )
}
export default JobComponent;
