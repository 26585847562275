import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTrashCan, faCircleChevronLeft, faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import ItemOfferJob from "./ItemOfferJob";
import "./JobsComponent.scss";

const OfferCategoryJob = (props) => {
    const [noInterestCV, setNoInterestCV] = useState({});
    const [CV, setCV] = useState(props.job.CV);
    const [cv, setcv] = useState(CV);
    const noInteresting = (val, idCat) => {
        const interest = (val === true) ? 1 : 0;

        // if(val === true){ console.log(val);
            const CVs = CV.filter(item => item.interesting === interest);
            setcv(CVs);
        // } else {
        //     setcv(CV);
        // }
        setNoInterestCV({[idCat] : !noInterestCV[idCat] });
    }

    return (
        <div className="category-job-component">
            <div className="category-job-component--header">
                <span className="header-title">{props.job.titleCategory}<span className="nr-cv">{props.job.CV.length}CV-uri</span></span>

                <div className="line"></div>
                <div className="filter-offers-category-job">
                   <div className="filter-offers-category-job-main-section">
                       <input id="selectAll" name="selectAll" value="1" type="checkbox"/>

                       <div className="lineHorizontal"></div>

                       <button className="clear"><FontAwesomeIcon icon={faTrashCan} />Șterge <div className="line"></div></button>

                       <div className="lineHorizontal"></div>

                       <div className="filter-form-saw">
                           <FontAwesomeIcon icon={faCheck} />

                           <select name="" id="">
                               <option value="">Markeaza</option>
                               <option value="">Ca Vazut</option>
                               <option value="">Ca Nevezut</option>
                           </select>
                       </div>
                   </div>

                <div className="statut">
                    <div className="icon"></div>
                    <select name="" id="">
                        <option value="">Statut Oferta</option>
                        <option value="">Oferte</option>
                        <option value="">Refuzuri</option>
                        <option value="">Acceptari</option>
                        <option value="">Angajari</option>
                    </select>
                </div>

              </div>
            </div>
            { cv && (cv.map(CV => <ItemOfferJob key={CV.idCv} CV={CV} /> ))}
        </div>
    );
}

export default OfferCategoryJob;
