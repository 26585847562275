import {apiClient} from "./index";

export const getCertificates = (params) => {
    return apiClient.get('/api/certificate',{params}).then(response => response.data);
}

export const addCertificate = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/certificate',data,{
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export const editCertificate = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/certificate/${Id}?_method=PUT`,data);
}

export const deleteCertificate = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/certificate/${Id}`);
}
