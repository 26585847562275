import React from 'react';
import {useSelector} from "react-redux";
import CategoryJob from "components/Profiles/JobsComponent/CategoryJob";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Applied = () => {
    const appliedJob = useSelector(state => state.appliedJobs.category);

    return (
        <>
            { appliedJob && (
                appliedJob.map(job =>
                    <CategoryJob key={job.id} job={job} />
                ))
            }
            <div className="pagination">
                <ul>
                    <li className="arrow"><FontAwesomeIcon icon={faChevronLeft} /></li>
                    <li>1</li>
                    <li className="active">2</li>
                    <li>3</li>
                    <li className="arrow"><FontAwesomeIcon icon={faChevronRight} /></li>
                </ul>
            </div>
        </>
    );
}

export default Applied;
