import React, {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPencil, faCamera} from "@fortawesome/free-solid-svg-icons";
import {convertFile2Base64} from "../../../utils/file.util";
import { changeBackground } from "../../../redux/reducer/cvReducer";
import {useDispatch, useSelector} from "react-redux";
import PersonalInformationEdit from "./PersonalInformationEdit";
import PersonalInformation from "./PersonalInformation";

const PersonalInformationCV = (props) => {
        const {user} = props;
        const [background, setBackground] = useState(user.BackgroundImage ?? null);
        const [editData, setEditData] = useState(false);
        const dispatch = useDispatch();


        const onChangeBackground = (e) =>
            e.target.files ?
                convertFile2Base64(e.target.files[0])
                    .then(
                        (valueItem) => {
                            dispatch(changeBackground({backgroundImage: valueItem}));
                            setBackground(valueItem);
                        }
                    )
                : dispatch(changeBackground({backgroundImage: ''}));

        const handleEditData = (value) => {
            setEditData(value);
        }

    return (
        <section className="main-personal-section-cv">
            <div className="head" style={{background: (background !== '' && background !== null && background !== undefined) ? `url(${background})` : ''}}>
                <div className="changePhoto">
                    <label htmlFor="changeBackground">
                        <FontAwesomeIcon icon={faCamera} />
                        <input type="file" style={{display: "none"}} onChange={(e) => onChangeBackground(e)} name="background" id="changeBackground" />
                    </label>
                </div>
            </div>
            <div className="personal-information-section">
                {
                    editData ? <PersonalInformationEdit user={user} editItem={handleEditData} background={background}/> : <PersonalInformation editItem={handleEditData} user={user}/>
                }
            </div>
            <div className="skills">
                <span>Schimbarea carierei</span>
                <span>Schimbarea carierei</span>
                <span>Schimbarea carierei</span>
            </div>
            <div className="lineBreak"></div>
        </section>
    )
}

export default PersonalInformationCV;
