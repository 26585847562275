import {apiClient} from "./index";

export const getOpportunities = (params) => {
    return apiClient.get('/api/opportunity',{params}).then(response => response.data);
}

export const getInfoOpportunity = (id) => {
    return apiClient.get(`/api/opportunity/${id}`);
}

export const addOpportunity = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/opportunity',data);
}

export const editOpportunity = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/opportunity/${Id}?_method=PUT`,data);
}

export const deleteOpportunity = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/opportunity/${Id}`);
}
