import React from 'react';
import BasicTable from "components/TableComponent/BasicTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

const CVLists = () => {
  const cvList = [
    {
      Id: 1,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      appliedCv: 2,
      pdfCV: 'https://jobhub.md',
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 2,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      appliedCv: 2,
      pdfCV: 'https://jobhub.md',
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 3,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      appliedCv: 2,
      pdfCV: 'https://jobhub.md',
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 4,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      appliedCv: 2,
      pdfCV: 'https://jobhub.md',
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 5,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      appliedCv: 2,
      pdfCV: 'https://jobhub.md',
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 6,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      appliedCv: 2,
      pdfCV: 'https://jobhub.md',
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    }
  ]

  const basicColumCVList = [
    {
      id: 'select',
      header: ({table}) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
          </div>
      ),
      cell: ({row}) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
            />
          </div>
      ),
    },
    {
      header: 'Logo',
      accessorKey: 'logo',
      enableSorting: false,
      cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)}
                                                        alt={info.getValue()}/></div>
    },
    {
      header: 'Nume',
      accessorKey: 'name',
      enableSorting: false,
    },
    {
      header: 'Prenume',
      accessorKey: 'surname',
      enableSorting: false,
    },
    {
      header: 'Tara',
      accessorKey: 'city',
      enableSorting: false,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      enableSorting: false,
    },
    {
      header: 'Aplicari',
      accessorKey: 'applied',
      enableSorting: false,
    },
    {
      header: 'CV-uri aplicate',
      accessorKey: 'appliedCv',
      enableSorting: false
    },
    {
      header: 'PDF Cv',
      accessorKey: 'pdfCV',
      enableSorting: false,
      cell: (info) => <a className="table-download" href={info.getValue()} target="_blank"><FontAwesomeIcon icon={faDownload} /></a>
    },
    {
      header: 'Data de inregistrare',
      accessorKey: 'createdAt',
      enableSorting: false
    },
    {
      header: 'Ultima logare',
      accessorKey: 'lastAuthentification',
      enableSorting: false
    }
  ];

  function IndeterminateCheckbox({
                                   indeterminate,
                                   className = '',
                                   ...rest
                                 }) {
    const ref = React.useRef(!null);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
  }


  return (
      <>
        {/*{error && <p>Something went wrong</p>}*/}
        {/*{ !loading && <Loader /> }*/}
        {
          <section className="table-JobsList">
            <BasicTable basic_column={basicColumCVList} perPage={3} data={cvList}
                        className="basic-table-JobsList clientColor2"/>
          </section>
        }
      </>
  );
}

export default CVLists;
