import React, {forwardRef, useState, useEffect} from 'react';
import DatePicker from "react-datepicker";
import {faCalendarDays, faSave, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {addNewCareer} from "../../redux/reducer/careerReducer";
import Image from "../Image/Image";
import {addNewCareerActions} from "../../redux/actions/careerApi";

const CareerAdd = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const {addButtonItem} = props;
    const [titleCompany, setTitleCompany] = useState('');
    const [job, setJob] = useState( "");
    const [typeOfJob, setTypeOfJob] = useState("");
    const [dataTo, setDataTo] = useState(new Date());
    const [dataFrom, setDataFrom] = useState( null);
    const [description,setDescription] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const handleAddCareer = () => {
        const data = {
           data: {
               titleCompany: titleCompany,
               job: job,
               dateStart: dataTo,
               dateEnd: dataFrom,
               logo: image,
               type: typeOfJob,
               description: description
           },
           token: token
        }
        dispatch(addNewCareerActions(data));
        addButtonItem(false);
    }

    const StyleDataComponent = forwardRef(({ value, onClick }, ref) => (
        <span className="data-input" onClick={onClick} ref={ref}>
          <span className="data">{value} <FontAwesomeIcon icon={faCalendarDays} /></span>
       </span>
    ));

    return (
        <div className="edit-form">
            <div className="image-edit">
                <Image value={image} onChange={(result) => setImage(result)} withBase64={true}/>
            </div>
           <input type="text" value={titleCompany} placeholder="Company Name" onChange={(e) => setTitleCompany(e.target.value)} />
           <input type="text" value={job} placeholder="Job" onChange={(e) => setJob(e.target.value)} />

            <select onChange={e => setTypeOfJob(e.target.value)} defaultValue={typeOfJob} >
               <option value={null}>Tipul Jobului</option>
               <option value={4}>Remote</option>
               <option value={3}>Temporary</option>
               <option value={2}>Part-time</option>
               <option value={1}>Full Time</option>
           </select>

            <div className="dataContainer">
                <DatePicker
                    selected={dataTo}
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => setDataTo(new Date(date))}
                    customInput={<StyleDataComponent />}
                    isClearable
                />

                <DatePicker
                    selected={dataFrom}
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => setDataFrom(new Date(date))}
                    isClearable
                    customInput={<StyleDataComponent />}
                />
            </div>

            <textarea name="" id="" cols="30" rows="10" onChange={(e) => setDescription(e.target.value) } value={description} />
            <div className="actions">
                <button onClick={() => addButtonItem(false) }><FontAwesomeIcon icon={faXmark} />Cancel</button>
                <button onClick={ handleAddCareer }><FontAwesomeIcon icon={faSave} />Save</button>
            </div>
        </div>
    )
}

export default CareerAdd;
