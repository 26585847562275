import {createAsyncThunk} from "@reduxjs/toolkit";
import {getAbout, editAbout} from "../../service/AboutService";

export const aboutGetAboutActions = createAsyncThunk(
    'about/GetAboutActions',
    async (params = {}) => {
        try {
            return await getAbout(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const aboutEditAboutActions = createAsyncThunk(
    'about/aboutEditAboutActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editAbout(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)
