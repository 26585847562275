import {createSlice} from "@reduxjs/toolkit";
import {
    cardCreateActions,
    cardDeleteActions,
    cardEditActions,
    cardGetCardActions,
} from "../actions/cardApi";

const cardReducer = createSlice({
    name: 'card',
    initialState: {
        card: [],
        error: null,
        load: false,
    },
    reducers: {
        addCard: (state) => {
            const dataCard = {
                id: state.card.length + 1,
                code: '',
                month: 0,
                year: 0,
                user_id: 0,
            }
            state.card = [...state.card, {...dataCard}];
        },

        allCards: (state, {payload}) => {
            state.card = payload;
        },
        editCard: (state, {payload}) => {
            const { id, code, month, year, user_id } = payload;
            return {
                ...state,
                card: state.card.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            code,
                            month,
                            year,
                            user_id
                        };
                    }
                    return item;
                })
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(cardGetCardActions.pending, (state, {payload}) => {
                state.error = null;
                state.load = true;
            })

            .addCase(cardGetCardActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.load = false;
                    state.error = payload.message;
                } else {
                    state.load = false;
                    state.error = null;
                    state.card = payload
                }
            })

            .addCase(cardGetCardActions.rejected, (state, {payload }) => {
                state.load = false;
                state.error = payload;
            })

            .addCase(cardEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.load = true;
            })

            .addCase(cardEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.load = false;
                        state.error = payload.message;
                    } else {
                        state.load = false;
                        state.error = null;
                    }
            })

            .addCase(cardEditActions.rejected, (state, {payload}) => {
                state.load = false;
                state.error = payload.message
            })

            .addCase(cardCreateActions.pending, (state, {payload}) => {
                state.load = true;
                state.error = null;
            })

            .addCase(cardCreateActions.fulfilled, (state, {payload}) => {
                state.load = false;
                if(payload.status === false) {
                    state.load = false;
                    state.error = payload.message;
                } else {
                    state.load = false;
                    state.error = null;
                }
            })

            .addCase(cardCreateActions.rejected, (state, {payload}) => {
                state.load = false;
                state.error = payload.message
            })

            .addCase(cardDeleteActions.pending, (state, {payload}) => {
                state.load = true;
                state.error = null;
            })

            .addCase(cardDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.load = false;
                    state.error = null;
                } else {
                    state.load = false;
                    state.error = payload.message;
                }
            })

            .addCase(cardDeleteActions.rejected, (state, {payload}) => {
                state.load = false;
                state.error = payload;
            })
    }
});

export default cardReducer.reducer;
export const { addCard, allCards, editCard } = cardReducer.actions;
