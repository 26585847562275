import React, { useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Field, Formik, Form, ErrorMessage} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookF, faGooglePlusG, faTwitter } from "@fortawesome/free-brands-svg-icons";
import './Form.scss';
import {useDispatch, useSelector} from "react-redux";
import {loginActions} from "../../redux/actions/authApi";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

const LoginComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { token, error, refreshToken } = useSelector(state => state.auth);

    const SignupSchema = Yup.object({
        username: Yup.string()
            .email(t('Login.invalidEmail'))
            .min(2,t('Email.minCharacterError'))
            .max(100,t('Login.maxCharacterError'))
            .required(t('Login.errorRequiredEmail')),
        password: Yup.string()
            .required(t('Login.errorRequiredPassword'))
            .min(8, t('Login.errorPasswordMin'))
            .matches(/[0-9]/, t('Login.errorPasswordDigit'))
            .matches(/[a-z]/, t('Login.errorPasswordLowerCase'))
            .matches(/[A-Z]/, t('Login.errorPasswordUppercase')),
    });

    useEffect(() => {
        if(token){
            navigate('/dashboard');
        }
    }, [token])

    return (
        <div className="login-component form-component">
            <Formik  initialValues={
                {
                    username: '',
                    password: '',
                }
            }
                     validationSchema={SignupSchema}
                     onSubmit={(values) => {
                         console.log(values);
                         const param = {
                             'grant_type': 'password',
                             'client_id': process.env.REACT_APP_CLIENT_ID,
                             'client_secret': process.env.REACT_APP_CLIENT_SECRET,
                             'username': values.username,
                             'password': values.password
                         }

                         dispatch(loginActions(param));
                     }}
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <Form className="Form loginForm" autoComplete="off">
                        <div className="form-input">
                            <Field type="text" name="username" placeholder={t('Login.Email')} />
                            <ErrorMessage name="username" render={msg => <span className="form-input-error">{msg}</span>} />
                        </div>

                        <div className="form-input">
                            <Field type="password" name="password" placeholder={t('Login.Password')} />
                            <ErrorMessage name="password" render={msg => <span className="form-input-error">{msg}</span>} />
                            {(!errors.message && error?.message) && <span className="form-input-error">{t('Login.errorForm')}</span> }
                        </div>

                        <div className="text-center flex align-items-center justify-center">
                            <Link
                                to="/forgot-password"
                                className="form-link"
                            >
                                {t('Login.ForgotPassword')}
                            </Link>
                        </div>

                        <button
                            type="submit"
                            className="form-button">
                            {t('Login.Title')}
                        </button>
                    </Form>
                )}
            </Formik>
                {/*<div className="other-section">*/}
                {/*    <div className="horizontalLine"></div>*/}
                {/*    Or*/}
                {/*    <div className="horizontalLine"></div>*/}
                {/*</div>*/}

                {/*<div className="social-login-section">*/}
                {/*    <button*/}
                {/*        className="socialButton">*/}
                {/*        <FontAwesomeIcon icon={faFacebookF} />*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className="socialButton">*/}
                {/*        <FontAwesomeIcon icon={faGooglePlusG} />*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className="socialButton">*/}
                {/*        <FontAwesomeIcon icon={faTwitter} />*/}
                {/*    </button>*/}
                {/*</div>*/}
                {/*<button className="form-button">{t('Login.AuthWithMPass')}</button>*/}
                <p className="dont-have-account">{t('Login.DontHaveAcount')} <Link to={"/registration"} className="font-medium hover:underline">{t('Login.SignUp')}</Link></p>
        </div>
    )
}

export default LoginComponent;
