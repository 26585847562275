import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import BlogItemGrid from "../../../components/BlogComponents/BlogItem/BlogItemGrid";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {blogGetBlogsActions} from "../../../redux/actions/blogApi";

const BlogPage = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams()
  const {loading, blog} = useSelector(state => state.blog);

  useEffect(() => {
    if(queryParameters.get('categoryId') !== undefined){ console.log('dsad');
      dispatch(blogGetBlogsActions({expand: 'user,tags,category', status: 5, category_id: queryParameters.get('categoryId')}));
    } else {
      dispatch(blogGetBlogsActions({expand: 'user,tags,category', status: 5}));
    }
  },[queryParameters]);

  console.log();
    return (
        <>
          {loading && <Loader />}
          {!loading &&
              <section className="blog-page">
                <div className="head">
                  <h1 className="title-head">{t('Home.ourBlogTitle')}</h1>
                </div>
                <div className="blog-section container">
                  <div className="flex flex-wrap justify-content-center">
                    {blog.map(item => <BlogItemGrid blog={item} key={item.Id}/>)}
                  </div>
                </div>
              </section>
          }
        </>
    );
}

export default BlogPage;
