import {apiClient} from "./index";

export const getAdvisers = (params) => {
    return apiClient.get('/api/advisers',{params}).then(response => response.data);
}

export const addAdvisers = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/advisers',data);
}

export const editAdvisers = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/advisers/${Id}?_method=PUT`,data);
}

export const deleteAdvisers = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/advisers/${Id}`);
}
