import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faSliders, faMagnifyingGlass, faMoneyBill1Wave, faPlay} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import CoachingTeam from "components/Coaching/Coaching";
import HowItWork from "components/HowItWork/HowItWork";
import Accordion from "components/AccordionComponent/Accordion";
import TestimonialSliderMultiple from "components/TestimonialSlider/TestimonialSliderMultiple";

const Coaching = () => {
    let [teams, setTeams] = useState([]);

    useEffect(() => {
        setTeams([
            {
                id: 0,
                name: 'Cristina Scutaru',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Chisinau',
                sex: 'F',
                age: 42,
                language: ["RO","RU","GB"]
            },
            {
                id: 1,
                name: 'Violeta Mates',
                job: 'Management',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Cluj-Napoca',
                sex: 'F',
                age: 31,
                language: ["RO","RU","IT"]
            },
            {
                id: 2,
                name: 'Roxana Sabu',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Bucuresti',
                sex: 'F',
                age: 29,
                language: ["RO","RU","FR"]
            },
            {
                id: 3,
                name: 'Ionel Androne',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Galati',
                sex: 'M',
                age: 30,
                language: ["RO","GB","FR"]
            },
            {
                id: 4,
                name: 'Roxana Borcsa',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Chisinau',
                sex: 'F',
                age: 25,
                language: ["RO","IT"]
            },
            {
                id: 5,
                name: 'Mihaela Avram',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Chisinau',
                sex: 'F',
                age: 20,
                language: ["RO","GB"]
            },
            {
                id: 6,
                name: 'Diana-Mihaela',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Sibiu',
                sex: 'F',
                age: 34,
                language: ["RU","FR"]
            },
            {
                id: 7,
                name: 'Cristina Eremia',
                job: 'Resurse Umane',
                image: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
                location: 'Orhei',
                sex: 'F',
                age: 38,
                language: ["RO"]
            }
        ])
    },[setTeams])

    const [search, setSearch] = useState(false);
    const [language, setLanguage] = useState();
    const [domain, setDomain] = useState();
    const [subDomain, setSubDomain] = useState();
    const state = useLocation();

    const availableOptions = [
        {
            value: '',
            label: 'Domeniu'
        },
        {
            value: 0,
            label: 'IT'
        },
        {
            value: 1,
            label: 'Business'
        },
        {
            value: 2,
            label: 'Marketing'
        }
    ];
    const subDomainOptions = [
        {
            value: '',
            label:'Sub domeniu'
        },
        {
            value: 0,
            label: 'WEB'
        },
        {
            value: 1,
            label: 'Business'
        },
        {
            value: 2,
            label: 'Marketing'
        }
    ];

    const allLanguages = [
        {
            value: '',
            label: 'Limba Sesiunii'
        },
        {
            value: 'RO',
            label: 'Romana'
        },
        {
            value: 'RU',
            label: 'Rusa'
        },
        {
            value: 'GB',
            label: 'Engleza'
        },
        {
            value: 'IT',
            label: 'Italiana'
        },
        {
            value: 'FR',
            label: 'Franceza'
        }
    ];

    const HowItWorkData = [
        {
           title: "Selectează un serviuciu",
           subTitle: "Identifică obiectivul de a cărui îndeplinire depinde evoluția ta"
        },
        {
            title: "Selectează un specialist",
            subTitle: "Verifică profilurile specialiștilor recomandați și alege-ți favoritul"
        },
        {
            title: "Programează o sesiune gratuită de 30 min",
            subTitle: "Verifică profilurile specialiștilor recomandați și alege-ți favoritul"
        },
    ]

    const faqData = [
        {
            id: 0,
            title: "Ce include sesiunea gratuită?",
            text: "Comunitatea de coaches certificați Bestjobs reflectă zeci ani de experiență în diverse domenii. Specialiștii noștri verificați, demonstrează rezultate, iar scopul lor este ca tu să îți atingi obiectivele."
        },
        {
            id: 1,
            title: "Care este prețul abonamentului?",
            text: "Comunitatea de coaches certificați Bestjobs reflectă zeci ani de experiență în diverse domenii. Specialiștii noștri verificați, demonstrează rezultate, iar scopul lor este ca tu să îți atingi obiectivele."
        },
        {
            id: 2,
            title: "Cum pot renunța la abonament?",
            text: "Comunitatea de coaches certificați Bestjobs reflectă zeci ani de experiență în diverse domenii. Specialiștii noștri verificați, demonstrează rezultate, iar scopul lor este ca tu să îți atingi obiectivele."
        }
    ]

    const handleSubmit = () => {
        setSearch(true);

        if(language !== undefined){
            const searchTeams = teams.filter(team => team.language.includes(language));
            setTeams(searchTeams)
        }
    }

    return (
        <main className="coaching-page">
            { search ?
                   <section className="coaching-page-search container">
                       <section className="coaching-page-search-header">
                           <h1 className="title">Coaching</h1>
                           <span className="filters">Filters <span className="icon"><FontAwesomeIcon icon={faSliders} /></span></span>
                       </section>
                       <section className="coaching-page-search-content">
                           { teams.map((team) => <CoachingTeam team={team} key={team.id} /> )}
                       </section>
                       <div className="action">
                           <Link to="" className="btn-load-more">Load More</Link>
                       </div>
                   </section>
                : <>
                    <section className="header_section container">
                        <div className="header_section_data">
                            <h1 className="title">Alege un specialist si atinge-ti obiectivele</h1>
                            <span className="promo">Cu o sesiune gratuita, de 30 min <br/> de coaching individual</span>
                            <button className="start"><FontAwesomeIcon icon={faPlay} />Start</button>
                        </div>

                        <div className="header_section_image">
                           <div className="circle"></div>
                           <div className="circle_image">
                               <img src={require("@images/happy-young-man.png")} alt="Coaching" />
                           </div>
                           <button className="start"><FontAwesomeIcon icon={faPlay} />Start</button>
                        </div>
                    </section>

                    <section className="area_job container">
                        <div className="area_job_data">
                            <div className="area_job_data_title">
                                <div className="icon">
                                    <img src={require("@images/yes.png")} alt="Alege o arie la care lucrezi cu un coach" />
                                </div>
                                <h3 className="title-head">Alege o arie la care lucrezi cu un coach</h3>
                            </div>


                                <ul className="flex flex-wrap areas-job">
                                    <li className="area-item">Schimbarea carierei</li>
                                    <li className="area-item">Promovare si crestere salariala</li>
                                    <li className="area-item">Cresterea productivitatii</li>
                                    <li className="area-item">Abilitati de management</li>
                                    <li className="area-item">Bunastarea generala</li>
                                    <li className="area-item">Anxietate si burnout</li>
                                </ul>
                        </div>
                        <div className="area_job_filter">
                                <div className="icon">
                                    <img src={require("@images/yes.png")} alt="Alege o arie la care lucrezi cu un coach" />
                                </div>
                                <h3 className="title-head">Caută și selectează specialistul după domeniu</h3>

                            <div className="form">
                                <select
                                    id="domain"
                                    name="domain"
                                    autoComplete="domain"
                                    onChange={selected => setDomain(selected.target.value)}
                                    className="filter-input"
                                >
                                    {availableOptions.map(item => <option value={item.value}>{item.label}</option> )}
                                </select>
                                <select
                                    id="subDomain"
                                    name="subDomain"
                                    autoComplete="profile"
                                    onChange={selected => setSubDomain(selected.target.value)}
                                    className="filter-input"
                                >
                                    {subDomainOptions.map(item => <option value={item.value}>{item.label}</option> )}
                                </select>
                                <select
                                    id="languages"
                                    name="languages"
                                    autoComplete="languages"
                                    onChange={selected => setLanguage(selected.target.value)}
                                    className="filter-input"
                                >
                                    {allLanguages.map(item => <option value={item.value}>{item.label}</option> )}
                                </select>
                                <button type="submit" name="button" onClick={handleSubmit} className='btn'><FontAwesomeIcon icon={faMagnifyingGlass} /> Cauta un specialist</button>
                            </div>
                        </div>
                    </section>

                    <section className="how-work-section">
                        <HowItWork title="Cum funcționează?" data={HowItWorkData} />
                    </section>

                    <section className="session-section container">
                        <h2 className="title-head">Sesiuni 1 la 1</h2>
                        <span className="free-text">După sesiunea gratuită: 2 X 45 min pe lună</span>
                        <span className="other-section">
                            <span className="credit">90 credite</span>
                            <span className="credit">1 credit = 1 euro + TVA</span>
                        </span>
                        <button className="btn"><FontAwesomeIcon icon={faMoneyBill1Wave} />Cumpără credite</button>
                    </section>

                    <section className="container section-specialist">
                        <h2 className="title-head">Cunoaște-ți specialiștii</h2>
                        <div className="description">
                           <span>Comunitatea de coaches certificați Bestjobs reflectă zeci ani de experiență în diverse domenii. Specialiștii noștri verificați, demonstrează rezultate, iar scopul lor este ca tu să îți atingi obiectivele.</span>
                        </div>

                        <div className="video">
                            <iframe src="https://www.youtube.com/embed/urZzqS0kVfw"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        <Link to={"/"} class="tryFree">Încearcă gratuit</Link>
                        <button className="start"><FontAwesomeIcon icon={faPlay} />Start</button>
                    </section>

                    <section className="testimonial-section container">
                        <h3 className="title-head">Ce spun utilizatorii noștri?</h3>

                        <div className="testimonial-content">
                           <TestimonialSliderMultiple />
                        </div>
                    </section>

                    <section className="container faq">
                        <h2 className="title-head">FAQ</h2>
                        <section className="faq_list">
                            {
                                faqData.map(item => <Accordion data={item}/>)
                            }
                        </section>
                    </section>
                </>
            }
        </main>
    )
}

export default Coaching;
