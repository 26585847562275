import React from 'react'
import ExperienceComponent from "../../ExperienceComponent/ExperienceComponent";

const MyExperience = () => {
    return (
        <ExperienceComponent perPage={3} />
    )
}

export default MyExperience;
