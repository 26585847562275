import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/styles/site.scss';
import App from './App';
import { Provider } from 'react-redux';
import {store} from "./redux/store";
import './i18n';

axios.get('https://ipapi.co/json/').then(res => sessionStorage.setItem('country',res.data.country_name)).catch(e => console.log(e.error));

axios.defaults.baseURL = `${process.env.REACT_APP_API}/`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Suspense fallback="loading">
            <App />
        </Suspense>
    </Provider>
);
