import React, {useEffect} from 'react';
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import Loader from "components/Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlay} from "@fortawesome/free-solid-svg-icons";
const Courses = () => {
  const dispatch = useDispatch();

  const courses = [
    {
      Id: 1,
      link: 'https://jobhub.md/courses',
      title: 'Laravel Courses',
      language: 'RO',
      nrTraining: 7,
      nrResources: 6,
      nrApplied: 8,
      contactPerson: {
        Name: 'Tudor Ursachi',
        Logo: 'career.jpeg'
      },
      dataCreated: '12.12.2024',
      updatedAt: '12.12.2025'
    },
    {
      Id: 2,
      link: 'https://jobhub.md/courses',
      title: 'Laravel Courses',
      language: 'RO',
      nrTraining: 7,
      nrResources: 6,
      nrApplied: 8,
      contactPerson: {
        Name: 'Tudor Ursachi',
        Logo: 'career.jpeg'
      },
      dataCreated: '12.12.2024',
      updatedAt: '12.12.2025'
    },
    {
      Id: 3,
      link: 'https://jobhub.md/courses',
      title: 'Laravel Courses',
      language: 'RO',
      nrTraining: 7,
      nrResources: 6,
      nrApplied: 8,
      contactPerson: {
        Name: 'Tudor Ursachi',
        Logo: 'career.jpeg'
      },
      dataCreated: '12.12.2024',
      updatedAt: '12.12.2025'
    },
    {
      Id: 4,
      link: 'https://jobhub.md/courses',
      title: 'Laravel Courses',
      language: 'RO',
      nrTraining: 7,
      nrResources: 6,
      nrApplied: 8,
      contactPerson: {
        Name: 'Tudor Ursachi',
        Logo: 'career.jpeg'
      },
      dataCreated: '12.12.2024',
      updatedAt: '12.12.2025'
    },
    {
      Id: 5,
      link: 'https://jobhub.md/courses',
      title: 'Laravel Courses',
      language: 'RO',
      nrTraining: 7,
      nrResources: 6,
      nrApplied: 8,
      contactPerson: {
        Name: 'Tudor Ursachi',
        Logo: 'career.jpeg'
      },
      dataCreated: '12.12.2024',
      updatedAt: '12.12.2025'
    },
    {
      Id: 6,
      link: 'https://jobhub.md/courses',
      title: 'Laravel Courses',
      language: 'RO',
      nrTraining: 7,
      nrResources: 6,
      nrApplied: 8,
      contactPerson: {
        Name: 'Tudor Ursachi',
        Logo: 'career.jpeg'
      },
      dataCreated: '12.12.2024',
      updatedAt: '12.12.2025'
    }
  ]

  const basicColumCoursesList = [
    {
      id: 'select',
      header: ({table}) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
          </div>
      ),
      cell: ({row}) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
            />
          </div>
      ),
    },
    {
      header: 'Link',
      accessorKey: 'link',
      enableSorting: false,
      cell: (info) => <a className="table-download" href={info.getValue()} target="_blank"><FontAwesomeIcon icon={faCirclePlay} /></a>
    },
    {
      header: 'Title',
      accessorKey: 'title',
      enableSorting: true,
    },
    {
      header: 'Language',
      accessorKey: 'language',
      enableSorting: false,
    },
    {
      header: 'Nr. of Trainings',
      accessorKey: 'nrTraining',
      enableSorting: false,
    },
    {
      header: 'Nr. of Resources',
      accessorKey: 'nrResources',
      enableSorting: false
    },
    {
      header: 'Nr of Assign',
      accessorKey: 'nrApplied',
      enableSorting: false,
    },
    {
      header: 'Creation Data',
      accessorKey: 'dataCreated',
      enableSorting: false,
    },
    {
      header: 'Last Modification data',
      accessorKey: 'updatedAt',
      enableSorting: false
    },
    {
      header: 'Creator Trainer',
      accessorKey: 'contactPerson',
      enableSorting: false,
      cell: (info) =>
          <div className="table-name-image">
            <div className="table-image">
              <img src={require(`@images/${info.getValue().Logo}`)} alt={info.getValue().Name}/>
            </div>
            <span className="name">{info.getValue().Name}</span>
          </div>
    }
  ];

  function IndeterminateCheckbox({
                                   indeterminate,
                                   className = '',
                                   ...rest
                                 }) {
    const ref = React.useRef(!null);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
  }


  return (
      <>
            <section className="table-CoursesList">
              <BasicTable basic_column={basicColumCoursesList} perPage={3} data={courses}
                          className="basic-table-JobsList clientColor2"/>
            </section>
      </>
  );
}

export default Courses;
