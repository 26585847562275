import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookOpenReader, faBriefcase, faBuilding, faCertificate, faChartSimple, faCheckDouble, faDollarSign,
  faFile,
  faFingerprint, faFolderPlus, faGear, faGraduationCap, faHandshake, faLanguage, faLightbulb, faMessage, faMoneyCheck,
  faNewspaper, faPencil, faRightFromBracket,
  faStar, faUserCheck, faUserGear, faUserGraduate, faUserPen, faUsers
} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../redux/reducer/authReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import ActionButton from "../Filters/ActionsButton/ActionButton/ActionButton";
import {faCircleCheck, faFaceSmile, faTrashCan} from "@fortawesome/free-regular-svg-icons";

const SidebarTrainer = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState('');

  const logoutHandle = () => {
    dispatch(logout());
    navigate("/login");
  }

    return (
        <>
          <div className="sidebar trainer">
            <div className="sidebar__profile">
              <div className="sidebar__profile__user">
                <div className="profile-image">
                  <img
                      src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                      className="border-1 shadow-sm border-white border- rounded-full w-full h-full object-cover"
                      alt="Full Name"/>
                </div>
                <span className="block user_name">Name Surname</span>
                <span className="block country">Moldova</span>
              </div>


              <div className="sidebar__profile__data">
                <span>Aplicari: 12</span>
                <span>Cursuri: 35</span>
              </div>
            </div>

            <div className="sidebar__menu">
              <div className="sidebar__menu__items">
                <NavLink to={"/dashboard/registration-course"} className="item-menu" onClick={() => setPage('registration-course')}><span><FontAwesomeIcon icon={faPencil}/> Inscrieri</span></NavLink>
                <NavLink to={"/dashboard/active-course"} className="item-menu" onClick={() => setPage('active-course')}><span><FontAwesomeIcon icon={faBookOpenReader}/> Cursuri active</span></NavLink>
                <NavLink to={"/dashboard/quiz"} className="item-menu" onClick={() => setPage('create-quiz')}><span><FontAwesomeIcon icon={faCheckDouble}/>Teste</span></NavLink>
                <NavLink to={"/dashboard/user/profile"} className="item-menu"><span><FontAwesomeIcon icon={faFingerprint}/> Informație personală</span></NavLink>
                <NavLink to={"/dashboard/graduates"} className="item-menu"><span><FontAwesomeIcon icon={faGraduationCap}/> Absolventi</span></NavLink>
                <NavLink to={"/dashboard/interview-ready"} className="item-menu"><span><FontAwesomeIcon icon={faUserCheck}/> Pregatire interviu</span></NavLink>
                <NavLink to={"/dashboard/personal-coaching"} className="item-menu"><span><FontAwesomeIcon icon={faLightbulb}/>Personal coaching</span></NavLink>
                <NavLink to={"/dashboard/collaboration-offers"} className="item-menu"><span><FontAwesomeIcon icon={faHandshake}/>Oferte de colaborare</span></NavLink>
                <NavLink to={"/dashboard/successful-hires"} className="item-menu"><span><FontAwesomeIcon icon={faFaceSmile}/>Angajari reusite</span></NavLink>
                <NavLink to={"/dashboard/certificate"} className="item-menu"><span><FontAwesomeIcon icon={faCertificate}/>Certificari</span></NavLink>
                <NavLink to={"/dashboard/reviews"} className="item-menu" onClick={() => setPage('my-statistic')}><span><FontAwesomeIcon icon={faMessage}/>Recenzii</span></NavLink>
                <NavLink to={"/dashboard/banking"} className="item-menu" onClick={() => setPage('my-statistic')}><span><FontAwesomeIcon icon={faDollarSign}/>Rechizite bancare</span></NavLink>
                <NavLink to={"/dashboard/my-statistic"} className="item-menu" onClick={() => setPage('my-statistic')}><span><FontAwesomeIcon icon={faChartSimple}/>Statistica</span></NavLink>
                <NavLink to={"/dashboard/about-me"} className="item-menu"><span><FontAwesomeIcon icon={faBuilding}/>Date personale</span></NavLink>
              </div>
              <div className='item-menu item-menu-exit' onClick={() => logoutHandle()}><FontAwesomeIcon icon={faRightFromBracket}/>Exit</div>
            </div>
          </div>
        </>
    );
}

export default SidebarTrainer;
