import React, {useEffect, useState} from 'react';
import {useFormikContext, Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react"
import Dropzone from "components/Dropzone/Dropzone";
import {useDispatch, useSelector} from "react-redux";
import { opportunityCreateActions } from "../../../../../redux/actions/opportunityApi";
import {useNavigate} from "react-router-dom";
import {categoryGetCategoriesActions} from "../../../../../redux/actions/categoryApi";

const OpportunityAdd = () => {
    const auth = useSelector(state => state.auth);
    const {category} = useSelector(state => state.category);
    const [token, setToken] = useState(auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(categoryGetCategoriesActions({type: 3}));
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const handleClose = () => {
        navigate('/dashboard/opportunity');
    }

    const SignupSchema = Yup.object({
        title: Yup.string()
            .min(2,'Title must be 2 characters or more')
            .max(150,'Title must be 150 characters or less')
            .required('Title is a required field'),
        altImage: Yup.string()
            .min(2, 'Alt Image must be 2 characters or more')
            .max(60, 'Alt image must be 60 characters or more'),
        metaDescription: Yup.string()
            .min(2, 'Meta Description must be 2 characters or more')
            .max(160, 'Meta Description must be 160 characters or more'),
        metaTitle: Yup.string()
            .min(2, 'Meta Title must be 2 characters or more')
            .max(60, 'Meta Description must be 60 characters or less')
    });

    return (
        <section className="add-article">
            <h2 className="title-head">Adauga un nou comunicat</h2>

            <section className="profile-form-section">
                <Formik  initialValues={
                    {
                        title: '',
                        shortDescription: '',
                        description: '',
                        status: 1,
                        tags: [],
                        category_id: null,
                        image: null,
                        altImage: '',
                        metaDescription: '',
                        metaTitle: '',
                    }
                }
                         validationSchema={SignupSchema}
                         onSubmit={(values) => {
                             const data = {
                                 data: values,
                                 token: token
                             }

                             dispatch(opportunityCreateActions(data)).then(navigate('/dashboard/opportunity'));
                         }}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                            <div className="form-input">
                                <Field type="text" name="title" placeholder="Title" />
                                <ErrorMessage name="title" render={msg => <div className="error-message">{msg}</div>} />
                            </div>

                            <div className="form-input">
                                <Field type="text" name="altImage" placeholder="Image Alt title" />
                                <ErrorMessage name="altImage" render={msg => <div className="error-message">{msg}</div>} />
                            </div>

                            <div className="form-input">
                                <Field type="text" name="metaTitle" placeholder="Meta Title" />
                                <ErrorMessage name="metaTitle" render={msg => <div className="error-message">{msg}</div>} />
                            </div>

                            <div className="form-input">
                                <Field type="text" name="metaDescription" placeholder="Meta Description" />
                                <ErrorMessage name="metaDescription" render={msg => <div className="error-message">{msg}</div>} />
                            </div>

                            <div className="form-input">
                                <Field as="select" name="status">
                                    <option value="1">Deleted</option>
                                    <option value="2">Archived</option>
                                    <option value="3">Draft</option>
                                    <option value="4">Pending</option>
                                    <option value="5">Published</option>
                                </Field>

                                <ErrorMessage name="status" render={msg => <div className="error-message">{msg}</div>} />
                            </div>

                            {
                                category.length &&
                                <div className="form-input">
                                    <Field as="select" name="category_id">
                                        {category.map(item => <option value={item.Id} key={item.Id}>{item.Title}</option>) }
                                    </Field>

                                    <ErrorMessage name="category_id" render={msg => <div className="error-message">{msg}</div>} />
                                </div>
                            }


                            {/*<div className="form-input">*/}
                            {/*    <Field name="tags" as="select">*/}
                            {/*        {({ field, form, meta }) => (*/}
                            {/*           // <Select options={item?.Tags.map((value) => ({ value: value, label: value, key: value}))} {...field} isMulti onChange={(selectedOptions) => setFieldValue('tags',selectedOptions)} />*/}
                            {/*        ) }*/}
                            {/*    </Field>*/}
                            {/*</div>*/}

                            <div className="form-message">
                                <Dropzone image={values.image}  onFileSelect={(file => setFieldValue('image', file))} />
                            </div>
                            <div className="form-message">
                                <CKEditor
                                    editor={ClassicEditor}
                                    onInit={editor => {}}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setFieldValue('shortDescription', data);
                                    }}
                                    data={values.shortDescription}
                                />
                            </div>
                            <div className="form-message">
                                <CKEditor
                                    editor={ClassicEditor}
                                    onInit={editor => {}}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setFieldValue('description', data);
                                    }}
                                    data={values.description}
                                />
                            </div>
                            <div className="actions-button-section">
                                <button onClick={() => handleClose()}>Close</button>
                                <button type="submit">Save</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </section>
        </section>
    );
}

export default OpportunityAdd;
