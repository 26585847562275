import React from 'react'
import InterestsComponent from "../../InterestsComponent/InterestsComponent";

const MyInterests = () => {
    return (
        <InterestsComponent />
    )
}

export default MyInterests;
