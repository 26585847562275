import React from 'react';
import '../BlogComponent.scss';
import {Link} from "react-router-dom";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {faShareNodes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {friendlyURL} from "../../../utils/string.util";
import { Parser } from 'html-to-react'
import {useTranslation} from "react-i18next";


const BlogItemGrid = ({blog}) => {
    const {t} = useTranslation();

    return (
        <div className="blog-item-grid">
            <Link to={`/blog/${friendlyURL(blog.Title)}/${blog.Id}`}>
                <div className="blog-item__image relative">
                    {blog.Image &&
                        <img className="w-full h-full object-cover" src={blog.Image} alt={blog.AltTitle} /> }

                    <span className="data absolute">
                        {blog.CreatedAt}
                    </span>
                    <span className="eye absolute">
                      <FontAwesomeIcon icon={faEye} style={{marginRight: '6px'}} />{blog.NrOfViews}
                    </span>
                    <span className="share absolute">
                      <FontAwesomeIcon icon={faShareNodes} style={{marginRight: '6px'}} />{0}
                    </span>
                </div>
            </Link>
            <div className="blog-item__content">
                <Link to={`blog/${friendlyURL(blog.Title)}/${blog.Id}`}><span className="blog-title">{blog.Title}</span></Link>
                  <p>{Parser().parse(blog.ShortDescription)} <Link className="read-more" to={`blog/${friendlyURL(blog.Title)}/${blog.Id}`}>...{t('Home.btnReadMore')}</Link></p>
            </div>
        </div>
    );
}

export default BlogItemGrid
