import React from 'react';
import { useFormInputValidation } from "react-form-input-validation";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./applied-popup.scss";
import ApplicationService from "../../service/ApplicationService";

const PopUpAppliedAtJob = (props) => {
    const {jobId, showModal, companyId} = props;

    const [fields, errors, form] = useFormInputValidation({
        username: "",
        telephone: "",
    }, {
        username: "required",
        telephone: "required"
    });

    const applyToJob = () => {
        ApplicationService.applyToJob({
            job_id: jobId,
            company_id: companyId,
            name: fields.username,
            phone: fields.telephone
        }).then(res => {
            showModal(false);
            toast.success("Ati aplicat cu success la job", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).catch(e =>
           {
            showModal(false);
            toast.warn(e.error, {
                position: toast.POSITION.TOP_CENTER,
            });
          });
    }

    return (
        <>
          <div
              className="pop-up-applied justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative popup">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="head flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className="title-applied-job">
                    Aplicare Job
                  </h3>
                  <button
                      className="btn-applied"
                      onClick={() => showModal(false)}
                  >
                    X
                  </button>
                </div>
                <div className="relative flex-auto popup-content">
                    <form noValidate autoComplete="off" className="applied-at-job">
                        <div className="form-input">
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={fields.username}
                                onBlur={form.handleBlurEvent}
                                onChange={form.handleChangeEvent}
                                data-attribute-name="Username"
                            />
                        </div>

                        <div className="form-input">
                            <input
                                type="text"
                                name="telephone"
                                placeholder="Telefon"
                                value={fields.telephone}
                                onBlur={form.handleBlurEvent}
                                onChange={form.handleChangeEvent}
                                data-attribute-name="Telephone"
                            />
                        </div>
                    </form>
                </div>
                <div className="footer flex justify-end items-center rounded-b">
                  <button
                      className="btn-applied"
                      type="button"
                      onClick={() => applyToJob()}
                  >
                    Aplica
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
         <ToastContainer autoClose={2000} />
        </>
    );
}

export default PopUpAppliedAtJob;
