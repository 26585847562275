import React from 'react';
import ActiveInactiveCourseItem from "components/Courses/ActiveInactiveCourseItem";

const ActiveCourse = (props) => {
    return (
        <section className="status_courses">
            <ActiveInactiveCourseItem />
            <ActiveInactiveCourseItem />
            <ActiveInactiveCourseItem />
        </section>
    );
}

export default ActiveCourse;
