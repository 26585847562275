import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import "components/ExperienceComponent/experience.scss";
import ExperienceComponent from "components/ExperienceComponent/ExperienceComponent";

const MyCareer = () => {
    return (
        <>
            <ExperienceComponent />
            <div className="actions-career">
                <span className="button"><FontAwesomeIcon icon={faPlay} /> Start</span>
            </div>
        </>
    );
}

export default MyCareer;
