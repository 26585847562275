import {createSlice} from '@reduxjs/toolkit';
import {
    jobCreateActions,
    jobEditActions,
    jobGetJobsActions,
    jobDeleteActions
} from "../actions/jobsApi";

const jobReducer = createSlice({
    name: 'jobs',
    initialState: {
        loading: false,
        error: null,
        jobs: [],
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(jobGetJobsActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(jobGetJobsActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.jobs = payload
                }
            })

            .addCase(jobGetJobsActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(jobEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(jobEditActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.jobs.map(item => {
                        if(item.Id === payload.data.Id) {
                            item.Title = payload.data.Title;
                            item.Description = payload.data.Description;
                            item.Country = payload.data.Country;
                            item.Active = payload.data.Active;
                            item.Long = payload.data.Long;
                            item.Lat = payload.data.Lat;
                            item.Salary = payload.data.Salary
                        }
                    });
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(jobEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(jobCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(jobCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.jobs = [...state.jobs, {...payload}]
                }
            })

            .addCase(jobCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(jobDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(jobDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(jobDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
})

export default jobReducer.reducer;
export const {} = jobReducer.actions
