import {apiClient} from "./index";

export const getResultOpportunity = (params) => {
    return apiClient.get('/api/result-opportunity',{params}).then(response => response.data);
}

export const addResultOpportunity = (data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post('/api/result-opportunity',data);
}

export const editResultOpportunity = (Id, data, token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/result-opportunity/${Id}?_method=PUT`,data);
}

export const deleteResultOpportunity = (Id,token) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.delete(`/api/result-opportunity/${Id}`);
}
