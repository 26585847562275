import React, {useState} from 'react';
import InterestVisible from "./InterestVisible";

const InterestsItem = (props) => {
    const {interest, editButton, editButtonItem} = props;
    const [editItem, setEditItem] = useState(false);

    const handleEditItemChange = (value) => {
        setEditItem(value);
    };

    return (
        <div className="interest-item">
            {
                <InterestVisible interest={interest} editItem={handleEditItemChange} editButton={editButton} />
            }
        </div>
    )
}

export default InterestsItem;
