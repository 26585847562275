import React, {useMemo, useState} from 'react';
import Pagination from "components/Pagination/Pagination";

import 'components/Profiles/MyQuiz/my-quiz.scss';
import Quiz from "components/Profiles/MyQuiz/Quiz";
const MyQuiz = () => {
    let PageSize = 8;
    const quizes = [
        {
            id: 0,
            titleQuiz: 'HTML',
            nrCountQuiz: 40,
        },
        {
            id: 1,
            titleQuiz: 'SQL',
            nrCountQuiz: 30,
        },
        {
            id: 2,
            titleQuiz: 'JAVA SCRIPT',
            nrCountQuiz: 25,
        },
        {
            id: 3,
            titleQuiz: 'REACT JS',
            nrCountQuiz: 45,
        },
        {
            id: 4,
            titleQuiz: 'PHP',
            nrCountQuiz: 16,
        },
        {
            id: 5,
            titleQuiz: 'HTML',
            nrCountQuiz: 40,
        },
        {
            id: 6,
            titleQuiz: 'HTML',
            nrCountQuiz: 40,
        },
        {
            id: 7,
            titleQuiz: 'SQL',
            nrCountQuiz: 30,
        },
        {
            id: 8,
            titleQuiz: 'JAVA SCRIPT',
            nrCountQuiz: 25,
        },
        {
            id: 9,
            titleQuiz: 'REACT JS',
            nrCountQuiz: 45,
        },
        {
            id: 10,
            titleQuiz: 'PHP',
            nrCountQuiz: 16,
        },
        {
            id: 11,
            titleQuiz: 'HTML',
            nrCountQuiz: 40,
        },
        {
            id: 12,
            titleQuiz: 'HTML',
            nrCountQuiz: 40,
        },
        {
            id: 13,
            titleQuiz: 'SQL',
            nrCountQuiz: 30,
        },
        {
            id: 14,
            titleQuiz: 'JAVA SCRIPT',
            nrCountQuiz: 25,
        },
        {
            id: 15,
            titleQuiz: 'REACT JS',
            nrCountQuiz: 45,
        },
        {
            id: 16,
            titleQuiz: 'PHP',
            nrCountQuiz: 16,
        },
        {
            id: 17,
            titleQuiz: 'HTML',
            nrCountQuiz: 40,
        },
    ];
    const [currentPage, setCurrentPage] = useState(1);

    const MyQuiz = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return quizes.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return (
        <>
            <section className="my-quiz-list">
                {
                    MyQuiz.map(quizItem => <Quiz quiz={quizItem} key={quizItem.id} />)
                }
            </section>

            <Pagination
                currentPage={currentPage}
                totalCount={quizes.length}
                pageSize={PageSize}
                template="template-two"
                onPageChange={page => setCurrentPage(page)}
            />
        </>
    );
}

export default MyQuiz;
