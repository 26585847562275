import {createAsyncThunk} from "@reduxjs/toolkit";
import {editInitiativeCompany, getInitiativeCompany} from "../../service/InititativeCompanyService";

export const getInitiativeCompanyActions = createAsyncThunk(
    'get/getInitiativeCompanyActions',
    async (params = {}) => {
        try {
            return await getInitiativeCompany(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const initiativeCompanyEditActions = createAsyncThunk(
    'edit/initiativeCompanyEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editInitiativeCompany(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)
