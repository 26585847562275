import React from 'react';
import InterviewEmployerItem from "components/Profiles/InterviewsEmployer/InterviewEmployerItem";
import "components/Profiles/InterviewsEmployer/inteerviews.scss";


const InterviewsEmployer = () => {
    return (
        <section className="interviews-employer">
           <InterviewEmployerItem />
        </section>
    );
}

export default InterviewsEmployer;
