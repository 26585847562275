import {createAsyncThunk} from "@reduxjs/toolkit";
import {getSetting, editSetting} from "../../service/SettingService";

export const settingGetSettingActions = createAsyncThunk(
    'setting/GetSettingActions',
    async (params = {}) => {
        try {
            return await getSetting(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const settingEditSettingActions = createAsyncThunk(
    'setting/settingEditSettingActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editSetting(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)
