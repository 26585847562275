import React, {useEffect, useState} from "react";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import Search from "../../components/Search";

const ResetCenterView = (props) => {
        const { selectPosition } = props;
        const map = useMap();

        useEffect(() => {

            if (selectPosition) {
                map.setView(
                    L.latLng(selectPosition?.lat, selectPosition?.lon),
                    map.getZoom(),
                    {
                        animate: true
                    }
                )
            }
        }, [selectPosition]);

        return null;
}

const FindJob = (props) => {
    const mainCoordinates = [46.9999648,28.7881368];
    const [selectPosition, setSelectPosition] = useState(null);

    const icon = L.icon({
      iconUrl: './placeholder.png',
      iconSize: [30,30]
    });

    return (
        <>
            <h1>Find Job</h1>
            <Search selectPosition={selectPosition} setSelectPosition={setSelectPosition} />
            <MapContainer center={mainCoordinates} zoom={12} style={{ width: '100vw', height: '100vh'}}>
                <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"></TileLayer>
                    <Marker position={selectPosition ?? mainCoordinates} icon={icon}></Marker>

                <ResetCenterView selectPosition={selectPosition} />
            </MapContainer>
        </>
    )
}

export default FindJob;
