import {createSlice} from "@reduxjs/toolkit";
import {categoryCreateActions, categoryDeleteActions, categoryEditActions, categoryGetCategoriesActions} from "../actions/categoryApi";

const categoryReducer = createSlice({
    name: 'category',
    initialState: {
        category: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(categoryGetCategoriesActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(categoryGetCategoriesActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.category = payload
                }
            })

            .addCase(categoryGetCategoriesActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(categoryEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(categoryEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.loading = false;
                        state.error = payload.message;
                    } else {
                        state.category.map(item => {
                            if(item.Id === payload.data.Id) {
                                item.Title = payload.data.Title;
                                item.Description = payload.data.Description;
                                item.Image = payload.data.Image;
                                item.Type = payload.data.Type;
                                item.Order = payload.data.Order;
                            }
                        });
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(categoryEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(categoryCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(categoryCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.category = [...state.category, {...payload}]
                }
            })

            .addCase(categoryCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(categoryDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(categoryDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(categoryDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default categoryReducer.reducer;
