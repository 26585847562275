import axios from "axios";

class ApplicationService {
    applyToJob(param)
    {
        return axios.post('/api/application',param,
        {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }).then(res => res.data).catch(e => console.log(e.error));
    }
}

export default new ApplicationService;
