import {createSlice} from "@reduxjs/toolkit";
import {getMenuActions, editMenuActions} from "../actions/menuApi";

const menuReducer = createSlice({
    name: 'menu',
    initialState: {
        menu: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMenuActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(getMenuActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.menu = payload
                }
            })

            .addCase(getMenuActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(editMenuActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(editMenuActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.menu.map(item => {
                        if(item.Id === payload.data.Id) {
                            item.Label = payload.data.Label;
                            item.Hide = payload.data.Hide;
                        }
                    });
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(editMenuActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })
    }
});

export default menuReducer.reducer;
