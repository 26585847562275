import React from "react";
import {useTranslation} from "react-i18next";
import ForgotPasswordComponent from "components/Forms/ForgotPasswordComponent";

const ForgotPassword = () => {
    const {t} = useTranslation();
    return (
        <main className="login-page container">
            <section className="content-page">
                <h1 className="title">{t('Forgot.Title')}</h1>
                <ForgotPasswordComponent />
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </section>
        </main>
    )
}

export default ForgotPassword;
