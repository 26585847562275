import React, {useEffect, useState} from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react"
import {useDispatch, useSelector} from "react-redux";
import {jobEditActions, jobDeleteActions, jobGetJobsActions} from "../../../../../redux/actions/jobsApi";
import {useParams, useNavigate} from "react-router-dom";
import Loader from "components/Loader/Loader";

const JobActiveEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {loading, jobs} = useSelector(state => state.jobs);
  const auth = useSelector(state => state.auth);
  const {company, loader} = useSelector(state => state.user)
  const [token, setToken] = useState(auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token === null) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }

  }, [token]);

  useEffect(() => {
     dispatch(jobGetJobsActions({expand: 'user', 'id': id}));
  },[dispatch]);

  const SignupSchema = Yup.object({
    title: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    description: Yup.string()
        .required('Description is a required field'),
    salary: Yup.string()
        .required('Salary is a required field'),
    currency: Yup.string()
        .required('Currency is a required field'),
  });

  return (
      <>
          { loading && <Loader /> }
          { !loading &&
            <section className="add-article">
              <h2 className="title-head">Editeaza Job-ul</h2>

              <section className="profile-form-section">
                  <Formik  initialValues={
                      {
                        title: jobs[0].Jobs[0]?.Title,
                        description: jobs[0].Jobs[0]?.Description,
                        company_id: company?.Id,
                        salary: jobs[0].Jobs[0]?.Salary,
                        country_id: 142,
                        currency: jobs[0].Jobs[0].Currency,
                        active: jobs[0].Jobs[0]?.Active,
                        typeOfEmployment: jobs[0].Jobs[0]?.Type.Id,
                        tags: []
                      }
                  }
                           validationSchema={SignupSchema}
                           onSubmit={(values) => {
                               const data = {
                                   Id: id,
                                   data: values,
                                   token: token
                               }

                               // handleEditData(false);
                               dispatch(jobEditActions(data)).then(navigate('/dashboard/job-active'));
                           }}
                  >
                      {({ errors, touched, values, setFieldValue }) => (
                          <Form>
                              <div className="form-input">
                                  <Field type="text" name="title" placeholder="Title" />
                                  <ErrorMessage name="title" render={msg => <div className="error-message">{msg}</div>} />
                              </div>

                              <div className="form-input">
                                  <Field type="text" name="salary" placeholder="Salary" />
                                  <ErrorMessage name="salary" render={msg => <div className="error-message">{msg}</div>} />
                              </div>

                              <div className="form-input">
                                  <Field as="select" name="currency">
                                      <option value="1">EUR</option>
                                      <option value="2">USD</option>
                                      <option value="3">MDL</option>
                                  </Field>

                                  <ErrorMessage name="currency" render={msg => <div className="error-message">{msg}</div>} />
                              </div>

                              <div className="form-input">
                                  <Field as="select" name="typeOfEmployment">
                                      <option value="1">Full-time</option>
                                      <option value="2">Part-time</option>
                                      <option value="3">Temporary</option>
                                      <option value="4">Remote</option>
                                  </Field>

                                  <ErrorMessage name="typeOfEmployment" render={msg => <div className="error-message">{msg}</div>} />
                              </div>

                              <div className="form-input">
                                  <Field as="select" name="active">
                                      <option value="0">Inactive</option>
                                      <option value="1">Active</option>
                                  </Field>

                                  <ErrorMessage name="active" render={msg => <div className="error-message">{msg}</div>} />
                              </div>

                              {/*<div className="form-input">*/}
                              {/*    <Field name="tags" as="select">*/}
                              {/*        {({ field, form, meta }) => (*/}
                              {/*           <Select {...field} isMulti onChange={(selectedOptions) => setFieldValue('tags',selectedOptions)} />*/}
                              {/*        ) }*/}
                              {/*    </Field>*/}
                              {/*</div>*/}

                              <div className="form-message">
                                  <CKEditor
                                      editor={ClassicEditor}
                                      onInit={editor => {}}
                                      onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setFieldValue('description', data);
                                      }}
                                      data={values.description}
                                  />
                              </div>
                              <div className="actions-button-section">
                                  <button onClick={() => navigate('/dashboard/job-active') }>Close</button>
                                  <button type="submit">Save</button>
                              </div>
                          </Form>
                      )}
                  </Formik>
              </section>
          </section> }
      </>
  );
}

export default JobActiveEdit;
