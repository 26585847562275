import {createSlice} from "@reduxjs/toolkit";
import {
    blogCreateActions,
    blogDeleteActions,
    blogEditActions,
    blogGetBlogsActions,
    blogInfoBlogActions
} from "../actions/blogApi";

const blogReducer = createSlice({
    name: 'blog',
    initialState: {
        blog: [],
        blogItem: null,
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(blogGetBlogsActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(blogGetBlogsActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.blog = payload
                }
            })

            .addCase(blogGetBlogsActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(blogEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(blogEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.loading = false;
                        state.error = payload.message;
                    } else {
                        state.blog.map(item => {
                            if(item.Id === payload.data.Id) {
                                item.Title = payload.data.Title;
                                item.Status = payload.data.Status;
                                item.AltImage = payload.data.AltImage;
                                item.Image = payload.data.Image;
                                item.MetaTitle = payload.data.MetaTitle;
                                item.MetaDescription = payload.data.MetaDescription;
                                item.Description = payload.data.Description
                            }
                        });
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(blogEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(blogCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(blogCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.blog = [...state.blog, {...payload}]
                }
            })

            .addCase(blogCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(blogDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(blogDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(blogDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(blogInfoBlogActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(blogInfoBlogActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.blogItem = payload.data
                }
            })

            .addCase(blogInfoBlogActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default blogReducer.reducer;
