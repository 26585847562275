import React from 'react';

const Quiz = (props) => {
    const {quiz} = props;
    return (
        <div className="quiz">
            <span className="title-quiz">{quiz.titleQuiz}</span>
            <span className="count-quiz">{quiz.nrCountQuiz} Questions covering the basic of {quiz.titleQuiz}</span>
            <div className="actions">
                <span className="quiz-btn">Start Quiz</span>
                <span className="quiz-btn">View Results</span>
            </div>
        </div>
    )
}

export default Quiz;
