import React, {useState} from 'react';
import EducationVisible from "./EducationVisible";
import EducationEdit from "./EducationEdit";

const EducationItem = (props) => {
    const {education, editButton, editButtonItem} = props;
    const [editItem, setEditItem] = useState(false);

    const handleEditItemChange = (value) => {
        setEditItem(value);
    };

    return (
        <div className="education-item">
            {
                !editItem ? <EducationVisible education={education} editItem={handleEditItemChange} editButton={editButton} /> :
                    <EducationEdit education={education} editItem={handleEditItemChange} editButton={editButton} editButtonItem={editButtonItem}/>
            }
        </div>
    )
}

export default EducationItem;
