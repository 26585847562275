import React, {useEffect, useState} from 'react';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react"
import Dropzone from "components/Dropzone/Dropzone";
import {useDispatch, useSelector} from "react-redux";
import {blogDeleteActions, blogEditActions} from "../../../../../redux/actions/blogApi";
import {categoryGetCategoriesActions} from "../../../../../redux/actions/categoryApi";
import {useParams, useNavigate} from "react-router-dom";

const BlogEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const blog = useSelector(state => state.blog.blog);
  const {category} = useSelector(state => state.category);
  const auth = useSelector(state => state.auth);
  const [token, setToken] = useState(auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(categoryGetCategoriesActions({type: 4}));
    // Check if token is null and set it from localStorage if available
    if (token === null) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }

  }, [token]);

  const item = blog.filter(item => item.Id === parseInt(id))[0];

  const SignupSchema = Yup.object({
    title: Yup.string()
        .min(2,'Title must be 2 characters or more')
        .max(150,'Title must be 150 characters or less')
        .required('Title is a required field'),
    altImage: Yup.string()
        .min(2, 'Alt Image must be 2 characters or more')
        .max(60, 'Alt image must be 60 characters or more'),
    metaDescription: Yup.string()
        .min(2, 'Meta Description must be 2 characters or more')
        .max(160, 'Meta Description must be 160 characters or more'),
    metaTitle: Yup.string()
        .min(2, 'Meta Title must be 2 characters or more')
        .max(60, 'Meta Description must be 60 characters or less')
  });

  const handleClose = () => {
    navigate('/dashboard/blog');
  }

  const handleRemove = () => {
    dispatch(blogDeleteActions({Id: id, token: token})).then(navigate('/dashboard/blog'));
  }

  return (
      <section className="add-article">
        <h2 className="title-head">Editeaza Articolul</h2>

        <section className="profile-form-section">
          <Formik  initialValues={
            {
              title: item?.Title,
              shortDescription: item?.ShortDescription,
              category_id: item?.CategoryId ?? null,
              description: item?.Description,
              status: item.Status?.Id,
              tags: item?.Tags ?? [],
              image: item?.Image ?? null,
              altImage: item?.AltImage ?? '',
              metaDescription: item?.MetaDescription ?? '',
              metaTitle: item?.MetaTitle ?? '',
            }
          }
                   validationSchema={SignupSchema}
                   onSubmit={(values) => {
                     const data = {
                       Id: id,
                       data: values,
                       token: token
                     }

                     // handleEditData(false);
                     dispatch(blogEditActions(data)).then(navigate('/dashboard/blog'));
                   }}
          >
            {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className="form-input">
                    <Field type="text" name="title" placeholder="Title" />
                    <ErrorMessage name="title" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="altImage" placeholder="Image Alt title" />
                    <ErrorMessage name="altImage" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="metaTitle" placeholder="Meta Title" />
                    <ErrorMessage name="metaTitle" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field type="text" name="metaDescription" placeholder="Meta Description" />
                    <ErrorMessage name="metaDescription" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  <div className="form-input">
                    <Field as="select" name="status">
                      <option value="1">Deleted</option>
                      <option value="2">Archived</option>
                      <option value="3">Draft</option>
                      <option value="4">Pending</option>
                      <option value="5">Published</option>
                    </Field>

                    <ErrorMessage name="status" render={msg => <div className="error-message">{msg}</div>} />
                  </div>

                  {
                      category.length &&
                      <div className="form-input">
                        <Field as="select" name="category_id">
                          {category.map(item => <option value={item.Id} key={item.Id}>{item.Title}</option>) }
                        </Field>

                        <ErrorMessage name="category_id" render={msg => <div className="error-message">{msg}</div>} />
                      </div>
                  }

                  {/*<div className="form-input">*/}
                  {/*    <Field name="tags" as="select">*/}
                  {/*        {({ field, form, meta }) => (*/}
                  {/*           // <Select options={item?.Tags.map((value) => ({ value: value, label: value, key: value}))} {...field} isMulti onChange={(selectedOptions) => setFieldValue('tags',selectedOptions)} />*/}
                  {/*        ) }*/}
                  {/*    </Field>*/}
                  {/*</div>*/}

                  <div className="form-message">
                    <Dropzone image={values.image} onFileSelect={(file => setFieldValue('image', file))} />
                  </div>

                  <div className="form-message">
                    <CKEditor
                        editor={ClassicEditor}
                        onInit={editor => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue('shortDescription', data);
                        }}
                        data={values.shortDescription}
                    />
                  </div>

                  <div className="form-message">
                    <CKEditor
                        editor={ClassicEditor}
                        onInit={editor => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue('description', data);
                        }}
                        data={values.description}
                    />
                  </div>
                  <div className="actions-button-section">
                    <button onClick={() => handleClose()}>Close</button>
                    <button type="submit">Save</button>
                    <button onClick={() => handleRemove()}>Delete</button>
                  </div>
                </Form>
            )}
          </Formik>
        </section>
      </section>
  );
}

export default BlogEdit;
