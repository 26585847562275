import {createAsyncThunk} from "@reduxjs/toolkit";
import {removeEducation} from "../reducer/educationReducer";
import {addEducation, editEducation, deleteEducation} from "../../service/EducationService";

export const addNewEducationActions = createAsyncThunk(
    'career/addNewEducationActions',
    async({data, token}, thunkApi) => {
        try{
          return await addEducation(data, token).then(response => {
              if (response?.status === 201) {
                  return response.data?.data;
              } else {
                  return response.data?.error_message;
              }
          })
        } catch (error) {
           return error.message;
        }
    }
)

export const editEducationActions = createAsyncThunk(
    'career/editEducationActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editEducation(Id, data, token).then(response => {
                if (response?.status === 200) {
                    return response.data?.data;
                } else {
                    return response.data?.error_message;
                }
            })
        } catch (error) {
            return error.message;
        }
    }
)

export const deleteEducationActions = createAsyncThunk(
    'career/deleteEducationActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteEducation(Id, token).then(response => {
                const {dispatch} = thunkApi;

                if (response?.status === 200) {
                    dispatch(removeEducation({Id: Id}));
                    return response.data;
                } else {
                    return response.data?.error_message;
                }
            })
        } catch (error) {
            return error.message;
        }
    }
)
