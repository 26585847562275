import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {blogInfoBlogActions} from "../../../redux/actions/blogApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import { Parser } from 'html-to-react'

const BlogViewPage = () => {
  const dispatch = useDispatch();
  const {blogId} = useParams();
  const {loading, blogItem} = useSelector(state => state.blog);

  useEffect(() => {
    console.log(blogId);
    if(blogId !== undefined){
      dispatch(blogInfoBlogActions({id: blogId}));
    }
  },[blogId]);

    return (
        <>
          {loading && <Loader />}
          {!loading &&
              <section className="blog-page container">
                {blogItem?.Image !== '' &&
                <div className="head-image">
                  <img src={blogItem?.Image} alt={blogItem?.AltImage ?? blogItem?.Title}/>
                </div>
                }
                <div className="blog-section container">
                    <h1>{blogItem?.Title}</h1>
                    <div className="info-section">
                      <span><FontAwesomeIcon icon={faEye} style={{marginRight: '6px'}} /> {blogItem?.NrOfViews}</span>
                      <span><i className="text-clientColor2">Created: </i>{blogItem?.CreatedAt}</span>
                    </div>
                    <div className="content">
                      {Parser().parse(blogItem?.Description)}
                    </div>
                </div>
              </section>
          }
        </>
    );
}

export default BlogViewPage;
