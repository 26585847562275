import React from 'react';
import "./InterviewsOffers.scss";
import Moment from 'react-moment';
import 'moment-timezone';

const ItemInterviewsOffers = (props) => {
    const {interview} = props;
    return (
        <div className="item_interviews_offers">
            <div className="data_main">
                <span className="data_main_data">
                    <Moment local date={interview.data} format="D" />
                </span>
                <span className="data_main_month">
                    <Moment local date={interview.data} format="MMM" />
                </span>
            </div>
            <div className="interview_content">
               <div className="interview_content__head">
                 <div className="interview_content__head__title">
                     <span className="title">{interview.titleCompany}</span>
                     <span className="date">
                         <Moment local date={interview.data} format="dddd, MMMM D, YYYY, h:mm a" />
                     </span>
                 </div>
                 <div className="interview_content__head__image">
                     <img src={interview.image} alt={interview.titleCompany} />
                 </div>
               </div>
               <div className="content_description">
                   {interview.description}
               </div>
               <div className="content_actions">
                  <div className="content_actions_first">
                      <span className="interview_button">Citește mai mult</span>
                      <span className="interview_button">Accept la interview</span>
                  </div>
                  <span className="interview_button refuse">Refuză</span>
               </div>
            </div>
        </div>
    );
}

export default ItemInterviewsOffers;
