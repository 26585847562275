import {createSlice} from "@reduxjs/toolkit";
import {socialEditSocialMediaActions, socialGetSocialMediaActions} from "../actions/socialMediaApi";

const socialMediaReducer = createSlice({
    name: 'socialMedia',
    initialState: {
        social: null,
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(socialGetSocialMediaActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(socialGetSocialMediaActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.social = payload;
                }
            })

            .addCase(socialGetSocialMediaActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(socialEditSocialMediaActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(socialEditSocialMediaActions.fulfilled, (state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.social = payload.data
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(socialEditSocialMediaActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })
    }
});

export default socialMediaReducer.reducer;
