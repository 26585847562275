import React, {useEffect, useState} from 'react';
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import Loader from "components/Loader/Loader";
import {opportunityGetOpportunitiesActions} from "../../../../../redux/actions/opportunityApi";
import {StatusBlog} from "../../../../../constants/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const Opportunity = () => {
    const dispatch = useDispatch();
    const {loading, error, opportunity} = useSelector(state => state.opportunity);

    useEffect(() => {
        dispatch(opportunityGetOpportunitiesActions({expand: 'user'}));
    },[dispatch])


    const basicColumOpportunitiesList = [
        {
            id: 'select',
            header: ({table}) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row}) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            header: 'Titlu',
            accessorKey: 'Title',
            enableSorting: true,
        },
        {
            header: 'Logo',
            accessorKey: 'Image',
            enableSorting: false,
            cell: (info) => <div className="table-image"><img src={info.getValue()} alt={info.getValue()}/></div>
        },
        {
            header: 'Status',
            accessorKey: 'Status',
            enableSorting: false,
            cell: (info) => info.getValue() && <div>{StatusBlog[info.getValue().Id - 1]}</div>,

        },
        {
            header: 'Nr. vizualizari',
            accessorKey: 'NrOfViews',
            enableSorting: false,
        },
        {
            header: 'Data crearii',
            accessorKey: 'CreatedAt',
            enableSorting: false
        },
        {
            header: 'Author',
            accessorKey: 'User',
            enableSorting: false,
            cell: (info) => info.getValue() &&
                <div className="table-name-image">
                    <div className="table-image">
                        <img src={info.getValue().Image} alt={info.getValue().Name}/>
                    </div>
                    <span className="name">{info.getValue().Name + ' ' + info.getValue().Surname}</span>
                </div>
        },
        {
            header: '',
            accessorKey: 'Id',
            enableSorting: false,
            cell: (info) => <div className="table-download"><Link to={`/dashboard/opportunity/${info.getValue()}`}><FontAwesomeIcon icon={faPencil} /></Link></div>
        }
    ];

    function IndeterminateCheckbox({
                                       indeterminate,
                                       className = '',
                                       ...rest
                                   }) {
        const ref = React.useRef(!null);

        React.useEffect(() => {
            if (typeof indeterminate === 'boolean') {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
        )
    }

    return (
        <>
            { loading && <Loader /> }
            {!loading &&
                <section className="table-opportunitiesList">
                    <BasicTable basic_column={basicColumOpportunitiesList} data={opportunity}
                                className="basic-table-JobsList clientColor2"/>
                </section>
            }
        </>
    );
}

export default Opportunity;
