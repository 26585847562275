import React, {useEffect} from 'react';
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import Loader from "components/Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCirclePlay} from "@fortawesome/free-solid-svg-icons";

const Candidates = () => {
  const dispatch = useDispatch();

  const trainer = [
    {
      Id: 1,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 2,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 3,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 4,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 5,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    },
    {
      Id: 6,
      logo: 'career.jpeg',
      name: 'Vasile',
      surname: 'Lupu',
      city: 'Moldova',
      email: 'vasile.lupu@gmail.com',
      applied: 1,
      createdAt: '12.01.2023',
      lastAuthentification: '12.02.2023',
    }
  ]

  const basicColumTrainersList = [
    {
      id: 'select',
      header: ({table}) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
          </div>
      ),
      cell: ({row}) => (
          <div className="px-1">
            <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
            />
          </div>
      ),
    },
    {
      header: 'Logo',
      accessorKey: 'logo',
      enableSorting: false,
      cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)}
                                                        alt={info.getValue()}/></div>
    },
    {
      header: 'Nume',
      accessorKey: 'name',
      enableSorting: false,
    },
    {
      header: 'Prenume',
      accessorKey: 'surname',
      enableSorting: false,
    },
    {
      header: 'Tara',
      accessorKey: 'city',
      enableSorting: false,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      enableSorting: false,
    },
    {
      header: 'Aplicari',
      accessorKey: 'applied',
      enableSorting: false
    },
    {
      header: 'Data de inregistrare',
      accessorKey: 'createdAt',
      enableSorting: false
    },
    {
      header: 'Ultima logare',
      accessorKey: 'lastAuthentification',
      enableSorting: false
    }
  ];

  function IndeterminateCheckbox({
                                   indeterminate,
                                   className = '',
                                   ...rest
                                 }) {
    const ref = React.useRef(!null);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
  }


  return (
      <section className="candidates-list-section">
        <div className="_header">
          <button><FontAwesomeIcon icon={faCheck} />Beneficiari</button>
          <button><FontAwesomeIcon icon={faCheck} />Studenti</button>
          <button><FontAwesomeIcon icon={faCheck} />Trainieri</button>
          <button><FontAwesomeIcon icon={faCheck} />Companii</button>
          <button><FontAwesomeIcon icon={faCheck} />Operatori</button>
          <button><FontAwesomeIcon icon={faCheck} />Administratori</button>
        </div>

        <div className="_content">
          <section className="tableList">
            <h4 className="text-clientColor1">Beneficiari</h4>
            <BasicTable basic_column={basicColumTrainersList} perPage={3} data={trainer}
                        className="basic-table-candidate clientColor2"/>
          </section>

          <section className="tableList">
            <h4 className="text-clientColor3">Studenti</h4>
            <BasicTable basic_column={basicColumTrainersList} perPage={3} data={trainer}
                        className="basic-table-candidate clientColor4" templateName="" />
          </section>

          <section className="tableList">
            <h4 className="text-clientColor2">Trainieri</h4>
            <BasicTable basic_column={basicColumTrainersList} perPage={3} data={trainer}
                        className="basic-table-candidate clientColor3" templateName="template-three"/>
          </section>

          <section className="tableList">
            <h4 className="text-clientColor4">Companii</h4>
            <BasicTable basic_column={basicColumTrainersList} perPage={3} data={trainer}
                        className="basic-table-candidate clientColor5" templateName="template-five"/>
          </section>

          <section className="tableList">
            <h4 className="text-clientColor1">Operatori</h4>
            <BasicTable basic_column={basicColumTrainersList} data={trainer} className="basic-table-candidate clientColor2"/>
          </section>
        </div>
        {/*{error && <p>Something went wrong</p>}*/}
        {/*{ !loading && <Loader /> }*/}
      </section>
  );
}

export default Candidates;
