import {createSlice} from "@reduxjs/toolkit";
import {addNewEducationActions, deleteEducationActions, editEducationActions} from "../actions/educationApi";

const educationReducer = createSlice({
    name: 'education',
    initialState: {
        education: [],
        error: null,
        loading: false,
    },
    reducers: {
        addAllEducation: (state, action) => {
            state.education = action.payload;
        },
        editEducationItem: (state,{payload}) => {
            state.education.map(item => {
                if(item.Id === payload.Id) {
                    item.TitleCompany = payload.TitleCompany;
                    item.Job = payload.Job
                    item.Location = payload.Location;
                    item.StartDate = payload.StartDate;
                    item.EndDate = payload.EndDate;
                    item.Type = payload.Type;
                    item.Logo = payload.Logo;
                    item.Description = payload.Description;
                }
            });
        },
        addNewEducation: (state, action) => {
            state.education = [...state.education, {...action.payload}]
        },
        removeEducation: (state, action) => {
            state.education = state.education.filter(item => item.Id !== action.payload.Id)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewEducationActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(addNewEducationActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.education = [...state.education, {...payload}]
                }
            })

            .addCase(addNewEducationActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(editEducationActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(editEducationActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.education.map(item => {
                        if(item.Id === payload.Id) {
                            item.TitleInstitution = payload.TitleInstitution;
                            item.Speciality = payload.Speciality;
                            item.Domain = payload.Domain;
                            item.TitleCertificate = payload.TitleCertificate;
                            item.StartDate = payload.StartDate;
                            item.EndDate = payload.EndDate;
                        }
                    });
                    state.loading = false;
                    state.error = null;
                }
            })

            .addCase(editEducationActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(deleteEducationActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(deleteEducationActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(deleteEducationActions.rejected, (state, {payload}) => {
                state.loading = false;
            })

    }
});

export default educationReducer.reducer;
export const { addAllEducation, editEducationItem, addNewEducation, removeEducation } = educationReducer.actions
