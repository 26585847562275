import {createAsyncThunk} from "@reduxjs/toolkit";
import {getAccountsBank, editAccountBank, addAccountBank, deleteAccountBank} from "../../service/AccountBankService";

export const accountBankGetBankActions = createAsyncThunk(
    'accountBank/accountBankGetBankActions',
    async (params = {}) => {
        try {
            return await getAccountsBank(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const accountBankEditActions = createAsyncThunk(
    'accountBank/accountBankEditActions',
    async({Id, data, token}, thunkAPI) => {
        try{
            return await editAccountBank(Id, data, token).then(response => response.data.data);
        } catch (error) {
            return error.message;
        }
    }
)

export const accountBankCreateActions = createAsyncThunk(
    'accountBank/accountBankCreateActions',
    async({data, token}) => {
        try{
            return await addAccountBank(data, token).then(response => response.data.data).catch(error => error.response.data);
        } catch (error) {
            return error.message
        }
    }
)

export const accountBankDeleteActions = createAsyncThunk(
    'accountBank/accountBankDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteAccountBank(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
