import React, {forwardRef, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {faCalendarDays, faSave, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import Image from "../Image/Image";
import {addNewEducationActions} from "../../redux/actions/educationApi";

const EducationAdd = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const {addButtonItem} = props;

    const [typeExperience, setTypeExperience] = useState('');
    const [titleSpeciality, setTitleSpeciality] = useState('');
    const [secondTitleSpeciality, setSecondTitleSpeciality] = useState('');
    const [grade, setGrade] = useState('');
    const [dataTo, setDataTo] = useState(new Date());
    const [dataFrom, setDataFrom] = useState(new Date());
    const [image, setImage] = useState('');

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const handleAddEducation = () => {
        const data = {
            data: {
                titleInstitution: typeExperience,
                speciality: titleSpeciality,
                titleCertificate: secondTitleSpeciality,
                domain: grade,
                dateStart: dataTo,
                dateEnd: dataFrom,
                logo: image
            },
            token: token
        }
        dispatch(addNewEducationActions(data));
        addButtonItem(false);
    }


    const StyleDataComponent = forwardRef(({ value, onClick }, ref) => (
        <span className="data-input" onClick={onClick} ref={ref}>
          <span className="data">{value} <FontAwesomeIcon icon={faCalendarDays} /></span>
       </span>
    ));

    return (
        <div className="edit-form">
            <div className="image-edit">
                <Image value={image} onChange={(result) => setImage(result)} withBase64={true}/>
            </div>
            <input type="text" value={typeExperience} placeholder="Institutie de invatamant" onChange={(e) => setTypeExperience(e.target.value)} />
            <input type="text" value={titleSpeciality} placeholder="Specialitatea" onChange={(e) => setTitleSpeciality(e.target.value)} />
            <input type="text" value={secondTitleSpeciality} placeholder="Domeniu de Studii" onChange={(e) => setSecondTitleSpeciality(e.target.value)} />
            <input type="text" value={grade} placeholder="Diploma" onChange={(e) => setGrade(e.target.value)} />

            <div className="dataContainer">
                <DatePicker
                    selected={dataTo}
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => setDataTo(date)}
                    customInput={<StyleDataComponent />}
                    isClearable
                />

                <DatePicker
                    selected={dataFrom}
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => setDataFrom(date)}
                    isClearable
                    customInput={<StyleDataComponent />}
                />
            </div>

            <div className="actions">
                <button onClick={() => addButtonItem(false) }><FontAwesomeIcon icon={faXmark} />Cancel</button>
                <button onClick={ handleAddEducation }><FontAwesomeIcon icon={faSave} />Save</button>
            </div>
        </div>
    )
}

export default EducationAdd;
