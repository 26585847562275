import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import BasicTable from "components/TableComponent/BasicTable";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import {advisersGetAdvisersActions} from "../../../../../redux/actions/advisersApi";
import Loader from "components/Loader/Loader";

const Advisers = () => {
    const dispatch = useDispatch();
    const {loading, error, advisers} = useSelector(state => state.advisers);

    useEffect(() => {
        dispatch(advisersGetAdvisersActions({expand: 'user'}));
    },[dispatch])

    const basicColumAdvisersList = [
        {
            header: 'Name',
            accessorKey: 'Name',
            enableSorting: true,
        },
        {
            header: 'Surname',
            accessorKey: 'Surname',
            enableSorting: true,
        },
        {
            header: 'Image',
            accessorKey: 'Image',
            enableSorting: false,
            cell: (info) => <div className="table-image"><img src={info.getValue()} alt={info.getValue()}/></div>
        },
        {
            header: '',
            accessorKey: 'Id',
            enableSorting: false,
            cell: (info) => <div className="table-download"><Link to={`/dashboard/advisers/${info.getValue()}`}><FontAwesomeIcon icon={faPencil} /></Link></div>
        }
    ];

    function IndeterminateCheckbox({
                                       indeterminate,
                                       className = '',
                                       ...rest
                                   }) {
        const ref = React.useRef(!null);

        React.useEffect(() => {
            if (typeof indeterminate === 'boolean') {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
        )
    }


    return (
        <>
            { loading && <Loader /> }
            {!loading &&
                <section className="table-articlesList">
                    <BasicTable basic_column={basicColumAdvisersList} data={advisers} className="basic-table-JobsList clientColor2"/>
                </section>
            }
        </>
    );
}

export default Advisers;
