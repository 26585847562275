import {createSlice} from "@reduxjs/toolkit";
import {
    companyGetCompanyActions,
    companyEditActions,
    companyDeleteActions,
    companyCreateActions
} from "../actions/companyApi";

const companyReducer = createSlice({
    name: 'company',
    initialState: {
        company: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(companyGetCompanyActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(companyGetCompanyActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.company = payload
                }
            })

            .addCase(companyGetCompanyActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(companyEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(companyEditActions.fulfilled, (state, {payload}) => {
                    if(payload?.status === false || payload?.status === undefined || payload === undefined) {
                        state.loading = false;
                        state.error = "Something is wrong, try again";
                    } else {
                        state.company.map(item => {
                            if(item.Id === payload.data.Id) {
                                item.Title = payload.data.Title;
                                item.Logo = payload.data.Logo;
                                item.Name = payload.data.Name;
                                item.Surname = payload.data.Surname;
                                item.Email = payload.data.Email;
                                item.IDNO = payload.data.IDNO;
                                item.CreatedAt = payload.data.CreatedAt
                            }
                        });
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(companyEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(companyCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(companyCreateActions.fulfilled, (state, {payload}) => {
                if(payload.status === false || payload.status === undefined || payload.errors) {
                    state.loading = false;
                    state.error = "Something is wrong, try again";
                } else {
                    state.loading = false;
                    state.error = null;
                    state.company = [...state.company, {...payload}]
                }
            })

            .addCase(companyCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(companyDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(companyDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(companyDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default companyReducer.reducer;
