import {createAsyncThunk} from "@reduxjs/toolkit";
import {getSocialMedia, editSocialMedia} from "../../service/SocialMediaService";

export const socialGetSocialMediaActions = createAsyncThunk(
    'social/GetSocialMediaActions',
    async (params = {}) => {
        try {
            return await getSocialMedia(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const socialEditSocialMediaActions = createAsyncThunk(
    'social/socialEditSocialMediaActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editSocialMedia(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)
