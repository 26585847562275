import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCertificates, editCertificate, addCertificate, deleteCertificate} from "../../service/CertificateService";

export const certificateGetCertificatesActions = createAsyncThunk(
    'certificate/certificateGetCertificatesActions',
    async (params = {}) => {
        try {
            return await getCertificates(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const certificateEditActions = createAsyncThunk(
    'certificate/certificateEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editCertificate(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)

export const certificateCreateActions = createAsyncThunk(
    'certificate/certificateCreateActions',
    async({data, token}) => {
        try{
            return await addCertificate(data, token).then(response => response.data.data)
        } catch (error) {
            return error.message
        }
    }
)

export const certificateDeleteActions = createAsyncThunk(
    'certificate/certificateDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteCertificate(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
