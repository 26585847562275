import {createSlice} from "@reduxjs/toolkit";
import {
    bankCreateActions,
    bankDeleteActions,
    bankEditActions,
    bankGetBankActions,
} from "../actions/bankApi";

const bankReducer = createSlice({
    name: 'bank',
    initialState: {
        bank: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(bankGetBankActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(bankGetBankActions.fulfilled,(state, {payload}) => {
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.bank = payload
                }
            })

            .addCase(bankGetBankActions.rejected, (state, {payload }) => {
                state.loading = false;
                state.error = payload;
            })

            .addCase(bankEditActions.pending, (state, {payload}) => {
                state.error = null;
                state.loading = true;
            })

            .addCase(bankEditActions.fulfilled, (state, {payload}) => {
                    if(payload.status === false) {
                        state.loading = false;
                        state.error = payload.message;
                    } else {
                        state.bank.map(item => {
                            if(item.Id === payload.data.Id) {
                                item.Title = payload.data.Title;
                                item.BankId = payload.data.BankId;
                            }
                        });
                        state.loading = false;
                        state.error = null;
                    }
            })

            .addCase(bankEditActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(bankCreateActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(bankCreateActions.fulfilled, (state, {payload}) => {
                state.loading = false;
                if(payload.status === false) {
                    state.loading = false;
                    state.error = payload.message;
                } else {
                    state.loading = false;
                    state.error = null;
                    state.bank = [...state.bank, {...payload}]
                }
            })

            .addCase(bankCreateActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload.message
            })

            .addCase(bankDeleteActions.pending, (state, {payload}) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(bankDeleteActions.fulfilled, (state, {payload}) => {
                if(payload.status === true){
                    state.loading = false;
                    state.error = null;
                } else {
                    state.loading = false;
                    state.error = payload.message;
                }
            })

            .addCase(bankDeleteActions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    }
});

export default bankReducer.reducer;
