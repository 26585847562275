import React, {forwardRef, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDays, faSave} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import './Info.scss';
import {userUpdateInfoActions} from "../../../redux/actions/userApi";
import { toast, ToastContainer } from 'react-toastify';


const Info = ({user}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);

    const [formData, setFormData] = useState({
        name: user?.Name ?? '',
        surname: user?.Surname ?? '',
        email: user?.Email ?? '',
        telephone: user?.Phone ?? '',
        idnp: user?.IDNP ?? '',
        birthDate: new Date(user?.BirthDate),
        placeOfBirth: user?.PlaceOfBirth ?? '',
        address: user?.Address.YourAddress ?? '',
        addressCurrent: user?.Address.CurrentAddress ?? '',
        noOfChildren: user?.NoOfChildren ?? '',
        familySituation: user?.FamilySituation ?? '',
        image: user?.Image ?? null,
        backgroundImage: user.BackgroundImage ?? null,
    });

    const [workFormData, setWorkFormData] = useState({
        titleCompany: user.Work?.TitleCompany ?? '',
        position: user.Work?.Position ?? '',
        duration: user.Work?.Duration ?? '',
        training: user.Work?.Training ?? ''
    });

    const [incomeFormData, setIncomeFormData] = useState({
        personalSalary: user.Income?.PersonalSalary ?? '',
        otherIncomes: user.Income?.OtherIncomes ?? '',
        familyMemberSalary: user.Income?.FamilyMemberSalary ?? '',
        personalProperty: user.Income?.PersonalProperty ?? '',
        personalImmobile: user.Income?.PersonalImmobile ?? '',
        personalExpenses: user.Income?.PersonalExpenses ?? ''
    });

    const handleInputChangePersonalInfo = (e) => {
        const { name, value } = e.target;
        setFormData((formData) => ({
            ...formData,
            [name]: value
        }));
    };

    const handleBirthDate = (name, value) => {
        setFormData((formData) => ({
            ...formData,
            [name]: value
        }));
    }

    const handleInputChangeWorkPersonalInfo = (e) => {
        const { name, value } = e.target;
        setWorkFormData((workFormData) => ({
            ...workFormData,
            [name]: value
        }));
    };

    const handleInputChangeIncomePersonalInfo = (e) => {
        const { name, value } = e.target;
        setIncomeFormData((incomeFormData) => ({
            ...incomeFormData,
            [name]: value
        }));
    };

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const handleSaveInfo = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const data = {...formData,...workFormData,...incomeFormData};
        dispatch(userUpdateInfoActions({data: data, token})).then(res => {
            toast.success("Successfully Saved", {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    }

    const handleCalendarClose = () => console.log("Calendar closed");
    const handleCalendarOpen = () => console.log("Calendar opened");

    const StyleDataComponent = forwardRef(({ value, onClick }, ref) => (
        <span className="data-input" onClick={onClick} ref={ref}>
          <span className="data">{value} <FontAwesomeIcon icon={faCalendarDays} /></span>
       </span>
    ));

    return (
        <div className="personal-information">
            <form>
                <div className="personal-information-section">
                    <span className="title-section">1. Informație personală</span>
                    <div className="personal-information-input">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            value={formData.name}
                            placeholder="Nume"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="surname"
                            name="surname"
                            type="text"
                            value={formData.surname}
                            placeholder="Prenume"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="idnp"
                            name="idnp"
                            type="text"
                            value={formData.idnp}
                            placeholder="IDNP"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="telephone"
                            name="telephone"
                            type="text"
                            value={formData.telephone}
                            placeholder="Telefon"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            placeholder="Email"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>
                </div>

                <div className="personal-information-section">
                    <span className="title-section">2. Data nașterii și adresa</span>
                    <div className="personal-information-input">
                        <DatePicker
                            selected={formData.birthDate}
                            onChange={(date) => handleBirthDate('birthDate', date)}
                            onCalendarClose={handleCalendarClose}
                            onCalendarOpen={handleCalendarOpen}
                            customInput={<StyleDataComponent />}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="placeOfBirth"
                            name="placeOfBirth"
                            type="text"
                            value={formData.placeOfBirth}
                            placeholder="Locul nasterii"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="address"
                            name="address"
                            type="text"
                            value={formData.address}
                            placeholder="Adresa din buletin"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="addressHome"
                            name="addressCurrent"
                            type="text"
                            value={formData.addressCurrent}
                            placeholder="Adresa de locuinta la moment"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>
                </div>

                <div className="personal-information-section">
                    <span className="title-section">3. Situația familiară</span>
                    <div className="personal-information-input">
                        <select name="familySituation" id="" defaultValue={formData.familySituation} onChange={handleInputChangePersonalInfo}>
                            <option value={1}>Alege</option>
                            <option value={2}>Alege</option>
                            <option value={3}>Alege</option>
                        </select>
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="nrOfCildren"
                            name="noOfChildren"
                            type="text"
                            value={formData.noOfChildren}
                            placeholder="Numărul Copiilor"
                            onChange={handleInputChangePersonalInfo}
                        />
                    </div>
                </div>

                <div className="personal-information-section">
                    <span className="title-section">4. Locul de muncă</span>
                    <div className="personal-information-input">
                        <select name="titleCompany" id="" defaultValue={workFormData.titleCompany} onChange={handleInputChangeWorkPersonalInfo}>
                            <option value={""}>Alege</option>
                        </select>
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="position"
                            name="position"
                            type="text"
                            value={workFormData.position}
                            placeholder="Funcția deținută"
                            onChange={handleInputChangeWorkPersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="term"
                            name="duration"
                            type="text"
                            value={workFormData.duration}
                            placeholder="Durata contractului"
                            onChange={handleInputChangeWorkPersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="trainingOfJob"
                            name="training"
                            type="text"
                            value={workFormData.training}
                            placeholder="Stagiu de lucru"
                            onChange={handleInputChangeWorkPersonalInfo}
                        />
                    </div>
                </div>

                <div className="personal-information-section">
                    <span className="title-section">5. Venituri</span>
                    <div className="personal-information-input">
                        {/*<select>*/}
                        {/*    <option>Salariu personal</option>*/}
                        {/*    <option>Salariu personal</option>*/}
                        {/*    <option>Salariu personal</option>*/}
                        {/*</select>*/}
                        <input
                            id="otherSalary"
                            name="personalSalary"
                            type="text"
                            value={incomeFormData.personalSalary}
                            placeholder="Salariu Personal"
                            onChange={handleInputChangeIncomePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="otherSalary"
                            name="otherIncomes"
                            type="text"
                            value={incomeFormData.otherIncomes}
                            placeholder="Alte Venituri"
                            onChange={handleInputChangeIncomePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="familySalary"
                            name="familyMemberSalary"
                            type="text"
                            value={incomeFormData.familyMemberSalary}
                            placeholder="Salariul membrilor de familie"
                            onChange={handleInputChangeIncomePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="personaleProperty"
                            name="personalProperty"
                            type="text"
                            value={incomeFormData.personalProperty}
                            placeholder="Bunuri personale"
                            onChange={handleInputChangeIncomePersonalInfo}
                        />
                    </div>

                    <div className="personal-information-input">
                        <input
                            id="buildings"
                            name="personalImmobile"
                            type="text"
                            value={incomeFormData.personalImmobile}
                            placeholder="Imobile (Casă, teren…)"
                            onChange={handleInputChangeIncomePersonalInfo}
                        />
                    </div>
                    <div className="personal-information-input">
                        <input
                            id="costs"
                            name="costs"
                            type="text"
                            value={incomeFormData.personalExpenses}
                            placeholder="Chieltuieli lunare"
                            onChange={handleInputChangeIncomePersonalInfo}
                        />
                    </div>
                </div>

                <div className="personal-information-action">
                    <button className="btn" type="submit" onClick={(e) =>  handleSaveInfo(e) }><FontAwesomeIcon icon={faSave}/> Save</button>
                </div>
            </form>
            <ToastContainer autoClose={3000} />
        </div>
    )
}

export default Info;
