import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";
import Header from './Header';
import Footer from "./Footer";
import {categoryGetCategoriesActions} from "../../redux/actions/categoryApi";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Loader/Loader";
import {getMenuActions} from "../../redux/actions/menuApi";
import {socialGetSocialMediaActions} from "../../redux/actions/socialMediaApi";


const Main = () => {
    const dispatch = useDispatch();
    const {loading,category} = useSelector(state => state.category);

    useEffect(() => {
        dispatch(getMenuActions());
        dispatch(socialGetSocialMediaActions());
        dispatch(categoryGetCategoriesActions({expand: 'opportunities,blogs,jobs'}));
    },[]);
    return (
        <>
            {loading && <Loader />}
            {!loading &&
                <>
                 <Header />
                   <Outlet context={[category]} />
                 <Footer category={category}/>
                </>
            }
        </>
    )
}

export default Main;
