import React, { useId } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faImage, faXmark} from "@fortawesome/free-solid-svg-icons";
import { convertFile2Base64 } from '../../utils/file.util';

import './image.scss'

const Image = (props) => {
   return <ImageBase {...props} />
}

const ImageBase = (props) => {
  const {size = 48, onChange, value, withBase64} = props
  const id = useId()

  const fileChangeBase64 = (e) =>
    e.target.files ?
          convertFile2Base64(e.target.files[0])
              .then(
                  (valueItem) => {
                    onChange && onChange(`changed;${valueItem}`)
                  }
              )
          : onChange && onChange(undefined)
   const isImageChanged = () => value?.search('changed;') > -1 ? value.replace('changed;', '') : value

    return (
      <div className="image-ct" style={{ height: size, width: size }}>
        {value ? <>
            <img src={withBase64 ? isImageChanged(value) : value} alt={value} />
              <div className='remove-icon' onClick={() => onChange(undefined)}>
                <FontAwesomeIcon icon={faXmark}/>
              </div>
        </> : <>
          <div className='empty'>
            <FontAwesomeIcon icon={faImage} />
          </div>
                <label className='add-icon' htmlFor={id}>
                 <FontAwesomeIcon icon={faCamera} />
                </label>
                <input onChange={fileChangeBase64} type='file' accept='image/*' id={id} className='hidden' />
        </>
        }
      </div>
  )
}

export default Image
