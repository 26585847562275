import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { editAccountBank } from "../../../../redux/reducer/accountBankReducer";

const AccountBanking = (props) => {
   const dispatch = useDispatch();
   const {accountBank, id, user, bank} = props;
   const [bankId, setBankId] = useState(accountBank.banks_id ?? 0);
   const [IBAN, setIBAN] = useState(accountBank.IBAN ?? '');

   const setBank = (value) => {
       setBankId(value);
       dispatch(editAccountBank({
           id: accountBank.id,
           IBAN: value,
           banks_id: bankId
       }));
   }

   const setIban = (value) => {
       setIBAN(value);
       dispatch(editAccountBank({
           id: accountBank.id,
           IBAN: value,
           banks_id: bankId
       }));
   }

    return (
        <div className="main-card">
            <span className="title-card"><input type="radio" value={id} defaultChecked={1} name="accountBank"/>Cont Bancar</span>
            <div className="account-banking">
                <select name="bank" id="bank" defaultValue={accountBank?.banks_id ?? 0}
                        onChange={event => setBank(event.target.value)}>
                    <option value={0}>Denumire Banca</option>
                    {bank.map(item => <option key={item.Id} value={item.Id}>{item.Title}</option>)}
                </select>
                <input type="text" placeholder="Full Name" disabled value={user?.Name + ' ' + user.Surname}/>
                <input type="text" placeholder="IBAN" maxLength={24} value={IBAN}
                       onChange={event => setIban(event.target.value)}/>
            </div>
        </div>
    );
}

export default AccountBanking;
