import React, {useState} from 'react';
import Select from "react-select";
import Creatable from 'react-select/creatable';

const JobApplications = () => {
    const [fullName, setFullName] = useState();
    const [email, setEmail] = useState();
    const [telephone, setTelephone] = useState();
    const [location, setLocation] = useState();
    const [conditionAdditional, setConditionAdditional] = useState();
    const [functions, setFunctions] = useState();
    const [newCategory, setNewCategory] = useState();
    const [salary, setSalary] = useState();
    const [newTravel, setNewTravel] = useState();
    const [newAccommodation, setNewAccommodation] = useState();
    const [displayNewCategory, setDisplayNewCategory] = useState(false);
    const [displayNewAccommodation, setDisplayNewAccommodation] = useState(false);
    const [displayNewTravel, setDisplayNewTravel] = useState(false);

    const category = [
        {
            value: 'Tamplar',
            label: 'Tamplar'
        },
        {
            value: 'lacatus',
            label: 'Lacatus'
        },
        {
            value: 'educator',
            label: 'Educator'
        },
        {
            value: 'new',
            label: 'Noua Categorie'
        }
    ];
    const accommodation = [
        {
            value: 'Nu necesit',
            label:'Nu necesit'
        },
        {
            value: 'Ajutor din partea Angajatorului',
            label: ' Ajutor din partea Angajatorului'
        },
        {
            value: 'Ajutor informațional',
            label: 'Ajutor informațional'
        },
        {
            value: 'noua modalitate',
            label: 'altă modalitate de ajutor ',
        }
    ];

    const jobTravel = [
        {
            value: 'Nu necesita transport',
            label: 'Nu necesita transport'
        },
        {
            value: 'Transportul Angajatorului',
            label: 'Transportul Angajatorului'
        },
        {
            value: 'Transportul personal',
            label: 'Transportul personal'
        },
        {
            value: 'new option travel',
            label: 'altă modalitate de transportare'
        }
    ]

    const training = [
        {
            value: 0,
            label: 'Training 1'
        },
        {
            value: 1,
            label: 'Training 2'
        },
        {
            value: 2,
            label: 'Training 3'
        },
    ];

    const dueDate = [
        {
            value: 0,
            label: 'cît mai curînd'
        },
        {
            value: 1,
            label: 'dată specifică'
        },
        {
            value: 2,
            label: 'interval'
        },
        {
            value: 3,
            label: 'Urgent'
        }
    ];

    const changeCategory = (selectedOption) => {
        setDisplayNewCategory(false);
        if(selectedOption.value === 'new') {
            setDisplayNewCategory(true);
        }
    }

    const changeAccommodation = (selectedOption) => {
        setDisplayNewAccommodation(false);
        if(selectedOption.value === 'noua modalitate') {
            setDisplayNewAccommodation(true);
        }
    }

    const changeTravel = (selectedOption) => {
        setDisplayNewTravel(false);
        if(selectedOption.value === 'new option travel') {
            setDisplayNewTravel(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <main className="job-applications">
            <div className="container">
                <form>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="categoryJob"
                                               className="block text-sm font-medium text-gray-700">
                                            Categoria <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <Select name="categoryJob" id="categoryjob" placeholder="Alegel Categoria" options={category} menuPosition="fixed" onChange={changeCategory}/>
                                    </div>

                                    { displayNewCategory ? <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="newCategory" className="block text-sm font-medium text-gray-700">
                                                Noua Categorie <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                            </label>
                                            <input
                                                id="newCategory"
                                                name="newCategory"
                                                autoComplete="newCategory"
                                                type="text"
                                                value={newCategory}
                                                onChange={e => setNewCategory(e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        : <></>}

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="trainig" className="block text-sm font-medium text-gray-700">
                                            Postul sau Trainingul solicitat
                                        </label>
                                        <Creatable name="training" id="training" placeholder="Postul sau Trainingul solicitat" isMulti options={training} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
                                            Disponibilitatea implicării
                                        </label>
                                        <Select name="dueDate" id="dueDate" placeholder="Disponibilitatea implicării" options={dueDate} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="newCategory" className="block text-sm font-medium text-gray-700">
                                            Locația solicitantului
                                        </label>
                                        <input
                                            id="conditionAdditional"
                                            name="conditionAdditional"
                                            autoComplete="conditionAdditional"
                                            type="text"
                                            value={conditionAdditional}
                                            onChange={e => setConditionAdditional(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="accommodation"
                                               className="block text-sm font-medium text-gray-700">
                                            Preferință de ajutor la Cazare/închiriere <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <Select name="accommodation" id="accommodation" placeholder="Preferinta de ajutor la Cazare" menuPosition="fixed" options={accommodation} onChange={changeAccommodation}/>
                                    </div>

                                    { displayNewAccommodation ? <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="newAccommodation" className="block text-sm font-medium text-gray-700">
                                            Modalitate de ajutor  <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="newAccommodation"
                                            name="newAccommodation"
                                            autoComplete="newAccommodation"
                                            type="text"
                                            value={newAccommodation}
                                            onChange={e => setNewAccommodation(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div> : <></>}

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="jobTravel"
                                               className="block text-sm font-medium text-gray-700">
                                            Preferință de deplasare la postul de muncă <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <Select name="jobTravel" id="jobTravel" placeholder="Preferinta de deplasare la postul de munca" menuPosition="fixed" options={jobTravel} onChange={changeTravel}/>
                                    </div>

                                    { displayNewTravel ? <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="newTravel" className="block text-sm font-medium text-gray-700">
                                            Altă modalitate de transportare  <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="newTravel"
                                            name="newTravel"
                                            autoComplete="newTravel"
                                            type="text"
                                            value={newTravel}
                                            onChange={e => setNewTravel(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div> : <></>}

                                    <div className="col-span6 sm:col-span-3">
                                            <label htmlFor="salary" className="block text-sm font-medium text-gray-700">
                                                Salariul dorit
                                            </label>
                                            <div className="relative mt-1 rounded-md shadow-sm">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <span className="text-gray-500 sm:text-sm">MDL</span>
                                                </div>
                                                <input
                                                    type="salary"
                                                    name="salary"
                                                    id="salary"
                                                    value={salary}
                                                    onChange={ e => setSalary(e.target.value)}
                                                    className="pl-14 mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="0.00"
                                                />
                                            </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Documente (certificate, diplome)</label>
                                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                        >
                                                            <span>Upload a file</span>
                                                            <input id="file-upload" multiple name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">PDF, JPEG, JPG, DOC, DOCX, XLS</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <h3>Date de contact (direct a persoanei solicitante/responsabile)</h3>
                                <div className="grid grid-cols-6 gap-6">

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                                            Numele Prenume <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="fullName"
                                            name="fullName"
                                            autoComplete="fullName"
                                            type="text"
                                            value={fullName}
                                            onChange={e => setFullName(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            autoComplete="email"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                            Locatie <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="location"
                                            name="location"
                                            autoComplete="location"
                                            type="location"
                                            value={location}
                                            onChange={e => setLocation(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="function" className="block text-sm font-medium text-gray-700">
                                            Functie <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="function"
                                            name="function"
                                            autoComplete="function"
                                            type="text"
                                            value={functions}
                                            onChange={e => setFunctions(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">
                                            Numar de telefon <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                        </label>
                                        <input
                                            id="telephone"
                                            name="telephone"
                                            autoComplete="telephone"
                                            type="text"
                                            value={telephone}
                                            onChange={e => setTelephone(e.target.value)}
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn inline-flex justify-center rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm"
                            >
                                Publica Anuntul
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default JobApplications;
