import React, {useEffect, useState} from 'react';
import 'components/Profiles/MyCertifacte/my-certificate.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import CertificateItem from "components/Profiles/MyCertifacte/CertificateItem";
import {certificateGetCertificatesActions, certificateCreateActions} from "../../../../redux/actions/certificateApi";
import Loader from "../../../../components/Loader/Loader";
import {toast, ToastContainer} from "react-toastify";

const MyCertificate = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const {user} = useSelector(state => state.user);
    const {loading, certificate} = useSelector(state => state.certificate);
    const [token, setToken] = useState(auth.token);

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    useEffect(() => {
        dispatch(certificateGetCertificatesActions({user_id: user?.Id, expand: 'user,tags'}));
    },[]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append("image", file);

            const data = {data: formData, token: token}

            dispatch(certificateCreateActions(data)).then(res => toast.success("Successfully Saved", {
                position: toast.POSITION.TOP_CENTER,
            }));
        }
    };

    return (
        <>
            { loading && <Loader /> }
            { !loading &&
                <section className="my-certificate-section">
                    <div className="certificate-add">
                        <input type="file" accept="image/*" id="image" onChange={handleImageUpload} style={{display: 'none'}}/>
                        <label htmlFor="image" style={{display: "flex",justifyContent:"center",alignItems:"center"}}><span>Adauga un certificat nou</span> <span className="icon"><FontAwesomeIcon icon={faPlus} /></span></label>
                    </div>

                    {certificate && certificate.map(item => <CertificateItem key={item.Id} certificate={item} />)}
                    <ToastContainer autoClose={3000} />
                </section>
            }
        </>
    )
}

export default MyCertificate;
