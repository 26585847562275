import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCompanies, editCompany, addCompany, deleteCompany} from "../../service/CompanyService";

export const companyGetCompanyActions = createAsyncThunk(
    'company/companyGetCompanyActions',
    async (params = {}) => {
        try {
            return await getCompanies(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const companyEditActions = createAsyncThunk(
    'company/companyEditActions',
    async({Id, data, token}, thunkAPI) => {
        try{
            return await editCompany(Id, data, token).then(response => response.data.data);
        } catch (error) {
            return error.message;
        }
    }
)

export const companyCreateActions = createAsyncThunk(
    'company/companyCreateActions',
    async({data, token}) => {
        try{
            return await addCompany(data, token).then(response => response.data.data).catch(error => error.response.data);
        } catch (error) {
            return error.message
        }
    }
)

export const companyDeleteActions = createAsyncThunk(
    'company/companyDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteCompany(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
