import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";

const EducationVisible = (props) => {
  const {education, editItem, editButton} = props;

    return (
        <>
          <div className="education-item-personal">
            <div className="education-item-image">
                <img src={(education.Logo === '' || education.Logo === null) ? require("@images/education-image-default.png") : (education.Logo?.search('changed;') > -1) ? education.Logo.replace('changed;', '') : education.Logo } className="image" alt={education.Speciality} />
            </div>
            <div className="personal-data">
              <span className="title-education">{education.TitleInstitution}</span>
              <span className="title-university">{education.Speciality} <div className="break"></div> {education.TitleCertificate}</span>
              <span className="period-time">General, {<Moment local date={education.StartDate} format="YYYY"/>} - {<Moment local date={education.EndDate} format="YYYY"/>}</span>
              <span className="grade">Grade: {education.Domain}</span>
            </div>
          </div>
          {editButton ? <span className="career-edit-item" onClick={() => editItem(true)}><FontAwesomeIcon icon={faPencil}/></span> : <></> }
        </>
    )
}

export default EducationVisible;
