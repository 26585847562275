import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { useFormInputValidation } from "react-form-input-validation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookF, faGooglePlusG, faTwitter } from "@fortawesome/free-brands-svg-icons";
import './Form.scss';
import {useDispatch, useSelector} from "react-redux";
import { registerActions } from "../../redux/actions/authApi";
import {useTranslation} from "react-i18next";
import { Parser } from 'html-to-react'


const RegisterComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {token, error} = useSelector(state => state.auth);

    const [fields, errors, form] = useFormInputValidation({
        email: "",
        password: "",
        password_confirmation: "",
        confirm: "",
    }, {
        email: "required|email",
        password: "required",
        password_confirmation: "required|same:password",
        confirm: "boolean"
    });


    const handleSubmit = async (e,roleType) => {
        e.preventDefault();
            const param = {
                'name': fields.email,
                'email': fields.email,
                'password': fields.password,
                'confirm_password': fields.password_confirmation,
                'user_types_id': roleType
            }

            dispatch(registerActions(param));
    }

    useEffect(() => {
        if(token){
            navigate('/login');
        }
    },[token]);

    return (
        <div className="register-component form-component">
            <form noValidate autoComplete="off" className="Form">
                <div className="form-input">
                    <input
                        type="text"
                        name="email"
                        placeholder={t('Register.Email')}
                        value={fields.email}
                        onBlur={form.handleBlurEvent}
                        onChange={form.handleChangeEvent}
                        data-attribute-name="Email"
                    />


                    {errors.email ? <span className="form-input-error">{errors.email}</span> : "" }
                </div>

                <div className="form-input">
                    <input
                        type="password"
                        name="password"
                        placeholder={t('Register.Password')}
                        value={fields.password}
                        onBlur={form.handleBlurEvent}
                        onChange={form.handleChangeEvent}
                        data-attribute-name="Password"
                    />
                    {errors.password ? <span className="form-input-error">{errors.password}</span> : "" }
                </div>

                <div className="form-input">
                    <input
                        type="password"
                        name="password_confirmation"
                        placeholder={t('Register.ConfirmPassword')}
                        value={fields.password_confirmation}
                        onBlur={form.handleBlurEvent}
                        onChange={form.handleChangeEvent}
                        data-attribute-name="Password Confirmation"
                    />
                    {errors.password_confirmation ? <span className="form-input-error">{errors.password_confirmation}</span> : "" }
                </div>

                <div className="form-input-radio">
                    <input type="radio" name="confirm" value={1} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} data-attribute-name="confirm" />
                        <label htmlFor="confirm">{Parser().parse(t('Register.Accept'))}</label>
                </div>

                <div className="registration-action">
                    <button type="submit" onClick={ e => handleSubmit(e,7)}>{t('Register.btnCreateEmployee')} </button>

                    <button type="submit" onClick={ e => handleSubmit(e,8)} >{t('Register.btnCreateUser')}</button>
                </div>

                {error && <span className="form-input-error">{error}</span>}
            </form>

            <div className="other-section">
                <div className="horizontalLine"></div>
                Or
                <div className="horizontalLine"></div>
            </div>

            <div className="social-login-section">
                <button
                    className="socialButton">
                    <FontAwesomeIcon icon={faFacebookF} />
                </button>
                <button
                    className="socialButton">
                    <FontAwesomeIcon icon={faGooglePlusG} />
                </button>
                <button
                    className="socialButton">
                    <FontAwesomeIcon icon={faTwitter} />
                </button>
            </div>
            <button className="form-button">{t('Register.AuthWithMPass')}</button>
        </div>
    )
}

export default RegisterComponent;
