import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencil} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import Image from "../Image/Image";

const InterestVisible = (props) => {
  const {interest, editItem, editButton} = props;

    return (
        <>
          <div className="interest-item-personal">
            <div className="interest-item-image">
              <img src={require("@images/courses.jpg")} className="image" alt={interest.fullName} />
            </div>
            <div className="personal-data">
              <span className="full-name">{interest.fullName}</span>
              <span className="job">{interest.job}</span>
              <span className="followers">{ interest.followers } followers</span>
              <span className="following-btn"><FontAwesomeIcon icon={faCheck} /> Following</span>
            </div>
          </div>
          {editButton ? <span className="interests-edit-item" onClick={() => editItem(true)}><FontAwesomeIcon icon={faPencil}/></span> : <></> }
        </>
    )
}

export default InterestVisible;
