import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleInfo, faBriefcase, faCog} from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

const Agreement = () => {
    return (
      <main className="agreement-page bg-gray-100">
         <h1 className="text-3xl text-center my-2">Protectie datelor personale</h1>

          <div className="container grid grid-flow md:grid-cols-12 grid-cols-1 gap-5">
              <div className="md:col-span-9 col-span-1 bg-white shadow p-4 mb-5 whitespace-pre-wrap break-words">
                  <h2 className="text-3xl text-center mb-2">TERMENELE ȘI CONDIȚIILE PRIVIND PROTECȚIA DATELOR CU CARACTER PERSONAL ȘI COMERȚUL ELECTRONIC</h2>
                  <h3 className="text-2xl mb-2">I. PROTECȚIA DATELOR CU CARACTER PERSONAL</h3>

                  <span className="text-2xl block my-3">1. Operatorul de date cu caracter personal</span>
                  <p>1.1. Compania ,,MANTIS HR” SRL, a stabilit scopurile și mijloacele de prelucrare a datelor cu caracter personal în sistemul de evidență al solicitanților de joburi, astfel, având calitatea de operator de date cu caracter personal (în continuare «operator»).
                      Operatorul este înregistrat sub numărul 0001663 în Registrul de evidență al operatorilor de date cu caracter personal (www.registru.datepersonale.md) și a fost autorizat de Centrul Național pentru Protecția Datelor cu Caracter Personal de a prelucra datele cu caracter personal ale clienților săi. Autorizarea de către Autoritatea responsabilă de regimul juridic al protecției datelor cu caracter personal demonstrează că operatorul prin măsurile organizatorice și tehnice adoptate asigură un nivel adecvat de protecție a datelor cu caracter personal.</p>

                  <span className="text-2xl block my-3">2. Termenii utilizați în prezentul Regulament au următoarea semnificație:</span>
                  <ul>
                      <li><b>consumator</b> - orice persoană fizică ce acționează în alte scopuri decât cele ale activității comerciale;</li>
                      <li><b>- creare de profiluri</b> – înseamnă orice formă de prelucrare automată a datelor cu caracter personal care constă în utilizarea datelor cu caracter personal pentru a evalua anumite aspecte personale referitoare la o persoană fizică, în special pentru a analiza sau prevedea aspecte privind performanța la locul de muncă, situația economică, sănătatea, preferințele personale, interesele, fiabilitatea, comportamentul, locul în care se află persoana fizică respectivă sau deplasările acesteia etc.;</li>
                      <li><b>- date cu caracter personal</b> – orice informație referitoare la o persoană fizică identificată sau identificabilă;</li>
                      <li><b>- categorii speciale de date cu caracter personal</b> – datele care dezvăluie originea rasială sau etnică a persoanei, convingerile ei politice, religioase sau filozofice, apartenența socială, datele privind starea de sănătate sau viața sexuală, precum și cele referitoare la condamnările penale, măsurile procesuale de constrângere sau sancțiunile contravenționale;</li>
                      <li><b>- subiect al datelor cu caracter personal</b> – este orice persoană fizică care poate fi identificată, direct sau indirect, prin referire la un număr de identificare sau la unul ori mai multe elemente specifice identității sale fizice, fiziologice, psihice, economice, culturale sau sociale;</li>
                      <li><b>- prelucrarea datelor cu caracter personal</b> – orice operațiune sau serie de operațiuni care se efectuează asupra datelor cu caracter personal prin mijloace automatizate sau neautomatizate, cum ar fi colectarea, înregistrarea, organizarea, stocarea, păstrarea, restabilirea, adaptarea ori modificarea, extragerea, consultarea, utilizarea, dezvăluirea prin transmitere, diseminare sau în orice alt mod, alăturarea ori combinarea, blocarea, ștergerea sau distrugerea;</li>
                      <li><b>- sistem de evidență a datelor cu caracter personal</b> – orice serie structurată de date cu caracter personal accesibile conform unor criterii specifice, fie că este centralizată, descentralizată ori repartizată după criterii funcționale sau geografice;</li>
                      <li><b>- operator</b> – persoana fizică sau persoana juridică de drept public sau de drept privat, inclusiv autoritatea publică, orice altă instituție ori organizație care, în mod individual sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal prevăzute în mod expres de legislația în vigoare;</li>
                      <li><b>- persoană împuternicită de către operator</b> - persoana fizică sau persoana juridică de drept public ori de drept privat, inclusiv autoritatea publică și subdiviziunile ei teritoriale, care prelucrează date cu caracter personal în numele și pe seama operatorului, pe baza instrucțiunilor primite de la operator;</li>
                      <li><b>- terț</b> – persoană fizică sau persoană juridică de drept public ori de drept privat, alta decât subiectul datelor cu caracter personal, decât operatorul ori persoana împuternicită de către operator și decât persoana care sub autoritatea directă a operatorului sau a persoanei împuternicite este autorizată să prelucreze date cu caracter personal;</li>
                      <li><b>- pseudonimizare</b> - înseamnă prelucrarea datelor cu caracter personal într-un asemenea mod încât acestea să nu mai poată fi atribuite unei anume persoane vizate fără a se utiliza informații suplimentare, cu condiția ca aceste informații suplimentare să fie stocate separat și să facă obiectul unor măsuri de natură tehnică și organizatorică care să asigure neatribuirea respectivelor date cu caracter personal unei persoane fizice identificate sau identificabile;</li>
                      <li><b>- Autoritatea responsabilă</b> - se are în vedere Centrul Național pentru Protecția Datelor cu Caracter Personal;</li>
                  </ul>


                  <span className="text-2xl block my-3">3. Cine poate utiliza site-ul www.jobhub.md</span>
                  <ul>
                      <li>3.1. Completarea anchetei și înregistrarea pe site poate fi efectuată de către orice persoană fizică, în nume propriu și doar dacă aceasta a atins vârsta de 16 ani, sau în cazul împlinirii vârstei de 15 ani, ancheta se va completa cu condiția existenței acordului scris al părintelui sau al reprezentanților legali.</li>
                      <li>3.2. Activitățile desfășurate de operator sunt destinate persoanelor rezidente în Republica Moldova.</li>
                      <li>3.3. Toate informațiile accesibile pe site care vizează conținutul de text, imagine, structură etc. sunt destinate în exclusivitate persoanelor fizice – solicitanți de joburi și persoanelor juridce care sunt în căutarea de angajați, în scop de utilizare personală și sunt protejate în conformitate cu legislația națională privind drepturile de autor și drepturile conexe. Orice copiere sau utilizare a acestor informații în alte scopuri decât cel enunțat este interzisă și duce la răspundere în conformitate cu legislația în vigoare.</li>
                  </ul>

                  <span className="text-2xl block my-3">4. Modul de autentificare în sistem</span>
                  <ul>
                      <li>4.1. Înregistrarea în sistem se efectuează prin accesarea paginii web www.jobhub.md, fiind necesar a fi consemnate următoarele categorii de date: adresa de email și parola care va conține cel cu o mărime de minimum 8 simboluri, care nu sînt legate de informaţia cu caracter personal a utilizatorului, nu conţin simboluri identice consecutive şi nu sînt compuse integral din grupuri de cifre sau litere. La adresa de email se va expedia un link de validare a înregistrării.</li>
                      <li>4.2. Înregistrarea va putea fi efectuată cu succes numai în urma completării tuturor câmpurilor obligatorii. Parola este confidențială și nu poate fi dezvăluită către alte persoane terțe.</li>
                      <li>4.3. Succesiv se va completa ancheta prin adăugare de CV. Completarea rubricilor marcate cu * sunt obligatorii a fi completate. La finalizarea completării rubricilor specifice, subiectul de date cu caracter personal confirmă prin bifare că datele cu caracter personal introduse de către acesta sunt veridice și îi aparțin.</li>
                      <li>4.4. Prin realizarea acestor pași, dvs. veți fi incluși în lista clienților (consumatorilor) din sistemul de evidență (baza de date) al solicitanților de joburi.</li>
                  </ul>

                  <span className="text-2xl block my-3">5. Categoriile de date cu caracter personal prelucrate</span>
                  <ul>
                      <li>
                          5.1. Operatorul va colecta următoarele categorii de date cu caracter personal:
                          <ul>
                              <li><i>- Obligatorii</i>, nume, prenume, data nașterii, localitatea de reședință, număr de telefon, poziția dorită, domeniul de activitate, salariul aproximativ, programul de lucru, poziție, organizație, domeniul de activitate, oraș, angajat din, eliberat din funcție în, eliberat din funcție în, sarcini, poziții realizări, studii deținute (instituția emitentă, specialitatea, anul de absolvire), competențe cheie, competențe lingvistice (limba maternă, cunoașterea limbei române/ruse),</li>
                              <li><i>- Facultative</i>, sexul, starea civilă, copii, email, existența experienței anterioare, recomandările angajaților precedenți (nume, poziție, organizație, telefon), portofoliu, Instruire continuă, cursuri de formare (denumire, organizator, oraș, absolvit în, dacă este student), cunoașterea altor limbi decât română/rusă, disponibilitatea privind călătoriile de afaceri, deținerea permisului de conducere, deținerea autovehicolului personal, adresă skype, informații despre solicitant după caz, alți identificatori ai subiectului de date în cazul în care aceste date vor fi consemnate la cererea acestuia prin: adresare, solicitare, reclamație sau orice alt mod în legătură cu obiectul prestării serviciilor.</li>
                          </ul>
                      </li>
                      <li>5.2. În cadrul acestui sistem de evidență este interzisă colectarea categoriei speciale a datelor cu caracter personal.</li>
                  </ul>

                  <span className="text-2xl block my-3">6. Scopul prelucrării datelor cu caracter personal</span>
                  <ul>
                      <li>6.1. Datele cu caracter personal sunt colectate și prelucrate în cadrul sistemului de evidență al solicitanților de joburi în scop de:
                        <ul>
                            <li>a) ținere a evidenței consumatorilor/clienților care sunt în căutare de job sau care caută să angajeze;</li>
                            <li>b) oferirea suportului privind serviciile platformei www.jobhub.md;</li>
                            <li>c) onorarea altor obligații ce rezultă din raporturile juridice stabilite cu consumatorii/clienții.</li>
                        </ul>
                      </li>
                      <li>6.2. Operatorul nu prelucrează datele cu caracter personal în alte scopuri decât cele indicate.</li>
                  </ul>

                  <span className="text-2xl block my-3">7. Notificarea privind transferul transfrontalier</span>
                  <ul>
                      <li>7.1. Datele cu caracter personal vor fi stocate pe serverele din Germania, însă sub monitorizarea și controlul operatorului.</li>
                  </ul>

                  <span className="text-2xl block my-3">8. Temeiul legal pentru prelucrarea datelor cu caracter personal</span>
                  <ul>
                      <li>8.1. Nu se va încheia un consimțământ separat dacă datele sunt colectate direct de la client (prin intervievarea/chestionarea față în față sau prin telefon, sau datele vor fi înregistrate în sistem prin interfața web nemijlocit de către utilizator), în cazul:
                        <ul>
                            <li>a) acțiunilor necesare înaintea încheierii contractului ce se referă la deschiderea profilului de utilizator pe pagina www.jobhub.md, perfectarea și examinarea anchetei consumatorului, preluarea comenzii;</li>
                            <li>b) activităților indispensabile executării contractului care se referă la ținerea evidenței consumatorilor, oferirea suportului față de serviciile www.jobhub.md, precum și alte aspecte de ordin civil care rezultă din raporturile juridice încheiate.</li>
                        </ul>
                      </li>
                      <li>8.2. În toate cazurile de prelucrare a datelor cu caracter personal în lipsa unui consimțământ încheiat separat, operatorul sau persoanele împuternicite de către operator vor informa subiecții de date cel puțin despre: scopul pentru care sunt colectate datele, temeiul legal, volumul și categoriile de date, termenul de stocare, modul de utilizare, persoanele împuternicite de către operator precum și drepturile acestora.</li>
                      <li>8.3. Operatorul de date cu caracter personal informează că datele cu caracter personal pot fi utilizate și în alte scopuri prevăzute expres de lege, cum ar fi: la solicitarea organelor din sectorul polițienesc sau organelor cu funcție de control - activități pe care operatorul de date nu le poate prestabili însă le ia în calcul atunci când colectează datele cu caracter personal. În cazul unor astfel de situații, operatorul de date va verifica corespunderea solicitării sub aspect de respectare a principiilor de protecție a datelor cu caracter personal și o va executa doar în cazul existenței scopului și temeiului legal.</li>
                  </ul>

                  <span className="text-2xl block my-3">9. Destinatarii datelor cu caracter personal</span>
                  <ul>
                      <li>
                          9.1. Operatorul poate dezvălui datele cu caracter personal către:
                         <ul>
                             <li>a) persoanele împuternicite de către operator;</li>
                             <li>b) subiectul de date sau reprezentantul său legal;</li>
                             <li>c) organele de control la solicitarea acestora;</li>
                             <li>d) companiile partenere.</li>
                         </ul>
                      </li>
                      <li>9.2. Transmiterea către alte persoane terțe este interzisă.</li>
                  </ul>

                  <span className="text-2xl block my-3">10. Prelucrarea datelor cu caracter personal în contextul utilizării serviciilor de comerț electronic ale solicitanților de joburi.</span>
                  <ul>
                      <li>10.1. În conformitate cu dispozițiile Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor), şi Legea nr. 133/2011 privind protecţia datelor cu caracter personal al Republicii Moldova, compania ,,MANTIS HR” SRL are obligația de a prelucra în condiții de siguranță și numai pentru scopurile specificate mai jos, datele personale pe care ni le furnizați despre dumneavoastră.</li>
                      <li>10.2. Informațiile Dumneavostră personale de identificare nu vor fi vândute, schimbate, transferate sau date oricăror terți, indiferent de motive, cu excepția companiilor beneficiare ale serviciilor www.jobhub.md care sunt în căutarea unor angajați, inclusiv companiilor împuternicite ale operatorului.</li>
                      <li>10.3. Prin utilizarea serviciilor de comerț electronic, acceptaţi termenii și condițiile de protecție a datelor cu caracter personal. Datele personale se vor putea prelucra doar în scopurile prevăzute mai sus atat timp cât este necesar conform legislaţiei fiscale în vigoare.</li>
                      <li>10.4. Compania «MANTIS HR» SRL va prelucra datele cu caracter persoanl pe perioada existenței raporturilor juridice cu consumatorii/beneficiarii serviciilor www.jobhub.md, dar nu mai mare decât perioada de 6 ani, termen în care operatorul de date va realiza actualizarea datelor și verificarea intenției beneficiarilor de a continua relația juridică.</li>
                      <li>10.5. Termenul de stocare al datelor ar putea fi extins, în situația în care, cu condiția existenței unui interes legitim al operatorului, atunci când spre exemplu: Autoritatea de supraveghere și/sau alte organe de control, inițiază anumite investigaţii conform termenelor prevăzute în Codului administrativ, Codul penal/contravențional sau alte legi specifice.</li>
                  </ul>

                  <span className="text-2xl block my-3">11. Drepturile subiecților de date cu caracter personal</span>
                  <ul>
                      <li>11.1. Dreptul la informare - constă în dreptul de a fi informat înainte de a fi colectate și prelucrate datele cu caracter personal cu privire la identitatea operatorului, scopul în care se face prelucrarea datelor, destinatarii sau categoriile de destinatari ai datelor, existența drepturilor prevăzute de Legea privind protecția datelor cu caracter personal, precum și condițiile în care pot fi exercitate. Subiectul este in dreptul de a explora metoda de procesare a datelor cu caracter personal pe pagina oficiala a companiei la care a aplicat CV-ul sau printr-o cerere individuala.</li>
                      <li>11.2. Dreptul de acces la date - constă în dreptul de a obține de la operator în temeiul unei cereri, confirmarea/infirmarea faptului dacă datele cu caracter personal care-l vizează au fost sau nu prelucrate, informații referitoare la scopurile și categoriile de date prelucrate, destinatarii sau categoriile de destinatari cărora le sânt dezvăluite datele, modul în care se efectuează prelucrarea automatizată a datelor, consecințele juridice generate de prelucrarea datelor pentru subiectul de date și modul de exercitare a dreptului de intervenție asupra datelor cu caracter personal.</li>
                      <li>11.3. Dreptul de intervenție - constă în obținerea în baza unei cereri, rectificarea, actualizarea, blocarea, ștergerea sau transformarea în date anonime a informațiilor a căror prelucrare nu este conformă cu cerințele Legii privind protecția datelor cu caracter personal, în special a datelor incomplete sau inexacte.</li>
                      <li>11.4. Dreptul de opoziție - constă în dreptul de a se opune în orice moment, din motive întemeiate și legitime legate de situația sa particulară, că datele care îl vizează să facă obiectul unei prelucrări, cu excepția cazurilor în care există dispoziții legale care prevăd altfel.</li>
                      <li>11.5. Dreptul de a nu fi supus unei decizii individuale - constă în posibilitatea de a cere și de a obține retragerea, anularea sau reevaluarea oricărei decizii care produce efecte juridice în privința subiectului de date care a fost adoptată exclusiv pe baza unei prelucrări automatizate destinate să evalueze unele aspecte ale personalității sale, precum competența profesională, credibilitatea, comportamentul ori alte asemenea aspecte.</li>
                      <li>11.6. Dreptul la justiție - constă în dreptul de a se adresa Centrului Național pentru Protecția Datelor cu Caracter Personal sau instanței de judecată, pentru apărarea sau repunerea în drepturile lezate. Așadar, aveți dreptul să depuneți o plângere Centrului Național pentru Protecția Datelor cu Caracter Personal dacă nu sunteți mulțumit de orice aspect al prelucrării datelor personale de către noi sau credeți că nu ne îndeplinim responsabilitățile noastre de operator de date. Detaliile de contact ale Autorității sunt: MD-2004, Republica Moldova, mun. Chișinău, str. Serghei Lazo, 48, tel:  +373-22-820801, fax: +373-22-820807, e-mail: centru@datepersonale.md</li>
                      <li>11.7. Pentru exercitarea acestor drepturi, vă puteți adresa cu o cerere scrisă transmisă prin utilizarea serviciilor poștale adresa: mun. Chișinău, str. Vasile Alecsandri 11, of. 53 în atenția Responsabilului de protecția datelor cu caracter personal sau prin utilizarea serviciilor de poștă electronică la adresa <a href="mailto:info@jobhub.md">info@jobhub.md</a>. Vom răspunde cererii în termen de 15 zile de la data primirii acesteia.</li>
                      <li>11.8. De asemeni, puteți refuza furnizarea datelor cu caracter personal către compania ,,MANTIS HR” SRL. Refuzul de a furniza astfel de date poate duce la imposibilitatea companiei de a furniza serviciile www.jobhub.md.</li>
                  </ul>

                  <span className="text-2xl block my-3">12. Fișiere Cookie</span>
                  <ul>
                      <li>12.1. Operatorul de date prelucrează două tipuri de cookie-uri: pe sesiune și fixe. Cele din urmă sunt fișiere temporare ce rămân în terminalul utilizatorului până la terminarea sesiunii sau la închiderea aplicației (browserului web).</li>
                      <li>12.2 Cookie-urile în sine nu solicită și nu sunt alăturate altor informații adiționale care ar putea duce la identificarea vizitatorilor neînregistrați.</li>
                      <li>12.3. La terminarea sesiunii de internet, modulele cookie de sesiune sunt șterse automat.</li>
                  </ul>

                  <span className="text-2xl block my-3">13. Blocarea accesului la site</span>
                  <ul>
                      <li>
                          13.1. Accesul la site poate fi blocat în cazul în care:
                         <ul>
                             <li>a) De către dvs. au fost încălcate regulile și cerințele prevăzute mai sus;</li>
                             <li>b) A fost realizat dreptul de opoziție;</li>
                             <li>c) Dacă dvs. ați creat mai multe profile de utilizator cu identități diferite.</li>
                         </ul>
                      </li>
                  </ul>

                  <span className="text-2xl block my-3">14. Cadrul juridic aplicabil și jurisdicția</span>

                  <ul>
                      <li>14.1. Operațiunile de prelucrare a datelor cu caracter personal efectuate în baza de date al solicitanților de joburi sunt efectuate sub supravegherea operatorului din Republica Moldova.</li>
                      <li>14.2. Indiferent de sediul juridic al persoanelor împuternicite de către operator, la prelucrarea datelor cu caracter personal aceștia vor asigura conformitatea și securitatea prelucrării datelor cu caracter personal cel puțin la nivelul următoarelor acte naționale și internaționale:
                        <ul>
                            <li>a) Convenția nr. 108 pentru protecția persoanelor referitor la prelucrarea automatizată a datelor cu caracter personal ( <a href="http://old.datepersonale.md/md/international003/" target="_blank">http://old.datepersonale.md/md/international003/</a>);</li>
                            <li>b) Reguamentul General privind Protecția Datelor nr. 679/2016, (<a href="https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX%3A32016R0679)" target="_blank">https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX%3A32016R0679</a>);</li>
                            <li>c) Legea Republicii Moldova, nr. 133/2011 privind protecția datelor cu caracter personal (<a href="https://www.legis.md/cautare/getResults?doc_id=122546&lang=ro" target="_blank">https://www.legis.md/cautare/getResults?doc_id=122546&lang=ro</a>);</li>
                            <li>d) Legea Republicii Moldova, nr. 284/2004 privind comerțul electronic (<a href="https://www.legis.md/cautare/getResults?doc_id=107529&lang=ro" target="_blank">https://www.legis.md/cautare/getResults?doc_id=107529&lang=ro</a>);</li>
                            <li>e) Hotărârea Guvernului Republicii Moldova nr. 1123 din 14.12.2010 privind aprobarea Cerințelor față de asigurarea securității datelor cu caracter personal la prelucrarea acestora în cadrul sistemelor informaționale de date cu caracter personal (<a href="https://www.legis.md/cautare/getResults?doc_id=16012&lang=ro" target="_blank">https://www.legis.md/cautare/getResults?doc_id=16012&lang=ro</a>);</li>
                            <li>f) alte acte normative relevante.</li>
                        </ul>
                      </li>
                  </ul>
              </div>
              <div className="md:col-span-3 col-span-1 bg-white shadow p-3 sm:h-auto md:h-1/5">
                  <span className="block my-3 text-xl"><FontAwesomeIcon icon={faCircleInfo} /> Despre noi</span>
                  <ul className="text-left">
                      <li><a href="">Opiniile clienților</a></li>
                      <li><a href="">Statistica site-ului</a></li>
                      <li><a href="">Despre noi</a></li>
                  </ul>

                  <span className="block my-3 text-xl"><FontAwesomeIcon icon={faBriefcase} /> Angajatorilor</span>
                  <ul className="text-left">
                      <li><a href="">Vip Joburi</a></li>
                      <li><a href="">Joburi urgente</a></li>
                      <li><a href="">Acces la baza de CV-uri</a></li>
                      <li><a href="">Cele mai bune anunturi</a></li>
                      <li><a href="">Pagina Personalizata</a></li>
                      <li><a href="">Logoul pe prima pagina</a></li>
                      <li><a href="">Publicitate tip banner</a></li>
                      <li><a href="">Recrutare de personal</a></li>
                      <li><a href="">Abonament anual 2022</a></li>
                  </ul>

                  <span className="block my-3 text-xl"><FontAwesomeIcon icon={faFile} /> Publicatii</span>

                  <ul className="text-left">
                      <li><a href="">Blog</a></li>
                      <li><a href="">Stirile site-ului</a></li>
                  </ul>

                  <span className="block my-3 text-xl"><FontAwesomeIcon icon={faCog} /> Servicii</span>

                  <ul className="text-left">
                      <li><a href="">Contacteaza moderatorul</a></li>
                      <li><a href="">Ajutor (FAQ)</a></li>
                      <li><a href="">Termeni si Conditii</a></li>
                  </ul>
              </div>
          </div>
      </main>
    );
}

export default Agreement;
