import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import "./JobsComponent.scss";

const ItemJob = ({CV,Data}) => {
    return (
        <div className="job-item">
          <div className="job-item--data">
              <div className="personal-data">
                  <input type="checkbox" name="job" className="selectItem"/>
                  <div className="job-item--data-image">
                      <img src={(CV.Image !== null && CV.Image !== undefined) ? CV?.Image : require("@images/happy-young-man.png")} alt={CV?.Name}/>
                  </div>
                  <span className="cv-name">{CV?.Name}</span>
              </div>
              <div className="data-job">
                  {Data}
              </div>
          </div>
          <div className="job-item--actions">
              <div className="job-item--actions--firstSection">
                  <span className="action-btn"><FontAwesomeIcon icon={faComment} />Comentează</span>
                  <span className="action-btn"><FontAwesomeIcon icon={faDownload} />Descarcă</span>
              </div>

              <div className="job-item--actions--lastSection">
                  <div className="radio-filter">
                      <label>
                          <input id="interestingCV" name="interestingCV" value="true" type="radio" checked={true}/>CV-uri Interesante
                      </label>
                      <label>
                          <input id="interestingCV" name="interestingCV" value="false" type="radio"/>CV-uri neînteressante
                      </label>
                  </div>

                  <select className="tags-filter" name="" id="">
                      <option value="">Toate tagurile</option>
                      <option value="">Ca Vazut</option>
                      <option value="">Ca Nevezut</option>
                  </select>
              </div>
          </div>
        </div>
    );
}

export default ItemJob;
