import React, {useMemo, useState} from 'react';
import CollaborationOfferItem from "components/Courses/ColaborationOfferItem";
import Pagination from "components/Pagination/Pagination";

const CollaborationOffers = () => {
    let pageSize = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const date = [{},{},{},{},{}];

    const data = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return date.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return (
        <section className="collaboration-offers-section">
              <CollaborationOfferItem />
              <CollaborationOfferItem />
              <CollaborationOfferItem />
              <CollaborationOfferItem />

            <div className="pagination" style={{marginTop: '45px'}}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={date.length}
                    pageSize={pageSize}
                    template="template-two"
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </section>
    );
}

export default CollaborationOffers;
