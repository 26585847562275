import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {faFolderPlus} from "@fortawesome/free-solid-svg-icons";
import {jobGetJobsActions} from "../../../../../redux/actions/jobsApi";
import Loader from "components/Loader/Loader";
import ActiveJobItem from "components/Job/ActiveJobItem";
import ActionButton from "components/Filters/ActionsButton/ActionButton/ActionButton";

const ActiveJobs = () => {
    const dispatch = useDispatch();
    const {loading, error, jobs} = useSelector(state => state.jobs);
    const {company, loader} = useSelector(state => state.user)

    useEffect(() => {
        if(company){
            if(company.Id){
                dispatch(jobGetJobsActions({expand: 'user,applications,applications.user,applications.guest', 'company_id': company?.Id}));
            }
        }
    },[company])

    return (
        <>
            {/*{error && <p>Something went wrong</p>}*/}
            { loading && <Loader /> }
            { !company && <p>First of all, you need to create the company</p> }
            {!loading && jobs &&
                <>
                    <ActionButton text="Add Job" path={"/dashboard/job/add"} icon={faFolderPlus} action="add" className="add"/><br/><br/>
                    {jobs[0]?.Jobs.map(job => <ActiveJobItem key={job?.Id} job={job}/> )}
                </>
            }
            { !loading && jobs.length === 0 && <p>There are no jobs</p>}
        </>
    );
}

export default ActiveJobs;
