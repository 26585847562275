import {apiClient} from "./index";

export const getTranslation = (params) => {
    return apiClient.get('/api/translation',{params}).then(response => response.data.data);
}

export const editTranslation = ({data, token}) => {
    apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    return apiClient.post(`/api/translation-update?_method=PUT`,data);
}
