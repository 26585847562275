import {apiClient} from "./index";

class AuthService {
    login(credentials) {
        return apiClient.post('/oauth/token',credentials,
            {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            });
    }

    register(credentials) {
        return apiClient.post('/register',credentials,
            {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            });
    }

    forgot(credentials) {
        return apiClient.post('/forgot',credentials,{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }).then(response => response).catch(error => error);
    }

    reset(credentials) {
        return apiClient.post('/reset',credentials,{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }).then(response => response).catch(error => error);
    }

    getAuth(token){
        apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        return apiClient.post('/api/user/info',{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        })
    }

    updateUser(data, token){
        apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        return apiClient.post('/api/user/update',data,{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        })
    }

    logout(token){
        apiClient.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        return apiClient.post('/logout',{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        })
    }
}

export default new AuthService;

