import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faUserCheck} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";

const InterviewEmployerItem = () => {
    const data = '12.04.2023';
    const id = 1;
    return (
        <div className="interview-item">
            <div className="data_main_interview">
                <span className="data_main_data">
                    <Moment local date={data} format="D" />
                </span>
                <span className="data_main_month">
                    <Moment local date={data} format="MMM" />
                </span>
            </div>
           <div className="interview-content">
               <h2 className="accordion-header" id={"heading" + id}>
                   <button className="accordion-button collapsed relative focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+ id} aria-expanded="true"
                           aria-controls={"collapse"+ id}>
                       <div className="header-detail-interview">
                           <div className="header-title">
                               <FontAwesomeIcon icon={faUserCheck} />
                              <span className="courseTitle">2 interviuri</span>
                           </div>

                           <div className="symbol-plus"><FontAwesomeIcon icon={faPlus} /></div>
                           <div className="symbol-min"><FontAwesomeIcon icon={faMinus} /></div>
                       </div>
                   </button>
               </h2>
               <div id={"collapse"+ id} className="header-detail-body accordion-collapse collapse" aria-labelledby={"heading" + id}
                    data-bs-parent="#accordionExample">
                   <div className="accordion-body interviews-list-info">
                       <div className="interview-data">
                           <div className="date">11:05</div>
                           <div className="interview-data-content">
                               <span className="employee-title">Ghenadie Parfenie - Agent de vanzari</span>
                               <span className="employer-title">Responsabil: Ecaterina Domosan</span>
                           </div>

                           <div className="interview-data-content">
                               <span className="employee-title">Ghenadie Parfenie - Agent de vanzari</span>
                               <span className="employer-title">Responsabil: Ecaterina Domosan</span>
                           </div>
                       </div>

                       <div className="interview-data">
                           <div className="date">11:05</div>
                           <div className="interview-data-content">
                               <span className="employee-title">Ghenadie Parfenie - Agent de vanzari</span>
                               <span className="employer-title">Responsabil: Ecaterina Domosan</span>
                           </div>

                           <div className="interview-data-content">
                               <span className="employee-title">Ghenadie Parfenie - Agent de vanzari</span>
                               <span className="employer-title">Responsabil: Ecaterina Domosan</span>
                           </div>
                       </div>

                       <div className="interview-data">
                           <div className="date">11:05</div>
                           <div className="interview-data-content">
                               <span className="employee-title">Ghenadie Parfenie - Agent de vanzari</span>
                               <span className="employer-title">Responsabil: Ecaterina Domosan</span>
                           </div>

                           <div className="interview-data-content">
                               <span className="employee-title">Ghenadie Parfenie - Agent de vanzari</span>
                               <span className="employer-title">Responsabil: Ecaterina Domosan</span>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    );
}

export default InterviewEmployerItem;
