import React, {useMemo, useState} from 'react';
import Review from "components/Profiles/Reviews/Review";
import Pagination from "components/Pagination/Pagination";
import "components/Profiles/Reviews/Reviews.scss";
import {useSelector} from "react-redux";

const MyReviews = () => {
  const reviews = useSelector(state => state.user.reviews);

  let PageSize = 5;

  const [currentPage, setCurrentPage] = useState(1);

  const ProfileReviews = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return reviews.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
      <>
        <section className={`profile-reviews`}>
          {
            ProfileReviews.map(review => <Review review={review} key={review.Id}/>)
          }
        </section>
        <Pagination
            currentPage={currentPage}
            totalCount={reviews.length}
            pageSize={PageSize}
            template="template-two"
            onPageChange={page => setCurrentPage(page)}
        />
      </>
  );
}

export default MyReviews;
