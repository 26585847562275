import {createAsyncThunk} from "@reduxjs/toolkit";
import {getBlogs, editBlog, addBlog, deleteBlog, getInfoBlog} from "../../service/BlogService";

export const blogGetBlogsActions = createAsyncThunk(
    'blog/blogGetBlogsActions',
    async (params = {}) => {
        try {
            return await getBlogs(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const blogInfoBlogActions = createAsyncThunk(
    'blog/blogInfoBlogActions',
    async ({id}) => {
        try {
            return await getInfoBlog(id).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const blogEditActions = createAsyncThunk(
    'blog/blogEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editBlog(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)

export const blogCreateActions = createAsyncThunk(
    'blog/blogCreateActions',
    async({data, token},) => {
        try{
            return await addBlog(data, token).then(response => response.data.data)
        } catch (error) {
            return error.message
        }
    }
)

export const blogDeleteActions = createAsyncThunk(
    'blog/blogDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteBlog(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
