import React from 'react'
import ActivityComponent from "../../ActivityComponent/ActivityComponet";

const MyActivity = () => {
    return (
        <ActivityComponent />
    )
}

export default MyActivity;
