
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {getTranslation} from "./service/TranslationService";

const axiosBackend = {
    type: 'backend',
    read: function (language, namespace, callback) {
        getTranslation().then((response) => {
                const translations = response;
                // Split and assign translations to language resource bundles
                if (translations) {
                    Object.keys(translations).forEach((lang) => {
                        i18n.addResourceBundle(lang, namespace, translations[lang]);
                    });
                }

                callback(null, translations[language]);
            })
            .catch((error) => {
                callback(error, null);
            });
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(axiosBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "en",
        lng: "ro",
        debug: false,
        defaultNs: 'translation',

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
