import {createAsyncThunk} from "@reduxjs/toolkit";
import {addExperience, editExperience, deleteExperience} from "../../service/CareerService";
import {removeCareer} from "../reducer/careerReducer";

export const addNewCareerActions = createAsyncThunk(
    'career/addNewCareerActions',
    async({data, token}, thunkApi) => {
        try{
          return await addExperience(data, token).then(response => {
              if (response?.status === 201) {
                  return response.data?.data;
              } else {
                  return response.data?.error_message;
              }
          })
        } catch (error) {
           return error.message;
        }
    }
)

export const editCareerActions = createAsyncThunk(
    'career/editCareerActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editExperience(Id, data, token).then(response => {
                if (response?.status === 200) {
                    return response.data?.data;
                } else {
                    return response.data?.error_message;
                }
            })
        } catch (error) {
            return error.message;
        }
    }
)

export const deleteCareerActions = createAsyncThunk(
    'career/deleteCareerActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteExperience(Id, token).then(response => {
                const {dispatch} = thunkApi;

                if (response?.status === 200) {
                    dispatch(removeCareer({Id: Id}));
                    return response.data;
                } else {
                    return response.data?.error_message;
                }
            })
        } catch (error) {
            return error.message;
        }
    }
)
