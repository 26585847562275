import React from "react";
import {useTranslation} from "react-i18next";
import LoginComponent  from 'components/Forms/LoginComponent';

const Login = () => {
    const {t} = useTranslation();
    return (
        <main className="login-page container">
            <section className="content-page">
                <h1 className="title">{t('Login.Title')}</h1>
                <LoginComponent />
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </section>
        </main>
    )
}

export default Login;
