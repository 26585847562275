import {createSlice} from '@reduxjs/toolkit';

const courseReducer = createSlice({
    name: 'course',
    initialState: {
        loading: false,
        error: null,
        courses: [
            {
                id: 1,
                title: 'Curs Vanzator-consultant',
                slug: 'curs-vanzator-consultant',
                shortDescription: 'Nou în domeniul antreprenoriatului? Începe acum să afle bazele antreprenoriatului care te pot ajuta pentru ați realiza visurile.',
                description: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo.</p>',
                quizNr: 0,
                lesson: [
                    {
                        id: 0,
                        title: 'Curs vanzator consultant cuprins',
                        url: '0-curs-vinzator-consultant-cuprins.docx'
                    },
                    {
                        id: 1,
                        title: 'Principiile fundamentale ale vânzării',
                        url: '1-principiile-fundamentale-ale-vanzarii.pdf'
                    },
                    {
                        id: 2,
                        title: 'Tipuri de vânzări',
                        url: '2-tipuri-de-vanzari.pdf'
                    },
                    {
                        id: 3,
                        title: 'Factorii principali ce influentează comportamentul de cumpărare',
                        url: '3-factorii-principali-ce-influenteaza-comportamentul-de-cumparare.pdf'
                    },
                    {
                        id: 4,
                        title: 'Diada cumpărător-vânzător',
                        url: '4-diada-cumparator-vanzator.pdf'
                    },
                    {
                        id: 5,
                        title: 'Tipurile de intrebari utilizate in vanzare',
                        url: '5-tipurile-de-intrebari-utilizate-in-vanzare.pdf'
                    },
                    {
                        id: 6,
                        title: 'Etapele procesului de negociere',
                        url: '6-etapele-procesului-de-negociere.pdf'
                    },
                    {
                        id: 7,
                        title: 'Pașii vânzării în 5 etape',
                        url: '7-pasii-vanzarii-in-5-etape.pdf'
                    },
                    {
                        id: 8,
                        title: 'Cele 13 Reguli în vânzări',
                        url: '8-cele-13-reguli-in-vanzari.pdf'
                    }
                ]
            },
            {
                id: 2,
                title: 'Curs Marketing digital',
                slug: 'curs-marketing-digital',
                shortDescription: 'Nou în domeniul antreprenoriatului? Începe acum să afle bazele antreprenoriatului care te pot ajuta pentru ați realiza visurile.',
                description: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo.</p>',
                quizNr: 0,
                lesson: [
                    {
                        id: 0,
                        title: 'Curs “MARKETING DIGITAL” - cuprins',
                        url: '0-curs-marketing-digital-cuprins.docx'
                    },
                    {
                        id: 1,
                        title: 'Strategia si planificarea',
                        url: '1-strategia-si-planificarea.pdf'
                    },
                    {
                        id: 2,
                        title: 'Canalele de promovare',
                        url: '2-canalele-de-promovare.pdf'
                    },
                    {
                        id: 3,
                        title: 'Social Media Marketing',
                        url: '3-social-media-marketing.pdf'
                    },
                    {
                        id: 4,
                        title: 'Promovarea pe Google',
                        url: '4-promovarea-pe-google.pdf'
                    },
                    {
                        id: 5,
                        title: 'SEO',
                        url: '5-SEO.pdf'
                    },
                    {
                        id: 6,
                        title: 'Email marketing',
                        url: '6-email-marketing.pdf'
                    },
                    {
                        id: 7,
                        title: 'Copywriting',
                        url: '7-Copywriting.pdf'
                    }
                ]
            },
            {
                id: 3,
                title: 'Curs Dezvoltare personala',
                slug: 'curs-dezvoltare-personala',
                shortDescription: 'Nou în domeniul antreprenoriatului? Începe acum să afle bazele antreprenoriatului care te pot ajuta pentru ați realiza visurile.',
                description: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo.</p>',
                quizNr: 4,
                lesson: [
                    {
                        id: 0,
                        title: 'Cuprins-Curs Dezvoltare personala',
                        url: '0-cuprins-curs-dezvoltare-personala.docx'
                    },
                    {
                        id: 1,
                        title: 'Orientarea în carieră',
                        url: '1-orientarea-in-cariera.pdf'
                    },
                    {
                        id: 2,
                        title: 'Tendințele pieței muncii',
                        url: '2-tendintele-pietei-muncii.pdf'
                    },
                    {
                        id: 3,
                        title: 'Educație și formare',
                        url: '3-educatie-si-formare.pdf'
                    },
                    {
                        id: 4,
                        title: 'Competențe pentru piața muncii',
                        url: '4-competente-pentru-piata-muncii.pdf'
                    },
                    {
                        id: 5,
                        title: 'Demersul de angajare',
                        url: '5-demersul-de-angajare.pdf'
                    },
                    {
                        id: 6,
                        title: 'Oportunități de angajare',
                        url: '6-oportunitati-de-angajare.pdf'
                    },
                    {
                        id: 7,
                        title: 'Angajarea',
                        url: '7-Angajarea.pdf'
                    },
                    {
                        id: 8,
                        title: 'Recomandări',
                        url: '8-Recomandari.pdf'
                    },
                    {
                        id: 9,
                        title: 'Test 1-Chestionar de orientare profesională Ce carieră ți se potrivește',
                        url: 'test-1-chestionar-de-orientare-profesionala-ce-cariera-ti-se-potriveste.pdf'
                    },
                    {
                        id: 10,
                        title: 'Test 2-Chestionar de interese profesionale',
                        url: 'test-2-chestionar-de-interese-profesionale.pdf'
                    },
                    {
                        id: 11,
                        title: 'Test 3-Chestionar de autoevaluare Crezi că ești antreprenor',
                        url: 'test-3-chestionar-de-autoevaluare-crezi-ca-esti-antreprenor.pdf'
                    },
                    {
                        id: 12,
                        title: 'Test 4-Chestionar de autoevaluare Angajat, liber profesionist sau antreprenor',
                        url: 'test-4-chestionar-de-autoevaluare-angajat-liber-profesionist-sau-antreprenor.pdf'
                    }
                ]
            },
            {
                id: 4,
                title: 'Curs educație financiară și antreprenorială',
                slug: 'curs-educatie-financiara-si-antreprenoriala',
                shortDescription: 'Nou în domeniul antreprenoriatului? Începe acum să afle bazele antreprenoriatului care te pot ajuta pentru ați realiza visurile.',
                description: '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo duo dolores et ea rebum. At vero eos et accusam et justo.</p>',
                quizNr: 4,
                lesson:  [
                    {
                      id: 0,
                      title: 'Lectia 0 Ce este antreprenoriatul?',
                      videoUrl: 'https://youtu.be/9g6FM7cjNKI'
                    },
                    {
                        id: 1,
                        title: 'Lectia 1 Trăsăturile unui antreprenor de success',
                        videoUrl: 'https://youtu.be/qMQbC1DDcKY'
                    },
                    {
                        id: 2,
                        title: 'Lectia 2 Motivația lansării unei afaceri.',
                        videoUrl: 'https://youtu.be/gWdbfp56G7Y'
                    },
                    {
                        id: 3,
                        title: 'Lectia 3 Identificarea ideei de afacere.',
                        videoUrl: 'https://youtu.be/V1YmXsylZNI'
                    },
                    {
                        id: 4,
                        title: 'Lectia 4 Testarea ideei de afacere.',
                        videoUrl: 'https://youtu.be/p6S3jXGjrCc'
                    },
                    {
                        id: 5,
                        title: 'Lectia 5 Definirea produsului.',
                        videoUrl: 'https://youtu.be/tPg8D7qps0k'
                    },
                    {
                        id: 6,
                        title: 'Lectia 6 Vizualizarea produsului.',
                        videoUrl: 'https://youtu.be/DEA2wO6HeJ0'
                    },
                    {
                        id: 7,
                        title: 'Lectia 7 Formele organizatorico-juridice ale activităților antreprenoriale.',
                        videoUrl: 'https://youtu.be/_bQWreqs6Do'
                    },
                    {
                        id: 8,
                        title: 'Lectia 8 Metoda Canvas ca metodă de analiză a ideilor de afaceri. (partea 1)',
                        videoUrl: 'https://youtu.be/XR4aCMiW-7g'
                    },
                    {
                        id: 9,
                        title: 'Lectia 9 Metoda Canvas ca metodă de analiză a ideilor de afaceri. (partea 2)',
                        videoUrl: 'https://youtu.be/dDfw7I9Yw8Q'
                    },
                    {
                        id: 10,
                        title: 'Lectia 10 Cum înregistrăm afacerea? (partea 1)',
                        videoUrl: 'https://youtu.be/PLCU9Yw-m60'
                    },
                    {
                        id: 11,
                        title: 'Lectia 11 Cum înregistrăm afacerea? (partea 2)',
                        videoUrl: 'https://youtu.be/I8Z-xvUyHbE'
                    },
                    {
                        id: 12,
                        title: 'Lectia 12 Finanțarea eficienta a afacerii: surse interne, surse externe. (partea 1)',
                        videoUrl: 'https://youtu.be/llAeQnxd1jk'
                    },
                    {
                        id: 13,
                        title: 'Lectia 13 Finanțarea eficienta a afacerii: surse interne, surse externe. (partea 2)',
                        videoUrl: 'https://youtu.be/srpeMIJIcRI'
                    },
                    {
                        id: 14,
                        title: 'Lectia 14 Descrierea afacerii în baza planului de afaceri. (partea 1)',
                        videoUrl: 'https://youtu.be/WI5lPfBw41A'
                    },
                    {
                        id: 15,
                        title: 'Lectia 15 Descrierea afacerii în baza planului de afaceri. (partea 2)',
                        videoUrl: 'https://youtu.be/2AR9U9Z1-p4'
                    },
                    {
                        id: 16,
                        title: 'Lectia 16 Managementul afacerii. Responsabilitatea socială corporativă. (partea 1)',
                        videoUrl: 'https://youtu.be/zz5gWMMt26U'
                    },
                    {
                        id: 17,
                        title: 'Lectia 17 Managementul afacerii. Responsabilitatea socială corporativă. (partea 2)',
                        videoUrl: 'https://youtu.be/vZZ7TR9yZNk'
                    },
                    {
                        id: 18,
                        title: 'Lectia 18 Planificarea, managementul și organizarea personalului. (partea 1)',
                        videoUrl: 'https://youtu.be/ew6PU5uaJq0'
                    },
                    {
                        id: 19,
                        title: 'Lectia 19 Planificarea, managementul și organizarea personalului. (partea 2)',
                        videoUrl: 'https://youtu.be/B9_TGOGzUjc'
                    },
                    {
                        id: 20,
                        title: 'Lectia 20 Impactul tehnologiei asupra afacerii: oportunități și amenințări.',
                        videoUrl: 'https://youtu.be/xNxD8mkB06k'
                    },
                ]
            }
        ],
    },
    reducers: {
    },
})

export default courseReducer.reducer;
export const {} = courseReducer.actions
