import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import './ActionButton.scss';

const ActionButton = (props) => {
    const navigate = useNavigate();
    const {text, icon, path, className} = props;

    const handleAddClick = ()  => {
        navigate(path);
    }

    return (
        <button className={`action-button ${className}`} onClick={handleAddClick}>{icon && <FontAwesomeIcon icon={icon} />}{text}</button>
    )
}

export default ActionButton;
