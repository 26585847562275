import React from 'react';
import "./Coaching.scss";
import ReactCountryFlag from "react-country-flag";

const CoachingTeam = (props) => {
    return (
        <div className="coaching-member">
            <a href="#">
                <div className="coaching-member-header">
                    <div className="image-main-block">
                        <div className="blue"></div>
                        <div className="green"></div>
                        <div className="yellow"></div>
                        <div className="purple"></div>
                        <div className="image">
                            <img src={props.team.image} alt={props.team.name} />
                        </div>
                    </div>
                    <div className="person-data">
                        { (props.team.sex === 'F') ? <img src={require("@images/F.png")} alt={props.team.name}/> : <img src={require("@images/M.png")} alt={props.team.name}/>}
                        {props.team.age} years
                    </div>
                    <div className="language">
                        <ul className="flex flex-wrap justify-around">
                            { props.team.language.map((item, i) =>
                                <li className="block" key={i}>{item}</li>
                            )}
                        </ul>
                    </div>
                </div>

                <div className="coaching-member-content">
                    <h3 className="member-title">{props.team.name}</h3>
                    <span className="job-title">{props.team.job}</span>
                    <span className="description">Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson Lorem Ipson</span>
                    <span className="location"><img src={require("@images/location.png")} alt={props.team.location} />{props.team.location}</span>
                </div>
            </a>
        </div>
    );
}

export default CoachingTeam;
