import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";

const Quiz = () => {
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const submitStart = (event) => {
        if(value){
            window.open(value, '_blank');
        }
    }

    return (
      <main className="quiz-page">
          <section className="header-quiz container">
                  <div className="header-quiz-data">
                      <h1 className="title">Jobhub<span className="quizz-title">Quizzes</span></h1>
                      <span className="promo">Nou în domeniul antreprenoriatului? Începe acum să afle bazele antreprenoriatului care te pot ajuta pentru ați realiza visurile</span>
                          <form>
                              <div className="search-quizz">
                                  <select name="quizz" onChange={handleChange}>
                                      <option value="">Încearcă un test</option>
                                      <option value="https://quizfirst.jobhub.ixobit.md">Formular de evaluare Inițială</option>
                                      <option value="https://quizsucces.jobhub.ixobit.md">Cheia succesului</option>
                                      <option value="https://quizprofesion.jobhub.ixobit.md">Compatibilitate profesională</option>
                                  </select>
                                  <button className="start" onClick={() => submitStart()}><FontAwesomeIcon icon={faPlay} />Start</button>
                              </div>
                          </form>
                  </div>
                  <div className="header-quiz-image">
                      <img src={require("@images/quiz.png")} alt="quiz" />
                          <form>
                              <div className="search-quizz">
                                  <select name="quizz">
                                      <option value="">Încearcă un test</option>
                                      <option value="https://quizfirst.jobhub.ixobit.md/">Formular de evaluare Inițială</option>
                                      <option value="https://quizsucces.jobhub.ixobit.md">Cheia succesului</option>
                                      <option value="https://quizprofesion.jobhub.ixobit.md">Compatibilitate profesională</option>
                                  </select>
                                  <button className="start" onClick={() => submitStart()}><FontAwesomeIcon icon={faPlay} />Start</button>
                              </div>
                          </form>
                  </div>
                  <div className="break"></div>
              </section>
      </main>
    );
}

export default Quiz;
