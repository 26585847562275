import React from 'react';

const Course = (props) => {
    const {course} = props;
    return (
        <div className="course">
           <div className="course-image">
               <img src={ require('assets/images/1500-04.jpg') } alt={course.titleCourse}/>
           </div>
           <div className="course-data">
              <div className="content-cours">
                  <div className="head">
                      <div className="logo">
                          <img src={ require('assets/images/1500-01.jpg') } alt={course.titleCourse}/>
                      </div>
                      <span className="title-course">{course.titleCourse}</span>
                  </div>
                  <div className="description">{course.descriptionCourse}</div>
                  <div className="hours">
                      <span>Teorie: {course.theoreticallyHours}</span>
                      <span>|</span>
                      <span>Practica: {course.practicallyHours}</span>
                      <span>|</span>
                      <span>Proiecte: {course.nrProject}</span>
                  </div>
              </div>
              <div className="actions">
                  <span className="btn-cours">Vezi Cursul</span>
                  <span className="btn-cours">Continua</span>
              </div>
           </div>
        </div>
    )
}

export default Course;
