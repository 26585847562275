import React from 'react';
import {Link} from "react-router-dom";

const FavouriteJob = ({item}) => {
    return (
        <div className="job-item">
            <div className="image overflow-hidden">
                <Link to={`vacancies?category=${item.Id}`} className="w-full h-full block">
                    <img className="w-full h-full object-cover" src={item.Image} alt={item.Title}/>
                    <h4 className="text-center mt-2">{item.Title}</h4>
                </Link>
            </div>
            <Link to={`vacancies?category=${item.Id}`} className="w-full h-full block text-center">
                <span className="text-center">{item.Title}</span>
            </Link>
        </div>
    )
}

export default FavouriteJob;
