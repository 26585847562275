import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import {typeJob} from "../../constants/constants";

const CareerVisible = (props) => {
  const {career, editItem, editButton} = props;
  const changeFormat = (d) => {
    const data = d.split(",");
    if(data[0] === '0 years'){
      return data[1];
    } else {
      return data[0] + ', '+data[1];
    }
  }

  const calculatePeriod = (dateTo, dateFrom) => {
    if(dateFrom !== '' && dateFrom !== null){
      return <><Moment local date={dateTo} format="MMM, YYYY"/> - <Moment local date={dateFrom} format="MMM, YYYY"/></>
    } else {
      return <><Moment local date={dateTo} format="MMM, YYYY"/> - Present, <Moment local date={dateTo} durationFromNow filter={changeFormat}/></>
    }
  }

    return (
        <>
          <div className="career-item-personal">
            <div className="career-item-image">
              <img src={(career.Logo === '' || career.Logo === null) ? require("@images/career-default.png") : (career.Logo?.search('changed;') > -1) ? career.Logo.replace('changed;', '') : career.Logo } className="image" alt={career.titleCompany} />
            </div>
            <div className="personal-data">
              <span className="title-company">{career.TitleCompany}</span>
              <span className="job">{career.Job} {typeJob[career.Type] !== 'Full Time' ?
                  <span><span className="break"></span>{typeJob[career.Type]}</span> : <></>}</span>
              <span className="period-time">{calculatePeriod(career.StartDate, career.EndDate)}</span>
              <span className="location">{career.location}</span>
            </div>
          </div>
          {career.Description ? <div className="career-item-about-job">{career.Description}</div> : <></>}
          {editButton ? <span className="career-edit-item" onClick={() => editItem(true)}><FontAwesomeIcon icon={faPencil}/></span> : <></> }
        </>
    )
}

export default CareerVisible;
