import {createAsyncThunk} from "@reduxjs/toolkit";
import {getAdvisers, editAdvisers, addAdvisers, deleteAdvisers} from "../../service/AdvisersService";

export const advisersGetAdvisersActions = createAsyncThunk(
    'advisers/advisersGetAdvisersActions',
    async (params = {}) => {
        try {
            return await getAdvisers(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const advisersEditActions = createAsyncThunk(
    'advisers/advisersEditActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editAdvisers(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)

export const advisersCreateActions = createAsyncThunk(
    'advisers/advisersCreateActions',
    async({data, token},) => {
        try{
            return await addAdvisers(data, token).then(response => response.data.data)
        } catch (error) {
            return error.message
        }
    }
)

export const advisersDeleteActions = createAsyncThunk(
    'advisers/advisersDeleteActions',
    async({Id, token}, thunkApi) => {
        try{
            return await deleteAdvisers(Id, token).then(response => response.data);
        } catch (error) {
            return error.message;
        }
    }
)
