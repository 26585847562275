import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CategoryJob from "components/Profiles/JobsComponent/CategoryJob";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {jobGetJobsActions} from "../../../../redux/actions/jobsApi";
import Loader from "components/Loader/Loader";

const AppliedInterview = () => {
    const appliedJob = useSelector(state => state.appliedJobs.category);
    const dispatch = useDispatch();
    const {loading, error, jobs} = useSelector(state => state.jobs);
    const {company} = useSelector(state => state.user)

    useEffect(() => {
        if(company){
            if(company.Id){
                dispatch(jobGetJobsActions({expand: 'user,applications,applications.user,applications.guest', 'company_id': company?.Id}));
            }
        }
    },[company])

    return (
        <>
            {error && <p>Something went wrong</p>}
            {loading && <Loader />}
            {!loading && company?.NrOfApplications >= 1 &&
                jobs.map(job => job.Jobs.map(item => <CategoryJob key={item.Id} job={item} />)) }
            {!loading && company?.NrOfApplications === 0 && <>Nu a aplicat nimini la job</>}
        </>
    )
}

export default AppliedInterview;
