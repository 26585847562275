import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import Pagination from "components/Pagination/Pagination";
import OfferCategoryJob from "components/Profiles/JobsComponent/OfferCategoryJob";


const OffersJobs = () => {
  const appliedJob = useSelector(state => state.appliedJobs.category);
  const [currentPage, setCurrentPage] = useState(1);
  return (
      <>
        <section className="offers-top-head">
          <span>Alege Optiunea</span>
          <span className="action"><FontAwesomeIcon icon={faCheck} /> Oferte</span>
          <span className="action"><FontAwesomeIcon icon={faCheck} /> Refuzuri</span>
          <span className="action"><FontAwesomeIcon icon={faCheck} /> Acceptari</span>
          <span className="action"><FontAwesomeIcon icon={faCheck} /> Angajari</span>
        </section>
        <section className="offers-jobs-section">
          { appliedJob && (
              appliedJob.map(job =>
                  <OfferCategoryJob key={job.id} job={job} />
              ))
          }
          <Pagination
              currentPage={currentPage}
              totalCount={4}
              pageSize={2}
              onPageChange={page => setCurrentPage(page)}
          />
        </section>
      </>
  );
}

export default OffersJobs;
