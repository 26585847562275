import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './resources.scss';
import {faEye, faLocationDot, faClapperboard} from "@fortawesome/free-solid-svg-icons";

const ResourcesComponent = () => {
    return (
        <section className="resources-section">
            <span className="title-resource">Resources</span>
            <span className="private"><FontAwesomeIcon icon={faEye} /> Private to you</span>

            <div className="resource-content">
                <div className="resource-item">
                  <FontAwesomeIcon icon={faClapperboard} />
                  <div className="content-item-resource">
                      <span className="content-item-resource-title">Creator mode <span className="off">OFF</span></span>
                      <span className="content-item-resource-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      </span>
                  </div>
                </div>

                <div className="resource-item">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <div className="content-item-resource">
                        <span className="content-item-resource-title">My network</span>
                        <span className="content-item-resource-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResourcesComponent;
