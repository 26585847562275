import React, {forwardRef, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {faCalendarDays, faSave, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import Image from "../Image/Image";
import {deleteCareerActions, editCareerActions} from "../../redux/actions/careerApi";

const CareerEdit = (props) => {
    const dispatch = useDispatch();
    const {career, editItem, editButtonItem} = props;
    const auth = useSelector(state => state.auth);
    const [token, setToken] = useState(auth.token);
    const [titleCompany, setTitleCompany] = useState(career.TitleCompany);
    const [job, setJob] = useState(career.Job ?? "");
    const [typeOfJob, setTypeOfJob] = useState(career.Type ?? 0);
    const [dataTo, setDataTo] = useState(career.StartDate !== '' ? new Date(career.StartDate) : null);
    const [dataFrom, setDataFrom] = useState(career.EndDate !== '' && career.EndDate !== null ? new Date(career.EndDate) : null);
    const [description,setDescription] = useState(career.Description ?? '');
    const [image, setImage] = useState(career.Logo);

    useEffect(() => {
        if(token === null){
            setToken(localStorage.getItem('token'));
        }
    },[token, setToken]);

    const handlerRemoveCareer = () => {
        editItem(false);
        editButtonItem(false);
        dispatch(deleteCareerActions({
            Id: career.Id,
            token: token
        }))
    }

    const handleUpdateCareer = () => {
        const data = {
            Id: career.Id,
            data: {
                titleCompany: titleCompany,
                job: job,
                dateStart: dataTo,
                dateEnd: dataFrom,
                type: typeOfJob,
                description: description,
                logo: image
            },
            token: token
        }

        editItem(false);
        editButtonItem(false);
        dispatch(editCareerActions(data));
    }

    const StyleDataComponent = forwardRef(({ value, onClick }, ref) => (
        <span className="data-input" onClick={onClick} ref={ref}>
          <span className="data">{value} <FontAwesomeIcon icon={faCalendarDays} /></span>
       </span>
    ));

    return (
        <div className="edit-form">
            <div className="image-edit">
                <Image value={image} onChange={(result) => setImage(result)} withBase64={true}/>
            </div>
           <input type="text" value={titleCompany} placeholder="Company Name" onChange={(e) => setTitleCompany(e.target.value)} />
           <input type="text" value={job} placeholder="Job" onChange={(e) => setJob(e.target.value)} />

            <select onChange={e => setTypeOfJob(e.target.value)} defaultValue={typeOfJob} >
                <option value={null}>Tipul Jobului</option>
                <option value={4}>Remote</option>
                <option value={3}>Temporary</option>
                <option value={2}>Part-time</option>
                <option value={1}>Full Time</option>
            </select>

            <div className="dataContainer">
                <DatePicker
                    selected={dataTo}
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => setDataTo(date)}
                    customInput={<StyleDataComponent />}
                    isClearable
                />

                <DatePicker
                    selected={dataFrom}
                    dateFormat="MM-dd-yyyy"
                    onChange={(date) => setDataFrom(date)}
                    isClearable
                    customInput={<StyleDataComponent />}
                />
            </div>

            <textarea name="" id="" cols="30" rows="10" onChange={(e) => setDescription(e.target.value) } value={description} />
            <div className="actions">
                <button onClick={ handlerRemoveCareer }><FontAwesomeIcon icon={faTrash} />Remove</button>
                <button onClick={() => { editItem(false); editButtonItem(false); }}><FontAwesomeIcon icon={faXmark} />Cancel</button>
                <button onClick={ handleUpdateCareer }><FontAwesomeIcon icon={faSave} />Save</button>
            </div>
        </div>
    )
}

export default CareerEdit;
