import React from 'react';
import "./applied-popup.scss";
import {Parser} from "html-to-react";
import i18n from "i18next";

const PopUpInitiativeCompany = (props) => {
    const {title, description, showModal} = props;

    return (
        <>
          <div
              className="pop-up-applied justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative popup">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="head flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className="title-applied-job">
                      {title[i18n.language]}
                  </h3>
                  <button
                      className="btn-applied"
                      onClick={() => showModal(false)}
                  >
                    X
                  </button>
                </div>
                <div className="relative flex-auto popup-content">
                    {Parser().parse(description[i18n.language])}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default PopUpInitiativeCompany;
