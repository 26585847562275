import {createAsyncThunk} from "@reduxjs/toolkit";
import {getResultOpportunity, editResultOpportunity} from "../../service/ResultOpportunityService";
export const resOpportunityGetOpportunityActions = createAsyncThunk(
    'resOpportunity/resOpportunityGetOpportunityActions',
    async (params = {}) => {
        try {
            return await getResultOpportunity(params).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
);

export const resOpportunityEditOpportunityActions = createAsyncThunk(
    'resOpportunity/resOpportunityEditOpportunityActions',
    async({Id, data, token}, thunkApi) => {
        try{
            return await editResultOpportunity(Id, data, token).then(response => response.data)
        } catch (error) {
            return error.message;
        }
    }
)
