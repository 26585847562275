import React from 'react'
import AnalyticsComponent from "../../AnalyticsComponent/AnalyticsComponent";

const Analytics = () => {
    return (
        <AnalyticsComponent />
    )
}

export default Analytics;
