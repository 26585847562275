import React from 'react'
import ResourcesComponent from "../../ResourcesComponent/ResourcesComponent";

const Resources = () => {
    return (
        <ResourcesComponent />
    )
}

export default Resources;
