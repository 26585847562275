import React, {useState} from 'react';
import BasicTable from "components/TableComponent/BasicTable";

const Employers = () => {

    const dataEmployeeList = [{
        id: 1,
        logo: 'career.jpeg',
        companyName: 'Vitanta',
        jobVacancies: '7',
        positionsClosed: '1',
        applications: '1',
        dateLastAnnouncement: '23/12/23',
        dateLastApplication: '23/12/23',
        contactPerson: {
            Name: 'Tudor Ursachi',
            Logo: 'career.jpeg'
        },
    },
        {
            id: 2,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
        {
            id: 3,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
        {
            id: 4,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
        {
            id: 5,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
        {
            id: 6,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
        {
            id: 7,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
        {
            id: 8,
            logo: 'career.jpeg',
            companyName: 'Vitanta',
            jobVacancies: '7',
            positionsClosed: '1',
            applications: '1',
            dateLastAnnouncement: '23/12/23',
            dateLastApplication: '23/12/23',
            contactPerson: {
                Name: 'Tudor Ursachi',
                Logo: 'career.jpeg'
            },
        },
    ];

    const basicColumEmployeeList = [
        {
            id: 'select',
            header: ({table}) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row}) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            header: 'Logo',
            accessorKey: 'logo',
            enableSorting: false,
            cell: (info) => <div className="table-image"><img src={require(`@images/${info.getValue()}`)}
                                                              alt={info.getValue()}/></div>
        },
        {
            header: 'Company Name +',
            accessorKey: 'companyName',
        },
        {
            header: 'Posturi Vacante +',
            accessorKey: 'jobVacancies'
        },
        {
            header: 'Posturi Închise +',
            accessorKey: 'positionsClosed'
        },
        {
            header: 'Aplicări +',
            // enableSorting: false,
            accessorKey: 'applications',
        },
        {
            header: 'Data Ultimului Anunț +',
            accessorKey: 'dateLastAnnouncement'
        },
        {
            header: 'Data Ultimei Aplicări +',
            accessorKey: 'dateLastApplication'
        },
        {
            header: 'Persoană contact +',
            accessorKey: 'contactPerson',
            enableSorting: false,
            cell: (info) =>
                <div className="table-name-image">
                    <div className="table-image">
                        <img src={require(`@images/${info.getValue().Logo}`)} alt={info.getValue().Name}/>
                    </div>
                    <span className="name">{info.getValue().Name}</span>
                </div>
        }
    ];

    function IndeterminateCheckbox({
                                       indeterminate,
                                       className = '',
                                       ...rest
                                   }) {
        const ref = React.useRef(!null);

        React.useEffect(() => {
            if (typeof indeterminate === 'boolean') {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
        )
    }


    return (
        <section className="table-EmployeeList">
            <BasicTable basic_column={basicColumEmployeeList} data={dataEmployeeList} className="basic-table-EmployeeList clientColor2"/>
        </section>
    );
}

export default Employers;
