import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTrashCan, faCircleChevronLeft, faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import ItemJob from "./ItemJob";
import "./JobsComponent.scss";

const CategoryJob = ({job}) => {
    const [noInterestCV, setNoInterestCV] = useState({});
    // const [CV, setCV] = useState(job.CV);
    // const [cv, setcv] = useState(CV);
    // const noInteresting = (val, idCat) => {
    //     const interest = (val === true) ? 1 : 0;
    //
    //     // if(val === true){ console.log(val);
    //         const CVs = CV.filter(item => item.interesting === interest);
    //         setcv(CVs);
    //     // } else {
    //     //     setcv(CV);
    //     // }
    //     setNoInterestCV({[idCat] : !noInterestCV[idCat] });
    // }

    return (
        <>{ job?.Applications.length >= 1 && <div className="category-job-component">
                <div className="category-job-component--header">
                    <span className="header-title">{job?.Title}<span className="nr-cv">{ job?.Applications.length ?? 0} CV-uri</span></span>

                    <div className="line"></div>
                    <div className="filter-category-job flex flex-wrap justify-content-between align-items-center align-content-center">
                        <input id="selectAll" name="selectAll" value="1" type="checkbox"/>

                        <div className="lineHorizontal"></div>

                        <button className="clear"><FontAwesomeIcon icon={faTrashCan} />Șterge <div className="line"></div></button>

                        <div className="lineHorizontal"></div>

                        <div className="filter-form-saw">
                            <FontAwesomeIcon icon={faCheck} />

                            <select name="" id="">
                                <option value="">Markeaza</option>
                                <option value="">Ca Vazut</option>
                                <option value="">Ca Nevezut</option>
                            </select>
                        </div>

                        <div className="radio-filter">
                            <label>
                                <input id="interestingCV" name="interestingCV" value="true" type="radio"/>CV-uri Interesante
                            </label>
                            <div className="lineHorizontal"></div>
                            <label>
                                <input id="interestingCV" name="interestingCV" value="false" type="radio"/>CV-uri neînteressante
                            </label>
                        </div>

                        <div className="lineHorizontal"></div>

                        <select className="tags-filter" name="" id="">
                            <option value="">Toate tagurile</option>
                            <option value="">Ca Vazut</option>
                            <option value="">Ca Nevezut</option>
                        </select>
                    </div>
                </div>
                { job?.Applications &&
                    job?.Applications.map(item => <ItemJob key={item.User.Id} CV={item.User} Data={item.CreatedAt}/> )}
            </div>
        }
        </>
    );
}

export default CategoryJob;
