import React, {useState} from 'react';
import Multiselect from 'multiselect-react-dropdown';
import Dropdown from "react-dropdown";


const RegisterEmployee = () => {
    const [fullName, setFullName] = useState();
    const [email, setEmail] = useState();
    const [interests, setInterests] = useState();
    const [telephone, setTelephone] = useState();
    const [address, setAddress] = useState();
    const [locality, setLocality] = useState();
    const typeOfJob = [
        {
            id: 0,
            name: 'Remote'
        },
        {
            id: 1,
            name: 'part-time'
        },
        {
            id: 2,
            name: 'full-time'
        },
        {
            id: 3,
            name: 'flexible'
        },
        {
            id: 4,
            name: 'cumul'
        },
        {
            id: 5,
            name: 'oricare'
        }
    ];

    const typeOfCourse = [
        {
            id: 0,
            name: 'Cursuri 1'
        },
        {
            id: 1,
            name: 'Cursuri 2'
        },
        {
            id: 2,
            name: 'Cursuri 3'
        },
        {
            id: 3,
            name: 'Cursuri 4'
        },
    ];

    const onSelectTypeOfJob = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const onSelectTypeOfCourse = (selectedList, selectedItem) => {
        console.log(selectedItem);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <main className="register-employer">
           <div className="container">
               <div className="mt-5 md:col-span-2 md:mt-0">
                   <form>
                       <div className="overflow-hidden shadow sm:rounded-md">
                           <div className="bg-white px-4 py-5 sm:p-6">
                               <div className="grid grid-cols-6 gap-6">
                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                                           Numele Prenume <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                       </label>
                                       <input
                                           id="fullName"
                                           name="fullName"
                                           autoComplete="fullName"
                                           type="text"
                                           value={fullName}
                                           onChange={e => setFullName(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                           Adresa de e-mail a Candidatului <span className="mt-2 text-red-400 dark:text-red-400">*</span>
                                       </label>
                                       <input
                                           id="email"
                                           name="email"
                                           autoComplete="email"
                                           type="email"
                                           value={email}
                                           onChange={e => setEmail(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="locality" className="block text-sm font-medium text-gray-700">
                                           Localitatea (raionul sau oraşul)
                                       </label>
                                       <input
                                           id="locality"
                                           name="locality"
                                           autoComplete="locality"
                                           type="locality"
                                           value={locality}
                                           onChange={e=> setLocality(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                           Adresă
                                       </label>
                                       <input
                                           id="address"
                                           name="address"
                                           autoComplete="address"
                                           type="text"
                                           value={address}
                                           onChange={e=> setAddress(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="typeOfJob" className="block text-sm font-medium text-gray-700">
                                           Tipul de serviciu căutat
                                       </label>
                                       <Multiselect
                                           id="typeOfJob"
                                           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                           options={typeOfJob} // Options to display in the dropdown
                                           // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                           onSelect={onSelectTypeOfJob} // Function will trigger on select event
                                           displayValue="name" // Property name to display in the dropdown options
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="typeOfCourse" className="block text-sm font-medium text-gray-700">
                                           Tipuri de cursuri interesat
                                       </label>
                                       <Multiselect
                                           id="typeOfCourse"
                                           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                           options={typeOfCourse} // Options to display in the dropdown
                                           // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                           onSelect={onSelectTypeOfCourse} // Function will trigger on select event
                                           displayValue="name" // Property name to display in the dropdown options
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="restrictionOfJob" className="block text-sm font-medium text-gray-700">
                                           Restricții de muncă (dacă există)
                                       </label>
                                       <select
                                           id="restrictionOfJob"
                                           name="restrictionOfJob"
                                           autoComplete="restrictionOfJob"
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       >
                                           <option>Nu sunt Restrictii</option>
                                           <option>Restrictii 1</option>
                                           <option>Restrictii 2</option>
                                           <option>Restrictii 3</option>
                                           <option>Restrictii 4</option>
                                           <option>Restrictii 5</option>
                                       </select>
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="radius" className="block text-sm font-medium text-gray-700">
                                           Proximitate jobului(pentru notificări)
                                       </label>
                                       <select
                                           id="radius"
                                           name="radius"
                                           autoComplete="radius"
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       >
                                           <option>0-5km</option>
                                           <option>5 - 10 km</option>
                                           <option>0 - 25 km</option>
                                           <option>25 - 50 km</option>
                                           <option>50 - 150 km</option>
                                       </select>
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">
                                           Număr telefon de contact
                                       </label>
                                       <input
                                           id="telephone"
                                           name="telephone"
                                           autoComplete="telephone"
                                           type="text"
                                           value={telephone}
                                           onChange={e=> setTelephone(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-3">
                                       <label htmlFor="interests" className="block text-sm font-medium text-gray-700">
                                           Hobby
                                       </label>
                                       <input
                                           id="interests"
                                           name="interests"
                                           autoComplete="interests"
                                           type="text"
                                           value={interests}
                                           onChange={e=> setInterests(e.target.value)}
                                           className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                       />
                                   </div>

                                   <div className="col-span-6 sm:col-span-6">
                                       <div>
                                           <label className="block text-sm font-medium text-gray-700">Avatar</label>
                                           <div className="mt-1 flex items-center">
                      <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                                               <button
                                                   type="button"
                                                   className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                               >
                                                   Change
                                               </button>
                                           </div>
                                       </div>

                                       <div>
                                           <label className="block text-sm font-medium text-gray-700">Cover Avatar</label>
                                           <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                               <div className="space-y-1 text-center">
                                                   <svg
                                                       className="mx-auto h-12 w-12 text-gray-400"
                                                       stroke="currentColor"
                                                       fill="none"
                                                       viewBox="0 0 48 48"
                                                       aria-hidden="true"
                                                   >
                                                       <path
                                                           d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                           strokeWidth={2}
                                                           strokeLinecap="round"
                                                           strokeLinejoin="round"
                                                       />
                                                   </svg>
                                                   <div className="flex text-sm text-gray-600">
                                                       <label
                                                           htmlFor="file-upload"
                                                           className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                       >
                                                           <span>Upload a file</span>
                                                           <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                       </label>
                                                       <p className="pl-1">or drag and drop</p>
                                                   </div>
                                                   <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                               </div>
                                           </div>
                                       </div>

                                       <div>
                                           <label className="block text-sm font-medium text-gray-700">Încarcă documente CV/Scrisoare de intenție/Diplome/Recomandări</label>
                                           <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                               <div className="space-y-1 text-center">
                                                   <svg
                                                       className="mx-auto h-12 w-12 text-gray-400"
                                                       stroke="currentColor"
                                                       fill="none"
                                                       viewBox="0 0 48 48"
                                                       aria-hidden="true"
                                                   >
                                                       <path
                                                           d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                           strokeWidth={2}
                                                           strokeLinecap="round"
                                                           strokeLinejoin="round"
                                                       />
                                                   </svg>
                                                   <div className="flex text-sm text-gray-600">
                                                       <label
                                                           htmlFor="file-upload"
                                                           className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                       >
                                                           <span>Upload a file</span>
                                                           <input id="file-upload" multiple name="file-upload" type="file" className="sr-only" />
                                                       </label>
                                                       <p className="pl-1">or drag and drop</p>
                                                   </div>
                                                   <p className="text-xs text-gray-500">PDF, JPEG, JPG, DOC, DOCX, XLS</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>

                                   <div className="col-span-6 sm:col-span6">
                                       <div>
                                           <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                               Descrierea
                                           </label>
                                           <div className="mt-1">
                                              <textarea
                                                  id="about"
                                                  name="about"
                                                  rows={5}
                                                  style={{paddingLeft: '10px'}}
                                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                  placeholder="Descrierea"
                                                  defaultValue={''}
                                              />
                                           </div>
                                       </div>

                                       <div className="flex items-start agree">
                                           <div className="flex h-5 items-center">
                                               <input
                                                   id="comments"
                                                   name="comments"
                                                   type="checkbox"
                                                   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                               />
                                           </div>
                                           <div className="ml-3 text-sm">
                                               <label htmlFor="comments" className="font-medium text-gray-700">
                                                   <p className="text-gray-500">Am făcut cunostința și sunt deacord cu termenii și condițiile pentru prelucrarea datelor personale și cu regulile site-ului</p>
                                               </label>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                               <button
                                   type="submit"
                                   onClick={handleSubmit}
                                   className="btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                               >
                                   Register
                               </button>
                           </div>
                       </div>
                   </form>
               </div>
           </div>
        </main>
    )
}

export default RegisterEmployee;
