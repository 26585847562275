import React from 'react';

const Review = (props) => {
    const {review} = props;
    const user = review?.User;

    return (
        <div className="profile_review_item">
          <div className="profile_review_data">
              <div className="profile_review_image">
                  {(user?.Image)? <img src={user.Image} alt={user.Name} /> : <img src={require('@images/happy-young-man.png')} alt={review.name} />}

              </div>
              <span className="profile_review_title">{user.Name + ' ' + user.Surname}</span>
              <span className="profile_review_job">Job</span>
          </div>
          <div className="profile_review_content">
              <span className="profile_review_data_review">{review.CreatedAt}</span>
              <div className="profile_review_text">
                  {review.Description}
              </div>
          </div>
        </div>
    )
}

export default Review;
