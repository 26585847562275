import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './interests.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import InterestsItem from "./InterestsItem";

const InterestsComponent = () => {
    const interests = useSelector(state => state.interests.interests);
    const [editButton, setEditButton] = useState(false);
    const [addButton, setAddButton] = useState(false);
    const [loadMore, setLoadMore] = useState(false);

    const showMore = () =>
    {
        setLoadMore(prevCheck => !prevCheck);
    }

    const loadCount = loadMore ? interests.length : 2;

    const handleChange = (value) => {
        setEditButton(value);
    }

    const handleChangeAdd = (value) => {
        setAddButton(value);
    }

    return (
        <section className="interests-section">
            <div className="interests-section-head">
                <span className="title">Interests</span>
                <div className="actions">
                    <span className="interest-action-button" onClick={() => setAddButton(true)}><FontAwesomeIcon icon={faPlus} /></span>
                    <span className="interest-action-button" onClick={() => setEditButton(true)}><FontAwesomeIcon icon={faPencil} /></span>
                </div>
            </div>
            <div className="interests-section-menu">
                <ul>
                    <li className="active"><Link to={"/"}><span data-hover="Menu 1">Menu 1</span></Link></li>
                    <li><Link to={"/"}><span data-hover="Menu 2">Menu 2</span></Link></li>
                    <li><Link to={"/"}><span data-hover="Menu 3">Menu 3</span></Link></li>
                    <li><Link to={"/"}><span data-hover="Menu 4">Menu 4</span></Link></li>
                </ul>
            </div>
            <div className="my-interests">
                {interests &&  interests.slice(0, loadCount).map((item, value) => <InterestsItem key={value} interest={item} editButton={editButton} editButtonItem={handleChange} />)}
                {addButton ? <></> : <></>}
            </div>

            <div className="interests-load-more">
                <span onClick={showMore}>{!loadMore ? `Show all ${interests.length - 2} top voices` : `Show the first 2 tov voices`}<FontAwesomeIcon icon={faChevronRight} /></span>
            </div>
        </section>
    )
}

export default InterestsComponent;
