import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './analytics.scss';
import {faEye, faUsers} from "@fortawesome/free-solid-svg-icons";
import {faSearchengin} from "@fortawesome/free-brands-svg-icons";

const AnalyticsComponent = () => {
    return (
        <section className="analytics-section">
            <span className="title-analytic">Analytics</span>
            <span className="private"><FontAwesomeIcon icon={faEye} /> Private to you</span>

            <div className="analytics-content">
                <div className="analytic-item">
                  <FontAwesomeIcon icon={faUsers} />
                  <div className="content-item-analytic">
                      <span className="content-item-analytic-title">19 profile view</span>
                      <span className="content-item-analytic-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      </span>
                  </div>
                </div>

                <div className="analytic-item">
                    <div className="image">
                        <img src={require('@images/analytic-icon.png')} alt="200 post impressions" />
                    </div>
                    <div className="content-item-analytic">
                        <span className="content-item-analytic-title">200 post impressions</span>
                        <span className="content-item-analytic-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      </span>
                    </div>
                </div>

                <div className="analytic-item">
                    <FontAwesomeIcon icon={faSearchengin} />
                    <div className="content-item-analytic">
                        <span className="content-item-analytic-title">13 search appearances</span>
                        <span className="content-item-analytic-description">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                      </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AnalyticsComponent;
