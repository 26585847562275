import {createSlice} from "@reduxjs/toolkit";
import {addNewCareerActions, deleteCareerActions, editCareerActions} from "../actions/careerApi";

const careerReducer = createSlice({
    name: 'career',
    initialState: {
        career: [],
        error: null,
        loading: false,
    },
    reducers: {
        addAllCareer: (state, action) => {
            state.career = action.payload;
        },
        editCareerItem: (state,{payload}) => {
            state.career.map(item => {
                if(item.Id === payload.Id) {
                    item.TitleCompany = payload.TitleCompany;
                    item.Job = payload.Job
                    item.Location = payload.Location;
                    item.StartDate = payload.StartDate;
                    item.EndDate = payload.EndDate;
                    item.Type = payload.Type;
                    item.Logo = payload.Logo;
                    item.Description = payload.Description;
                }
            });
        },
        addNewCareer: (state, action) => {
            state.career = [...state.career, {...action.payload}]
        },
        removeCareer: (state, action) => {
            state.career = state.career.filter(item => item.Id !== action.payload.Id)
        }
    },
    extraReducers: (builder) => {
       builder
           .addCase(addNewCareerActions.pending, (state, {payload}) => {
               state.error = null;
               state.loading = true;
           })

           .addCase(addNewCareerActions.fulfilled,(state, {payload}) => {
               if(payload.status === false) {
                   state.loading = false;
                   state.error = payload.message;
               } else {
                   state.loading = false;
                   state.error = null;
                   state.career = [...state.career, {...payload}]
               }
           })

           .addCase(addNewCareerActions.rejected, (state, {payload }) => {
               state.loading = false;
               state.error = payload;
           })

           .addCase(editCareerActions.pending, (state, {payload}) => {
               state.error = null;
               state.loading = true;
           })

           .addCase(editCareerActions.fulfilled,(state, {payload}) => {
               if(payload.status === false) {
                   state.loading = false;
                   state.error = payload.message;
               } else {
                   state.career.map(item => {
                       if(item.Id === payload.Id) {
                           item.TitleCompany = payload.TitleCompany;
                           item.Job = payload.Job
                           item.Location = payload.Location;
                           item.StartDate = payload.StartDate;
                           item.EndDate = payload.EndDate;
                           item.Type = payload.Type;
                           item.Logo = payload.Logo;
                           item.Description = payload.Description;
                       }
                   });
                   state.loading = false;
                   state.error = null;
               }
           })

           .addCase(editCareerActions.rejected, (state, {payload }) => {
               state.loading = false;
               state.error = payload;
           })

           .addCase(deleteCareerActions.pending, (state, {payload}) => {
               state.loading = true;
               state.error = null;
           })

           .addCase(deleteCareerActions.fulfilled, (state, {payload}) => {
               if(payload.status === true){
                   state.loading = false;
                   state.error = null;
               } else {
                   state.loading = false;
                   state.error = payload.message;
               }
           })

           .addCase(deleteCareerActions.rejected, (state, {payload}) => {
               state.loading = false;
           })

    }
});

export default careerReducer.reducer;
export const { addAllCareer, editCareerItem, addNewCareer, removeCareer } = careerReducer.actions
