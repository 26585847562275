import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBookOpenReader, faChartSimple, faDollarSign,
  faFile,
  faFingerprint, faFolderPlus, faGear, faLanguage,
  faNewspaper, faRightFromBracket,
  faStar, faUserGear, faUserGraduate, faUserPen, faUsers
} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../redux/reducer/authReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import ActionButton from "../Filters/ActionsButton/ActionButton/ActionButton";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";

const SidebarAdmin = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState('');

  const logoutHandle = () => {
    dispatch(logout());
    navigate("/login");
  }

    return (
        <>
          <div className="sidebar admin">
            <div className="sidebar__profile">
              <div className="sidebar__profile__user">
                <div className="profile-image">
                  <img
                      src={user.Image ?? 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'}
                      className="border-1 shadow-sm border-white border- rounded-full w-full h-full object-cover"
                      alt={user.Name + ' ' + user.Surname}/>
                </div>
                <span className="block user_name">Slogan</span>
                <span className="block country">{user.Address.Country.Name}</span>
              </div>


              <div className="sidebar__profile__data">
                <span>Angajatori: 12</span>
                <span>Joburi: 112</span>
                <span>Traineri: 35</span>
                <span>Cursuri: 2</span>
                <span>Candidați: 35</span>
                <span>CV-uri: 12</span>
              </div>
            </div>

            <div className="sidebar__menu">
              <div className="sidebar__menu__items">
                <NavLink to={"/dashboard/employers"} className="item-menu" onClick={() => setPage('')}><span><FontAwesomeIcon icon={faUsers}/> Angajatori</span></NavLink>
                <NavLink to={"/dashboard/blog"} className="item-menu" onClick={() => setPage('blog')}><span><FontAwesomeIcon icon={faNewspaper}/> Articole</span></NavLink>
                <NavLink to={"/dashboard/opportunity"} className="item-menu" onClick={() => setPage('opportunity')}><span><FontAwesomeIcon icon={faNewspaper}/> Comunicate</span></NavLink>
                <NavLink to={"/dashboard/advisers"} className="item-menu" onClick={() => setPage('advisers')}><span><FontAwesomeIcon icon={faUsers}/> Consultanti</span></NavLink>
                <NavLink to={"/dashboard/user/profile"} className="item-menu"><span><FontAwesomeIcon icon={faFingerprint}/> Informație personală</span></NavLink>
                <NavLink to={"/dashboard/job"} className="item-menu"><span><FontAwesomeIcon icon={faStar}/> Joburi</span></NavLink>
                <NavLink to={"/dashboard/trainers"} className="item-menu"><span><FontAwesomeIcon icon={faUserGraduate}/> Traineri</span></NavLink>
                <NavLink to={"/dashboard/courses"} className="item-menu"><span><FontAwesomeIcon icon={faBookOpenReader}/> Cursuri</span></NavLink>
                <NavLink to={"/dashboard/candidates"} className="item-menu"><span><FontAwesomeIcon icon={faUserPen}/> Candidati</span></NavLink>
                <NavLink to={"/dashboard/cv-lists"} className="item-menu"><span><FontAwesomeIcon icon={faFile}/>CV-uri</span></NavLink>
                <NavLink to={"/dashboard/settings"} className="item-menu"><span><FontAwesomeIcon icon={faGear}/>Setari</span></NavLink>
                <NavLink to={"/dashboard/dictionary"} className="item-menu" onClick={() => setPage('dictionary')}><span><FontAwesomeIcon icon={faLanguage}/>Dictionary</span></NavLink>
                <NavLink to={"/dashboard/home-settings"} className="item-menu"><span><FontAwesomeIcon icon={faGear}/>Home Setari</span></NavLink>
                <NavLink to={"/dashboard/how-it-work"} className="item-menu"><span><FontAwesomeIcon icon={faGear}/>Editarea How it Works?</span></NavLink>
                <NavLink to={"/dashboard/menu"} className="item-menu"><span><FontAwesomeIcon icon={faGear}/>Setting Menu</span></NavLink>
                <NavLink to={"/dashboard/initiative-company"} className="item-menu"><span><FontAwesomeIcon icon={faGear}/>Editarea Why initiative company?</span></NavLink>
                <NavLink to={"/dashboard/user/banking"} className="item-menu"><span><FontAwesomeIcon icon={faDollarSign}/>Rechizite bancare</span></NavLink>
                <NavLink to={"/dashboard/user/banking"} className="item-menu"><span><FontAwesomeIcon icon={faChartSimple}/>Statistica</span></NavLink>
                <NavLink to={"/dashboard/user/banking"} className="item-menu"><span><FontAwesomeIcon icon={faUserGear}/>Drepturi si roluri</span></NavLink>
              </div>
              <div className='item-menu item-menu-exit' onClick={() => logoutHandle()}><FontAwesomeIcon icon={faRightFromBracket}/>Exit</div>
            </div>
          </div>

          { (page === 'blog' || page === 'opportunity' || page === 'advisers') &&
            <div className="actions-button-section">
              {
                page === 'blog' && <>
                    <ActionButton text="Create Article" path={"/dashboard/blog/add"} icon={faFolderPlus} action="add" className="add" />
                    <ActionButton text="Delete Article" icon={faTrashCan} action="delete" className="remove" />
                  </>
              }
              {
                  page === 'advisers' && <>
                    <ActionButton text="Create Article" path={"/dashboard/advisers/add"} icon={faFolderPlus} action="add" className="add" />
                  </>
              }
              {
                  page === 'opportunity' && <>
                    <ActionButton text="Create Opportunity" path={"/dashboard/opportunity/add"} icon={faFolderPlus} action="add" className="add" />
                    <ActionButton text="Delete Opportunity" icon={faTrashCan} action="delete" className="remove" />
                  </>
              }
            </div>
          }
        </>
    );
}

export default SidebarAdmin;
