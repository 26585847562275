import React from 'react';
import {faEye, faXmark, faTrophy} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './takeSkill.scss';

const TakeSkillComponent = () => {
    return (
        <section className="take-skill-quiz">
           <div className="head-skill">
               <div className="head-skill-info">
                   <span className="title">Take a skill quiz</span>
                   <span className="private"><FontAwesomeIcon icon={faEye} /> Private to you</span>
               </div>
               <span className="skill-button"><FontAwesomeIcon icon={faXmark}/></span>
           </div>

           <div className="skill-content">
               <FontAwesomeIcon icon={faTrophy} />
               <div className="skill-content-info">
                   <span className="skill-title">Stand out in recruiter searches</span>
                   <span className="skill-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</span>
                   <span className="take-skill-button">Take skill quiz</span>
               </div>
           </div>
        </section>
    )
}

export default TakeSkillComponent;
