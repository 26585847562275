import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Header from './Header';
import Footer from "./Footer";
import {useDispatch, useSelector} from "react-redux";
import {userInfoActions} from "../../redux/actions/userApi";
import {categoryGetCategoriesActions} from "../../redux/actions/categoryApi";
import {getMenuActions} from "../../redux/actions/menuApi";
import "../../assets/styles/Profiles.scss";
import SidebarUser from "components/Sidebars/SidebarUser";
import SidebarAdmin from "components/Sidebars/SidebarAdmin";
import SidebarEmployer from "components/Sidebars/SidebarEmployer";
import SidebarTrainer from "components/Sidebars/SidebarTrainer";
import Loader from "components/Loader/Loader";
import {socialGetSocialMediaActions} from "../../redux/actions/socialMediaApi";


const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [className, setClassName] = useState('employee');
    const {loading, error, user, reviews, company} = useSelector(state => state.user);
    const {category} = useSelector(state => state.category);
    const experiences = useSelector(state => state.career.career);
    const educations = useSelector(state => state.education.education);
    const token = localStorage.getItem('token');
    const tokenExpire = localStorage.getItem('expireToken');

    useEffect(() => {
        dispatch(getMenuActions());
        dispatch(socialGetSocialMediaActions());
        dispatch(categoryGetCategoriesActions({expand: 'opportunities,blogs,jobs'}));

        if((token === null || token === undefined) || (error)){
            window.location.href = '/';
        } else if(user.length === 0){
            dispatch(userInfoActions(token));
        }
    }, [token, dispatch, error]);

    useEffect(() => {
        if(user.length !== 0){
            switch(user.UserType?.Id){
                case 1:
                case 2: setClassName('admin'); navigate('/dashboard/user/profile'); break;
                case 4:
                case 5:
                case 6: setClassName('trainer'); navigate('/dashboard/user/profile'); break;
                case 7: setClassName('employer'); navigate('/dashboard/about-company'); break;
                case 8: setClassName('employee'); navigate('/dashboard/user/profile'); break;
                default: setClassName('employee');
            }
        }
    },[user])


    return (
        <>
            {(loading || (user.length === 0)) && <Loader/>}
            {(!loading && (user.length !== 0)) &&
                <>
                    <Header/>
                    <main className="profile-page container">
                        <section className="profile">
                            <div className={`profile-section ${className}`}>
                                <div className="profile-content">
                                    <div className="sidebar-main-content">
                                        {className === 'admin' && <SidebarAdmin user={user}/>}
                                        {className === 'employee' && <SidebarUser user={user}/>}
                                        {className === 'employer' && <SidebarEmployer user={user} company={company}/> }
                                        {className === 'trainer' &&  <SidebarTrainer /> }
                                    </div>
                                    <div className={`content ${className}`}>
                                        <Outlet/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    <Footer category={category}/>
                </>
            }
        </>
    );
}

export default Dashboard;
